import React from 'react';
import { CSVLink } from 'react-csv';
import { getFileCSVarchive, getFileCSVtests } from '../../../model';
import { ExportButtonStyled } from './ExportButtonStyled';
import FileUploadTwoTone from '@mui/icons-material/FileUploadTwoTone';

const ExportButton = ({
  additionalData = [],
  activeClasses = [],
  fileHeader = 'class',
  btnStyle = 'default',
  callAlert = () => { },
  closeAlert = () => { },
}) => {
  const onExportCSV = () => {
    if (!activeClasses.length) {
      callAlert({
        type: false,
        text: 'Ooops, something went wrong!'
          + ' Please, select the data you want to export.',
        close: closeAlert,
        show: true,
      });
      return false;
    }
    callAlert({
      type: true,
      text: 'Export CSV Success!',
      close: closeAlert,
      show: true,
    });
    return true;
  };

  let data;
  let headers;
  if (fileHeader === 'class') {
    headers = [
      { label: 'Year', key: 'year' },
      { label: 'Class', key: 'class' },
      { label: 'Av.Score', key: 'avScore' },
      { label: 'Av.Speed', key: 'avSpeed' },
      { label: 'Teachers ( email | phone )', key: 'teachers' },
    ];
    data = getFileCSVarchive(activeClasses);
  } else if (fileHeader === 'teacher') {
    headers = [
      { label: 'TeacherName', key: 'teacherName' },
      { label: 'TeacherId', key: 'teacherId' },
      { label: 'Phone', key: 'phone' },
      { label: 'Email', key: 'email' },
      { label: 'Av.Score', key: 'stats.avScore.score' },
      { label: 'Av.Speed', key: 'stats.avStatus.speed' },
      { label: 'Classes', key: 'classesString' },
    ];
    data = activeClasses;
  } else if (fileHeader === 'tests') {
    headers = [
      { label: 'TestLabel', key: 'label' },
      { label: 'Concept', key: 'concept' },
      { label: 'Status', key: 'status' },
      { label: 'Av.Score', key: 'avScore' },
      { label: 'Av.Speed', key: 'avSpeed' },
      { label: 'Classes', key: 'classes' },
      { label: 'Teachers', key: 'teachers' },
    ];
    data = getFileCSVtests(activeClasses);
  } else if (fileHeader === 'student') {
    headers = [
      { label: 'StudentName', key: 'name' },
      { label: 'Birthday', key: 'birthday' },
      { label: 'School ID', key: 'id' },
      { label: 'Class', key: 'class' },
      { label: 'Av.Score', key: 'avScore.score' },
      { label: 'Av.Speed', key: 'avSpeed.speed' },
      { label: 'FirstParent-Name', key: 'parentsDetailInfo.firstParent.name' },
      { label: 'FirstParent-Email', key: 'parentsDetailInfo.firstParent.email' },
      { label: 'FirstParent-Phone', key: 'parentsDetailInfo.firstParent.phone' },
      { label: 'SecondParent-Name', key: 'parentsDetailInfo.secondParent.name' },
      { label: 'SecondParent-Email', key: 'parentsDetailInfo.secondParent.email' },
      { label: 'SecondParent-Phone', key: 'parentsDetailInfo.secondParent.phone' },
    ];
    data = activeClasses;
  } else if (fileHeader === 'gradebook') {
    headers = [
      { label: 'Student', key: 'name' },
      { label: 'Test', key: 'test' },
      { label: 'Score', key: 'score' },
      { label: 'Speed', key: 'speed' },
      { label: 'Concept', key: 'concept' },
    ];

    const newArr = [];

    activeClasses.forEach(item => {
      for (let i = 0; i < activeClasses[0].tests.length; i++) {
        const newDataCSV = {
          name: item.name,
          concept: additionalData[i].concept,
          test: additionalData[i].testName
        };

        if (item.tests[i].absent) {
          newDataCSV.score = 'Absent'
          newDataCSV.speed = 'Absent'
        } else if (item.tests[i].testId === null) {
          newDataCSV.score = '-'
          newDataCSV.speed = '-'
        } else {
          const time = new Date(item.tests[i].speed.speed * 1000).toISOString().slice(11, 19);
          newDataCSV.score = item.tests[i].score.score
          newDataCSV.speed = time
        }

        newArr.push(newDataCSV)
      }
    });
    data = newArr;
  }

  const csvReport = {
    filename: 'Report.csv',
    headers: headers,
    data: data,
  };

  return (
    <>
      <ExportButtonStyled>
        <button className='ExportBtn'>
          <CSVLink
            onClick={onExportCSV}
            {...csvReport}
            className={btnStyle}
          >
            <FileUploadTwoTone />
            Export CSV
          </CSVLink>
        </button>
      </ExportButtonStyled>
    </>
  );
};

export default ExportButton;
