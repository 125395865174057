import styled from 'styled-components';

export const StyledNotFoundPage = styled.div`
.NotFoundPageText{
  margin-top: 24px;
  text-align: center;
  font-family: Rubik;
  font-size: 16px;
  line-height: 22px;
  color: #777777;
}
.NotFoundPageImgWrapper{
  margin: 0 auto;
  padding-bottom: 60px;
}
.NotFoundPageImage{
  display:block;
  width: 50%;
  margin: 38px auto;
}
`