import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AppProvider } from './context/AppContext';

import { StudentAnalyticsPage } from './pages/StudentAnalyticPage/StudentAnalyticPage';
import { AnalyticsPage } from './pages/AnalyticsPage/AnalyticsPage';
import { ClassGradeBookPage } from './pages/ClassGradeBookPage/ClassGradeBookPage';
import { SignInPage } from './pages/auth/SignInPage/SignInPage';
import { RecoverPasswordPage } from './pages/auth/RecoverPasswordPage/RecoverPasswordPage';
import { SignUpPage } from './pages/auth/SignUpPage/SignUpPage';
import { GradeBookPage } from './pages/GradeBookPage/GradeBookPage';
import { CreateNewPasswordPage } from './pages/auth/CreateNewPasswordPage/CreateNewPasswordPage';
import { Onboarding } from './pages/Onboarding/Onboarding';
import { ArchivePage } from './pages/ArchivePage/ArchivePage';
import { ClassArchivePage } from './pages/ClassArchivePage/ClassArchivePage';
import { StudentsPage } from './pages/StudentsPage/StudentsPage';
import { ParentPage } from './pages/ParentPage/ParentPage';
import { config } from './config';
import { routes } from './routes';
import { TestsPage } from './pages/TestsPage';
import { TeachersPage } from './pages/TeachersPage/TeachersPage';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
// import { boolean } from 'io-ts';

require('typeface-rubik');

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});



function App() {

  return (
    <div className="wrapper">
      <QueryClientProvider client={queryClient}>
        {config.ENV === 'development' && (
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        )}
        <BrowserRouter>
          <AppProvider>
            <Switch>
              <Route path="/" exact component={GradeBookPage} />
              <Route path={routes.signUp()} component={SignUpPage} />
              <Route path={routes.signIn()} component={SignInPage} />
              <Route path="/recoverPassword" component={RecoverPasswordPage} />
              <Route path="/createNewPassword" component={CreateNewPasswordPage} />

              <Route path="/onboarding" component={Onboarding} />
             
              <Route path="/parent" component={ParentPage} />

              <Route path={routes.analytics()} exact component={AnalyticsPage} />
              <Route path="/analytics/student" component={StudentAnalyticsPage} />

              <Route path={routes.gradebooks()} exact component={GradeBookPage} />
              <Route path="/gradebook/class" component={ClassGradeBookPage} />

              <Route path={routes.tests()} exact component={TestsPage} />

              <Route path={routes.students()} exact component={StudentsPage} />

              <Route path={routes.teachers()} exact component={TeachersPage} />

              <Route path={routes.archive()} exact component={ArchivePage} />
              <Route path="/archive/class" component={ClassArchivePage} />

              <Route path="*" component={NotFoundPage} />
            </Switch>
          </AppProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </div>
  );
}

export default App;
