import React, { useCallback, useContext, useEffect, useState } from 'react';
import { TestsService } from './TestsService';
import { AppContext } from '../../context/AppContext';
import { useSearchFilter } from '../../hooks/searchFilter';
import { LoaderFullBook } from '../../components/Loaders/LoaderFullBook/LoaderFullBook';
import ErrorBoundary from '../../components/ErrorBaundary';
import NoTestsContent from '../../components/TestsComponents/NoTestsContent';
import NoSearchResults from '../../components/NoSearchResults';
import { InternalPageContainer } from '../../components/InternalPageContainer/InternalPageContainer';
import { FiltersContainer } from '../../components/FiltersContainer/FiltersContainer';
import TestsFilters from '../../components/TestsComponents/TestsFilters';
import TestsHeader from '../../components/TestsComponents/TestsHeader';
import TestsTable from '../../components/TestsComponents/TestsTable';
import AddTestModal from '../../components/TestsComponents/TestModals/AddTestModal';
import TestsModalForArchiving from '../../components/TestsComponents/TestsModalForArchiving';
import "./TestsPage.scss";

const service = new TestsService();

function TestsPage() {
  // for getTest
  const { currentSchoolId: schoolId } = useContext(AppContext);
  const [testsData, setTestsData] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [loader, setLoader] = useState(true);
  //for selectedTests
  const [selectedTests, setSelectedTests] = useState([]);
  // for modals
  const [rerender, setRerender] = useState(false);
  const [modalArchive, setModalArchive] = useState(false);
  const [modalUnarchive, setModalUnarchive] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const [addTestModal, setAddTestModal] = useState(false);
  // for search and filter
  const [noSearchRes, setNoSearchRes] = useState(false);
  const [filteredTestData, setFilteredTestData] = useState(testsData);
  const [resetFilters, setResetFilters] = useState(false);
  const {
    enteredSearchValue,
    setEnteredSearchValue,
    availableItems: availableTestsData,
  } = useSearchFilter(filteredTestData, 'label', 500);

  const openAddTestModal = useCallback(() => setAddTestModal(true), []);
  const closeAddTestModal = useCallback(() => setAddTestModal(false), []);
  const openArchiveModal = useCallback(() => setModalArchive(true), []);
  const closeArchiveModal = useCallback(() => setModalArchive(false), []);
  const openUnarchiveModal = useCallback(() => setModalUnarchive(true), []);
  const closeUnarchiveModal = useCallback(() => setModalUnarchive(false), []);

  const getData = useCallback(() => {
    service.getTests(schoolId)
      .then((response) => {
        setTestsData(response);
        setLoader(false);
        setHasError(false);
      })
      .catch((e) => {
        setLoader(false);
        setHasError(true);
      })
  }, [schoolId]);

  // for get Tests data
  useEffect(() => {
    setLoader(true);
    if (schoolId !== null) { // to avoid double rendering
      getData();
    }
  }, [getData, schoolId]);

  // after changing test or table data
  useEffect(() =>
    rerender ?
      (getData(), setRerender(false))
      : null, [getData, rerender]);

  // for Search
  useEffect(() => {
    availableTestsData.length > 0
      ? setNoSearchRes(false)
      : setNoSearchRes(true);
  }, [availableTestsData.length]);

  //to determine which modal to open for archiving or unarchiving tests
  useEffect(() => {
    const notArchivedTests = selectedTests
      .filter((test) =>
        test.isArchive === 0);

    if (notArchivedTests.length > 0
      && selectedTests.length !== 0) {
      setModalStatus(false);
    }

    if (notArchivedTests.length === 0
      && selectedTests.length !== 0) {
      setModalStatus(true);
    }
  }, [selectedTests]);

  const hasTestsContent = testsData.length > 0;

  // get ids for Archive/Unarchive test(s)
  const testsForArchiving = selectedTests
    .map((test) => test.id);

  return (
    <>
      <InternalPageContainer />
      <div className="testsPage">
        {!loader ? (
          <ErrorBoundary error={hasError}>
            <FiltersContainer>
              {hasTestsContent ? (
                <TestsFilters
                  testsData={testsData}
                  setFilteredTestData={setFilteredTestData}
                  reset={resetFilters}
                  setReset={setResetFilters}
                />) : null}
            </FiltersContainer>
            <TestsHeader
              hasTestsContent={hasTestsContent}
              testsData={availableTestsData}
              searchValue={enteredSearchValue}
              setSearchValue={setEnteredSearchValue}
              selectedTests={selectedTests}
              setSelectedTests={setSelectedTests}
              modalStatus={modalStatus}
              openArchiveModal={openArchiveModal}
              openUnarchiveModal={openUnarchiveModal}
              openAddTestModal={openAddTestModal}
            />
            <NoTestsContent show={!hasTestsContent}>
              <div className="inner">
                <NoSearchResults
                  show={noSearchRes}
                  searchValue={enteredSearchValue}
                  backFn={() => {
                    setNoSearchRes(false);
                    setEnteredSearchValue('');
                    setResetFilters(true);
                  }}
                  delay={550}>
                  <TestsTable
                    data={availableTestsData}
                    selectedTests={selectedTests}
                    setSelectedTests={setSelectedTests}
                    setRerender={setRerender}
                  />
                </NoSearchResults>
              </div>
            </NoTestsContent>
          </ErrorBoundary>) : (
          <LoaderFullBook />)}
        {(modalArchive || modalUnarchive) && (
          <TestsModalForArchiving
            archiveData={testsForArchiving}
            modalArchive={modalArchive}
            close={modalArchive
              ? closeArchiveModal
              : closeUnarchiveModal
            }
            callRerender={setRerender}
            setSelectedTests={setSelectedTests}
          />)}
        {addTestModal && (
          <AddTestModal
            closeModal={closeAddTestModal}
            callRerender={setRerender}
          />)}
      </div>
    </>
  );
}

export default TestsPage;
