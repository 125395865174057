import React, { useState } from 'react';
import { ModalWrapper } from '../../../Modal/ModalWrapper/ModalWrapper';
import { ModalHeader } from '../../../Modal/ModalHeader/ModalHeader';
import { ModalFooter } from '../../../Modal/ModalFooter/ModalFooter';
import { ModalPrimaryButton } from '../../../Modal/buttons/ModalPrimaryButton/ModalPrimaryButton';
import { ModalSecondaryButton } from '../../../Modal/buttons/ModalSecondaryButton/ModalSecondaryButton';
import { Controller, useForm } from 'react-hook-form';
import { OutlinedTextField } from '../../../OutlinedTextField/OutlinedTextField';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { addNewSchool } from '../../../../services/schools';
import styles from "./AddSchoolModal.module.scss"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const AddSchoolModal = ({
  onClose,
  schools,
  updateSchools,
  setShowLoader,
  // setShowAlert,
  callAlert,
  closeAlert
}) => {
  const [newSchools, setNewSchools] = useState({});

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    //  mode: 'onChange',
    mode: 'onBlur',
  });

  const newSchool = {
    // userId:
    name: newSchools.schoolName,
    address: newSchools?.schoolAddress ?? '',
  };

  const onSubmit = async () => {
    console.log('schools', schools)
    console.log('newSchools', newSchools);
    if (!newSchools.schoolName || newSchools.schoolName === undefined) {
      callAlert({
        type: true,
        text: 'Enter School Name',
        close: closeAlert,
        show: true
      });
      return;
    } else if
      (schools.find(school => school.Name === newSchools.schoolName
      )) {
       callAlert({
         type: true,
         text: 'This school name already exists. Enter anoter school name.',
         close: closeAlert,
         show: true,
       });
      // alert('This school name already exists. Enter anoter school name.');
      return;
    } else {
      setShowLoader(true);
      addNewSchool(newSchool)
        .then((response) => {
          // console.log("addNewSchool-response", response);
          updateSchools([
            ...schools,
            {
              Name: newSchools.schoolName,
              Address: newSchools.schoolAddress,
              ClassCount: 0,
              TeacherCount: 0,
              TestCount: 0,
              StudentCount: 0,
              Id: response.data.id,
            },
          ]);
          setShowLoader(false);
        })
        .catch(function (error) {
          console.log('error', error);
        });
      onClose();
    }
  };

  const onChange = ({ target }) => {
    const { name, value } = target;
    setNewSchools((prevState) => ({ ...prevState, [name]: value }));
    // console.log('newSchoolsOnChange', newSchools)
  };

  return (
    <ModalWrapper>
      <form
        // className={styles.fieldSet}
        className={styles.AddSchoolModal_wrapper}
        onSubmit={handleSubmit(onSubmit)}
      >
        <ModalHeader header="Add School" onClose={onClose} />
        {/* <div className={styles.AddSchoolModal_InputWrapper}> */}
        <div className={styles.inputWrapper}>
          {/* <Controller
            control={control}
            name="schoolName"
            rules={{ required: true, minLength: 2 }}
            render={({ field }) => ( */}
          <OutlinedTextField
            {...register(
              'schoolName',
              {
                required: 'Required Field',
                minLength: 1,
              }
            )}
            fullWidth
            // variant="outlined"
            // margin="normal"
            // id="schoolName"
            // color="primary"
            // name="schoolName"
            label="School name"
            placeholder={'Enter School Name'}
            style={{ width: 302, margin: 0 }}
            autoComplete="off"
            onChange={onChange}
            // error={Boolean(errors?.schoolName)}
            // helperText={errors.schoolName?.message}
            // {...field}
            // required
          >
            {/* )} */}
            {/* /> */}
          </OutlinedTextField>
          {/* <div className={styles.AddSchoolModal_errorInInput}> */}
          <div className={styles.textHelper}>
            {errors?.schoolName && (
              <>
                <span>{errors?.schoolName?.message || 'Required Field'}</span>
                <InfoOutlinedIcon />
              </>
            )}
          </div>
        </div>
        {/* {errors.schoolName && (
            <span className={styles.AddSchoolModal_errorInInput}>
              School name is required <ReportProblemOutlinedIcon style={{ cursor: 'default' }} />
            </span>
          )} */}
        <div className={styles.inputWrapper}>
          <Controller
            control={control}
            name="schoolAddress"
            // rules={{ }}
            render={({ field }) => (
              <OutlinedTextField
                variant="outlined"
                margin="normal"
                id="schoolAddress"
                color="primary"
                label="Address (optional)"
                name="schoolAddress"
                style={{ width: 302, margin: 0, marginTop: 26 }}
                autoComplete="off"
                // error={Boolean(errors?.schoolAddress)}
                // helperText={errors.schoolAddress?.message}
                {...field}
                onChange={onChange}
              />
            )}
          />
        </div>
        <ModalFooter>
          <ModalSecondaryButton onClick={onClose}>Cancel</ModalSecondaryButton>
          <ModalPrimaryButton onClick={onSubmit}>Add</ModalPrimaryButton>
        </ModalFooter>
      </form>
    </ModalWrapper>
  );
};

export default AddSchoolModal;


// ===== SCHOOL ENDPOINTS TO BACKEND =====
// Add school
// base_url/api/school
// type POST
// request: {
// 	userId: 0,
// 	name: '',
// 	address: ''
// }
// response: {
// 	id: 0 //School ID
// }

