import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { LoaderFullBook } from '../../../../components/Loaders/LoaderFullBook/LoaderFullBook';
import { AppContext } from '../../../../context/AppContext';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import styles from './NameSelect.module.scss';


export const NameSelect = () => {
  const { showLoader,
          setShowLoader, 
          students,       
          setStudents,
          currentStudentId,
          setCurrentStudentId,
    updateCurrentStudentId,
    currentStudent } = useContext(AppContext);

  const [mouseOverStudentId, setMouseOverStudentId] = useState(null);

  const mouseOverStudent = useMemo(
    () => students.find((school) => school.Id === mouseOverStudentId),
    [mouseOverStudentId]
  );

  // console.log('currentSchool', currentSchool);
  // console.log('currentSchoolId', currentSchoolId);
  // console.log('mouseOverSchool', mouseOverSchool);
  // console.log('mouseOverStudentId', mouseOverStudentId);

  const handleStudentMouseOver = useCallback(
    (studentId) => () => setMouseOverStudentId(studentId),
    []
  );
  const handleSchoolMouseOut = useCallback(() => setMouseOverStudentId(null), []);

  const handleChooseStudent = (studentId) => () => {
    console.log('handleChooseStudent');
    students.find(({ Id }) => Id === studentId);
    setCurrentStudentId(studentId);
    // localStorage.setItem('currentStudentId', JSON.stringify(studentId));
  };

  return (
    <>
      {showLoader && <LoaderFullBook />}
      <div
        // id="SchoolSelectContainer"
        className={styles.container}
        onMouseLeave={handleSchoolMouseOut}
      >
        <div className={styles.currentSchoolName}>
          {currentStudent?.Name ? currentStudent.Name : students[0]?.Name}{' '}
          {currentStudent?.LastName ? currentStudent.LastName : students[0]?.LastName}
        </div>
        <div className={styles.optionsContainer}></div>

        {students.map(({ Id = 1, Name, LastName }) => (
          <div
            key={Id}
            onClick={handleChooseStudent(Id)}
            onMouseOver={handleStudentMouseOver(Id)}
            className={styles.option}
          >
            {Name} {LastName}
          </div>
        ))}

        <ArrowDropDownIcon color="disabled" fontSize="large" className={styles.arrowDown} />

        {/* {mouseOverSchool && (
          <SchoolInfoPopover
            student={mouseOverStudent}
            handleEditSchool={handleEditSchoolModal}
            handleRemoveSchool={handleRemoveSchoolModal}
          />
        )} */}
      </div>
    </>
  );
};


