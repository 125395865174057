import React from 'react';
import SubHeaderCollapse from '../../SubHeaderCollapse';
import { TableHeadLine } from '../../TableHeadLine/TableHeadLine';
import { PageHead } from '../../PageHead/PageHead';

function TestsHeader({
  hasTestsContent,
  testsData,
  searchValue,
  setSearchValue,
  selectedTests,
  setSelectedTests,
  modalStatus,
  openArchiveModal,
  openUnarchiveModal,
  openAddTestModal,
}) {

  return (
    <>
      {hasTestsContent ? (
        selectedTests.length > 0 ? (
          <SubHeaderCollapse
            activeCounter={selectedTests}
            setActiveCounter={setSelectedTests}
            selectedType={modalStatus}
            dataType="tests"
            openModalArchive={openArchiveModal}
            openModalUnArchive={openUnarchiveModal}
          />) : (
          <TableHeadLine
            headlineType="tests"
            dataType="tests"
            tableRowData={testsData}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            config={{ headline: 'headlineInputTestsWrapper' }}
            openModal={openAddTestModal}
          />)) : (
        <PageHead
          shouldRenderActionButton={true}
          actionButtonText="Add test"
          onActionButtonClick={openAddTestModal}
          title='tests'
        />)
      }
    </>
  );
}

export default TestsHeader;
