import { config } from '../../config';

export class ArchiveService {
  _apiBase = `${config.API_URL}/api/gradebook/archive/list?schoolId=`;
  _apiUnarchive = `${config.API_URL}/api/gradebook/unarchive`;

  getArchive = async (schoolId: number | string ) => {
    const response = await fetch(`${this._apiBase}${schoolId}`);

    if (!response.ok) {
      throw new Error(`Could not fetch ${this._apiBase}, received ${response.status}`);
    }

    return await response.json();
  };

  removeFromArchive = async (data: Array<number> = []) => {
    const response = await fetch(this._apiUnarchive, {
      method: 'POST',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
      body: JSON.stringify({
        ids: data,
      }),
    });

    if (!response.ok) {
      throw new Error(`Could not fetch ${this._apiUnarchive}, received ${response.status}`);
    }
  };
}
