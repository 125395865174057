import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { config } from '../config';

interface BuildApiClient {
  baseUrl?: string;
  headers?: Record<string, string | null>;
  onError?: (error: any, apiClient: AxiosInstance) => any;
}

const buildApiClient = ({ baseUrl, headers = {}, onError }: BuildApiClient) => {
  const apiClient = axios.create({
    baseURL: baseUrl,
  });

  const requestInterceptor: (config: AxiosRequestConfig) => AxiosRequestConfig = (config) => {
    config.headers = { ...config.headers, ...headers };

    return config;
  };

  const responseErrorInterceptor = (err: any) => {
    if (typeof onError === 'function') {
      return onError(err, apiClient);
    }

    return Promise.reject(err);
  };

  apiClient.interceptors.request.use(requestInterceptor);
  apiClient.interceptors.response.use((response) => response, responseErrorInterceptor);

  return apiClient;
};

export const apiClient = buildApiClient({
  baseUrl: config.API_URL,
});

export const stagingApiClient = buildApiClient({
  baseUrl: config.STAGING_API_URL,
});
