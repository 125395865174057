export const getSearchIndicator = (data, searchValue) => {
  const indicator = !!Object.values(data)
    .flatMap((obj) => Object.values(obj))
    .flatMap((obj) => Object.values(obj))
    .filter(
      (obj) =>
        obj.class.toLowerCase().includes(searchValue.toLowerCase())
        || obj.teachers.filter((teacher) =>
          teacher.name.toLowerCase().includes(searchValue.toLowerCase()))
          .length
    ).length;

  return indicator;
};
