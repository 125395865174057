export const getFileCSVarchive = (data) => {
  return data.map((item) => ({
    year: item.year,
    class: item.class,
    avScore: item.avScore?.score,
    avSpeed: item.avSpeed?.speed,
    teachers: item.teachers
      .map((teacher) => ` ${teacher.name} ( ${teacher.email} | ${teacher.phone} )`),
  }));
};
