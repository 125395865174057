import React, {useContext} from 'react';
import HeaderProfile from "../../../../components/HeaderProfile/HeaderProfile";
import { SchoolSelect } from '../../../../components/SchoolSelect/SchoolSelect';
import { WidthLimiter } from "../../../../components/WidthLimiter/WidthLimiter";
import imgHeaderLogo from '../../../../images/LOGO-header.png';
import { NameSelect } from '../NameSelect/NameSelect';
import './ParentHeader.scss'

const ParentHeader = () => {

  return (
      <div className="headerWrapper">
      <WidthLimiter>
        <header className="header">
          <div className="headerLogo_wrapper">
            <img className="imgLogo" src={imgHeaderLogo} alt="" />
            <SchoolSelect />
            <NameSelect />
          </div>
             {/* <div className="navBar">
               select
              </div> */}
          <HeaderProfile/>
        </header>
      </WidthLimiter>
    </div>
  );
}

export default ParentHeader;