import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  IconButtonCont: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  IconButtonFirstCont: {
    width: '40px',
    height: '40px',
    background: 'rgba(242, 242, 242, 1)',
    cursor: `pointer`,
  },
  IconButtonSecondCont: {
    width: '40px',
    height: '40px',
  },
  IconButtonThirdCont: {
    width: '32px',
    height: '32px',
  },
  IconButtonFourthCont: {
    width: '32px',
    height: '32px',
    background: 'rgba(242, 242, 242, 1)',
    cursor: `pointer`,
  },

  IconButtonFirstContDisabled: {
    width: '40px',
    height: '40px',
    background: `rgba(255, 255, 255, 1)`,
    cursor: `pointer`,
  },
  IconButtonSecondContDisabled: {
    width: '40px',
    height: '40px',
  },
  IconButtonThirdContDisabled: {
    width: '32px',
    height: '32px',
  },
  IconButtonFourthContDisabled: {
    width: '32px',
    height: '32px',
    background: 'rgba(242, 242, 242, 1)',
    cursor: `pointer`,
  },

  IconButtonFirst: {
    color: `rgba(192, 192, 192, 1)`,
    backgroundColor: `rgba(0, 0, 0, 0)`,
    fontSize: `24px`,
    '&:hover': {
      color: `rgba(119, 119, 119, 1)`,
      backgroundColor: `rgba(0, 0, 0, 0)`,
      cursor: `pointer`,
    },
  },

  IconButtonSecond: {
    color: `rgba(192, 192, 192, 1)`,
    backgroundColor: `rgba(0, 0, 0, 0)`,
    fontSize: `24px`,
    '&:hover': {
      color: `rgba(119, 119, 119, 1)`,
      backgroundColor: `rgba(0, 0, 0, 0)`,
      cursor: `pointer`,
    },
  },
  IconButtonThird: {
    color: `rgba(192, 192, 192, 1)`,
    backgroundColor: `rgba(0, 0, 0, 0)`,
    fontSize: `24px`,
    '&:hover': {
      color: `rgba(119, 119, 119, 1)`,
      backgroundColor: `rgba(0, 0, 0, 0)`,
      cursor: `pointer`,
    },
  },
  IconButtonFourth: {
    color: `rgba(192, 192, 192, 1)`,
    backgroundColor: `rgba(0, 0, 0, 0)`,
    fontSize: `16px`,
    '&:hover': {
      color: `rgba(119, 119, 119, 1)`,
      backgroundColor: `rgba(0, 0, 0, 0)`,
      cursor: `pointer`,
    },
  },

  IconButtonFirstDisable: {
    color: `rgba(242, 242, 242, 1)`,
    backgroundColor: `rgba(0, 0, 0, 0)`,
    fontSize: `23px`,
    '&:hover': {
      color: `rgba(242, 242, 242, 1)`,
      backgroundColor: `rgba(0, 0, 0, 0)`,
      cursor: `pointer`,
    },
  },

  IconButtonSecondDisable: {
    color: `rgba(242, 242, 242, 1)`,
    backgroundColor: `rgba(0, 0, 0, 0)`,
    fontSize: `23px`,
    '&:hover': {
      color: `rgba(242, 242, 242, 1)`,
      backgroundColor: `rgba(0, 0, 0, 0)`,
      cursor: `pointer`,
    },
  },
  IconButtonThirdDisable: {
    color: `rgba(242, 242, 242, 1)`,
    backgroundColor: `rgba(0, 0, 0, 0)`,
    fontSize: `16px`,
    '&:hover': {
      color: `rgba(242, 242, 242, 1)`,
      backgroundColor: `rgba(0, 0, 0, 0)`,
      cursor: `pointer`,
    },
  },
  IconButtonFourthDisable: {
    color: `rgba(192, 192, 192, 1)`,
    backgroundColor: `rgba(0, 0, 0, 0)`,
    fontSize: `16px`,
    '&:hover': {
      backgroundColor: `rgba(0, 0, 0, 0)`,
      cursor: `pointer`,
    },
  },
});
