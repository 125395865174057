import { useEffect, useState, useCallback } from "react";
import { LoaderFullBook } from "../../../components/Loaders/LoaderFullBook/LoaderFullBook";
import NoSearchResults from "../../../components/NoSearchResults";
import NoSearchResultsIndicator from "../../../components/NoSearchResults/NoSearchResultsIndicator";
import SubHeaderCollapse from "../../../components/SubHeaderCollapse";
import { Table } from "../../../components/Table/Table";
import { TableHeadLine } from "../../../components/TableHeadLine/TableHeadLine";
import TeachersModalArchive from "../TeachersModalInfo/TeacherModalArchive/TeacherModalArchive";


const TeachersContent = ({
  setShowAlert,
  sortByLetter,
  sortByScore,
  scoreSortDir,
  tableHeadForTeachers,
  typeTableRow,
  openModalAdd,
  openModalEdit,
  fillDefaultEditValues,
  tableData,
  searchValue,
  backFn,
  submitForm,
  sortByStatus,
  statusSortDir,
  callAlert,
  noResultPage,
  setSearchValue,
  letterSortDir,
  closeAlert }) => {
  const [selectedTeacher, setSelectedTeacher] = useState([]);
  const [selectedTeacherType, setSelectedTeacherType] = useState(2);
  const [isSelectAll, setIsSelectedAll] = useState(false);

  useEffect(() => {
    setSelectedTeacherType(selectedTeacher.length ? selectedTeacher[0].isArchive : 2);
  }, [selectedTeacher])

  const openSelectMenu = (teacher, operation) => {
    teacher.classesString = Object.values(teacher.classes).join(', ');

    if (operation) {
      setSelectedTeacher(prev => {
        const newArr = [];
        newArr.push(...prev);
        const index = newArr.findIndex(item => {
          if (item.teacherId === teacher.teacherId) {
            return true;
          }
          return false;
        });
        newArr.splice(index, 1);
        return newArr
      });
    } else {
      setSelectedTeacher(prev => {
        const newArr = [];
        newArr.push(...prev, teacher);
        return newArr
      });
    }
  }

  const selectAll = (operation) => {
    if (operation) {
      setSelectedTeacher([]);
      setIsSelectedAll(false);
    } else {
      setIsSelectedAll(true);
      setSelectedTeacher(Array.of(...tableData));
    }
  }

  const [modalArchive, setOpenModalArchive] = useState(false);
  const [modalUnArchive, setModalUnArchive] = useState(false);

  const openModalArchive = useCallback(() => setOpenModalArchive(true), []);
  const closeModalArchive = useCallback(() => setOpenModalArchive(false), []);

  const closeModalUnArchive = useCallback(() => {
    setModalUnArchive(false);
    setOpenModalArchive(false);
    setSelectedTeacher([]);
  }, []);
  const openModalUnArchive = useCallback((teacher) => {
    if (teacher?.teacherId) {
      const newArr = []
      newArr.push(teacher)
      setSelectedTeacher(newArr);
    }
    setModalUnArchive(true);
    setOpenModalArchive(true);
  }, []);


  return (
    <>
      {selectedTeacher.length > 0 ?
        (<SubHeaderCollapse
          selectedType={selectedTeacherType}
          activeCounter={selectedTeacher}
          setActiveCounter={setSelectedTeacher}
          dataType='teacher'
          openModalUnArchive={openModalUnArchive}
          openModalArchive={openModalArchive} />)
        : (<TableHeadLine
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          handleOnClickDownloadCsv={undefined}
          openModal={openModalAdd}
          tableRowData={tableData}
          dataType={'teacher'}
          config={{
            btnType: '2',
            btnText: 'Export CSV',
            btnIcon: 'FileUploadTwoTone',
            headline: 'headlineInputTeachersWrapper'
          }}
        />)}

      {noResultPage ?
        (
          <div className="noResultWrapper">
            <NoSearchResultsIndicator
              searchValue={searchValue}
              backFn={backFn} />
          </div>
        ) : (
          <Table
            setIsSelectedAll={setIsSelectedAll}
            sortByStatus={sortByStatus}
            statusSortDir={statusSortDir}
            scoreSortDir={scoreSortDir}
            letterSortDir={letterSortDir}
            sortByScore={sortByScore}
            sortByLetter={sortByLetter}
            typeTableRow={typeTableRow}
            typeTableHead={tableHeadForTeachers}
            tableRowData={tableData}
            forPage={undefined}
            openModal={openModalEdit}
            openSelectMenu={openSelectMenu}
            selectedTeacher={selectedTeacher}
            selectAll={selectAll}
            fillDefaultEditValues={fillDefaultEditValues}
            openModalUnArchive={openModalUnArchive}
            selectedTeacherType={selectedTeacherType}
            isSelectAll={isSelectAll}
            callAlert={callAlert}
            closeAlert={closeAlert}
          />
        )
      }


      {modalArchive && (
        <TeachersModalArchive
          setShowAlert={setShowAlert}
          submitForm={submitForm}
          unArchive={modalUnArchive}
          selectedTeacher={selectedTeacher}
          close={modalUnArchive ? closeModalUnArchive : closeModalArchive}
          setSelectedTeacher={setSelectedTeacher} />
      )}
    </>
  );
}

export default TeachersContent;