import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import ButtonText from '../../../../../components/buttons/buttonText/ButtonText';
import { ModalWrapper } from '../../../../../components/Modal/ModalWrapper/ModalWrapper';
import { ModalContainer } from '../../../../../components/Modal/ModalContainer/ModalContainer';
import { ModalHeader } from '../../../../../components/Modal/ModalHeader/ModalHeader';
import { ModalFooter } from '../../../../../components/Modal/ModalFooter/ModalFooter';
import { ModalSecondaryButton } from '../../../../../components/Modal/buttons/ModalSecondaryButton/ModalSecondaryButton';
import { ModalPrimaryButton } from '../../../../../components/Modal/buttons/ModalPrimaryButton/ModalPrimaryButton';
import { LoaderFullBook } from '../../../../../components/Loaders/LoaderFullBook/LoaderFullBook';
import { SuccessfulEditClass } from './SuccessfulEditClass/SuccessfulEditClass';
import { OutlinedTextField } from '../../../../../components/OutlinedTextField/OutlinedTextField';
import { AppContext } from '../../../../../context/AppContext';
import { config } from '../../../../../config';
import './EditClassModal.scss';
import { Box } from '@material-ui/core';

const icon = <CheckBoxOutlineBlankIcon fontSize="medium" />;
const checkedIcon = <CheckBoxIcon fontSize="medium" style={{ color: '#A6192E' }} />;

const gradeToSelectDefault = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
// const teachrsNamesDefault = [{ teacherName: '', teacherId: '' }];
const arrOfYearsToSelect = [];
const currentYear = new Date().getFullYear();
let currentYearPlusTwo = currentYear + 2;
for (let i = 0; i < 11; i++) {
  arrOfYearsToSelect.push(currentYearPlusTwo);
  currentYearPlusTwo -= 1;
}
//   console.log('arrOfYearsToSelect', arrOfYearsToSelect)
//   console.log('currentYear', typeof currentYear)

export const EditClassModal = ({ close, objectForEditClassModal, classInfo, loader, loadDataForGradeBook }) => {
  const { showLoader, setShowLoader, setClassInfo, currentSchoolId, currentClassId } =
    useContext(AppContext);
  // console.log('classInfo', classInfo)
  
  const [teacherName, setTeacherName] = useState([]); // all teachers in school
  // const [ gradeToSelect, setGradeToSelect ] = useState( gradeToSelectDefault ) // get from server
  const [grade, setGrade] = useState(classInfo.grade);
  const [newClassName, setNewClassName] = useState(classInfo.className);
  // const [newClassName, setNewClassName] = useState(classInfo.grade + classInfo.className);
  const [selectedTeachers, setSelectedTeachers] = useState(
     classInfo?.teachers ?
    classInfo?.teachers?.reduce(
      (sum, currentTeacher) => [
        ...sum,
        { title: currentTeacher.name, value: currentTeacher.teacherId },
      ],
      []
    )
    : 0
  );
  const [year, setYear] = useState(classInfo.year);
  // const [showSelectTeacher, setShowSelectTeacher] = useState(false);

  const [successfulEditClass, setSuccessfulEditClass] = useState(null);

  // console.log('selectedTeachers', selectedTeachers);
  const newTeachersIdArr = []; // в запрос нужен TeachersId
  if (selectedTeachers !== 0) {
  selectedTeachers?.forEach((item) => {
    newTeachersIdArr.push(item.value);
  }); 
  }
  // console.log('newTeachersIdArr', newTeachersIdArr);
  

  // SET GRADE
  const handleChangeGrade = (event) => {
    setErrorInGrade(false)
    setGrade(event.target.value);
  };


  //LOAD TEACHERS
  useEffect(() => {
    setShowLoader(true);
    fetch(`${config.API_URL}/api/header/info?schoolId=${currentSchoolId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'cache-control': 'no-cache',
        credentials: 'include',
      },
    })
      .then(async (res) => await res.json())
      .then((json) => {
        const toSetTeachers = json.teachers.reduce(
          (sum, currentTeacher) => [
            ...sum,
            { title: currentTeacher.teacherName, value: currentTeacher.teacherId },
          ],
          []
        );
        setTeacherName(toSetTeachers);
        setShowLoader(false);
      });
  }, []);

  const defaultValueTeacher = [];
  if (teacherName.length > 0) {
    for (let i = 0; i < teacherName.length; i++) {
      for (let k = 0; k < selectedTeachers.length; k++) {
        if (teacherName[i].value === selectedTeachers[k].value) {
          defaultValueTeacher.push(i);
        }
      }
    }
  }
  // console.log('defaultValueTeacher', defaultValueTeacher);

  // FOR HANDLING ERRORS
  const [errorInGrade, setErrorInGrade] = useState();
  const [errorInClassName, setErrorInClassName] = useState();
  const [errorInTeacher, setErrorInTeacher] = useState();
  const [errorInYear, setErrorInYear] = useState();

  // handle send New CHANGES
  const handleSaveClassChanges = async () => {
    if (!newClassName) {
      setErrorInClassName(true);
    }
    if (selectedTeachers.length < 1) {
      setErrorInTeacher(true);
    }
    if (!newClassName || selectedTeachers.length < 1) return;

    const validateClassName = newClassName.toUpperCase().trim();

    // ========request==========
    // UPDATE GRADEBOOK
    // base_url/api/gradebook/
    // type PATCH
    // request body
    // {
    //   id: 1,
    //   grade: 1,
    //   className: 'A',
    //   schoolId: 1,
    //   teachers: [1, 2, 3]
    // }
    // ==========================

    const requestBody = {
      id: currentClassId,
      schoolId: currentSchoolId,
      grade: grade,
      className: validateClassName,
      teachers: newTeachersIdArr,
      year: year,
    };
    console.log('requestBody', requestBody);

    try {
      setShowLoader(true);
      const response = await axios.patch(`${config.API_URL}/api/gradebook/`, requestBody);
      if (response.data) {
        console.log('response.data', response.data);
        setShowLoader(false);
        setClassInfo(prev => {
        //  console.log('prev', prev)
        //  Object
        // classId: "114"
        // className: "B"
        // grade: 1
        // score: 50
        // speed: "Above Expected"
        // teachers: Array(1)
        // 0: {teacherId: 601, photoPath: '', name: 'TeacherName Teacher', firstName: 'TeacherName', lastName: 'Teacher', …}
        // length: 1
        // [[Prototype]]: Array(0)
        // tests: 4
        // year: 2023
          const classToUpdate = { ...prev };
          classToUpdate.className = validateClassName;
          classToUpdate.grade = grade;
          classToUpdate.teachers = newTeachersIdArr;
          classToUpdate.year = year;
            return classToUpdate
        })
        setSuccessfulEditClass(true);
        loadDataForGradeBook()
      }
    } catch (e) {
      if (e.message === 'Request failed with status code 409') {
        setShowLoader(false);
        alert('Class with this params already exist');
        return;
      }
      close();
      setShowLoader(false);
      setSuccessfulEditClass(false);
      console.log(e);
    }
  };

  // SET NEW TEACHERS
  const handleChangeTeacher = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedTeachers(typeof value === 'string' ? value.split(',') : value);
  };

  const handleChangeYear = (event) => {
    setYear(event.target.value);
  };

  return (
    <>
      {showLoader && <LoaderFullBook />}
      <ModalWrapper loader={loader}>
        <ModalContainer>
          <ModalHeader header="Edit Class" onClose={close} />

          {/* SELECT NEW GRADE */}
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '302px', marginTop: 10 },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <OutlinedTextField
                fullWidth
                select
                label="Select Grade"
                defaultValue={classInfo?.grade}
                value={grade}
                onChange={handleChangeGrade}
                onFocus={() => {
                  setErrorInGrade(false);
                  // setErrorClassIsExist(false);
                }}
              >
                {gradeToSelectDefault.map((grade) => {
                  return <MenuItem value={grade}>{grade} Grade</MenuItem>;
                })}
              </OutlinedTextField>
            </div>
          </Box>

          {/* ENTER CLASS NAME */}
          <div className="inputWrapper">
            <div
              style={{
                marginTop: 25,
                border: errorInClassName ? '1px solid #A6192E' : null,
              }}
              className="editClassModal_editClassNameWrapper"
            >
              <OutlinedTextField
                fullWidth
                // id="outlined-basic"
                label="Enter Class Name"
                // variant="outlined"
                // error
                // defaultValue={classInfo?.className}
                value={newClassName}
                onChange={(e) => {
                  setNewClassName(e.target.value);
                  setErrorInClassName(false);
                }}
              />
            </div>
            {errorInClassName && (
              <div className="editClassModal_errorWrapper textHelper">
                Class name is required{' '}
                <ReportProblemOutlinedIcon style={{ cursor: 'default', marginLeft: 5 }} />
              </div>
            )}
          </div>

          {/* ENTER TEACHER */}
          <div
            className="editClassModal_editTeacherNameWrapper"
            style={{
              marginTop: 25,
              border: errorInTeacher ? '1px solid #A6192E' : null,
              borderRadius: 0,
            }}
          >
            {teacherName.length > 0 ? (
              <Autocomplete
                defaultValue={defaultValueTeacher.reduce(
                  (sum, number) => [...sum, teacherName[number]],
                  []
                )}
                // defaultValue = {teacherName[0]}
                onChange={(event, newValue) => {
                  setSelectedTeachers(newValue);
                }}
                // error
                multiple
                id="checkboxes-tags-demo"
                options={teacherName}
                onFocus={() => {
                  setErrorInTeacher(false);
                }}
                disableCloseOnSelect
                getOptionLabel={(option) => option.title}
                renderOption={(props, option, { selected }) => (
                  <li {...props} style={{ fontSize: 16 }}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8, height: 25 }}
                      checked={selected}
                      // style={{ fontSize: 16 }}
                    />
                    {option.title}
                  </li>
                )}
                style={{ width: 302 }}
                renderInput={(params) => (
                  <OutlinedTextField
                    {...params}
                    label="Select Teacher"
                    placeholder="Select Teacher"
                    style={{ fontSize: 16 }}
                  />
                )}
              />
            ) : null}
          </div>

          <div style={{ position: 'relative', marginTop: -25 }} className="">
            {errorInTeacher && (
              <div className="editClassModal_errorWrapper">
               Teacher Name is required
              </div>
            )}
          </div>

          {/* SELECT YEAR */}
          <div className="editClassModal_editYearNameWrapper" style={{ marginTop: 50 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '302px' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <OutlinedTextField
                  fullWidth
                  select
                  label="Select Year"
                  defaultValue={classInfo?.grade}
                  value={year}
                  // onFocus={() => {
                  //   setErrorInYear(false);
                  // }}
                  onChange={handleChangeYear}
                >
                  {arrOfYearsToSelect.map((year) => {
                    return <MenuItem value={year}>{year}</MenuItem>;
                  })}
                </OutlinedTextField>
              </div>
            </Box>
            {/* <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Select Year</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={year}
                label="Age"
                onChange={handleChangeYear}
              >
                {arrOfYearsToSelect.map((year) => {
                  return <MenuItem value={year}>{year}</MenuItem>;
                })}
              </Select>
            </FormControl> */}
          </div>

          <div className="editClassModal_archiveButtonWrapper">
            <ButtonText
              type={`3`}
              text={` Archive`}
              iconName={`ArchiveTwoTone`}
              active={true}
              funtionality={() => {
                console.log('123546879');
              }}
            />
          </div>

          <ModalFooter>
            <ModalSecondaryButton onClick={close}>Cancel</ModalSecondaryButton>
            <ModalPrimaryButton typeBtn="submit" onClick={handleSaveClassChanges}>
              Edit
            </ModalPrimaryButton>
          </ModalFooter>
        </ModalContainer>
      </ModalWrapper>

      {successfulEditClass !== null && (
        <SuccessfulEditClass
          successfulEditClass={successfulEditClass}
          close={close}
          loader={loader}
          setSuccessfulEditClass={setSuccessfulEditClass}
          // loadDataForGradeBook={loadDataForGradeBook}
          // successfulAddClass={successfulAddClass}
        />
      )}
    </>
  );
};
