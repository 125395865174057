import { ModalContainer } from "../../../../components/Modal/ModalContainer/ModalContainer";
import { ModalHeader } from "../../../../components/Modal/ModalHeader/ModalHeader";
import { ModalWrapper } from "../../../../components/Modal/ModalWrapper/ModalWrapper";
import { TeachersFormFields } from "../TeachersFormFields/TeachersFormFields";

// import styles from "./TeacherModalEdit.module.scss";

const TeachersModalEdit = ({
  close,
  teacherData,
  submitForm,
  setShowAlert,
  openOnSubmitModal,
  loader,
  changeModalLoader }) => {

  return (
    <ModalWrapper loader={loader}>
      <ModalContainer>
        <ModalHeader header="Edit Teacher" onClose={close} />
        <TeachersFormFields
          setShowAlert={setShowAlert}
          changeModalLoader={changeModalLoader}
          close={close}
          isEdit={true}
          defaultValues={teacherData}
          submitForm={submitForm}
          openOnSubmitModal={openOnSubmitModal} />
        {/* {children} */}
      </ModalContainer>
    </ModalWrapper>
  )
}

export default TeachersModalEdit;
