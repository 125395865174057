import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { TestsService } from '../../../../pages/TestsPage/TestsService';
import { AppContext } from '../../../../context/AppContext';
import { convertStringTimeToSeconds } from '../../../../model';
import ErrorBoundary from '../../../ErrorBaundary';
import TestInputSelect from './TestInputSelect';
import { OutlinedTextField } from '../../../OutlinedTextField/OutlinedTextField';
import { ModalFooter } from '../../../Modal/ModalFooter/ModalFooter';
import { ModalSecondaryButton } from '../../../Modal/buttons/ModalSecondaryButton/ModalSecondaryButton';
import { ModalPrimaryButton } from '../../../Modal/buttons/ModalPrimaryButton/ModalPrimaryButton';
import TestsModalForArchiving from '../../TestsModalForArchiving';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArchiveTwoToneIcon from '@mui/icons-material/ArchiveTwoTone';
import styles from './TestForm.module.scss';

const service = new TestsService();

function TestForm({
  closeModal,
  callAlert,
  closeAlert,
  callRerender,
  setLoader,
  isEditForm,
  editData = [],
}) {
  const {
    id: testId,
    isArchive: testIsArchive,
    label: testLabel,
    concept: testConcept,
    classes,
  } = editData;
  const { currentSchoolId: schoolId } = useContext(AppContext);
  // for classes select
  const [loaderClasses, setLoaderClasses] = useState(false);
  const [classesData, setClassesData] = useState([]);
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [openClasses, setOpenClasses] = useState(false);
  // for other fields
  const [openConcept, setOpenConcept] = useState(false);
  const [enteredSpeed, setEnteredSpeed] = useState('');
  // for addTest Data
  const [label, setLabel] = useState(isEditForm ? testLabel : '');
  const [classesId, setClassesId] = useState([]);
  const [questions, setQuestions] = useState('');
  const [concept, setConcept] = useState(isEditForm ? testConcept : '');
  const [speed, setSpeed] = useState('');

  // for editTest variant modal
  const [modalArchive, setModalArchive] = useState(false);
  const openArchiveModal = useCallback(() =>
    setModalArchive(true), []);
  const closeArchiveModal = useCallback(() =>
    setModalArchive(false), []);

  const testClasses = (typeof classes !== 'undefined') ?
    classes.map(({ id, grade, className }) => {
      return {
        id,
        className: `${grade}${className}`,
      }
    }) : [];

  console.log('classes', classes)
  console.log('testClasses', testClasses);
  const {
    register,
    control,
    formState: { errors, isValid },
    handleSubmit,
    reset,
  } = useForm({
    mode: 'onBlur',
  });

  // if there are a request for concepts
  // const [loaderConcept, setLoaderConcept] = useState(false);
  const conceptsData = ['Arithmetic', 'Multiplication'];
  // for speed input field
  const speedPattern = /^(?:([01]?\d|2[0-3])[a-zA-Z]+\s?)([0-5]?\d)[a-zA-Z]+\s?([0-5]?\d)[a-zA-Z]+\s?$/iu;
  const speedRegisterOptions = {
    required: 'Required Field',
    pattern: {
      value: speedPattern,
      message: "Speed format mismatch: XXh XXm XXs"
    }
  };

  // for Submit Success - close AddModal with delay
  const delayedClosure = useCallback(() =>
    setTimeout(() =>
      closeModal(), 100), [closeModal]);

  // for get classes data request
  const getClasses = useCallback(() => {
    service.getAllClasses(schoolId)
      .then((response) => {
        const allClasses = response
          .map(({ Id, Grade, ClassName }) => {
            return {
              id: Id,
              className: `${Grade}${ClassName}`,
            }
          })
          .sort((a, b) => a - b);
        setClassesData(allClasses);
        setLoaderClasses(false);
      })
      .catch(() => {
        setLoaderClasses(false);
        callAlert({
          type: false,
          text: 'Ooops, server error.'
            + ' Please, contact the admin of the website.',
          close: closeAlert,
          show: true,
        });
      })
  }, [callAlert, closeAlert, schoolId]);

  // for get classes
  useEffect(() => {
    if (openClasses) {
      setLoaderClasses(true);
      getClasses();
    }
  }, [getClasses, openClasses]);

  // for get classes ids to addTest request
  useEffect(() => {
    if (selectedClasses.length > 0) {
      const ids = selectedClasses
        .map(({ id }) => id);
      setClassesId(ids);
    }
    if (selectedClasses.length === 0) {
      setClassesId([]);
    }
  }, [selectedClasses]);

  // for get speed number to addTest request
  useEffect(() => {
    const splitForSpeed = /[a-zA-Z]+\s?/iu;
    if (enteredSpeed.length !== '') {
      const speedValue = convertStringTimeToSeconds(enteredSpeed, splitForSpeed);
      setSpeed(speedValue);
    }
    if (enteredSpeed.length === '') {
      setSpeed('');
    }
  }, [enteredSpeed]);

  // after Submit Success - close AddModal
  useEffect(() => {
    return () => {
      clearTimeout(delayedClosure);
    }
  }, [delayedClosure]);

  const onSubmitAddForm = () => {
    if (!classesId) {
      return
    }
    setLoader(true);
    service.addTest(label, questions, concept, speed, classesId)
      .then(() => {
        setLoader(false);
        reset();
        callAlert({
          type: true,
          text: 'Operation Success!',
          close: closeAlert,
          show: true,
        });
        // delayedClosure();
        closeModal();
        callRerender(true);
      })
      .catch(() => {
        setLoader(false);
        closeModal();
        callAlert({
          type: false,
          text: 'Ooops, server error.'
            + ' Please, contact the admin of the website.',
          close: closeAlert,
          show: true,
        });
        callRerender(false);
      });
  };

  const onSubmitEditForm = () => {
    setLoader(true);
    service.updateTest(testId, label, questions, concept, speed, classesId)
      .then(() => {
        setLoader(false);
        callRerender(true);
        reset();
        callAlert({
          type: true,
          text: 'Operation Success!',
          close: closeAlert,
          show: true,
        });
        // delayedClosure();
         closeModal();
      })
      .catch(() => {
        setLoader(false);
        callAlert({
          type: false,
          text: 'Ooops, server error.'
            + ' Please, contact the admin of the website.',
          close: closeAlert,
          show: true,
        });
        callRerender(false);
      });
  };

  return (
    <form
      className={styles.form}
      onSubmit={isEditForm ? handleSubmit(onSubmitEditForm) : handleSubmit(onSubmitAddForm)}
    >
      <ErrorBoundary>
        <div className={styles.inputWrapper}>
          <OutlinedTextField
            className={styles.formField}
            {...register('testLabel', {
              required: 'Required Field',
            })}
            label="Test Label"
            defaultValue={isEditForm ? testLabel : ''}
            fullWidth
            onChange={(e) => setLabel(e.target.value)}
          />
          <div className={styles.textHelper}>
            {errors?.testLabel && (
              <>
                <p>{errors?.testLabel?.message || 'Error!'}</p>
                <InfoOutlinedIcon />
              </>
            )}
          </div>
        </div>
        <TestInputSelect
          className={styles.formField}
          control={control}
          name={'сlasses'}
          multiple={true}
          freeSolo={false}
          loader={loaderClasses}
          open={openClasses}
          setOpen={setOpenClasses}
          limitTags={2}
          data={classesData}
          defaultData={isEditForm ? testClasses : []}
          inputLabel="Select Class"
          setSelectedValue={setSelectedClasses}
        />
        <div className={styles.inputWrapper}>
          <OutlinedTextField
            className={`${styles.formField} ${styles.questions}`}
            type="number"
            {...register('totalQuestions', {
              required: true,
              min: 1,
            })}
            label="Total Questions"
            fullWidth
            defaultValue={questions}
            value={questions}
            onChange={(e) => setQuestions(parseFloat(e.target.value))}
          />
          <div className={styles.textHelper}>
            {errors?.totalQuestions && (
              <>
                <p>{'Minimum questions quantityty is 1'}</p>
                <InfoOutlinedIcon />
              </>
            )}
          </div>
        </div>
        <TestInputSelect
          className={styles.formField}
          control={control}
          name={'сoncept'}
          multiple={false}
          freeSolo={true}
          loader={false} //loaderConcept if has concept data on backend
          open={openConcept}
          setOpen={setOpenConcept}
          limitTags={1}
          data={conceptsData}
          defaultData={isEditForm ? testConcept : ''}
          inputLabel="Enter or Select a Concept"
          setSelectedValue={setConcept}
        />
        <div className={styles.inputWrapper}>
          <OutlinedTextField
            className={styles.lastFormField}
            {...register('expSpeed', speedRegisterOptions)}
            label="Expected Speed"
            placeholder="XXh XXm XXs"
            fullWidth
            value={enteredSpeed}
            onChange={(e) => setEnteredSpeed(e.target.value)}
          />
          <div className={styles.lastTextHelper}>
            {errors?.expSpeed && (
              <>
                <p>{errors?.expSpeed?.message || 'Error!'}</p>
                <InfoOutlinedIcon />
              </>
            )}
          </div>
        </div>
        {isEditForm && (
          <button
            className={styles.archiveBtn}
            type="button"
            onClick={openArchiveModal}
            disabled={testIsArchive}
          >
            <ArchiveTwoToneIcon />
            <span>archive test</span>
          </button>
        )}
        {modalArchive && (
          <TestsModalForArchiving
            archiveData={[testId]}
            modalArchive={modalArchive}
            close={closeArchiveModal}
            callRerender={callRerender}
          />
        )}
      </ErrorBoundary>
      <ModalFooter>
        <ModalSecondaryButton onClick={closeModal}>Cancel</ModalSecondaryButton>
        <ModalPrimaryButton typeBtn="submit" disabled={!isValid}>
          {isEditForm ? 'Confirm' : 'Add'}
        </ModalPrimaryButton>
      </ModalFooter>
    </form>
  );
};

export default TestForm;
