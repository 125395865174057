// import React, {useContext} from 'react';
// import { AuthContext } from '../../../components/context';
import HeaderProfile from "../../../components/HeaderProfile/HeaderProfile";
import { WidthLimiter } from "../../../components/WidthLimiter/WidthLimiter";
import imgHeaderLogo from "../../../images/LOGO-header.png";
import './OnboardingHeader.scss'

const navLinksDisabled = [
  {text: 'Analytics'},
  {text: 'Gradebooks'},
  {text: 'Tests'},
  {text: 'Students'},
  {text: 'Teachers'},
  {text: 'Archive'}
 ]

const OnboardingHeader = () => {

  // const { isAuth} = useContext(AuthContext);

  return (
      <div className="headerWrapper">
      <WidthLimiter>
        <header className="header">
          <div className="headerLogo_wrapper">
            <img className="imgLogo" src={imgHeaderLogo} alt="" />
          </div>
          {/* NAVBAR DISABLED */}
             <div className="navBar">
                {navLinksDisabled.map((link) => (
                  <div className="navLink">
                    <span className="navLinkLabel">{link.text}</span>
                  </div>
                ))}
              </div>
          <HeaderProfile/>
        </header>
      </WidthLimiter>
    </div>
  );
}

export default OnboardingHeader;