import { useState } from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { StyledDropdownSelect } from './StyledDropdownSelect';

function DropdownSelect({
  dataToSelect,
  stateFilter,
  setStateFilter,
  defaultValue,
  type,
  setClassFilter,
}) {
  const [localStateToShowSelected, setLocalStateToShowSelected] = useState('');
  // let classes = null;
  // let values = null;
  // if (typeof dataToSelect === 'object' && !Array.isArray({ dataToSelect })) {
  //   classes = Object.keys(dataToSelect);
  //   values = Object.values(dataToSelect);
  // }

  let gradesKeys = [];
  let gradesValues = [];
  let allClasses = [];

  if (type === 'grade') {
    gradesValues = Object.values(dataToSelect);
    gradesKeys = Object.keys(dataToSelect);
  }

  if (type === 'class') {
    gradesValues = Object.values(dataToSelect);
    gradesKeys = Object.keys(dataToSelect);
    // have to get all classes
    for (let i = 0; i < gradesValues.length; i++) {
      allClasses = [...allClasses, ...gradesValues[i]];
    }
  }

  const handleStateFilter = (filter) => {
    setStateFilter(filter);
  };
  // проверка являються ли массивы одинаковыми

  return (
    <>
      {/* простой селект без вложеностей */}
      {typeof dataToSelect === 'object' && dataToSelect.constructor === Array && !type && (
        <StyledDropdownSelect>
          <div className="textAdaArrowWrapper">
            <div className="text_selectWrapper">
              {!stateFilter
                ? defaultValue
                : stateFilter.length > 17
                ? stateFilter.substr(0, 14) + '...'
                : stateFilter}
            </div>
            <ArrowDropDownIcon />
          </div>
          <div className="dropDownWrapper">
            {stateFilter !== defaultValue && stateFilter && (
              <div
                className="dropDown_item"
                onClick={() => {
                  handleStateFilter(null);
                }}
              >
                {defaultValue}
              </div>
            )}

            {dataToSelect.map((concept, index) => {
              if (stateFilter === concept) {
                return null;
              }
              if (concept.length > 17) {
                return (
                  <div
                    key={index}
                    className="dropDown_item"
                    onClick={() => {
                      handleStateFilter(concept);
                    }}
                  >
                    {concept.substr(0, 14)} . . .
                  </div>
                );
              }
              return (
                <div
                  key={index}
                  className="dropDown_item"
                  onClick={() => {
                    handleStateFilter(concept);
                  }}
                >
                  {concept}
                </div>
              );
            })}
          </div>
        </StyledDropdownSelect>
      )}
      {type === 'sortBy' && (
        <StyledDropdownSelect>
          <div className="textAdaArrowWrapper">
            <div className="text_selectWrapper">
              {!stateFilter
                ? defaultValue
                : stateFilter.length > 17
                ? stateFilter.substr(0, 14) + '...'
                : stateFilter}
            </div>
            <ArrowDropDownIcon />
          </div>
          <div className="dropDownWrapper">
            {stateFilter !== defaultValue && stateFilter && (
              <div
                className="dropDown_item"
                onClick={() => {
                  handleStateFilter(defaultValue);
                  setClassFilter([]);
                }}
              >
                {defaultValue}
              </div>
            )}

            {dataToSelect.map((concept, index) => {
              if (stateFilter === concept) {
                return null;
              }
              if (concept.length > 17) {
                return (
                  <div
                    key={index}
                    className="dropDown_item"
                    onClick={() => {
                      handleStateFilter(concept);
                    }}
                  >
                    {concept.substr(0, 14)} . . .
                  </div>
                );
              }
              return (
                <div
                  key={index}
                  className="dropDown_item"
                  onClick={() => {
                    handleStateFilter(concept);
                    setClassFilter([]);
                  }}
                >
                  {concept}
                </div>
              );
            })}
          </div>
        </StyledDropdownSelect>
      )}

      {type === 'grade' && (
        <StyledDropdownSelect>
          <div className="textAdaArrowWrapper">
            <div className="text_selectWrapper">
              {stateFilter.length === 0 ? defaultValue : localStateToShowSelected}
            </div>
            <ArrowDropDownIcon />
          </div>
          <div className="dropDownWrapper">
            {stateFilter.length > 0 && (
              <div
                className="dropDown_item"
                onClick={() => {
                  setStateFilter([]);
                }}
              >
                {defaultValue}
              </div>
            )}
            {
              //  получаем значение (велью) обьекта классес --  массив массивов из классов + его ключи
              //      gradesValues = Object.values(dataToSelect)
              // gradesKeys = Object.keys(dataToSelect)

              gradesKeys.map((grade, index) => {
                const allClassId = gradesValues[index].reduce(
                  (sum, current) => [...sum, current.id],
                  []
                );
                if (stateFilter[0] === allClassId[0]) {
                  return null;
                }
                return (
                  <div
                    key={index}
                    className="dropDown_item"
                    onClick={() => {
                      setStateFilter([...allClassId]);
                      setLocalStateToShowSelected(`${grade} Grade`);
                    }}
                  >
                    {grade} GRADE
                  </div>
                );
              })
            }
          </div>
        </StyledDropdownSelect>
      )}
      {type === 'class' && (
        <StyledDropdownSelect>
          <div className="textAdaArrowWrapper">
            <div className="text_selectWrapper">
              {stateFilter.length === 0 ? defaultValue : localStateToShowSelected}
            </div>
            <ArrowDropDownIcon />
          </div>
          <div className="dropDownWrapper">
            {stateFilter.length > 0 && (
              <div
                className="dropDown_item"
                onClick={() => {
                  setStateFilter([]);
                }}
              >
                {defaultValue}
              </div>
            )}
            {allClasses.map((classs, index) => {
              return (
                <div
                  key={index}
                  className="dropDown_item"
                  onClick={() => {
                    setStateFilter([classs.id]);
                    setLocalStateToShowSelected(classs.class);
                  }}
                >
                  {classs.class}
                </div>
              );
            })}
          </div>
        </StyledDropdownSelect>
      )}
      {type === 'teachers' && (
        <StyledDropdownSelect type={'teachers'}>
          <div className="textAdaArrowWrapper">
            <div className="text_selectWrapper">
              {stateFilter.length === 0
                ? defaultValue
                : localStateToShowSelected.length > 19
                ? localStateToShowSelected.substr(0, 18) + '...'
                : localStateToShowSelected}
            </div>
            <ArrowDropDownIcon />
          </div>
          <div className="dropDownWrapper">
            {stateFilter.length > 0 && (
              <div
                className="dropDown_item"
                onClick={() => {
                  setStateFilter([]);
                }}
              >
                {defaultValue}
              </div>
            )}
            {dataToSelect.map((teacher, index) => {
              // получить массив айдишников класса учителя

              const teacherGradesValues = [...Object.values(teacher.classes)];
              // const teacherGradesKeys = Object.keys(teacher.classes);
              const teacherAllClass = teacherGradesValues.reduce(
                (sum, current) => [...sum, ...current],
                []
              );
              const teacherAllClassId = teacherAllClass.reduce(
                (sum, current) => [...sum, current.id],
                []
              );
              if (stateFilter[0] === teacherAllClassId[0] && teacherAllClassId[0]) {
                return null;
              }
              return (
                <div
                  key={index}
                  className="dropDown_item"
                  onClick={() => {
                    setStateFilter([-1, ...teacherAllClassId]);
                    setLocalStateToShowSelected(teacher.teacherName);
                  }} //  was  [ 0 , ...teacherAllClassId]
                >
                  {teacher.teacherName.length > 19
                    ? teacher.teacherName.substr(0, 18) + ' . . .'
                    : teacher.teacherName}
                </div>
              );
            })}
          </div>
        </StyledDropdownSelect>
      )}
    </>
  );
};

export { DropdownSelect };
