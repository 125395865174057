import { useState, useEffect, useCallback, useContext } from 'react';
import { ArchiveService } from './ArchiveService';
import { AppContext } from '../../context/AppContext';
import { useDebounce } from '../../hooks/debounce';
import { getSearchIndicator } from '../../model';
import { InternalPageContainer } from '../../components/InternalPageContainer/InternalPageContainer';
import { TableHeadLine } from '../../components/TableHeadLine/TableHeadLine';
import SubHeaderCollapse from '../../components/SubHeaderCollapse';
import ArchivePageYearRow from './ArchivePageYearRow/ArchivePageYearRow';
import ArchiveExportCsvModal from '../../components/ArchiveComponents/ArchiveExportCsvModal';
import ArchivePageModalUnarchive from '../../components/ArchiveComponents/ArchivePageModalUnarchive';
import ArchiveNoData from '../../components/ArchiveComponents/ArchiveNoData';
import NoSearchResults from '../../components/NoSearchResults';
import ErrorBoundary from '../../components/ErrorBaundary';
import { LoaderFullBook } from '../../components/Loaders/LoaderFullBook/LoaderFullBook';
import './ArchivePage.scss';

const service = new ArchiveService();

function ArchivePage() {
  // for data
  // const { currentSchoolId: schoolId } = useContext(AppContext);
  const { currentSchoolId } = useContext(AppContext);
  const [archiveData, setArchiveData] = useState({});
  const [hasError, setHasError] = useState(false);
  const [loader, setLoader] = useState(true);
  // for Search
  const [searchValue, setSearchValue] = useState('');
  const [noSearchRes, setNoSearchRes] = useState(false);
  const searchValueDelay = useDebounce(searchValue, 500);
  // for modal
  const [rerender, setRerender] = useState(false);
  const [modalUnarchive, setModalUnarchive] = useState(false);
  const [exportCSVModal, setExportCSVModal] = useState(false);
  const onOpenModalUnarchive = useCallback(() => setModalUnarchive(true), []);
  const onCloseModalUnarchive = useCallback(() => setModalUnarchive(false), []);
  const onOpenExportCSVModal = useCallback(() => setExportCSVModal(true), []);
  const onCloseExportCSVModal = useCallback(() => setExportCSVModal(false), []);
  //for selectClasses
  const [activeClasses, setActiveClasses] = useState([]);

  const hasArchiveData =
    Object.keys(archiveData).length === 0 && archiveData.constructor === Object;

  const unarchiveClasses = activeClasses.map((unarchiveClass) => unarchiveClass.classId);

  console.log('currentSchoolId', currentSchoolId);

  const getData = useCallback(() => {
    service
      .getArchive(currentSchoolId)
      .then((response) => {
        setArchiveData(response);
        setLoader(false);
        setHasError(false);
      })
      .catch((e) => {
        setLoader(false);
        setHasError(true);
      });
  }, [currentSchoolId]);

  // for Data
  useEffect(() => {
    setLoader(true);
    if (currentSchoolId !== null) {
      // to avoid double rendering
      getData();
    }
  }, [getData, currentSchoolId]);

  // after unarchiveClasses
  useEffect(() => (rerender ? (getData(), setRerender(false)) : null), [getData, rerender]);

  // for Search
  useEffect(() => {
    setNoSearchRes(false);
    const hasSearchValueInData = getSearchIndicator(archiveData, searchValueDelay);
    if (hasSearchValueInData) {
      return setNoSearchRes(false);
    }
    return setNoSearchRes(true);
  }, [archiveData, searchValueDelay]);

  // for checkboxes to work correctly
  const toggleSelectedClasses = (selected) => {
    setActiveClasses((activeClasses) => {
      let selectedClasses = [];
      selected.forEach((item) => {
        if (!activeClasses.includes(item)) {
          selectedClasses.push(item);
        }
      });
      activeClasses.forEach((item) => {
        if (!selected.includes(item)) {
          selectedClasses.push(item);
        }
      });
      return selectedClasses;
    });
  };

  const yearsList = Object.keys(archiveData).map((year, index) => {
    return (
      <ArchivePageYearRow
        year={year}
        index={index}
        key={index}
        serverResponse={archiveData}
        activeClasses={activeClasses}
        setActiveClasses={setActiveClasses}
        toggleSelectedClasses={toggleSelectedClasses}
        searchValue={searchValueDelay}
        callRender={setRerender}
      />
    );
  });

  return (
    <>
      <InternalPageContainer />
      <div className="archivePage">
        <ErrorBoundary error={hasError}>
          <div className="archivePageHeader__container" />
          {!loader ? (
            <ArchiveNoData show={hasArchiveData}>
              {activeClasses.length > 0 ? (
                <SubHeaderCollapse
                  activeCounter={activeClasses}
                  setActiveCounter={setActiveClasses}
                  selectedType={true}
                  dataType="class"
                  openModalUnArchive={onOpenModalUnarchive}
                />
              ) : (
                <TableHeadLine
                  archiveClass={false}
                  headlineType="archive"
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  handleOnClickDownloadCsv={onOpenExportCSVModal}
                />
              )}
              <div className="archivePageTable__inner">
                <NoSearchResults
                  show={noSearchRes}
                  searchValue={searchValue}
                  backFn={() => {
                    setNoSearchRes(false);
                    setSearchValue('');
                  }}
                  delay={550}
                >
                  {yearsList}
                </NoSearchResults>
              </div>
            </ArchiveNoData>
          ) : (
            <LoaderFullBook />
          )}
        </ErrorBoundary>
        {modalUnarchive && (
          <ArchivePageModalUnarchive
            unarchive={unarchiveClasses}
            close={onCloseModalUnarchive}
            callRender={setRerender}
            setActiveClasses={setActiveClasses}
          />
        )}
        {exportCSVModal && <ArchiveExportCsvModal close={onCloseExportCSVModal} />}
      </div>
    </>
  );
}

export { ArchivePage };
