import React from 'react';
import './ButtonPrimary.scss';
import { ControllerObjs } from './ControllerObjs';
import { ButtonPrimaryLow } from './ButtonPrimaryLow';

export const ButtonPrimary = ({ status, active, functionality }) => {
  return (
    <div className={'primaryButtonWrapper'}>
      <ButtonPrimaryLow
        functionality={functionality}
        selector={ControllerObjs[status]}
        active={active}
      />
    </div>
  );
};
