const throwError = (variableName: string) => {
  throw new Error(`Environment variable ${variableName} is required for operation`);
};

const getConfig = () => ({
  ENV: process.env.NODE_ENV,
  API_URL: process.env.REACT_APP_API_URL || throwError('REACT_APP_API_URL'),
  STAGING_API_URL: process.env.REACT_APP_STAGING_API_URL || throwError('REACT_APP_STAGING_API_URL'),
});

export const config = getConfig();
