export const defaultResponseFromServer = {
  classInfo: {
    tests: '',
    year: '',
    grade: '',
    className: '',
    classId: '',
    speed: '',
    score: '',
    teachers: [
      {
        photoPath: '',
        teacherId: '',
        name: '',
        email: '',
        phone: '',
      },
      {
        photoPath: '',
        teacherId: '',
        name: '',
        email: '',
        phone: '',
      },
    ],
  },
  tests: [
    {
      testName: 'Finding Averages 1 to 400',
      testId: 111,
      totalQuestions: 200,
      estTime: '01H 25M 01S',
      concept: 'Multiplication',
      date: 'JUN 03 2021',
      status: 'Complete',
      testAvScoreInClass: { score: 95, color: '#4285F4' },
      testAvSpeedInClass: { speed: 'As expected', color: '#0F9D58' },
    },
    {
      testName: 'Divide by 1 to 12 (400 problem to resolve)',
      testId: 222,
      totalQuestions: 100,
      estTime: '01H 02M 52S',
      concept: 'Multiplication',
      date: 'JUN 03 2021',
      status: 'Complete',
      testAvScoreInClass: { score: 95, color: '#4285F4' },
      testAvSpeedInClass: { speed: 'As expected', color: '#0F9D58' },
    },
    {
      testName: 'Fractions (Part 1) by 1 to 12 ',
      testId: 333,
      totalQuestions: 100,
      estTime: '01H 00M 00S',
      concept: 'Fractions',
      date: 'JUN 03 2021',
      status: 'Complete',
      testAvScoreInClass: { score: 95, color: '#4285F4' },
      testAvSpeedInClass: { speed: 'As expected', color: '#0F9D58' },
    },
    {
      testName: 'Geometry  (Part 1)  ',
      testId: 444,
      totalQuestions: 100,
      estTime: '01H 00M 00S',
      concept: 'Fractions',
      date: 'JUN 03 2021',
      status: 'Complete',
      testAvScoreInClass: { score: 95, color: '#4285F4' },
      testAvSpeedInClass: { speed: 'As expected', color: '#0F9D58' },
    },
    {
      testName: 'Trygonometry  (Part 1)  ',
      testId: 555,
      totalQuestions: 100,
      estTime: '01H 00M 00S',
      concept: 'Fractions',
      date: 'JUN 03 2021',
      status: 'Complete',
      testAvScoreInClass: { score: 95, color: '#4285F4' },
      testAvSpeedInClass: { speed: 'As expected', color: '#0F9D58' },
    },
  ],
  students: [
    {
      name: 'Albert Flores',
      studentIdSchool: 66114514514,
      studentId: 1111,
      parentsInfo: {
        firstParent: {
          name: 'Antony Kidman',
          email: 'gabi74@gmail.com',
          phone: '+1 084 (755) 08 - 11',
        },
        secondParent: {
          name: 'Jesica Alba-Gabriella',
          email: 'gabi74@gmail.com',
          phone: '+1 084 (755) 08 - 11',
        },
      },
      tests: [
        {
          testId: 111,
          absent: 1,
          // score: { score: 98, color: '#0F9D58' },
          // speed: { speed: '00H 42M 52S', color: '#4285F4' }
        },
        {
          testId: 222,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 4362, color: '#4285F4' },
        },

        {
          testId: 444,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3285, color: '#4285F4' },
        },
        {
          testId: 555,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 4392, color: '#4285F4' },
        },
      ],
    },
    {
      name: 'Mangy Flores',
      studentIdSchool: 45114514514,
      studentId: 2222,
      parentsInfo: {
        firstParent: {
          name: 'Antony Kidman',
          email: 'gabi74@gmail.com',
          phone: '+1 084 (755) 08 - 11',
        },
        secondParent: {
          name: 'Jesica Alba-Gabriella',
          email: 'gabi74@gmail.com',
          phone: '+1 084 (755) 08 - 11',
        },
      },
      tests: [
        {
          testId: 222,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3200, color: '#4285F4' },
        },
        {
          testId: 111,
          absent: 0,
          score: { score: 98, color: '#0F9D58' },
          speed: { speed: 3458, color: '#4285F4' },
        },
        {
          testId: 333,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 4365, color: '#4285F4' },
        },
        {
          testId: 444,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3985, color: '#4285F4' },
        },
        {
          testId: 555,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 5394, color: '#4285F4' },
        },
      ],
    },
    {
      name: 'Harry Flores',
      studentIdSchool: 45114514514,
      studentId: 3333,
      parentsInfo: {
        firstParent: {
          name: 'Antony Kidman',
          email: 'gabi74@gmail.com',
          phone: '+1 084 (755) 08 - 11',
        },
        secondParent: {
          name: 'Jesica Alba-Gabriella',
          email: 'gabi74@gmail.com',
          phone: '+1 084 (755) 08 - 11',
        },
      },
      tests: [
        {
          testId: 222,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3985, color: '#4285F4' },
        },

        {
          testId: 333,
          absent: 1,

          //    score: { score: 86, color: '#4285F4' },
          //    speed: { speed: '00H 42M 52S', color: '#4285F4' }
        },
        {
          testId: 444,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3985, color: '#4285F4' },
        },
        {
          testId: 555,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3985, color: '#4285F4' },
        },
      ],
    },
    {
      name: 'John Flores',
      studentIdSchool: 45114514514,
      studentId: 4444,
      parentsInfo: {
        firstParent: {
          name: 'Antony Kidman',
          email: 'gabi74@gmail.com',
          phone: '+1 084 (755) 08 - 11',
        },
        secondParent: {
          name: 'Jesica Alba-Gabriella',
          email: 'gabi74@gmail.com',
          phone: '+1 084 (755) 08 - 11',
        },
      },
      tests: [
        {
          testId: 222,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3985, color: '#4285F4' },
        },
        {
          testId: 111,
          absent: 0,
          score: { score: 98, color: '#0F9D58' },
          speed: { speed: 3985, color: '#4285F4' },
        },
        {
          testId: 333,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3985, color: '#4285F4' },
        },
        {
          testId: 444,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3985, color: '#4285F4' },
        },
        {
          testId: 555,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3985, color: '#4285F4' },
        },
      ],
    },
    {
      name: 'Drue Flores',
      studentIdSchool: 45114514514,
      studentId: 5555,
      parentsInfo: {
        firstParent: {
          name: 'Antony Kidman',
          email: 'gabi74@gmail.com',
          phone: '+1 084 (755) 08 - 11',
        },
        secondParent: {
          name: 'Jesica Alba-Gabriella',
          email: 'gabi74@gmail.com',
          phone: '+1 084 (755) 08 - 11',
        },
      },
      tests: [
        {
          testId: 222,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3985, color: '#4285F4' },
        },
        {
          testId: 111,
          absent: 0,
          score: { score: 98, color: '#0F9D58' },
          speed: { speed: 3985, color: '#4285F4' },
        },
        {
          testId: 333,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3985, color: '#4285F4' },
        },
        {
          testId: 444,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3985, color: '#4285F4' },
        },
        {
          testId: 555,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3985, color: '#4285F4' },
        },
      ],
    },
    {
      name: 'Justin Flores',
      studentIdSchool: 45114514514,
      studentId: 66666,
      parentsInfo: {
        firstParent: {
          name: 'Antony Kidman',
          email: 'gabi74@gmail.com',
          phone: '+1 084 (755) 08 - 11',
        },
        secondParent: {
          name: 'Jesica Alba-Gabriella',
          email: 'gabi74@gmail.com',
          phone: '+1 084 (755) 08 - 11',
        },
      },
      tests: [
        {
          testId: 222,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3985, color: '#4285F4' },
        },
        {
          testId: 111,
          absent: 0,
          score: { score: 98, color: '#0F9D58' },
          speed: { speed: 3985, color: '#4285F4' },
        },
        {
          testId: 333,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3985, color: '#4285F4' },
        },
        {
          testId: 444,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3985, color: '#4285F4' },
        },
        {
          testId: 555,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3985, color: '#4285F4' },
        },
      ],
    },
    {
      name: 'Justin Flores',
      studentIdSchool: 45114514514,
      studentId: 77777,
      parentsInfo: {
        firstParent: {
          name: 'Antony Kidman',
          email: 'gabi74@gmail.com',
          phone: '+1 084 (755) 08 - 11',
        },
        secondParent: {
          name: 'Jesica Alba-Gabriella',
          email: 'gabi74@gmail.com',
          phone: '+1 084 (755) 08 - 11',
        },
      },
      tests: [
        {
          testId: 222,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3985, color: '#4285F4' },
        },
        {
          testId: 111,
          absent: 0,
          score: { score: 98, color: '#0F9D58' },
          speed: { speed: 3985, color: '#4285F4' },
        },
        {
          testId: 333,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3985, color: '#4285F4' },
        },
        {
          testId: 444,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3985, color: '#4285F4' },
        },
        {
          testId: 555,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3985, color: '#4285F4' },
        },
      ],
    },
    {
      name: 'Justin Flores',
      studentIdSchool: 45114514514,
      studentId: 8888,
      parentsInfo: {
        firstParent: {
          name: 'Antony Kidman',
          email: 'gabi74@gmail.com',
          phone: '+1 084 (755) 08 - 11',
        },
        secondParent: {
          name: 'Jesica Alba-Gabriella',
          email: 'gabi74@gmail.com',
          phone: '+1 084 (755) 08 - 11',
        },
      },
      tests: [
        {
          testId: 222,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3985, color: '#4285F4' },
        },
        {
          testId: 111,
          absent: 0,
          score: { score: 98, color: '#0F9D58' },
          speed: { speed: 3985, color: '#4285F4' },
        },
        {
          testId: 333,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3985, color: '#4285F4' },
        },
        {
          testId: 444,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3985, color: '#4285F4' },
        },
        {
          testId: 555,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3985, color: '#4285F4' },
        },
      ],
    },
    {
      name: 'Justin Flores',
      studentIdSchool: 45114514514,
      studentId: 99999,
      parentsInfo: {
        firstParent: {
          name: 'Antony Kidman',
          email: 'gabi74@gmail.com',
          phone: '+1 084 (755) 08 - 11',
        },
        secondParent: {
          name: 'Jesica Alba-Gabriella',
          email: 'gabi74@gmail.com',
          phone: '+1 084 (755) 08 - 11',
        },
      },
      tests: [
        {
          testId: 222,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3985, color: '#4285F4' },
        },
        {
          testId: 111,
          absent: 0,
          score: { score: 98, color: '#0F9D58' },
          speed: { speed: 3985, color: '#4285F4' },
        },
        {
          testId: 333,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3985, color: '#4285F4' },
        },
        {
          testId: 444,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3985, color: '#4285F4' },
        },
        {
          testId: 555,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3985, color: '#4285F4' },
        },
      ],
    },
    {
      name: 'Justin Flores',
      studentIdSchool: 45114514514,
      studentId: 101010,
      parentsInfo: {
        firstParent: {
          name: 'Antony Kidman',
          email: 'gabi74@gmail.com',
          phone: '+1 084 (755) 08 - 11',
        },
        secondParent: {
          name: 'Jesica Alba-Gabriella',
          email: 'gabi74@gmail.com',
          phone: '+1 084 (755) 08 - 11',
        },
      },
      tests: [
        {
          testId: 222,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3985, color: '#4285F4' },
        },
        {
          testId: 111,
          absent: 0,
          score: { score: 98, color: '#0F9D58' },
          speed: { speed: 3985, color: '#4285F4' },
        },
        {
          testId: 333,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3985, color: '#4285F4' },
        },
        {
          testId: 444,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3985, color: '#4285F4' },
        },
        {
          testId: 555,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3985, color: '#4285F4' },
        },
      ],
    },
    {
      name: 'Justin Flores',
      studentIdSchool: 45114514514,
      studentId: 10110,
      parentsInfo: {
        firstParent: {
          name: 'Antony Kidman',
          email: 'gabi74@gmail.com',
          phone: '+1 084 (755) 08 - 11',
        },
        secondParent: {
          name: 'Jesica Alba-Gabriella',
          email: 'gabi74@gmail.com',
          phone: '+1 084 (755) 08 - 11',
        },
      },
      tests: [
        {
          testId: 222,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3985, color: '#4285F4' },
        },
        {
          testId: 111,
          absent: 0,
          score: { score: 98, color: '#0F9D58' },
          speed: { speed: 3985, color: '#4285F4' },
        },
        {
          testId: 333,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3985, color: '#4285F4' },
        },
        {
          testId: 444,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3985, color: '#4285F4' },
        },
        {
          testId: 555,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3985, color: '#4285F4' },
        },
      ],
    },
    {
      name: 'Justin Flores',
      studentIdSchool: 45114514514,
      studentId: 121212,
      parentsInfo: {
        firstParent: {
          name: 'Antony Kidman',
          email: 'gabi74@gmail.com',
          phone: '+1 084 (755) 08 - 11',
        },
        secondParent: {
          name: 'Jesica Alba-Gabriella',
          email: 'gabi74@gmail.com',
          phone: '+1 084 (755) 08 - 11',
        },
      },
      tests: [
        {
          testId: 222,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3985, color: '#4285F4' },
        },
        {
          testId: 111,
          absent: 0,
          score: { score: 98, color: '#0F9D58' },
          speed: { speed: 3985, color: '#4285F4' },
        },
        {
          testId: 333,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3985, color: '#4285F4' },
        },
        {
          testId: 444,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3985, color: '#4285F4' },
        },
        {
          testId: 555,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3985, color: '#4285F4' },
        },
      ],
    },
    {
      name: 'Justin Flores',
      studentIdSchool: 45114514514,
      studentId: 131313,
      parentsInfo: {
        firstParent: {
          name: 'Antony Kidman',
          email: 'gabi74@gmail.com',
          phone: '+1 084 (755) 08 - 11',
        },
        secondParent: {
          name: 'Jesica Alba-Gabriella',
          email: 'gabi74@gmail.com',
          phone: '+1 084 (755) 08 - 11',
        },
      },
      tests: [
        {
          testId: 222,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3985, color: '#4285F4' },
        },
        {
          testId: 111,
          absent: 0,
          score: { score: 98, color: '#0F9D58' },
          speed: { speed: 3985, color: '#4285F4' },
        },
        {
          testId: 333,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3985, color: '#4285F4' },
        },
        {
          testId: 444,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3985, color: '#4285F4' },
        },
        {
          testId: 555,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3985, color: '#4285F4' },
        },
      ],
    },
    {
      name: 'Merlin Flores',
      studentIdSchool: 45114514514,
      studentId: 141414,
      parentsInfo: {
        firstParent: {
          name: 'Antony Kidman',
          email: 'gabi74@gmail.com',
          phone: '+1 084 (755) 08 - 11',
        },
        secondParent: {
          name: 'Jesica Alba-Gabriella',
          email: 'gabi74@gmail.com',
          phone: '+1 084 (755) 08 - 11',
        },
      },
      tests: [
        {
          testId: 222,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3985, color: '#4285F4' },
        },
        {
          testId: 111,
          absent: 0,
          score: { score: 98, color: '#0F9D58' },
          speed: { speed: 3985, color: '#4285F4' },
        },
        {
          testId: 333,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3672, color: '#4285F4' },
        },
        {
          testId: 444,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 3458, color: '#4285F4' },
        },
        {
          testId: 555,
          absent: 0,
          score: { score: 86, color: '#4285F4' },
          speed: { speed: 4235, color: '#4285F4' },
        },
      ],
    },
  ],
};
