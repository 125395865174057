import styled from 'styled-components';

export const StyledContentContainer = styled.div`
/* .ContentContainer {
  padding-left: 10px;
  padding-right: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 0;
  max-width: 320px;
}
@media screen and (min-width: 768px) {
  .ContentContainer {
    max-width: 728px;
  }
} */
@media screen and (min-width: 1440px) {
  .ContentContainer {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 1440px;
  }
}
`