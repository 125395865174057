import { useCallback, useState } from 'react';
import { SubHeaderCollapseStyled } from './SubHeaderCollapseStyled';
import ContentContainer from '../../containers/ContentContainer';
import ExportButton from '../buttons/exportBtn/ExportButton';
import { Alert } from '../Alert/Alert';
import CloseIcon from '@mui/icons-material/Close';
import ArchiveTwoToneIcon from '@mui/icons-material/ArchiveTwoTone';
import UnarchiveTwoToneIcon from '@mui/icons-material/UnarchiveTwoTone';

const SubHeaderCollapse = ({
  activeCounter,
  selectedType,
  setActiveCounter,
  dataType = '',
  openModalUnArchive,
  openModalArchive,
}) => {
  const [isAlert, setIsAlert] = useState({
    type: false,
    text: '',
    close: () => { },
    show: false,
  });
  const callAlert = useCallback((obj) =>
    setIsAlert(obj), []);
  const closeAlert = useCallback(() => setIsAlert({
    type: false,
    text: '',
    close: () => { },
    show: false,
  }), []);

  const selectWord = () => {
    switch (dataType) {
      case 'class':
        return (activeCounter.length <= 1 ? 'class' : 'classes');
      case 'tests':
        return (activeCounter.length <= 1 ? 'test' : 'tests');
      case 'teacher':
        return (activeCounter.length <= 1 ? 'teacher' : 'teachers');
      case 'student':
        return (activeCounter.length <= 1 ? 'student' : 'students');
      default:
        return (activeCounter.length <= 1 ? 'field' : 'fields');
    }
  }

  const contentButton = (selectedType) ? (
    <>
      <UnarchiveTwoToneIcon />
      Unarchive Selected
    </>) : (
    <>
      <ArchiveTwoToneIcon />
      Archive Selected
    </>);

  return (
    <>
      <SubHeaderCollapseStyled>
        <ContentContainer>
          <div className="SubHeaderCollapseWrapper">
            <div className="SubHeaderCollapseInfo">
              {activeCounter.length} {selectWord()} selected
            </div>
            <div className="SubHeaderCollapseBtnWrapper">
              <button className="SubHeaderCollapseBtnCancel" onClick={() => setActiveCounter([])}>
                <CloseIcon />
                Cancel Selection
              </button>
              <ExportButton
                activeClasses={activeCounter}
                fileHeader={dataType}
                btnStyle={'white'}
                callAlert={callAlert}
                closeAlert={closeAlert}
              />
              <button
                className="SubHeaderCollapseBtn"
                style={{ width: selectedType ? 159 : 142 + 'px' }}
                onClick={selectedType ? openModalUnArchive : openModalArchive}>
                {contentButton}
              </button>
            </div>
          </div>
        </ContentContainer>
      </SubHeaderCollapseStyled>
      {isAlert.show &&
        <Alert
          alertType={isAlert.type}
          text={isAlert.text}
          close={isAlert.close}
        />}
    </>
  );
};

export default SubHeaderCollapse;
