import axios from "axios";
import { config } from "../config"

export const getAllSchools = async () => {
  try {
    return await axios.get(`${config.API_URL}/api/schools`);
  } catch (error) {
    throw error;
  }
};

export const addNewSchool = async (school) => {
  try {
    return await axios.post(`${config.API_URL}/api/schools`, school);
  } catch (error) {
    throw error;
  }
};

export const editSchool = async (school) => {
  try {
    return await axios.patch(`${config.API_URL}/api/schools`, school);
  } catch (error) {
    throw error;
  }
};

export const removeSchool = async (id) => {
  try {
    return await axios.delete(`${config.API_URL}/api/schools/${id}`);
  } catch (error) {
    throw error;
  }
};
