import { FC } from 'react';
import styles from './PageHead.module.scss';
import { Button } from '../Button/Button';

interface Props {
  title: string;
  shouldRenderActionButton?: boolean;
  actionButtonText?: string;
  onActionButtonClick?: () => void;
}

export const PageHead: FC<Props> = ({
  title,
  shouldRenderActionButton,
  actionButtonText,
  onActionButtonClick,
}) => {
  return (
    <div className={styles.pageHead}>
      <h2 className={styles.title}>{title}</h2>
      {shouldRenderActionButton && (
        <Button onClick={onActionButtonClick}>{actionButtonText}</Button>
      )}
    </div>
  );
};
