import React, { FC, Children, cloneElement, isValidElement } from 'react';
import styles from './ModalFooter.module.scss';
import { ModalSecondaryButton } from '../buttons/ModalSecondaryButton/ModalSecondaryButton';
import { ModalPrimaryButton } from '../buttons/ModalPrimaryButton/ModalPrimaryButton';



export const ModalFooter: FC = ({ children}) => (
  <div className={styles.root}>
    {Children.map(children, (child) => {
      if (!isValidElement(child)) {
        return null;
      }

      return child.type === ModalSecondaryButton || child.type === ModalPrimaryButton
        ? cloneElement(child, { ...child.props, className: styles.button })
        : child;
    })}
  </div>
);
