import { FC, memo } from 'react';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { routes } from '../../../../routes';
import styles from './SignInUpButtons.module.scss';

const renderLink = (text: string, path: string) => (
  <Link to={path} className={classNames(styles.item, styles.link)}>
    {text}
  </Link>
);

export const SignInUpButtons: FC = memo(() => {
  const { pathname } = useLocation();

  return (
    <div className={styles.root}>
      {pathname.startsWith(routes.signIn()) && (
        <>
          <div className={styles.item}>LOGIN</div>
          {renderLink('REGISTER', routes.signUp())}
        </>
      )}
      {pathname.startsWith(routes.signUp()) && (
        <>
          {renderLink('LOGIN', routes.signIn())}
          <div className={styles.item}>REGISTER</div>
        </>
      )}
    </div>
  );
});
