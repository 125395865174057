import React, {useContext, useState} from 'react';
import { Controller } from 'react-hook-form';
// import axios from 'axios';
// import { config } from "../../../../config"
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { ModalHeader } from '../../../Modal/ModalHeader/ModalHeader';
import { OutlinedTextField } from '../../../OutlinedTextField/OutlinedTextField';
import { IncorrectPasswordPopover } from '../../../../pages/auth/components/IncorrectPasswordPopover/IncorrectPasswordPopover';
import styles from "./ContainChangePassword.module.scss"

const ContainChangePassword = ({
  onClose, 
  prevStep, 
  control, 
  errors, 
  onChange,
  errorInConfirm,
  // setErrorInConfirm
}) => {
  const [showPassword, setShowPassword] = useState(false);
 
  const handleClickShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // open password error tooltip
  const [iconAnchorEl, setIconAnchorEl] = useState(null);
  const openTestInfo = Boolean(iconAnchorEl);
  const handleOpenInfoTest = (event) => setIconAnchorEl(event.currentTarget);
  const handleCloseInfoTeacher = () => setIconAnchorEl(null);
  
  // openConfirmPassword
  const [iconAnchorElConfirm, setIconAnchorElConfirm] = useState(null);
  const openConfirmPassword = Boolean(iconAnchorElConfirm);
  const handleOpenConfirmPassword = (event) => setIconAnchorElConfirm(event.currentTarget);
  const handleCloseConfirmPassword = () => setIconAnchorElConfirm(null);

  return (
    <>
        <div style={{ paddingLeft: 20 }}>
        <ModalHeader header="Change Password" onClose={onClose}  />
         </div>
        <div className={styles.EditProfileModal_ChangePasswordWrapper} onClick={prevStep}>
          <ArrowBackIosNewOutlinedIcon/>
        </div>
      
        <div className={styles.EditProfileModal_ChangePasswordInputsWrapper}>
            {/* PASSWORD */}
             <div style={{ position: 'relative' }}
              className={styles.EditProfileModal_controller_wrapper}>
              <Controller
                control={control}
                name="password"
                rules={{
                  required: true,
                  pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,255}$/,
                }}
                // pattern: /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/g
                render={({ field }) => (
                  <OutlinedTextField
                    variant="outlined"
                    margin="normal"
                    style={{ width: 302 }}
                    name="password"
                    label="Password"
                    type={showPassword ? 'text' : 'password'} 
                    id="password"
                    autoComplete="off"
                    {...field}
                    error={Boolean(errors?.password)}
                    InputProps={{
                      // <-- This is where the toggle button is added.
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onChange={onChange}
                  />
                )}
              />
              {errors.password && (
                <span className={styles.EditProfileModal_errorPasswordInput}>
                  Incorrect Password
                <InfoOutlinedIcon onClick={(e) => handleOpenConfirmPassword(e)}
                style={{ cursor: 'pointer', marginLeft: 5 }}
                />
                  <IncorrectPasswordPopover
                    visible={iconAnchorElConfirm}
                    onClose={handleCloseConfirmPassword}
                    anchorElement={iconAnchorElConfirm}
                  style={{ cursor: 'pointer', marginLeft: 5}} 
                />
                </span>
              )}
             </div>
        
              {/* NEW  PASSWORD */}
             <div style={{ position: 'relative' }}
              className={styles.EditProfileModal_controller_wrapper}>
              <Controller
                control={control}
                name="newPassword"
                rules={{
                  required: true,
                  pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,255}$/,
                }}
                // pattern: /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/g
                render={({ field }) => (
                  <OutlinedTextField
                    variant="outlined"
                    margin="normal"
                    style={{ width: 302 }}
                    name="newPassword"
                    label="New Password"
                    type={showPassword ? 'text' : 'password'} 
                    id="newPassword"
                    autoComplete="off"
                    {...field}
                    error={Boolean(errors?.password)}
                    InputProps={{
                      // <-- This is where the toggle button is added.
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onChange={onChange}
                  />
                )}
              />
              {errors.password && (
                <span className={styles.EditProfileModal_errorPasswordInput}>
                  Incorrect Password
                <InfoOutlinedIcon onClick={(e) => handleOpenConfirmPassword(e)}
                style={{ cursor: 'pointer', marginLeft: 5 }}
                />
                  <IncorrectPasswordPopover
                    visible={iconAnchorElConfirm}
                    onClose={handleCloseConfirmPassword}
                    anchorElement={iconAnchorElConfirm}
                  style={{ cursor: 'pointer', marginLeft: 5 }} 
                />
                </span>
              )}
           </div>
        
             {/*CONFIRM NEW PASSWORD */}
            <div className={styles.EditProfileModal_controller_wrapper}>
              <Controller
                control={control}
                name="confirmNewPassword"
                rules={{
                  required: true,
                  pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,255}$/,
                }}
                // pattern: /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/g
                render={({ field }) => (
                  <OutlinedTextField
                    variant="outlined"
                    margin="normal"
                    style={{ width: 302 }}
                    name="confirmNewPassword"
                    label="Confirm New Password"
                    type={showPassword ? 'text' : 'password'} 
                    id="confirmNewPassword"
                    autoComplete="off"
                    {...field}
                    error={Boolean(errors?.confirmNewPassword)}
                    InputProps={{
                      // <-- This is where the toggle button is added.
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                              style={{ cursor: 'pointer', marginLeft: 5 }} 
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onChange={onChange}
                  />
                )}
              />
              {errors.confirmPassword && (
                <span className={styles.EditProfileModal_errorPasswordInput}>
                  Incorrect Password
                <InfoOutlinedIcon onClick={(e) => handleOpenInfoTest(e)}
                  style={{ cursor: 'pointer', marginLeft: 5 }}
                />
                  <IncorrectPasswordPopover
                    visible={openTestInfo}
                    onClose={handleCloseInfoTeacher}
                    anchorElement={iconAnchorEl}
                  />
                </span>
              )}
              {errorInConfirm && (
                <span className={styles.EditProfileModal_errorPasswordInput}>
                  Password confirmation does not match
                </span>
              )}
            </div>
       </div>
    </>
  );
}

export default ContainChangePassword;