import React, { useState, useEffect, useMemo } from 'react';

import { Typography } from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import CreateIcon from '@material-ui/icons/Create';
import styles from './FirstParentAddedFormFields.module.scss';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';

export const FirstParentAddedFormFields = ({
  nextStep,
  getValues,
  setDisabledBtn,
  setValue,
  setStep,
  setDefaultParent,
  setEditParentIndex,
}) => {
  const parents = getValues('parents');
  const newParent = {
    firstName: getValues('newParentFirstName'),
    lastName: getValues('newParentLastName'),
    email: getValues('newParentEmail'),
    phone: getValues('newParentPhone'),
  };

  const newSelectedParent = getValues('secondParent');
  const savedNewSelectedParent = getValues('savedNewParent');
  const secondCreatedParent = {
    firstName: getValues('secondParentFirstName'),
    lastName: getValues('secondParentLastName'),
    email: getValues('secondParentEmail'),
    phone: getValues('secondParentPhone'),
  }
  const parentsToSubmit = [];

  if (typeof savedNewSelectedParent === 'object' || savedNewSelectedParent?.length) {
    parentsToSubmit.push(savedNewSelectedParent);
  }
  if (parents?.length) {
    parentsToSubmit.push(...parents);
  }
  if (Object.values(newParent).filter(item => item !== '' && item !== undefined).length) {
    parentsToSubmit.push(newParent);
  }
  if (Object.values(secondCreatedParent).filter(item => item !== '' && item !== undefined).length) {
    parentsToSubmit.push(secondCreatedParent);
  }
  if (newSelectedParent?.length) {
    parentsToSubmit.push(newSelectedParent[0]);
  }

  if (getValues('firstEditedParent')) {
    parentsToSubmit[0] = getValues('firstEditedParent');
  }

  if (getValues('secondEditedParent')) {
    parentsToSubmit[1] = getValues('secondEditedParent');
  }

  setValue('parentsToSubmit', parentsToSubmit);

  const [deleteParentNum, setDeleteParentNum] = useState(0);

  return (
    <div className={styles.fieldSet}>

      <Typography className={styles.text} gutterBottom paragraph>
        2/2 Parents information (optional)
      </Typography>

      {parentsToSubmit.length >= 1 && (
        <>
          <div className={styles.parentWrapper}>

            {deleteParentNum === 1 ? (
              <Typography className={styles.parentTextDelete} gutterBottom paragraph>
                <DeleteTwoToneIcon /> Remove Parent?
              </Typography>
            ) : (
              <Typography className={styles.parentText} gutterBottom paragraph disabled={deleteParentNum === 2}>
                Parent 1
              </Typography>
            )}


            {deleteParentNum !== 1 && (
              <div className={styles.parentIconsWrapper} disabled={deleteParentNum === 2}>
                <div className={styles.actionContainer}>
                  <DeleteOutlinedIcon
                    color="disabled"
                    className={styles.actionIcon}
                    onClick={() => {
                      setDisabledBtn(true);
                      setDeleteParentNum(1);
                    }}
                  />
                </div>
                <div className={styles.actionContainer}>
                  <CreateIcon
                    color="disabled"
                    className={styles.actionIcon}
                    onClick={() => {
                      setEditParentIndex(0)
                      setDefaultParent(parentsToSubmit[0]);
                      setStep(5);
                    }}
                  />
                </div>
              </div>
            )}

          </div>
          <div className={styles.newTextWrapper} disabled={deleteParentNum === 2}>
            <Typography className={styles.newText} gutterBottom paragraph>
              <b>Name: </b> {`${parentsToSubmit[0].firstName} ${parentsToSubmit[0].lastName}`}
            </Typography>

            <Typography className={styles.newText} gutterBottom paragraph>
              <b>Email: </b> {parentsToSubmit[0].email}
            </Typography>

            <Typography className={styles.newText} gutterBottom paragraph>
              <b>Phone: </b> {parentsToSubmit[0].phone}
            </Typography>
          </div>

          {deleteParentNum === 1 && (
            <div className={styles.deleteMenuBtns}>
              <div onClick={() => {
                setDisabledBtn(false);
                setDeleteParentNum(0);
              }}>
                Cancel
              </div>
              <div
                style={{ color: '#a6192e' }}
                onClick={() => {
                  if (parents?.length) {
                    const newArr = [...parents];
                    newArr.shift();
                    console.log(newArr);
                    setValue('parents', newArr);
                  } else if (Object.values(newParent).filter(item => item !== '' && item !== undefined).length) {
                    setValue('newParentFirstName', '');
                    setValue('newParentLastName', '');
                    setValue('newParentEmail', '');
                    setValue('newParentPhone', '');
                  } else if (Object.values(secondCreatedParent).filter(item => item !== '' && item !== undefined).length) {
                    setValue('secondParentFirstName', '');
                    setValue('secondParentLastName', '');
                    setValue('secondParentEmail', '');
                    setValue('secondParentPhone', '');
                  } else if (newSelectedParent?.length) (
                    setValue('secondParent', [])
                  )

                  setValue('firstEditedParent', getValues('secondEditedParent'));
                  setValue('secondEditedParent', undefined);



                  if (parentsToSubmit.length !== 1) {
                    setDisabledBtn(false);
                  } else {
                    setValue('savedNewParent', undefined);
                  }
                  setDeleteParentNum(0);
                }}
              >
                Remove
              </div>
            </div>
          )}
        </>
      )}



      {parentsToSubmit.length === 2 ? (
        <>
          <div className={styles.parentWrapper} style={{ marginTop: '16px' }}>
            {deleteParentNum === 2 ? (
              <Typography className={styles.parentTextDelete} gutterBottom paragraph>
                <DeleteTwoToneIcon /> Remove Parent?
              </Typography>
            ) : (
              <Typography className={styles.parentText} gutterBottom paragraph disabled={deleteParentNum === 1}>
                Parent 2
              </Typography>
            )}

            {deleteParentNum !== 2 && (
              <div className={styles.parentIconsWrapper} disabled={deleteParentNum === 1}>
                <div className={styles.actionContainer}>
                  <DeleteOutlinedIcon
                    color="disabled"
                    className={styles.actionIcon}
                    onClick={() => {
                      setDisabledBtn(true);
                      setDeleteParentNum(2);
                    }}
                  />
                </div>
                <div className={styles.actionContainer}>
                  <CreateIcon
                    color="disabled"
                    className={styles.actionIcon}
                    onClick={() => {
                      setEditParentIndex(1)
                      setDefaultParent(parentsToSubmit[1]);
                      setStep(5);
                    }}
                  />
                </div>
              </div>
            )}

          </div>
          <div className={styles.newTextWrapper} disabled={deleteParentNum === 1}>
            <Typography className={styles.newText} gutterBottom paragraph>
              <b>Name: </b> {`${parentsToSubmit[1].firstName} ${parentsToSubmit[1].lastName}`}
            </Typography>

            <Typography className={styles.newText} gutterBottom paragraph>
              <b>Email: </b> {parentsToSubmit[1].email}
            </Typography>

            <Typography className={styles.newText} gutterBottom paragraph>
              <b>Phone: </b> {parentsToSubmit[1].phone}
            </Typography>
          </div>
        </>
      ) : (
        <>
          {deleteParentNum === 0 && (
            <Typography
              type='button'
              className={styles.textAccent}
              gutterBottom
              paragraph
              onClick={() => {
                if (parentsToSubmit.length === 0) {
                  setStep(2);
                } else {
                  if (Object.values(secondCreatedParent).filter(item => item !== '' && item !== undefined).length) {
                    setValue('savedNewParent', secondCreatedParent);
                  }
                  if (newSelectedParent?.length) {
                    setValue('savedNewParent', newSelectedParent[0]);
                  }

                  nextStep();
                }
              }}
            >
              Add One More Parent
            </Typography>
          )}
        </>
      )}

      {deleteParentNum === 2 && (
        <div className={styles.deleteMenuBtns}>
          <div onClick={() => {
            setDisabledBtn(false);
            setDeleteParentNum(0);
          }}>
            Cancel
          </div>
          <div
            style={{ color: '#a6192e' }}
            onClick={() => {
              if (parents?.length === 2) {
                const newArr = [...parents];
                newArr.pop();
                setValue('parents', newArr);
              } else if (Object.values(newParent).filter(item => item !== '' && item !== undefined).length) {
                setValue('newParentFirstName', '');
                setValue('newParentLastName', '');
                setValue('newParentEmail', '');
                setValue('newParentPhone', '');
              } else if (Object.values(secondCreatedParent).filter(item => item !== '' && item !== undefined).length) {
                setValue('secondParentFirstName', '');
                setValue('secondParentLastName', '');
                setValue('secondParentEmail', '');
                setValue('secondParentPhone', '');
              } else if (newSelectedParent?.length) (
                setValue('secondParent', [])
              )

              setValue('secondEditedParent', undefined);

              if (parentsToSubmit.length !== 1) {
                setDisabledBtn(false);
              }
              setDeleteParentNum(0);
            }}
          >
            Remove
          </div>
        </div>
      )}

    </div>
  );
};
