import { useCallback, useState } from 'react';
import HeaderProfilePhoto from './HeaderProfilePhoto/HeaderProfilePhoto';
import HeaderProfilePopover from './HeaderProfilePopover/HeaderProfilePopover';
import EditProfileModal from './EditProfileModal/EditProfileModal';

const HeaderProfile = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [modalEditProfileOpen, setModalEditProfileOpen] = useState(false);

  // === for HeaderProfilePopover
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    // console.log(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // === for EditProfileModal
 
  const handleEditProfileModalOpen = useCallback(() => {
    setAnchorEl(null);
    setModalEditProfileOpen(true);
    // console.log('handleEditProfileModalOpen')
    },
    []
  );

  const handleEditProfileModalClose = useCallback(() => setModalEditProfileOpen(false), []);

  return (
      <>
         <HeaderProfilePhoto handleClick={handleClick} />
         <HeaderProfilePopover
            open={open}
            anchorEl={anchorEl}
            handleClose={handleClose}
            handleEditProfileModalOpen={handleEditProfileModalOpen} />
        {modalEditProfileOpen && (
            <EditProfileModal onClose={handleEditProfileModalClose}  />
        )}
      </>  
  )
}

export default HeaderProfile;