import React, { FC } from 'react';
import { ModalButton } from '../ModalButton/ModalButton';

interface Props {
  onClick?: () => void;
  className?: string;
}

export const ModalSecondaryButton: FC<Props> = ({ onClick, className, children }) => (
  <ModalButton onClick={onClick} className={className}>
    {children}
  </ModalButton>
);
