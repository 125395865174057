import React, { useState, useEffect } from 'react';

import { Typography } from '@material-ui/core';
import { OutlinedTextField } from '../../../../../components/OutlinedTextField/OutlinedTextField';
import styles from './StudentsEditParentFormFields.module.scss';
import SelectParent from '../../../../../components/SelectParents/SelectParent';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


export const StudentsEditParentFormFields = ({
  setStep,
  register,
  errors,
  control,
  getValues,
  setValue,
  defaultParent,
  editParentIndex
}) => {
  const [parentsCounter, setParentsCounter] = useState(defaultParent?.id ? [defaultParent].flat() : []);

  return (
    <div className={styles.fieldSet}>

      <Typography
        className={styles.text}
        gutterBottom
        paragraph
      >
        2/2 Parents information (optional)
      </Typography>

      {/* <Typography className={styles.parentText} gutterBottom paragraph>
        Edit Parent
      </Typography> */}

      <SelectParent
        formValue={'editParent'}
        className={styles.formField}
        control={control}
        setParentsCounter={setParentsCounter}
        defaultValue={defaultParent?.id ? defaultParent : ''}
      />

      <Typography
        className={styles.newText}
        gutterBottom
        paragraph
        disabled={parentsCounter?.length === 1}
      >
        Edit Parent
      </Typography>

      <div
        className={styles.inputWrapper}
        key={parentsCounter.length + 1}
        disabled={parentsCounter?.length === 1}>
        <OutlinedTextField
          {...register('editFirstName', {
            required: parentsCounter.length === 0 ? 'Required Field' : false,
          })}
          fullWidth
          label="First Name"
          defaultValue={defaultParent?.id ? '' : defaultParent.firstName}
          placeholder={'Enter First Name'}
          className={styles.formField}
        >
        </OutlinedTextField>


        <div className={styles.textHelper}>
          {errors?.editFirstName &&
            (
              <>
                <p>{errors?.editFirstName?.message || 'Error!'}</p>
                <InfoOutlinedIcon />
              </>
            )}
        </div>

      </div>

      <div
        key={parentsCounter.length + 2}
        className={styles.inputWrapper}
        disabled={parentsCounter?.length === 1}>
        <OutlinedTextField
          {...register('editLastName', {
            required: parentsCounter.length === 0 ? 'Required Field' : false,
          })}
          fullWidth
          label="Last Name"
          defaultValue={defaultParent?.id ? '' : defaultParent.lastName}
          placeholder={'Enter Last Name'}
          className={styles.formField}
        >
        </OutlinedTextField>


        <div className={styles.textHelper}>
          {errors?.editLastName &&
            (
              <>
                <p>{errors?.editLastName?.message || 'Error!'}</p>
                <InfoOutlinedIcon />
              </>
            )}
        </div>


      </div>

      <div
        key={parentsCounter.length + 3}
        className={styles.inputWrapper}
        disabled={parentsCounter?.length === 1}>
        <OutlinedTextField
          key={parentsCounter.length}
          {...register('editEmail', {
            required: parentsCounter.length === 0 ? 'Required Field' : false,
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: "Entered value does not match email format"
            }
          })}
          fullWidth
          label="Email"
          defaultValue={defaultParent?.id ? '' : defaultParent.email}
          placeholder={'XXXXXXX@XXXX.XX'}
          className={styles.formField}
        >
        </OutlinedTextField>

        <div className={styles.textHelper} disabled={parentsCounter?.length === 2}>
          {errors?.editEmail &&
            (
              <>
                <p>{errors?.editEmail?.message || 'Error!'}</p>
                <InfoOutlinedIcon />
              </>
            )}
        </div>


      </div>

      <div
        key={parentsCounter.length + 4}
        className={styles.inputWrapper}
        disabled={parentsCounter?.length === 1}>
        <OutlinedTextField
          key={parentsCounter.length}
          {...register('editPhone', {
            required: parentsCounter.length === 0 ? 'Required Field' : false,
            pattern: {
              value: /[0-9]{3}-[0-9]{3}-[0-9]{4}/,
              message: "Entered value does not match phone format"
            }
          })}
          fullWidth
          label="Phone"
          defaultValue={defaultParent?.id ? '' : defaultParent.phone}
          placeholder={'XXX-XXX-XXXX'}
        >
        </OutlinedTextField>

        <div
          style={{ bottom: '5px' }}
          className={styles.textHelper}>
          {errors?.editPhone &&
            (
              <>
                <p>{errors?.editPhone?.message || 'Error!'}</p>
                <InfoOutlinedIcon />
              </>
            )}
        </div>


      </div>

      <div className={styles.bottomBtns}>
        <button
          onClick={() => {
            setValue('editParent', undefined);
            setValue('editFirstName', undefined);
            setValue('editLastName', undefined);
            setValue('editEmail', undefined);
            setValue('editPhone', undefined);
            setStep(3);
          }}
        >
          Back
        </button>
        <button
          type='submit'
          style={{ color: '#A6192E' }}
          onClick={() => {
            let newValue;

            if (parentsCounter.length === 1) {
              newValue = getValues('editParent')[0];
            } else {
              newValue = {
                firstName: getValues('editFirstName'),
                lastName: getValues('editLastName'),
                email: getValues('editEmail'),
                phone: getValues('editPhone'),
              }
            }

            if (editParentIndex === 0){
              setValue('firstEditedParent', newValue)
            } else {
              setValue('secondEditedParent', newValue)
            }

            setValue('editParent', undefined);
            setValue('editFirstName', undefined);
            setValue('editLastName', undefined);
            setValue('editEmail', undefined);
            setValue('editPhone', undefined);
            setStep(3);
          }}
        >
          Save Changes
        </button>
      </div>


    </div>
  );
};
