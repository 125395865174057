import React from 'react';

export const ControllerObjs = {
  signIn: {
    className: 'signIn',
    addClassName: '',
    text: 'SIGN IN',
    href: '',
  },
  send: {
    className: 'send',
    addClassName: '',
    text: 'SEND',
    href: '',
  },
  backToSignIn: {
    className: 'backToSignIn',
    addClassName: '',
    text: 'BACK TO SIGN IN',
    href: '',
  },
  signUp: {
    className: 'signUp',
    addClassName: '',
    text: 'SIGN UP',
    href: '',
  },
  next: {
    className: 'next',
    addClassName: 'defaultBtnBig',
    text: 'NEXT',
    href: '',
  },
  finish: {
    className: 'finish',
    addClassName: 'defaultBtnBig',
    text: 'FINISH',
    href: '',
  },
  startWorking: {
    className: 'startWorking',
    addClassName: 'defaultBtnBig',
    text: 'START WORKING',
    href: '',
  },
  editProfile: {
    className: 'editProfile',
    addClassName: '',
    text: 'EDIT PROFILE',
    href: '',
  },
  saveChanges: {
    className: 'saveChanges',
    addClassName: '',
    text: 'SAVE CHANGES',
    href: '',
  },
  add: {
    className: 'add',
    addClassName: '',
    text: 'ADD',
    href: '',
  },
  okRed: {
    className: 'add',
    addClassName: '',
    text: 'OK',
    href: '',
  },
  login: {
    className: 'add',
    addClassName: '',
    text: 'LOGIN',
    href: '',
  },

  addStudent: {
    className: 'addStudent',
    addClassName: '',
    text: 'ADD STUDENT',
    href: '',
  },
  addTest: {
    className: 'addTest',
    addClassName: '',
    text: 'ADD TEST',
    href: '',
  },
  ok: {
    className: 'ok',
    addClassName: 'defaultBtnOkBlack',
    text: 'OK',
    href: '',
  },
  archive: {
    className: 'archive',
    addClassName: 'defaultBtnBlack',
    text: 'ARCHIVE',
    href: '',
  },
  upgrade: {
    className: 'upgrade',
    addClassName: 'defaultBtnBlack',
    text: 'UPGRADE',
    href: '',
  },
  editResults: {
    className: 'editResults',
    addClassName: 'defaultBtn',
    text: 'EDIT RESULTS',
    href: '',
  },
  cancel: {
    className: 'editResults',
    addClassName: 'defaultBtn',
    text: 'Cancel',
    href: '',
  },
};
