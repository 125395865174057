import React, { useState } from 'react';
import { ModalWrapper } from '../../../Modal/ModalWrapper/ModalWrapper';
import { ModalHeader } from '../../../Modal/ModalHeader/ModalHeader';
import { ModalFooter } from '../../../Modal/ModalFooter/ModalFooter';
import { ModalPrimaryButton } from '../../../Modal/buttons/ModalPrimaryButton/ModalPrimaryButton';
import { ModalSecondaryButton } from '../../../Modal/buttons/ModalSecondaryButton/ModalSecondaryButton';
import { Controller, useForm } from 'react-hook-form';
import { OutlinedTextField } from '../../../OutlinedTextField/OutlinedTextField';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { editSchool } from '../../../../services/schools';
import styles from "./EditSchoolModal.module.scss"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';



const EditSchoolModal = ({
  schools,
  onClose,
  currentSchool,
  setShowLoader,
  updateSchools,
  setCurrentSchoolId,
  mouseOverSchoolId,
  setUpdateSchoolInfo
}) => {
  console.log('currentSchool', currentSchool);
  const [editedSchool, setEditedSchool] = useState({});

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onBlur',
  });

  const schoolToEdit = {
    id: currentSchool.Id,
    name: editedSchool?.schoolName ?? currentSchool.Name,
    address: editedSchool?.schoolAddress ?? '',
  };

  const onSubmit = async () => {
    console.log('currentSchool', currentSchool);
    console.log('editedSchool.schoolName', editedSchool.schoolName);
    console.log('editedSchool.schoolAddress', editedSchool.schoolAddress);
    console.log('schoolToEdit', schoolToEdit);

    // if (!editedSchool.schoolName || editedSchool.schoolName === undefined) {
    //   return;
    // }
    setShowLoader(true);
    editSchool(schoolToEdit)
      .then((response) => {
        // console.log("EditSchool-response", response);
        updateSchools((prevSchools) =>
          prevSchools.map((prevSchool) => {
            if (prevSchool.Id === schoolToEdit.id) {
              const editedSchool = {
                Id: schoolToEdit.id,
                Name: schoolToEdit.name,
                Address: schoolToEdit.address,
                ClassCount: 0,
                TeacherCount: 0,
                TestCount: 0,
                StudentCount: 0,
              };
              setCurrentSchoolId(currentSchool.Id);
              localStorage.setItem('currentSchoolId', JSON.stringify(currentSchool.Id));
              setUpdateSchoolInfo((prev) => !prev);
              return editedSchool;
            } else {
              return prevSchool;
            }
          })
        );
        setShowLoader(false);
      })
      .catch(function (error) {
        console.log('error', error);
        setShowLoader(false);
      });
    onClose();
  };

  const onChange = ({ target }) => {
    const { name, value } = target;
    setEditedSchool((prevState) => ({ ...prevState, [name]: value }));
    // console.log('editedSchoolOnChange', editedSchool)
  };

  return (
    <ModalWrapper>
      <form className={styles.EditSchoolModal_wrapper} onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader header="Edit School" onClose={onClose} />
        <div className={styles.inputWrapper}>
          <OutlinedTextField
            {...register('schoolName', {
              required: 'Required Field',
              minLength: 1,
            })}
            fullWidth
            label="School name"
            autoComplete="off"
            defaultValue={currentSchool?.Name}
            style={{ width: 302, margin: 0 }}
            onChange={onChange}
          />
                  <div className={styles.textHelper}>
            {errors?.schoolName && (
              <>
                <span>{errors?.schoolName?.message || 'Required Field'}</span>
                <InfoOutlinedIcon />
              </>
            )}
          </div>
        </div>
        <div className={styles.inputWrapper}>
          <Controller
            control={control}
            name="schoolAddress"
            render={({ field }) => (
              <OutlinedTextField
                variant="outlined"
                margin="normal"
                id="schoolAddress"
                color="primary"
                label="Address (optional)"
                name="schoolAddress"
                defaultValue={currentSchool?.Address}
                {...field}
                onChange={onChange}
                style={{ width: 302, margin: 0, marginTop: 26 }}
                autoComplete="off"
                error={Boolean(errors?.schoolAddress)}
                helperText={errors.schoolAddress?.message}
              />
            )}
          />
        </div>
        <ModalFooter>
          <ModalSecondaryButton onClick={onClose}>Cancel</ModalSecondaryButton>
          <ModalPrimaryButton onClick={onSubmit}>Save Changes</ModalPrimaryButton>
        </ModalFooter>
      </form>
    </ModalWrapper>
  );
};

export default EditSchoolModal;

// ===== SCHOOL ENDPOINTS TO BACKEND =====
// Update school
// base_url/api/school
// type PATCH
// request: {
// 	id: 0,
// 	name: '',
// 	address: ''
// }