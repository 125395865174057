import styled, { css } from 'styled-components/macro';

// import StyledTableRow from '../TableRow/StyledTableRow';

// props {checkBox, columns}
export const StyledTableRow = styled.div`
  box-sizing: border-box;
  margin: auto;
  margin-top: ${(props) => (props.margin === true ? '3px' : null)};
  margin-bottom: ${(props) => (props.margin === true ? '3px' : null)};
  padding-left: ${(props) =>
    props.typeTableRow.rowPaddingLeft ? props.typeTableRow.rowPaddingLeft : '20px'};

  font-size: ${(props) => (props.typeTableRow.fontSize ? props.typeTableRow.fontSize : '1.4rem')};
  padding-right: 20px;
  display: grid;
  grid-template-columns: ${(props) => props.typeTableRow.tableCellWidth};

  width: ${(props) => props.typeTableRow.tableWidth};

  height: ${(props) => (props.typeTableRow.height ? props.typeTableRow.height : '48px')};

  /* 48px; */
  grid-column-gap: ${(props) => (props.typeTableRow.gridGap ? props.typeTableRow.gridGap : '24px')};

  box-shadow: ${(props) =>
    props.typeTableRow.boxShadow
      ? props.typeTableRow.boxShadow
      : ' 0px 1px 4px rgba(91, 91, 91, 0.15)'};

  /* 0px 1px 4px rgba(91, 91, 91, 0.15); */

  color: ${(props) => (props.tableRowColor ? props.tableRowColor : '#828282')};

  background-color: ${(props) =>
    props.typeTableRow.background
      ? props.typeTableRow.background
      : props.index % 2 === 1
        ? '#F9F9F9'
        : '#FFFFFF'};

  background-color: ${(props) => (props.testStatus === 'inProgress' ? '#F7E5E5' : null)};

  border: ${(props) => (props.testStatus === 'inProgress' ? '1px solid #A6192E' : null)};

   ${props => props.selected && css`
    background-color: #f2f2f2;
    border: 1px solid #c0c0c0;
  `}
`;
