import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CheckBoxOutlineBlankTwoToneIcon from '@mui/icons-material/CheckBoxOutlineBlankTwoTone';
import CheckBoxTwoToneIcon from '@mui/icons-material/CheckBoxTwoTone';
import axios from 'axios';

import { LoaderFullBook } from '../../../components/Loaders/LoaderFullBook/LoaderFullBook';
import { SignInUpButtons } from '../components/SignInUpButtons/SignInUpButtons';
import { PageContainer } from '../components/PageContainer/PageContainer';
import { VendorSignInButtons } from '../components/VendorSignInButtons/VendorSignInButtons';
import { ContentHead } from '../components/ContentHead/ContentHead';
import { FormHeader } from '../components/FormHeader/FormHeader';
import { MemoriaLogo } from '../components/MemoriaLogo/MemoriaLogo';
import { IncorrectPasswordPopover } from '../components/IncorrectPasswordPopover/IncorrectPasswordPopover';
import { OutlinedTextField } from '../../../components/OutlinedTextField/OutlinedTextField';
import { config } from "../../../config"
import { AppContext } from '../../../context/AppContext';
import styles from './SignUpPage.module.scss';
import { IncorrectEmailPopover } from '../components/IncorrectEmailPopover/IncorrectEmailPopover';

export const SignUpPage = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [errorInConfirm, setErrorInConfirm] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [acceptPolicy, setAcceptPolicy] = useState(false);
  const history = useHistory();

  const {user, updateUser, setUserId}= useContext(AppContext);

  const {
    handleSubmit,
    control,
    formState: { errors },
    } = useForm({
      mode: 'onBlur',
    });

  const handleClickShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = async (values) => {
    if (!acceptPolicy) return;
    if (values.password !== values.confirmPassword) {
      setErrorInConfirm(true);
      return;
    }
    setShowLoader(true);
    // console.log('data-values', values);
    const dataToSend = {
      email: values.email,
      password: values.password,
    };

    try {
      const data = await axios.post(`${config.API_URL}/api/auth/signup`, dataToSend);
      if (data.data) {
        // console.log('responseIdData', data.data)
        // console.log('responseIdData', data.data.addUserResult)
        // console.log('responseIdData', data.data.addUserResult.Id)
        updateUser({
          ...user,
          email: dataToSend.email, 
          password: dataToSend.password,
          id: data.data.addUserResult.Id
        })
        setUserId(data.data.addUserResult.Id)
        setShowLoader(false);
      }
    } catch (e) {
      console.log(e);
      setShowLoader(false);
    }
    // console.log('user', user)
    // console.log('userId', userId)
    history.push("/onboarding")
  };

  // open email tooltip
  const [iconAnchorElConfirmEmail, setIconAnchorElConfirmEmail] = useState(null);
  const openConfirmEmail = Boolean(iconAnchorElConfirmEmail);
  const handleOpenConfirmEmail = (event) => setIconAnchorElConfirmEmail(event.currentTarget);
  const handleCloseConfirmEmail = () => setIconAnchorElConfirmEmail(null);

  // open password tooltip
  const [iconAnchorElConfirmPassword, setIconAnchorElConfirmPassword] = useState(null);
  const openConfirmPassword = Boolean(iconAnchorElConfirmPassword);
  const handleOpenConfirmPassword = (event) => setIconAnchorElConfirmPassword(event.currentTarget);
  const handleCloseConfirmPassword = () => setIconAnchorElConfirmPassword(null);

  return (
    <PageContainer>
      {showLoader && <LoaderFullBook />}
      <MemoriaLogo />
      <div className={styles.pageBody}>
        <ContentHead />
        <SignInUpButtons />
        <FormHeader>Create your account</FormHeader>
        <form className={styles.singUp_form} onSubmit={handleSubmit((values) => onSubmit(values))}>
          <div className={styles.singUp_controller_wrapper}>
            <Controller
              control={control}
              name="email"
              rules={{
                required: true,
                pattern: /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/g,
              }}
              render={({ field }) => (
                <OutlinedTextField
                  id="outlined-basic"
                  label="Email"
                  placeholder={'XXXX@XXXX.XX'}
                  variant="outlined"
                  style={{ width: 302 }}
                  autoComplete="off"
                  name="email"
                  {...field}
                  error={Boolean(errors?.email)}
                  helperText={errors.email?.message}
                />
              )}
            />
            {errors.email && (
              <span className={styles.singUp_errorEmailInput}>
                Please, enter valid e-mail
                <ReportProblemOutlinedIcon
                  style={{ cursor: 'pointer', marginLeft: 5 }}
                  onClick={(e) => handleOpenConfirmEmail(e)}
                />
                <IncorrectEmailPopover
                  visible={openConfirmEmail}
                  onClose={handleCloseConfirmEmail}
                  anchorElement={iconAnchorElConfirmEmail}
                />
              </span>
            )}
          </div>
          <div style={{ height: 8 }} className=""></div>
          <div style={{ position: 'relative' }} className={styles.singUp_controller_wrapper}>
            <Controller
              control={control}
              name="password"
              rules={{
                required: true,
                pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,255}$/,
              }}
              // pattern: /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/g
              render={({ field }) => (
                <OutlinedTextField
                  variant="outlined"
                  margin="normal"
                  style={{ width: 302 }}
                  name="password"
                  label="Password"
                  type={showPassword ? 'text' : 'password'} // <- magic
                  id="password"
                  autoComplete="off"
                  {...field}
                  error={Boolean(errors?.password)}
                  helperText={errors.password?.message}
                  InputProps={{
                    // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            {errors.password && (
              <span className={styles.singUp_errorPasswordInput}>
                Incorrect Password
                <InfoOutlinedIcon
                  style={{ cursor: 'pointer', marginLeft: 5 }}
                  onClick={(e) => handleOpenConfirmPassword(e)}
                />
                <IncorrectPasswordPopover
                  visible={openConfirmPassword}
                  onClose={handleCloseConfirmPassword}
                  anchorElement={iconAnchorElConfirmPassword}
                />
              </span>
            )}
          </div>
          <div className={styles.singUp_controller_wrapper}>
            <Controller
              control={control}
              name="confirmPassword"
              rules={{
                required: true,
                pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,255}$/,
              }}
              // pattern: /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/g
              render={({ field }) => (
                <OutlinedTextField
                  variant="outlined"
                  margin="normal"
                  style={{ width: 302 }}
                  name="confirmPassword"
                  label="Confirm Password"
                  type={showPassword ? 'text' : 'password'} // <- magic
                  id="confirmPassword"
                  autoComplete="off"
                  {...field}
                  error={Boolean(errors?.confirmPassword)}
                  helperText={errors.confirmPassword?.message}
                  InputProps={{
                    // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          style={{ cursor: 'default', marginLeft: 5 }}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            {errors.confirmPassword && (
              <span className={styles.singUp_errorPasswordInput}>
                Incorrect Password
                <InfoOutlinedIcon
                  style={{ cursor: 'pointer', marginLeft: 5 }}
                  onClick={(e) => handleOpenConfirmPassword(e)}
                />
                <IncorrectPasswordPopover
                  visible={openConfirmPassword}
                  onClose={handleCloseConfirmPassword}
                  anchorElement={iconAnchorElConfirmPassword}
                />
              </span>
            )}
            {errorInConfirm && (
              <span className={styles.singUp_errorPasswordInput}>
                Password confirmation does not match
              </span>
            )}
          </div>
          <div
            style={{ background: acceptPolicy ? '#F2F2F2' : null }}
            className={styles.singUpPage_acceptionWrapper}
            onClick={() => setAcceptPolicy((prevState) => !prevState)}
          >
            {!acceptPolicy ? (
              <CheckBoxOutlineBlankTwoToneIcon style={{ color: '#777777' }} />
            ) : (
              <CheckBoxTwoToneIcon style={{ color: '#777777' }} />
            )}
            <div className={styles.singUpPage_acception_tooltip}>
              I accept the <b>Terms of Use</b> & <b>Privacy Policy</b>
            </div>
          </div>
          <div className={styles.singUpPage_actionWrapper}>
            <button
              type="submit"
              className={styles.singUpPage_submitSingUpBtn}
              style={{
                background: acceptPolicy ? '#A6192E' : '#F2F2F2',
                cursor: acceptPolicy ? 'pointer' : 'not-allowed',
              }}
            >
              REGISTER
            </button>
          </div>
        </form>

        <VendorSignInButtons />
      </div>
    </PageContainer>
  );
};
