import { TableCell } from '../TableCell/TableCell';
import { StyledTableRow } from './StyledTableRow';
import './TableRow.scss';

export const TableRow = ({ typeTableRow, index, children, tableRowColor, testStatus, margin, selected }) => {
  // console.log('tableRowColo', tableRowColor)
  return (
    // <div className="tableRow_wrapper_background">
    <StyledTableRow
      tableRowColor={tableRowColor}
      index={index}
      typeTableRow={typeTableRow}
      testStatus={testStatus}
      margin={margin}
      selected={selected}
    >
      {children}
    </StyledTableRow>
    //  </div>
  );
};
