import React from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import img from '../../../images/noSearchResults.svg';
import styles from './NoSearchResultsIndicator.module.scss';

function NoSearchResultsIndicator({ searchValue, backFn }) {
  return (
    <>
      <div className={styles.inner}>
        <ArrowBackIosNewIcon onClick={() => backFn()} />
        <h2 className={styles.title}>
          <span className={styles.text}>
            {searchValue === '' ? ' 0 results' : ' 0 results for'}
          </span>
          {searchValue !== '' ? (
            <span className={styles.value}>"{searchValue}"</span>
          ) : ''}
        </h2>
      </div>
      <div className={styles.img}>
        <img
          src={img}
          width="405"
          height="388"
          alt="The search has not given any results, try again!"
        />
      </div>
    </>
  );
}

export default NoSearchResultsIndicator;
