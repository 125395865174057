export const responseDataStudents = [
  {
    name: 'John Doe',
    id: '7512552212',
    class: '1D',
    isArchive: 0,
    avScore: { score: '96%', color: '#0F9D58' },
    avSpeed: { speed: 'BelowExpected', color: '#DB4437' },
    parents: 'Antony Kidman, Jesica Alba-Gabriella',
    parentsDetailInfo: {
      firstParent: {
        name: 'Antony Kidman',
        email: 'gabi74@gmail.com',
        phone: '+1 084 (755) 08 - 11',
      },
      secondParent: {
        name: 'Jesica Alba-Gabriella',
        email: 'gabi74@gmail.com',
        phone: '+1 084 (755) 08 - 11',
      },
    },
    studentDetailInfo: {
      tests: [
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '-', color: '' },
          speed: { speed: '-', color: '' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: false,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
      ],
      name: 'John Doe',
      id: '7512552212',
      avScore: { score: '96%', color: '#0F9D58' },
      avSpeed: { speed: 'BelowExpected', color: '#DB4437' },
    },
  },
  {
    name: 'Ferdinand El Mundo',
    id: '7512552313',
    class: '1D',
    isArchive: 0,
    avScore: { score: '76%', color: '#E2B534' },
    avSpeed: { speed: 'Above Expecte', color: '#4285F4' },
    parents: 'Antony Kidman, Jesica Alba-Gabriella',
    parentsDetailInfo: {},
    studentDetailInfo: {
      tests: [
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
      ],
      name: 'John Doe',
      id: '7512552212',
      avScore: { score: '96%', color: '#0F9D58' },
      avSpeed: { speed: 'BelowExpected', color: '#DB4437' },
    },
  },
  {
    name: 'Adam Lambert',
    id: '7512552214',
    class: '1D',
    isArchive: 1,
    avScore: { score: '49%', color: '#DB4437' },
    avSpeed: { speed: 'Above Expected', color: '#4285F4' },
    parents: 'Antony Kidman, Jesica Alba-Gabriella',
    parentsDetailInfo: {
      firstParent: {
        name: 'Antony Kidman',
        email: 'gabi74@gmail.com',
        phone: '+1 084 (755) 08 - 11',
      },
      secondParent: {
        name: 'Jesica Alba-Gabriella',
        email: 'gabi74@gmail.com',
        phone: '+1 084 (755) 08 - 11',
      },
    },
    studentDetailInfo: {
      tests: [
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
      ],
      name: 'John Doe',
      id: '7512552212',
      avScore: { score: '96%', color: '#0F9D58' },
      avSpeed: { speed: 'BelowExpected', color: '#DB4437' },
    },
  },
  {
    name: 'Benjamin Button',
    id: '7512552215',
    class: '1D',
    isArchive: 0,
    avScore: { score: '81%', color: '#0F9D58' },
    avSpeed: { speed: 'As Expected', color: '#0F9D58' },
    parents: 'Antony Kidman, Jesica Alba-Gabriella',
    parentsDetailInfo: {
      firstParent: {
        name: 'Antony Kidman',
        email: 'gabi74@gmail.com',
        phone: '+1 084 (755) 08 - 11',
      },
      secondParent: {
        name: 'Jesica Alba-Gabriella',
        email: 'gabi74@gmail.com',
        phone: '+1 084 (755) 08 - 11',
      },
    },
    studentDetailInfo: {
      tests: [
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
      ],
      name: 'John Doe',
      id: '7512552212',
      avScore: { score: '96%', color: '#0F9D58' },
      avSpeed: { speed: 'BelowExpected', color: '#DB4437' },
    },
  },
  {
    name: 'Christine Jobs',
    id: '7512552216',
    class: '1D',
    isArchive: 0,
    avScore: { score: '94', color: '#4285F4' },
    avSpeed: { speed: 'As Expected', color: '#0F9D58' },
    parents: 'Antony Kidman, Jesica Alba-Gabriella',
    parentsDetailInfo: {
      firstParent: {
        name: 'Antony Kidman',
        email: 'gabi74@gmail.com',
        phone: '+1 084 (755) 08 - 11',
      },
      secondParent: {
        name: 'Jesica Alba-Gabriella',
        email: 'gabi74@gmail.com',
        phone: '+1 084 (755) 08 - 11',
      },
    },
    studentDetailInfo: {
      tests: [
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
      ],
      name: 'John Doe',
      id: '7512552212',
      avScore: { score: '96%', color: '#0F9D58' },
      avSpeed: { speed: 'BelowExpected', color: '#DB4437' },
    },
  },
  {
    name: 'Karlos DeLa Muchos',
    id: '7512552217',
    class: '1D',
    isArchive: 1,
    avScore: { score: '55%', color: '#E2B534' },
    avSpeed: { speed: 'Below expected', color: '#DB4437' },
    parents: 'Antony Kidman, Jesica Alba-Gabriella',
    parentsDetailInfo: {
      firstParent: {
        name: 'Antony Kidman',
        email: 'gabi74@gmail.com',
        phone: '+1 084 (755) 08 - 11',
      },
      secondParent: {
        name: 'Jesica Alba-Gabriella',
        email: 'gabi74@gmail.com',
        phone: '+1 084 (755) 08 - 11',
      },
    },
    studentDetailInfo: {
      tests: [
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
      ],
      name: 'John Doe',
      id: '7512552212',
      avScore: { score: '96%', color: '#0F9D58' },
      avSpeed: { speed: 'BelowExpected', color: '#DB4437' },
    },
  },
  {
    name: 'Simon Simpson',
    id: '7512552218',
    class: '1D',
    isArchive: 0,
    avScore: { score: '89%', color: '#0F9D58' },
    avSpeed: { speed: 'Above Expected', color: '#4285F4' },
    parents: 'Antony Kidman, Jesica Alba-Gabriella',
    parentsDetailInfo: {
      firstParent: {
        name: 'Antony Kidman',
        email: 'gabi74@gmail.com',
        phone: '+1 084 (755) 08 - 11',
      },
      secondParent: {
        name: 'Jesica Alba-Gabriella',
        email: 'gabi74@gmail.com',
        phone: '+1 084 (755) 08 - 11',
      },
    },
    studentDetailInfo: {
      tests: [
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
      ],
      name: 'John Doe',
      id: '7512552212',
      avScore: { score: '96%', color: '#0F9D58' },
      avSpeed: { speed: 'BelowExpected', color: '#DB4437' },
    },
  },
  {
    name: 'Sirena Evans',
    id: '7512552219',
    class: '1D',
    isArchive: 0,
    avScore: { score: '81%', color: '#0F9D58' },
    avSpeed: { speed: 'As Expected', color: '#0F9D58' },
    parents: '',
    parentsDetailInfo: {},
    studentDetailInfo: {
      tests: [
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
      ],
      name: 'John Doe',
      id: '7512552212',
      avScore: { score: '96%', color: '#0F9D58' },
      avSpeed: { speed: 'BelowExpected', color: '#DB4437' },
    },
  },
  {
    name: 'Mickle Jerry',
    id: '7512552220',
    class: '1D',
    isArchive: 0,
    avScore: { score: '84%', color: '#0F9D58' },
    avSpeed: { speed: 'As Expected', color: '#0F9D58' },
    parents: 'Antony Kidman, Jesica Alba-Gabriella',
    parentsDetailInfo: {
      firstParent: {
        name: 'Antony Kidman',
        email: 'gabi74@gmail.com',
        phone: '+1 084 (755) 08 - 11',
      },
      secondParent: {
        name: 'Jesica Alba-Gabriella',
        email: 'gabi74@gmail.com',
        phone: '+1 084 (755) 08 - 11',
      },
    },
    studentDetailInfo: {
      tests: [
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
      ],
      name: 'John Doe',
      id: '7512552212',
      avScore: { score: '96%', color: '#0F9D58' },
      avSpeed: { speed: 'BelowExpected', color: '#DB4437' },
    },
  },
  {
    name: 'John Doe',
    id: '7512552212',
    class: '1D',
    isArchive: 0,
    avScore: { score: '96%', color: '#0F9D58' },
    avSpeed: { speed: 'BelowExpected', color: '#DB4437' },
    parents: 'Antony Kidman, Jesica Alba-Gabriella',
    parentsDetailInfo: {
      firstParent: {
        name: 'Antony Kidman',
        email: 'gabi74@gmail.com',
        phone: '+1 084 (755) 08 - 11',
      },
      secondParent: {
        name: 'Jesica Alba-Gabriella',
        email: 'gabi74@gmail.com',
        phone: '+1 084 (755) 08 - 11',
      },
    },
    studentDetailInfo: {
      tests: [
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '-', color: '' },
          speed: { speed: '-', color: '' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: false,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
      ],
      name: 'John Doe',
      id: '7512552212',
      avScore: { score: '96%', color: '#0F9D58' },
      avSpeed: { speed: 'BelowExpected', color: '#DB4437' },
    },
  },
  {
    name: 'Ferdinand El Mundo',
    id: '7512552313',
    class: '1D',
    isArchive: 0,
    avScore: { score: '76%', color: '#E2B534' },
    avSpeed: { speed: 'Above Expecte', color: '#4285F4' },
    parents: 'Antony Kidman, Jesica Alba-Gabriella',
    parentsDetailInfo: {
      firstParent: {
        name: 'Antony Kidman',
        email: 'gabi74@gmail.com',
        phone: '+1 084 (755) 08 - 11',
      },
      secondParent: {
        name: 'Jesica Alba-Gabriella',
        email: 'gabi74@gmail.com',
        phone: '+1 084 (755) 08 - 22',
      },
    },
    studentDetailInfo: {
      tests: [
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
      ],
      name: 'John Doe',
      id: '7512552212',
      avScore: { score: '96%', color: '#0F9D58' },
      avSpeed: { speed: 'BelowExpected', color: '#DB4437' },
    },
  },
  {
    name: 'Adam Lambert',
    id: '7512552214',
    class: '1D',
    isArchive: 0,
    avScore: { score: '49%', color: '#DB4437' },
    avSpeed: { speed: 'Above Expected', color: '#4285F4' },
    parents: 'Antony Kidman, Jesica Alba-Gabriella',
    parentsDetailInfo: {
      firstParent: {
        name: 'Antony Kidman',
        email: 'gabi74@gmail.com',
        phone: '+1 084 (755) 08 - 11',
      },
      secondParent: {
        name: 'Jesica Alba-Gabriella',
        email: 'gabi74@gmail.com',
        phone: '+1 084 (755) 08 - 11',
      },
    },
    studentDetailInfo: {
      tests: [
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
      ],
      name: 'John Doe',
      id: '7512552212',
      avScore: { score: '96%', color: '#0F9D58' },
      avSpeed: { speed: 'BelowExpected', color: '#DB4437' },
    },
  },
  {
    name: 'Benjamin Button',
    id: '7512552215',
    class: '1D',
    isArchive: 0,
    avScore: { score: '81%', color: '#0F9D58' },
    avSpeed: { speed: 'As Expected', color: '#0F9D58' },
    parents: 'Antony Kidman, Jesica Alba-Gabriella',
    parentsDetailInfo: {
      firstParent: {
        name: 'Antony Kidman',
        email: 'gabi74@gmail.com',
        phone: '+1 084 (755) 08 - 11',
      },
      secondParent: {
        name: 'Jesica Alba-Gabriella',
        email: 'gabi74@gmail.com',
        phone: '+1 084 (755) 08 - 11',
      },
    },
    studentDetailInfo: {
      tests: [
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
      ],
      name: 'John Doe',
      id: '7512552212',
      avScore: { score: '96%', color: '#0F9D58' },
      avSpeed: { speed: 'BelowExpected', color: '#DB4437' },
    },
  },
  {
    name: 'Christine Jobs',
    id: '7512552216',
    class: '1D',
    isArchive: 0,
    avScore: { score: '94', color: '#4285F4' },
    avSpeed: { speed: 'As Expected', color: '#0F9D58' },
    parents: 'Antony Kidman, Jesica Alba-Gabriella',
    parentsDetailInfo: {
      firstParent: {
        name: 'Antony Kidman',
        email: 'gabi74@gmail.com',
        phone: '+1 084 (755) 08 - 11',
      },
      secondParent: {
        name: 'Jesica Alba-Gabriella',
        email: 'gabi74@gmail.com',
        phone: '+1 084 (755) 08 - 11',
      },
    },
    studentDetailInfo: {
      tests: [
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
      ],
      name: 'John Doe',
      id: '7512552212',
      avScore: { score: '96%', color: '#0F9D58' },
      avSpeed: { speed: 'BelowExpected', color: '#DB4437' },
    },
  },
  {
    name: 'Karlos DeLa Muchos',
    id: '7512552217',
    class: '1D',
    isArchive: 0,
    avScore: { score: '55%', color: '#E2B534' },
    avSpeed: { speed: 'Below expected', color: '#DB4437' },
    parents: 'Antony Kidman, Jesica Alba-Gabriella',
    parentsDetailInfo: {
      firstParent: {
        name: 'Antony Kidman',
        email: 'gabi74@gmail.com',
        phone: '+1 084 (755) 08 - 11',
      },
      // secondParent: {
      //     name: 'Jesica Alba-Gabriella',
      //     email: 'gabi74@gmail.com',
      //     phone: '+1 084 (755) 08 - 11'
      // }
    },
    studentDetailInfo: {
      tests: [
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
        {
          testName: 'Finding Averages 1 to 400',
          score: { score: '61', color: '#E2B534' },
          speed: { speed: '1h 12m 41s', color: '#4285F4' },
          totalQns: '400',
          expSpeed: '1h 00m 00s',
          concept: 'Concept',
          date: 'APR 30 2021',
          absent: true,
        },
      ],
      name: 'John Doe',
      id: '7512552212',
      avScore: { score: '96%', color: '#0F9D58' },
      avSpeed: { speed: 'BelowExpected', color: '#DB4437' },
    },
  },
];
