import { useState } from 'react'
import { ModalPrimaryButton } from "../../../../components/Modal/buttons/ModalPrimaryButton/ModalPrimaryButton";
import { ModalSecondaryButton } from "../../../../components/Modal/buttons/ModalSecondaryButton/ModalSecondaryButton";
import { ModalContainer } from "../../../../components/Modal/ModalContainer/ModalContainer";
import { ModalFooter } from "../../../../components/Modal/ModalFooter/ModalFooter";
import { ModalHeader } from "../../../../components/Modal/ModalHeader/ModalHeader";
import { ModalWrapper } from "../../../../components/Modal/ModalWrapper/ModalWrapper";
import { config } from '../../../../config';
import styles from "./StudentsModalArchive.module.scss";

const StudentsModalArchive = ({
  setShowAlert = () => {},
  unArchive,
  close,
  closeModalEdit = () => { },
  selectedStudent,
  submitForm = () => { },
  setSelectedStudent = () => { } }) => {
  const [loading, setLoading] = useState(false);

  const requestFetch = (url, method, body) => {
    const headers = {
      'Content-Type': 'application/json'
    }

    return fetch(url, {
      method: method,
      body: JSON.stringify(body),
      headers: headers
    }).then(response => {
      if (response.ok) {
        return response
      }

      return response.json().then(error => {
        const e = new Error('Smth gone wrong')
        e.data = error
        throw e
      })
    });
  }
  return (
    <ModalWrapper loader={loading}>
      <ModalContainer>
        <ModalHeader
          header={unArchive ? 'Unarchive Student(s)' : 'Archive Student(s)'}
          onClose={close}
          color='darkHeader' />
        <div className={styles.text}>
          {`Are you sure you want to ${unArchive ? 'un' : ''}archive selected student(s)?`}
        </div>
        <ModalFooter>
          <ModalSecondaryButton onClick={close}>Cancel</ModalSecondaryButton>
          <ModalPrimaryButton
            classStyle={'dark'}
            onClick={async () => {
              setLoading(true);
              const newObj = {}
              const idsArr = []

              selectedStudent.forEach(item => {
                idsArr.push(item.studentId);
              });

              newObj.ids = idsArr;
              newObj.isArchive = unArchive ? 0 : 1;

              await requestFetch(`${config.API_URL}/api/student/archive`, 'POST', newObj)
                .then((data) => {
                  console.log(data);
                })
                .catch((err) => {
                  console.log(err);
                  setShowAlert(true);
                });

              setSelectedStudent([]);
              setLoading(false);
              close();
              closeModalEdit();
              submitForm();
            }}>
            {unArchive ? 'Unarchive' : 'Archive'}
          </ModalPrimaryButton>
        </ModalFooter>
      </ModalContainer>
    </ModalWrapper>
  )
}

export default StudentsModalArchive;