import './TestInfoPopOver.scss';

export const TestInfoPopOver = ({ testName, totalQuestions, estTime, concept, date, status }) => {
  // const time = Number(estTime).toISOString().substr(11, 8);
  const getStringDateFromSeconds = (seconds) => {
    const int = Number(seconds);
    if (typeof int !== 'number') {
      return null;
    }
    const date = new Date(seconds * 1000);
    const stringData = date.toISOString().substr(11, 8);
    const dateArr = stringData.split(':');

    return ` ${dateArr[0]}H ${dateArr[1]}M ${dateArr[2]}S  `;
  };
  //
  // log('time', time)

  return (
    <div className="testInfoPopOver_wrapper">
      <div className="testInfoPopOver_headerWrapper">{testName}</div>
      <div className="testInfoPopOver_infoItem">
        <span> Total Questions: </span> {totalQuestions}
      </div>
      <div className="testInfoPopOver_infoItem">
        <span> Est. Speed: </span> {getStringDateFromSeconds(estTime)}
      </div>
      <div className="testInfoPopOver_infoItem">
        <span> Concept: </span> {concept.length > 25 ? concept.substring(0, 24) + '. . .' : concept}
      </div>
      <div className="testInfoPopOver_infoItem">
        <span> Date: </span> {date}
      </div>
      <div className="testInfoPopOver_infoItem">
        <span> Status: </span> {status}
      </div>
    </div>
  );
};
