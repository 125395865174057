import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import CreateIcon from '@material-ui/icons/Create';
import styles from './SchoolInfoPopover.module.scss';

export const SchoolInfoPopover = ({ school, handleEditSchool, handleRemoveSchool }) => {
 
  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        {school.Name}
        <div className={styles.actionsContainer}>
          <div className={styles.actionContainer}>
            <DeleteOutlinedIcon
              color="disabled"
              className={styles.actionIcon}
              onClick={handleRemoveSchool}
            />
          </div>
          <div className={styles.actionContainer}>
            <CreateIcon color="disabled" className={styles.actionIcon} onClick={handleEditSchool} />
          </div>
        </div>
      </div>

      <div>
        <div className={styles.addressLabel}>{school.Address}</div>
        <div className={styles.addressValue}>Address</div>
      </div>

      <div className={styles.schoolInfoValues}>
        <span className={styles.schoolInfoValue}>{school.ClassCount}</span>
        <span className={styles.schoolInfoValue}>{school.StudentCount}</span>
        <span className={styles.schoolInfoValue}>{school.TeacherCount}</span>
        <span className={styles.schoolInfoValue}>{school.TestCount}</span>
      </div>

      <div className={styles.schoolInfoCaptions}>
        <span className={styles.schoolInfoCaption}>Classes</span>
        <span className={styles.schoolInfoCaption}>Students</span>
        <span className={styles.schoolInfoCaption}>Teachers</span>
        <span className={styles.schoolInfoCaption}>Tests Completed</span>
      </div>
    </div>
  );
};
