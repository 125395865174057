import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

import { StudentInfoPopOver } from '../StudentInfoPopOver/StudentInfoPopOver';
import { StyledGradeBookStudentRow } from './StyledGradeBookStudentRow';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useState } from 'react';
export const GradeBookStudentRow = ({ student, testOrder, index }) => {
  const [iconAnchorEl, setIconAnchorEl] = useState(null);
  const openStudentInfo = Boolean(iconAnchorEl);
  const handleOpenInforStudent = (event) => setIconAnchorEl(event.currentTarget);
  const handleCloseInforStudent = () => setIconAnchorEl(null);
  // сравнивать и устанавливать порядок тестов и результатов учеников этих тестов
  // сюда апрокинуть массив с айтишниками тестов и сравнивать эти айдишники с обэктами струдентов и выводить
  const studentTestsInRightOrder = [];
  for (let i = 0; i < testOrder.length; i++) {
    const testIdCorrect = student.tests.find((test) => test.testId === testOrder[i]);
    if (testIdCorrect === undefined) {
      studentTestsInRightOrder.push(['No Results']);
    } else studentTestsInRightOrder.push(testIdCorrect);
  }

  const gridWidth = [];
  for (let i = 0; i < studentTestsInRightOrder.length; i++) {
    gridWidth.push('262px');
  }
  // функция которая спреврвтит количество секунд в строку времени
  const getStringDateFromSeconds = (seconds) => {
    const int = Number(seconds);
    if (typeof int !== 'number') {
      return null;
    }
    const date = new Date(seconds * 1000);
    const stringData = date.toISOString().substr(11, 8);
    const dateArr = stringData.split(':');

    return ` ${dateArr[0]}H ${dateArr[1]}M ${dateArr[2]}S  `;
  };
  return (
    <StyledGradeBookStudentRow
      rowLength={studentTestsInRightOrder}
      gridTemplate={gridWidth.join(' ')}
      index={index}
    >
      {studentTestsInRightOrder.length > 0 &&
        studentTestsInRightOrder.map((test) => {
          return (
            <>
              {test[0] === 'No Results' ? (
                <div className="gradeBookStudentRow_testResultWrapper gradeBookStudentRow_noResult">
                  NO RESULTS
                </div>
              ) : test.absent ? (
                <div className="gradeBookStudentRow_testResultWrapper gradeBookStudentRow_absent">
                  {' '}
                  Absent
                </div>
              ) : (
                <div className="gradeBookStudentRow_testResultWrapper">
                  <div style={{ color: test.score.color }} className="">
                    {' '}
                    {test.score.score}{' '}
                  </div>
                  <div style={{ color: test.speed.color }} className="">
                    {getStringDateFromSeconds(test.speed.speed)}
                  </div>
                </div>
              )}
            </>
          );
        })}
    </StyledGradeBookStudentRow>
  );
};
