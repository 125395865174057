import React, { FC, memo } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import styles from './ModalHeader.module.scss';

interface Props {
  header: string;
  onClose?: () => void;
  color?: string;
}

export const ModalHeader: FC<Props> = memo(({ header, onClose, color='header' }) => (
  <div className={styles.root}>
    <div className={styles[color]}>{header}</div>

    {typeof onClose === 'function' && (
      <button className={styles.buttonClose} onClick={onClose}>
        <CloseIcon className={styles.closeIcon} />
      </button>
    )}
  </div>
));
