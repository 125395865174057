import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { apiClient } from '../../../../../api/client';
import { ModalWrapper } from '../../../../../components/Modal/ModalWrapper/ModalWrapper';
import { ModalContainer } from '../../../../../components/Modal/ModalContainer/ModalContainer';
import { ModalHeader } from '../../../../../components/Modal/ModalHeader/ModalHeader';
import { ModalFooter } from '../../../../../components/Modal/ModalFooter/ModalFooter';
import { ModalSecondaryButton } from '../../../../../components/Modal/buttons/ModalSecondaryButton/ModalSecondaryButton';
import { ModalPrimaryButton } from '../../../../../components/Modal/buttons/ModalPrimaryButton/ModalPrimaryButton';
import { SuccessfulAddClass } from '../AddClassModal/SuccessfulAddClass/SuccessfulAddClass';
import { OutlinedTextField } from '../../../../../components/OutlinedTextField/OutlinedTextField';
import { AppContext } from '../../../../../context/AppContext';
import { config } from '../../../../../config';
import { LoaderFullBook } from '../../../../../components/Loaders/LoaderFullBook/LoaderFullBook';
import './AddClassModal.scss';
import { Box } from '@material-ui/core';


const icon = <CheckBoxOutlineBlankIcon fontSize="medium" />;
const checkedIcon = <CheckBoxIcon fontSize="medium" style={{ color: '#A6192E' }} />;

const gradeToSelectDefault = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
// const teachrsNamesDefault = [{ teacherName: '', teacherId: '' }];

const arrOfYearsToSelect = [];
const currentYear = new Date().getFullYear();
let currentYearPlusTwo = currentYear + 2;
for (let i = 0; i < 11; i++) {
  arrOfYearsToSelect.push(currentYearPlusTwo);
  currentYearPlusTwo -= 1;
}

export const AddClassModal = ({ close, allClasses, loadDataForGradeBook, loader }) => {
  const { showLoader, setShowLoader, currentSchoolId, updateSchools } = useContext(AppContext);

  const [teacherName, setTeacherName] = useState([{ value: '', title: '' }]); // all teachers in school
  //  form validation
  const [errorInClassName, setErrorInClassName] = useState();
  const [errorInYear, setErrorInYear] = useState();
  const [errorInGrade, setErrorInGrade] = useState();
  const [errorInTeacher, setErrorInTeacher] = useState();

  const [errorClassIsExist, setErrorClassIsExist] = useState(false);

  const [year, setYear] = useState();
  const [grade, setGrade] = useState();
  const [newClassName, setNewClassName] = useState();
  const [selectedTeachers, setSelectedTeachers] = useState([]);

  const [successfulAddClass, setSuccessfulAddClass] = useState(null);
  // const [showSelectTeacher, setShowSelectTeacher] = useState(false)
  // const [ selectedTeachers, setSelectedTeachers ] = useState(objectForEditClassModal.teachers.reduce((sum, currentTeacher)=> [ ...sum, currentTeacher.teacherName], [] ))

  // get all teachers in school
  useEffect(() => {
    apiClient.get(`/api/header/info?schoolId=${currentSchoolId}`).then(({ data }) => {
      console.log('data', data);
      console.log('data.teachers', data.teachers);
      // setForGreyHeader(json) reduce method
      const toSetTeachers = data.teachers.reduce(
        (sum, currentTeacher) => [
          ...sum,
          { title: currentTeacher.teacherName, value: currentTeacher.teacherId },
        ],
        []
      );
      setTeacherName(toSetTeachers);
      // console.log('toSetTeachers', toSetTeachers);
    });
  }, []);

  // SET NEW Grade
  const handleChangeGrade = (event) => {
    setGrade(event.target.value);
    setErrorInGrade(false);
  };

  // // SET NEW Teacher
  const newTeachersIdArr = []; // в запрос нужен TeachersId
  selectedTeachers.forEach((item) => {
    newTeachersIdArr.push(item.value);
  });

  const handleChangeTeacher = (event, newValue) => {
    setSelectedTeachers(newValue);
    // const {target: { value }} = event;
    // setSelectedTeachers(typeof value === 'string' ? value.split(',') : value);
    // console.log('selectedTeachers', selectedTeachers);
  };

  // SET NEW YEAR
  const handleChangeYear = (event) => {
    setYear(event.target.value);
  };

  // send to BeckEnd Info about new class
  const handleSaveClassChanges = async () => {
    if (!grade && grade !== 0) {
      setErrorInGrade(true);
    }
    if (!newClassName) {
      setErrorInClassName(true);
    }
    if (!year) {
      setErrorInYear(true);
    }
    const validateClassName = newClassName.toUpperCase().trim();

    // cheking if class already exist
    for (let i = 0; i < allClasses[grade].length; i++) {
      if (allClasses[grade][i].class === validateClassName) {
        console.log('class  already exist');
        setErrorClassIsExist(true);
        return;
      }
    }

    // ========request==response======
    // ADD GRADEBOOK
    // base_url/api/gradebook/add
    // type POST
    // request body
    // {
    //   grade: 1,
    //   className: 'A',
    //   schoolId: 1,
    //   year: 2022,
    //   teachers: [1, 2, 3]
    // }
    // ===============================
    // response body
    // { classId }
    // ===============================

    const requestBody = {
      grade: grade,
      className: validateClassName,
      schoolId: currentSchoolId,
      teachers: newTeachersIdArr,
      year: year,
    };
    console.log('requestBody', requestBody);

    try {
      setShowLoader(true);
      const response = await axios.post(
        `${config.API_URL}/api/gradebook/add`,
        requestBody
      );
      // console.log('response', response);
      // console.log('response.data', response.data);
      if (response.message) {
        setShowLoader(false);
      }
      if (response.data) {
        setShowLoader(false);
        updateSchools(prev => {
          const schoolsArr = prev.map(item => ({...item}))
          const school = schoolsArr.find((item) => item.Id === currentSchoolId);
          school.ClassCount += 1;
          return schoolsArr;
        })
        setSuccessfulAddClass(true);
      }
    } catch (e) {
      if (e.message === 'Request failed with status code 409') {
        setShowLoader(false);
        alert('Class with this params already exist');
        return;
      }
      console.log(e);
      // console.log('e.message', e.message);
      setShowLoader(false);
      setSuccessfulAddClass(false);
    }
  };

  // const ref = useRef(null);

  return (
    <div className="addClassModal_wrapper">
      {showLoader && <LoaderFullBook />}
      {successfulAddClass === null && (
        <>
          <ModalWrapper loader={loader}>
            <ModalContainer>
              <ModalHeader header="Add Class" onClose={close} />
              <div className="inputWrapper">
                {errorClassIsExist && (
                  <div className="addClassModal_errorClassIsExist">
                    Class with name: "{newClassName}" already exists in grade: "{grade}"
                  </div>
                )}

                {/* SELECT NEW GRADE */}
                <div
                  className="addClassModal_SelectGrade"
                  style={{
                    border: errorInGrade ? '1px solid #A6192E' : null,
                    borderRadius: 0,
                    height: 56,
                    marginBottom: 24,
                  }}
                >
                  <Box
                    component="form"
                    sx={{
                      '& .MuiTextField-root': {
                        m: 1,
                        width: '302px',
                        // marginBottom: 24,
                        boxShadow: 0,
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <OutlinedTextField
                      fullWidth
                      select
                      label="Select Grade"
                      value={grade}
                      onChange={handleChangeGrade}
                      onFocus={() => {
                        setErrorInGrade(false);
                        setErrorClassIsExist(false);
                      }}
                    >
                      {gradeToSelectDefault.map((grade) => {
                        return (
                          <MenuItem value={grade} key={grade}>
                            {grade} Grade
                          </MenuItem>
                        );
                      })}
                    </OutlinedTextField>
                  </Box>
                </div>
                {errorInGrade && (
                  <div className="textHelper">
                    Grade is required{' '}
                    <ReportProblemOutlinedIcon style={{ cursor: 'default', marginLeft: 5 }} />
                  </div>
                )}
              </div>

              {/* ENTER CLASS NAME */}
              <div className="inputWrapper">
                <div
                  className="addClassModal_addClassNameWrapper"
                  style={{
                    border: errorInClassName ? '1px solid #A6192E' : null,
                    borderRadius: 0,
                    height: 56,
                    marginBottom: 24,
                  }}
                >
                  {/* {! errorInClassName &&  */}
                  <OutlinedTextField
                    fullWidth
                    id="outlined-basic"
                    label="Enter Class Name"
                    variant="outlined"
                    // error
                    value={newClassName}
                    onChange={(e) => {
                      setNewClassName(e.target.value);
                      setErrorInClassName(false);
                      setErrorInClassName(false);
                    }}
                    onFocus={() => {
                      setErrorInClassName(false);
                      setErrorClassIsExist(false);
                    }}
                    sx={{ marginBottom: 3 }}
                  />
                </div>
                {errorInClassName && (
                  <div className="textHelper">
                    Class name is required{' '}
                    <ReportProblemOutlinedIcon style={{ cursor: 'default', marginLeft: 5 }} />
                  </div>
                )}
                {/* {errorClassIsExist && (
                  <div className="textHelper">
                    Class already exists{' '}
                    <ReportProblemOutlinedIcon style={{ cursor: 'default', marginLeft: 5 }} />
                  </div>
                )} */}
              </div>

              {/* SELECT TEACHER */}
              <div className="inputWrapper">
                <div
                  className="editClassModal_editTeacherNameWrapper"
                  // style={{ border: errorInTeacher ? '1px solid #A6192E' : null, borderRadius: 0 }}
                >
                  <Autocomplete
                    value={selectedTeachers}
                    onChange={(event, newValue) => handleChangeTeacher(event, newValue)}
                    error
                    multiple
                    id="checkboxes-tags-demo"
                    options={teacherName}
                    onFocus={() => {
                      setErrorInTeacher(false);
                    }}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.title}
                    renderOption={(props, option, { selected }) => (
                      <li {...props} style={{ fontSize: 16 }}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8, height: 25 }}
                          checked={selected}
                          // style={{ fontSize: 16 }}
                        />
                        {option.title}
                      </li>
                    )}
                    style={{ width: 302 }}
                    renderInput={(params) => (
                      <OutlinedTextField
                        {...params}
                        label="Select Teacher"
                        placeholder="Select Teacher"
                        style={{ fontSize: 16 }}
                      />
                    )}
                  />
                </div>
                {/* {errorInTeacher && (
                  <div className="editClassModal_errorWrapper editClassModal_teacherError">
                    Teacher name is required
                  </div>
                )} */}
              </div>

              {/* SELECT YEAR */}
              <div
                className="inputWrapper"
              >
                <div
                  className="editClassModal_editYearNameWrapper"
                  style={{
                    border: errorInYear ? '1px solid #A6192E' : null,
                    borderRadius: 0,
                    height: 56,
                  }}
                >
                  {/* <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Select Year</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={year}
                      label="Age"
                      onChange={handleChangeYear}
                      onFocus={() => {
                        setErrorInYear(false);
                      }}
                      sx={{ borderRadius: 0, height: '56px' }}
                    >
                      {arrOfYearsToSelect.map((year) => {
                        return <MenuItem value={year}>{year}</MenuItem>;
                      })}
                    </Select> */}
                  <Box
                    component="form"
                    sx={{
                      '& .MuiTextField-root': { m: 1, width: '302px' },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <div>
                      <OutlinedTextField
                        fullWidth
                        select
                        label="Select Year"
                        value={year}
                        onFocus={() => {
                          setErrorInYear(false);
                        }}
                        onChange={handleChangeYear}
                      >
                        {arrOfYearsToSelect.map((year) => {
                          return <MenuItem value={year}>{year}</MenuItem>;
                        })}
                      </OutlinedTextField>
                    </div>
                  </Box>
                  {/* </FormControl> */}
                </div>

                {errorInYear && (
                  <div className="textHelper">
                    Year is required{' '}
                    <ReportProblemOutlinedIcon style={{ cursor: 'default', marginLeft: 5 }} />
                  </div>
                )}

                <div style={{ height: 2 }} className="editClassModal_archiveButtonWrapper"></div>
              </div>

              <ModalFooter>
                <ModalSecondaryButton onClick={close}>Cancel</ModalSecondaryButton>
                <ModalPrimaryButton typeBtn="submit" onClick={handleSaveClassChanges}>
                  Add
                </ModalPrimaryButton>
              </ModalFooter>
            </ModalContainer>
          </ModalWrapper>
        </>
      )}
      {successfulAddClass !== null && (
        <SuccessfulAddClass
          setSuccessfulAddClass={setSuccessfulAddClass}
          close={close}
          loader={loader}
          loadDataForGradeBook={loadDataForGradeBook}
          successfulAddClass={successfulAddClass}
        />
      )}
    </div>
  );
};