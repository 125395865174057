import { useState } from 'react';
import axios from 'axios';

import CloseIcon from '@mui/icons-material/Close';
import { EditResultsTableRow } from '../EditResultsTableRow/EditResultsTableRow';
import { ButtonPrimary } from '../../../../../components/buttons/buttonPrimary/ButtonPrimary';
import { SecondaryButton } from '../../../../../components/buttons/SecondaryButton/SecondaryButton';
import { LoaderFullBook } from '../../../../../components/Loaders/LoaderFullBook/LoaderFullBook';
import { config } from '../../../../../config';
import './EditResultsModal.scss';

export const EditResultsModal = ({ close, resultsToEdit, testId, fetchToGetdata, test }) => {
  const [showLoader, setShowLoader] = useState(false);
  const [testResultsEdit, setTestResultsEdit] = useState(resultsToEdit);
  // console.log('testResultsEdit', testResultsEdit)

  const handleSaveEditResults = async () => {
    for (let i = 0; i < resultsToEdit.length; i++) {
      if (resultsToEdit[i].results.score.score > test.totalQuestions) return;
    }

    setShowLoader(true);
    console.log('hello)');
    const toSendEditResults = [];
    for (let i = 0; i < resultsToEdit.length; i++) {
      if (
        !resultsToEdit[i].results.score.score &&
        !resultsToEdit[i].results.speed.speed &&
        !resultsToEdit[i].results.absent
      )
        continue;
      if (resultsToEdit[i].results.absent) {
        toSendEditResults.push({
          studentId: resultsToEdit[i].studentId,
          // name : resultsToEdit[i].studentName,
          score: 0,
          speed: 0,
          absent: 1,
          testId: testId,
        });
        continue;
      }
      toSendEditResults.push({
        studentId: resultsToEdit[i].studentId,
        // name : resultsToEdit[i].studentName,
        score: +resultsToEdit[i].results.score.score,
        speed: +resultsToEdit[i].results.speed.speed,
        absent: 0,
        testId: testId,
      });
    }

    console.log('toSendEditResults', toSendEditResults);

    axios.post(`${config.API_URL}/api/testresult/update`, toSendEditResults).then(
      (response) => {
        console.log(response);
        close();
        fetchToGetdata();
        setShowLoader(false);
      },
      (error) => {
        console.log(error);
      }
    );
    // fetchToGetdata()
  };

  return (
    <>
      {showLoader && <LoaderFullBook />}
      <div className="editResultsModal_wrapper">
        <div className="editClassModal_header">
          <div className="editClassModal_header_textWrapper">Edit Results</div>
          <div className="editClassModal_header_iconWrapper">
            <CloseIcon onClick={close} />
          </div>
        </div>
        <div className="editClassModal_testNameWrapper">
          TEST: <span>{test.testName}</span>
        </div>
        <div className="editClassModal_tableHeadWrapper">
          <div className="">
            Students <span> ({resultsToEdit.length}) </span>
          </div>
          <div className="">Score</div>
          <div className="">Speed</div>
          <div className="">Absent</div>
        </div>

        <div className="editClassModal_testTable_wrapper">
          {resultsToEdit.map((studentInfo, index) => {
            return (
              <EditResultsTableRow
                resultsToEdit={resultsToEdit}
                setTestResultsEdit={setTestResultsEdit}
                studentInfo={studentInfo}
                index={index}
                test={test}
              />
              // null
            );
          })}
        </div>
        <div className="editClassModal_buttonsWrapper">
          <div className="">
            <SecondaryButton status={'cancel'} active={true} functionality={close} />
          </div>

          <div className="">
            <ButtonPrimary
              status={'saveChanges'}
              active={true}
              functionality={handleSaveEditResults}
            />
          </div>
        </div>
      </div>
    </>
  );
};
