import styled from 'styled-components/macro';

// import StyledTableRow from '../TableRow/StyledTableRow';

// props {checkBox, columns}
export const StyledDatePicker = styled.div`
  /* .datePicker_wrapper{ */
  border-bottom: 1px solid #c0c0c0;
  height: 28px;
  width: 201px;
  position: relative;
  transition: color 500ms ease;
  display: inline-block;
  margin-right: 5px;
  margin-left: 5px;
  padding-top: 5.5px;

  &:hover .datePicker_visibleInput {
    transition: color 500ms ease;
    color: #f2f2f2;
  }
  /* } */
  .datePicker_customInput {
    display: block;
    opacity: 0;
    width: 201px;
    height: 29px;

    line-height: 1.17;
    background-color: #f2f2f24f;
    color: rgba(14, 14, 14, 0.582);
    &:hover {
      cursor: pointer;
    }
  }
  .datePicker_inputBox {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
  .datePicker_visibleInput {
    width: 201px;
    font-weight: 400;
    color: #c0c0c0;
    text-transform: uppercase;
    font-size: 1.2rem;
    line-height: 29px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    svg {
      display: inline-block;
    }
    // border: 2px solid rgba(115, 255, 0, 0.589);;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background-color: #a6192e;
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: #a6192e;
    &:focus-visible {
      border: 1pz solid red;
    }
  }
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__quarter-text--in-selecting-range:hover,
  .react-datepicker__quarter-text--in-range:hover,
  .react-datepicker__year-text--selected:hover,
  .react-datepicker__year-text--in-selecting-range:hover,
  .react-datepicker__year-text--in-range:hover {
    background-color: #8d1426;
  }
  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__month-text--keyboard-selected:hover,
  .react-datepicker__quarter-text--keyboard-selected:hover,
  .react-datepicker__year-text--keyboard-selected:hover {
    background-color: #8d1426;
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    &:focus-visible {
      outline-color: #8d1426;
    }
  }
`;
