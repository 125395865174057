import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { Button } from '../../Button/Button';
import { QuestionSign } from '../../../images/SvgElements/QuestionSign';
import { AppContext } from '../../../context/AppContext';
import './ProfilePopUp.scss';
import { config } from "../../../config";
import axios from "axios";
// HeaderProfilePopup
export const ProfilePopUp = ({ handleEditProfileModalOpen }) => {
  const history = useHistory();
  const {
    isAuth,
    setIsAuth,
    user,
    updateUser,
    defaultUser,
    updateSchools,
    setCurrentSchoolId,
    updateCurrentClassId,
    setUploadedPhoto,
    setFileToSave,
  } = useContext(AppContext);

  const userDirector = {
    userName: `${user.firstName} ${user.lastName}` ?? "No Name",
    status: 'School manager',
    imgPath: user.photo ?? "",
    userEmail: `${user.email}` ?? "",
  };

  const logout = async () =>{
    try {
      await axios.get(`${config.API_URL}/api/auth/logout`, {
        withCredentials: true
      }).then((response) => {
       return response
      });
    } catch (e) {
      console.log(e);
    }
 
  }
  const handleLogout = () => {
    logout()
    // console.log(x)
    setIsAuth(false);
    updateUser(defaultUser);
    updateSchools([]);
    setCurrentSchoolId(null);
    updateCurrentClassId(null);
    setUploadedPhoto('');
    setFileToSave({
    photo: null,
  })
    
    localStorage.clear();
    history.push("/sign-in")
  }

  const userHasNoName =
    user.firstName === "No Name" ||
    user.firstName === undefined;

  const userHasNoPhoto =
    user.photo === null ||
    user.photo === "";

  return (
    <div className="portfolioPopUp_wrapper">
      <div className="portfolioPopUp_privateInfo">
          { userHasNoName && userHasNoPhoto &&
            ( <div className="portfolioPopUp_QuestionWrapper">
            <QuestionSign width="17px" height="17px"/>
            </div>)}
          {!userHasNoName && userHasNoPhoto &&
            (<div className="portfolioPopUp_IconWrapper">
              <PersonOutlineOutlinedIcon />
            </div>)}
          {!userHasNoName && !userHasNoPhoto &&
            (<div className="portfolioPopUp_photo">
            <img className="portfolioPopUp_photo__img" src={userDirector.imgPath} alt="profileImg" />
            </div>)} 
        
          <div className="portfolioPopUp_statusWrapper">
            <div className="portfolioPopUp_fullName">{userDirector.userName}</div>
            <div className="portfolioPopUp_position">
              <span className="portfolioPopUp_status">Status:</span>
              <span className="portfolioPopUp_statusName">{userDirector.status}</span>
            </div>
          </div>
        </div>
      <div className="portfolioPopUp_userEmailWrapper">
        <span className="portfolioPopUp_userEmail">EMAIL:</span>
        <span className="portfolioPopUp_userEmailData">{userDirector.userEmail}</span>
      </div>
     <div className="portfolioPopUp_main">
        {isAuth ? (
            <div className="portfolioPopUp_buttonWrapper">
            <Button onClick={handleEditProfileModalOpen}>Edit profile</Button>
            </div> 
        )
          : (
            <div className="portfolioPopUp_buttonWrapper" style={{ marginBottom: 72 }}>
            </div>
        )    
        }
      <div className="greyHorizontalLine"></div>
        <div className="portfolioPopUp_exitWrapper" onClick={handleLogout}>
          <LoginOutlinedIcon style={{ marginRight: 5 }}/>
            Log out
        </div>
      </div>
    </div>
  );
};
