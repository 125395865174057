import { useState } from 'react';
import axios from 'axios';
// import CloseIcon from '@mui/icons-material/Close';
// import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'; // empty
// import CheckBoxTwoToneIcon from '@mui/icons-material/CheckBoxTwoTone';
import { SecondaryButton } from '../../../../../components/buttons/SecondaryButton/SecondaryButton';
import { ButtonPrimary } from '../../../../../components/buttons/buttonPrimary/ButtonPrimary';
import { ModalWrapper } from '../../../../../components/Modal/ModalWrapper/ModalWrapper';
import { ModalContainer } from '../../../../../components/Modal/ModalContainer/ModalContainer';
import { ModalHeader } from '../../../../../components/Modal/ModalHeader/ModalHeader';
import { config } from '../../../../../config';
import './UpgradeClassModal.scss';

export const UpgradeClassModal = ({ onClose, activeClasses, openGradebooksPage, loadDataForGradeBook }) => {
  console.log('activeClasses', activeClasses);

  // const [confirm, setConfirm] = useState(false);
  const [successfulRequest, setSuccessfulRequest] = useState(null);

  const handleSendToArchiveClasses = async () => {

    const dataToSend = {
      ids: activeClasses,
      isUpdate: true,
    };
    try {
      const data = await axios.post(
        `${config.API_URL}/api/gradebook/archive`,
        dataToSend
        );
        console.log('data', data)
        console.log('data.data.success', data.data.success);
        data.data.success ? setSuccessfulRequest(true) : setSuccessfulRequest(false) ;
    } catch (e) {
      console.log(e);
      setSuccessfulRequest(false);
    }
  };

  return (
    <>
      {successfulRequest === null && (
        <>
          <ModalWrapper>
            <ModalContainer>
              <ModalHeader header="Upgrade Class(es)" color="darkHeader" onClose={onClose} />
              <div className="upgradeClassModal_tooltipWrapper">
                Do you want to upgrade <span>class(es)</span> ?
              </div>
              <div className="upgradeClassModal_smallTooltipWrapper">
                *All students will be duplicated without test results. Test results will be
                archived.
              </div>
              {/* <div
                style={{ background: confirm ? '#F2F2F2' : null, cursor: 'pointer' }}
                className="upgradeClassModal_confirmationWrapper"
                onClick={() => {
                  setConfirm((prevState) => !prevState);
                }}
              >
                {confirm ? <CheckBoxTwoToneIcon /> : <CheckBoxOutlineBlankIcon />}
                Upgrade Class(es)
              </div> */}
              <div style={{ width: 250 }} className="upgradeClassModal_buttonsWrapper">
                <div className="">
                  <SecondaryButton status={'cancel'} active={true} functionality={onClose} />
                </div>

                <div className="">
                  <ButtonPrimary
                    status={'upgrade'}
                    active={true}
                    functionality={handleSendToArchiveClasses}
                  />
                </div>
              </div>
            </ModalContainer>
          </ModalWrapper>
        </>
      )}
      {successfulRequest === false && (
        <>
          <ModalWrapper>
            <ModalContainer>
              <ModalHeader
                header="Upgrade Error"
                color="darkHeader"
                onClose={() => {
                  onClose();
                  setSuccessfulRequest(null);
                }}
              />
              <div className="upgradeClassModal_tooltipWrapper">
                Something went wrong. Please try again later.
              </div>
              <div style={{ height: 40, marginTop: 20, marginLeft: 80 }} className="">
                <ButtonPrimary
                  status={'ok'}
                  active={true}
                  functionality={() => {
                    onClose();
                    setSuccessfulRequest(null);
                  }}
                />
              </div>
            </ModalContainer>
          </ModalWrapper>
        </>
      )}
      {successfulRequest === true && (
        <>
          <ModalWrapper>
            <ModalContainer>
              <div className="upgradeClassModal_headerWrapper">
                <div
                  style={{ marginLeft: 37, color: '#A6192E' }}
                  className="upgradeClassModal_header"
                >
                  Successfully Upgraded
                </div>
              </div>

              <div style={{ height: 40, marginTop: 30, marginLeft: 80 }} className="">
                <ButtonPrimary
                  status={'okRed'}
                  active={true}
                  functionality={() => {
                    setSuccessfulRequest(null);
                    loadDataForGradeBook();
                    openGradebooksPage();
                    onClose();
                  }}
                />
              </div>
            </ModalContainer>
          </ModalWrapper>
        </>
      )}
    </>
  );
};
