import { useState } from 'react';

import { DropdownSelect } from '../../components/DropdownSelect/DropdownSelect';
import { DatePickerRange } from '../../components/DatePicker/DatePicker';
import ButtonText from '../../components/buttons/buttonText/ButtonText';
import { TableHeadLine } from '../../components/TableHeadLine/TableHeadLine';
import { StudentAnalyticsContainer } from '../../containers/StudentAnalyticsContainer/StudentAnalyticsContainer.js';
import { StudentAnalyticsTable } from '../../containers/StudentAnalyticsTable/StudentAnalyticsTable';
import { allConcepts } from "./dataStudentAnalytic"
import { defaultStudent } from "./dataStudentAnalytic"

import './StudentAnalyticPage.scss';

export const StudentAnalyticsPage = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const studentId = queryParams.get('studentId');
  console.log('studentId', studentId);
  //get info from BE
  const [student, setStudent] = useState(defaultStudent);

  const [conceptFilter, setConceptFilter] = useState(null);
  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);

  const [startDateVisual, setStartDateVisual] = useState(null);
  const [endDateVisual, setEndDateVisual] = useState(null);

  const [filterAverageScore, setFilterAverageScore] = useState(null);
  const [filterAverageSpeed, setFilterAverageSpeed] = useState(null);
  // pagination state
  const [pageSize, setPageSize] = useState(10); // 10, 25, 50
  const [currentPage, setCurrentPage] = useState(0); // 1
  const [totalCount, setTotalCount] = useState(106); // 100

  //sort state
  const [labelSort, setLabelSort] = useState(null);
  const [dateSort, setDateSort] = useState(null);
  const [scoreSort, setScoreSort] = useState(null);
  const [speedSort, setSpeedSort] = useState(null);

  // const [directionLabelSort, setDirectionLabelSort] = useState(false)

  const requestParams = {
    // studentId: studentId,
    // // filtration
    // conceptFilter: conceptFilter,
    // startDateFilter: startDateFilter,
    // endDateFilter: endDateFilter,
    // filterAverageScore: filterAverageScore,
    // filterAverageSpeed: filterAverageSpeed,
    // // for pagination
    // pageSize: pageSize,
    // currentPage: currentPage,
    // // sort params
    // labelSort: labelSort,
    // dateSort: dateSort,
    // scoreSort: scoreSort,
    // speedSort: speedSort  ,

    sort: '',
    sortDirection: '',
  };

  const handleClearFilters = () => {
    setConceptFilter(null);
    setStartDateFilter(null);
    setEndDateFilter(null);
    setStartDateVisual(null);
    setEndDateVisual(null);
  };
  const headlineType = {
    titleName: 'Analytics ' + student.name,
    inputPlaceHolder: 'Enter Test Label',
    buttonText: 'Export csv',

    isInput: true,
    isExportButton: false,
    isExportCsv: true,
  };

  if (labelSort !== null) {
    requestParams.sort = 'label';

    requestParams.sortDirection = labelSort === true ? true : false;
  }
  if (dateSort !== null) {
    requestParams.sort = 'date';

    requestParams.sortDirection = dateSort === true ? true : false;
  }
  if (speedSort !== null) {
    requestParams.sort = 'avSpeed';

    requestParams.sortDirection = speedSort === true ? true : false;
  }
  if (scoreSort !== null) {
    requestParams.sort = 'avScore';

    requestParams.sortDirection = scoreSort === true ? true : false;
  }

  const sortFunctionForLabel = () => {
    if (labelSort === null) {
      setLabelSort(true);
      setDateSort(null);
      setScoreSort(null);
      setSpeedSort(null);
      return;
    }
    setLabelSort((prevState) => !prevState);
    setDateSort(null);
    setScoreSort(null);
    setSpeedSort(null);
  };
  const sortFunctionForDate = () => {
    if (dateSort === null) {
      setDateSort(true);
      setLabelSort(null);
      setScoreSort(null);
      setSpeedSort(null);
      return;
    }
    setDateSort((prevState) => !prevState);
    setLabelSort(null);
    setScoreSort(null);
    setSpeedSort(null);
  };
  const sortFunctionForScore = () => {
    if (scoreSort === null) {
      setScoreSort(true);
      setDateSort(null);
      setLabelSort(null);
      setSpeedSort(null);
      return;
    }
    setScoreSort((prevState) => !prevState);
    setDateSort(null);
    setLabelSort(null);
    setSpeedSort(null);
  };
  const sortFunctionForSpeed = () => {
    if (speedSort === null) {
      setSpeedSort(true);
      setScoreSort(null);
      setDateSort(null);
      setLabelSort(null);
      return;
    }
    setScoreSort(null);
    setDateSort(null);
    setLabelSort(null);
    setSpeedSort((prevState) => !prevState);
  };

  return (
    <>
      <div className="analyticsPageHeader_wrapper">
        <div className="greyDropdownWrapper">
          <div className="all_selectWrapper">
            <DropdownSelect
              dataToSelect={allConcepts}
              defaultValue={'All Concepts'}
              stateFilter={conceptFilter}
              setStateFilter={setConceptFilter}
            />
            <DatePickerRange
              setStartDateFilter={setStartDateFilter}
              setEndDateFilter={setEndDateFilter}
              startDateFilter={startDateFilter}
              endDateFilter={endDateFilter}
              startDateVisual={startDateVisual}
              setStartDateVisual={setStartDateVisual}
              endDateVisual={endDateVisual}
              setEndDateVisual={setEndDateVisual}
            />
            <ButtonText
              type={`4`}
              text={`Clear all`}
              iconName={`Clear`}
              active={true}
              funtionality={() => {
                handleClearFilters();
              }}
            />
          </div>
        </div>
      </div>
      <TableHeadLine headlineType={headlineType} />
      <div style={{ justifyContent: 'center' }} className="analiticsContainer_generalSchoolInfo">
        <div className="generalSchoolInfo_item" style={{ marginRight: 24 }}>
          <span>{student.class}</span>
          Class
        </div>
        <div className="generalSchoolInfo_item" style={{ marginRight: 24 }}>
          <span>{student.allTests}</span>
          Tests
        </div>
        <div className="generalSchoolInfo_item" style={{ marginRight: 24 }}>
          <span>{student.avScore}%</span>
          Av. Score
        </div>
        <div className="generalSchoolInfo_item" style={{ marginRight: 24 }}>
          <span>{student.avSpeed}</span>
          Av. Speed
        </div>
        <div className="generalSchoolInfo_item" style={{ marginRight: 24 }}>
          <span>{student.id}</span>
          ID
        </div>
      </div>

      <StudentAnalyticsContainer
        student={student}
        filterAverageScore={filterAverageScore}
        setFilterAverageScore={setFilterAverageScore}
        filterAverageSpeed={filterAverageSpeed}
        setFilterAverageSpeed={setFilterAverageSpeed}
      />

      <StudentAnalyticsTable
        filterAverageScore={filterAverageScore}
        filterAverageSpeed={filterAverageSpeed}
        pageSize={pageSize}
        setPageSize={setPageSize}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalCount={totalCount}
        setTotalCount={setTotalCount}
        tableData={student.tests}
        sortFunctionForLabel={sortFunctionForLabel}
        sortFunctionForDate={sortFunctionForDate}
        sortFunctionForSpeed={sortFunctionForSpeed}
        sortFunctionForScore={sortFunctionForScore}
      />
    </>
  );
};
