import { useCallback, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../context/AppContext';
import { ArchiveService } from '../../../pages/ArchivePage/ArchiveService';
import { ModalWrapper } from '../../Modal/ModalWrapper/ModalWrapper';
import { ModalContainer } from '../../Modal/ModalContainer/ModalContainer';
import { ModalHeader } from '../../Modal/ModalHeader/ModalHeader';
import ArchiveCsvConstructor from './ArchiveCsvConstructor';
import { Alert } from '../../Alert/Alert';

const service = new ArchiveService();

function ArchiveExportCsvModal({ close }) {
  const [archiveData, setArchiveData] = useState({});
  const [loader, setLoader] = useState(true);
  const [isAlert, setIsAlert] = useState({
    type: false,
    text: '',
    close: () => { },
    show: false,
  });
  const callAlert = useCallback((obj) =>
    setIsAlert(obj), []);
  const closeAlert = useCallback(() => setIsAlert({
    type: false,
    text: '',
    close: () => { },
    show: false,
  }), []);

  //get SchoolId
  const { currentSchoolId: schoolId } = useContext(AppContext);

  // useEffect for Data
  useEffect(() => {
    setLoader(true);
    const getData = () => {
      service.getArchive(schoolId)
        .then((response) => {
          setArchiveData(response);
          setLoader(false);
        })
        .catch((e) => {
          setLoader(false);
          callAlert({
            type: false,
            text: 'Ooops, server error.'
              + ' Please, contact the admin of the website.',
            close: closeAlert,
            show: true,
          });
        });
    };
    if (schoolId !== null) { // to avoid double rendering
      getData();
    }
    return () => setArchiveData({});
  }, [callAlert, closeAlert, schoolId]);

  return (
    <>
      <ModalWrapper loader={loader}>
        <ModalContainer>
          <ModalHeader
            header="Export CSV"
            onClose={close}
          />
          <ArchiveCsvConstructor
            archiveData={archiveData}
            callAlert={callAlert}
            closeAlert={closeAlert}
            closeModal={close}
            setLoader={setLoader}
          />
        </ModalContainer>
      </ModalWrapper>
      {isAlert.show &&
        <Alert
          alertType={isAlert.type}
          text={isAlert.text}
          close={isAlert.close}
        />}
    </>
  );
}

export default ArchiveExportCsvModal;
