import { useRef, useContext } from 'react';
import BackupTwoToneIcon from '@mui/icons-material/BackupTwoTone';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { AppContext } from '../../context/AppContext';
import "./DragNDrop.scss"

const DragNDrop = ({fileDNDWrapperForm, fileDNDImg}) => {

  const {
    uploadedPhoto,
    setUploadedPhoto,
    fileToSave,
    setFileToSave,
  } = useContext(AppContext);

  const ref = useRef(null);

  const dragStartHandler = (e) => {
    e.preventDefault();
  };

  const onDropHandler = (e) => {
    e.preventDefault();
    let files = [...e.dataTransfer.files];
    // console.log('files', files);
    // console.log('files[0]', files[0]);
    setFileToSave((prevState) => {
      return { ...prevState, photo: files[0] };
    });
    // console.log('onDropHandler-fileToSave', fileToSave);
    const uploadedFile = files[0];
    getBase64(uploadedFile, (base64string) => {
      setUploadedPhoto(base64string)
      // console.log('uploadedPhoto', uploadedPhoto)
    })
  };
  // console.log('fileToSave', fileToSave);
  
  const handlerRemovePhoto = () => {
    setUploadedPhoto("");
    setFileToSave((prevState) => {
      return { ...prevState, photo: null };
    });
  }
  
  const fileSelectedHandler = () => {
    setFileToSave((prevState) => {
      return {
        ...prevState, photo: ref.current.files[0]
      };
    })
    const uploadedFile = ref.current.files[0];
    getBase64(uploadedFile, (base64string) => {
      setUploadedPhoto(base64string)
      // console.log('uploadedPhoto', uploadedPhoto)
    })
  }
  
  // console.log("fileToSave", fileToSave)

// func to transform img into base64
  function getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    }
    reader.onError = function (error) {
      console.log("error:", error)
    }
  }
  
  return (
    <div className="fileUploaderWrapper">
    {/* {!fileToSave.photo ? ( */}
    {!uploadedPhoto ? (
      <>
      <div
            className={`dragAndDropWrapper_grey ${fileDNDWrapperForm}`} 
        draggable
        onDragStart={(e) => dragStartHandler(e)}
        onDragLeave={(e) => dragStartHandler(e)}
        onDragOver={(e) => dragStartHandler(e)}
        onDrop={(e) => onDropHandler(e)}
      >
        <div className="dragAndDrop_greyCloud_svg">
          <BackupTwoToneIcon />
        </div>
        <div className="dragAndDrop_greyText">Drag Photo to Upload</div>
      </div>
      <label htmlFor="addImg">choose file</label>
      <input
        type="file"
        id="addImg"
        onChange={fileSelectedHandler} 
        ref={ref}
      />
     </>
    ) : (
          <div className={`dragAndDropWrapper ${fileDNDWrapperForm}`}>
          <img
              className={`dragAndDropWrapper_Img ${fileDNDImg}`} 
            src={uploadedPhoto}
            alt="user photo"/>
        <div className="dragAndDrop_remove" onClick={handlerRemovePhoto}>
          <DeleteOutlineIcon/>
          Remove photo
        </div>
      </div>
     )} 
  </div>
  );
}

export default DragNDrop;