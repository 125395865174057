import { useState } from 'react';
import axios from 'axios';
// import CloseIcon from '@mui/icons-material/Close';
// import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'; // empty
// import CheckBoxTwoToneIcon from '@mui/icons-material/CheckBoxTwoTone';
import { SecondaryButton } from '../../../../../components/buttons/SecondaryButton/SecondaryButton';
import { ButtonPrimary } from '../../../../../components/buttons/buttonPrimary/ButtonPrimary';
import { ModalWrapper } from '../../../../../components/Modal/ModalWrapper/ModalWrapper';
import { ModalContainer } from '../../../../../components/Modal/ModalContainer/ModalContainer';
import { ModalHeader } from '../../../../../components/Modal/ModalHeader/ModalHeader';
import { config } from '../../../../../config';
import './ArchiveClassModal.scss';


export const ArchiveClassModal = ({ onClose, activeClasses, loadDataForGradeBook }) => {
  // const [confirm, setConfirm] = useState(false);
  const [successfulRequest, setSuccessfulRequest] = useState(null);

  const handleSendToArchiveClasses = async () => {
    // ARCHIVE
    // base_url/api/gradebook/archive
    // type POST
    // request body
    // {
    //   ids: [1, 2, 3],
    //   isUpdate: true/false   ???
    // }

    const dataToSend = {
      ids: activeClasses,
      // classesId: activeClasses,
      // confirm: confirm,
    };
    try {
      const data = await axios.post(`${config.API_URL}/api/gradebook/archive`, dataToSend);
      if (data) {
        setSuccessfulRequest(true);
      }
    } catch (e) {
      // close()
      console.log(e);
      setSuccessfulRequest(false);
    }
  };

  return (
    <>
      {successfulRequest === null && (
        <>
          <ModalWrapper>
            <ModalContainer>
              <ModalHeader header="Archive Class(es)" color="darkHeader" onClose={onClose} />
              <div className="upgradeClassModal_tooltipWrapper">
                Are you sure you want to archive <span>class(es)</span> ?
              </div>
              <div className="upgradeClassModal_smallTooltipWrapper">
                *All students and test results will be archived.
              </div>
              {/* <div
                style={{ background: confirm ? '#F2F2F2' : null, cursor: 'pointer' }}
                className="upgradeClassModal_confirmationWrapper"
                onClick={() => {
                  setConfirm((prevState) => !prevState);
                }}
              >
                {confirm ? <CheckBoxTwoToneIcon /> : <CheckBoxOutlineBlankIcon />}
                Archive Class(es)
              </div> */}
              <div style={{ width: 250 }} className="upgradeClassModal_buttonsWrapper">
                <div className="">
                  <SecondaryButton status={'cancel'} active={true} functionality={onClose} />
                </div>

                <div className="">
                  <ButtonPrimary
                    status={'archive'}
                    active={true}
                    functionality={handleSendToArchiveClasses}
                  />
                </div>
              </div>
            </ModalContainer>
          </ModalWrapper>
        </>
      )}
      {successfulRequest === false && (
        <>
          <ModalWrapper>
            <ModalContainer>
              <ModalHeader
                header="Archivation Error"
                color="darkHeader"
                onClose={() => {
                  onClose();
                  setSuccessfulRequest(null);
                }}
              />
              <div className="upgradeClassModal_tooltipWrapper">
                Something went wrong. Please try again later.
              </div>
              <div style={{ height: 40, marginTop: 20, marginLeft: 80 }} className="">
                <ButtonPrimary
                  status={'ok'}
                  active={true}
                  functionality={() => {
                    onClose();
                    setSuccessfulRequest(null);
                  }}
                />
              </div>
            </ModalContainer>
          </ModalWrapper>
        </>
      )}
      {successfulRequest === true && (
        <>
          <ModalWrapper>
            <ModalContainer>
              <div className="upgradeClassModal_headerWrapper">
                <div
                  style={{ marginLeft: 37, color: '#A6192E' }}
                  className="upgradeClassModal_header"
                >
                  Successfully Archived
                </div>
              </div>

              <div style={{ height: 40, marginTop: 30, marginLeft: 80 }} className="">
                <ButtonPrimary
                  status={'okRed'}
                  active={true}
                  functionality={() => {
                    onClose();
                    setSuccessfulRequest(null);
                    loadDataForGradeBook();
                  }}
                />
              </div>
            </ModalContainer>
          </ModalWrapper>
        </>
      )}
    </>
  );
};
