import { FC } from 'react';
import { ErrorPopover } from '../../../../components/ErrorPopover/ErrorPopover';
import Popover from '@material-ui/core/Popover';

interface Props {
  anchorElement?: Element | null;
  visible: boolean;
  onClose: () => void;
}

export const IncorrectPasswordPopover: FC<Props> = ({ anchorElement, visible, onClose }) => (
  <Popover
    open={visible}
    onClose={onClose}
    anchorOrigin={{
      vertical: 'center',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'center',
      horizontal: 'left',
    }}
    anchorEl={anchorElement}
    elevation={2}
  >
    <ErrorPopover
      header="Incorrect Password"
      subheader="Password requirements:"
      lines={[
        'At least 8 characters,',
        'A mixture of both uppercase and lowercase letters,',
        'A mixture of letters and numbers,',
        'Inclusion of at least one special character, e.g., ! @ # ? ]',
      ]}
      warning="Note: do not use < or > in your password, as both can cause problems in Web browsers."
    />
  </Popover>
);
