import { TableHeadLine } from '../../components/TableHeadLine/TableHeadLine';
import { Table } from '../../components/Table/Table';
import { Pagination } from '../../components/Pagination/Pagination';
import { TableHead } from '../../components/TableHead/TableHead';

import './StudentAnalyticsTable';

const typeTableRow = {
  tableFor: 'studentAnalytics',
  tableCellWidth: `240px 96px 78px 240px 88px 80px 104px `,
  tableWidth: '1144px',
  // gridTemplateColum: '12px',
  gridGap: '23px',
  rowPaddingLeft: '20px',
  //  background: '#F2F2F2' ,
  height: '32px',
  boxShadow: 'none',
  fontSize: '1.6rem',
};

export const StudentAnalyticsTable = ({
  filterAverageScore,
  filterAverageSpeed,
  pageSize,
  setPageSize,
  currentPage,
  setCurrentPage,
  totalCount,
  setTotalCount,
  tableData,
  setLabelSort,
  setDateSort,
  setScoreSort,
  setSpeedSort,
  sortFunctionForLabel,
  sortFunctionForDate,
  sortFunctionForSpeed,
  sortFunctionForScore,
}) => {
  const headerForAvScore =
    filterAverageScore === 'below50'
      ? 'below 50% accuracy  '
      : filterAverageScore === 'from50to70'
        ? '50 - 79% accuracy  '
        : filterAverageScore === 'from70to90'
          ? '80 - 89% accuracy'
          : filterAverageScore === 'from90to100'
            ? '90%+ accuracy'
            : null;
  const headerForSpeed =
    filterAverageSpeed === 'belowExpected'
      ? 'Below expected speed  '
      : filterAverageSpeed === 'asExpected'
        ? ' As expected speed '
        : filterAverageSpeed === 'aboveExpected'
          ? 'Above expected speed'
          : null;
  const headerColorForAvScore =
    filterAverageScore === 'below50'
      ? '#FB463B '
      : filterAverageScore === 'from50to70'
        ? '#E2B534'
        : filterAverageScore === 'from70to90'
          ? '#0F9D58'
          : filterAverageScore === 'from90to100'
            ? '#4285F4'
            : null;
  const headerColorForAvSpeed =
    filterAverageSpeed === 'belowExpected'
      ? '#FB463B'
      : filterAverageSpeed === 'asExpected'
        ? '#0F9D58'
        : filterAverageSpeed === 'aboveExpected'
          ? '#4285F4'
          : null;

  const showHeadLine = filterAverageSpeed || filterAverageScore ? true : false;

  const headlineType = {
    titleName: 'tests',
    speedTitle: headerForSpeed,
    speedColor: headerColorForAvSpeed,
    scoreTitle: headerForAvScore,
    scoreColor: headerColorForAvScore,
    // input : false
    inputPlaceHolder: 'Enter Student Name, Teacher Name or Test Label',
    buttonText: 'Export csv',

    isInput: false,
    isExportButton: false,
    isExportCsv: false,
  };

  const tableHeadForTestsAnalitics = [
    {
      fieldName: 'Label',
      width: '240px',
      sortByLetters: true,
      sortByNum: false,
      functionality: sortFunctionForLabel,
    },
    {
      fieldName: 'Concept',
      width: '96px',
      sortByLetters: false,
      sortByNum: false,
    },
    {
      fieldName: 'Status',
      width: '78px',
      sortByLetters: false,
      sortByNum: false,
    },
    {
      fieldName: 'Teachers',
      width: '240px',
      sortByLetters: false,
      sortByNum: false,
    },
    {
      fieldName: 'Date',
      width: '88px',
      sortByLetters: false,
      sortByNum: true,
      functionality: sortFunctionForDate,
    },
    {
      fieldName: 'Score',
      width: '80px',
      sortByLetters: false,
      sortByNum: true,
      functionality: sortFunctionForScore,
    },
    {
      fieldName: 'Speed',
      width: '104px',
      sortByLetters: false,
      sortByNum: true,
      functionality: sortFunctionForSpeed,
    },
  ];

  //  console.log(tableData)
  return (
    <div className="ableForAnalyticPage_wrapper">
      {showHeadLine && <TableHeadLine headlineType={headlineType} />}
      <TableHead typeTableHead={tableHeadForTestsAnalitics} typeTableRow={typeTableRow} />
      <Table
        tableRowData={tableData}
        typeTableHead={tableHeadForTestsAnalitics}
        forPage={'testsAnalytics'}
        typeTableRow={'testsAnalytics'}
      />
      <Pagination
        pageSize={pageSize}
        setPageSize={setPageSize}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalCount={totalCount}
      />
    </div>
  );
};
