import React from 'react';
import './SecondaryButton.scss';
import { ControllerObjs } from './ControllerObjs';
import { SecondaryButtonLow } from './SecondaryButtonLow';

export const SecondaryButton = ({
  status,
  type,
  active,
  icon,
  iconName,
  iconColor,
  iconFontSize,
  iconPosition,
  functionality,
}) => {
  return (
    <div className={'secondaryButtonWrapper'}>
      <SecondaryButtonLow
        selector={ControllerObjs[status]}
        type={type}
        active={active}
        icon={icon}
        iconName={iconName}
        iconColor={iconColor}
        iconFontSize={iconFontSize}
        iconPosition={iconPosition}
        functionality={functionality}
      />
    </div>
  );
};
