import React from 'react';
import * as MuiIcons from '@mui/icons-material';
import classNames from 'classnames';
import { useStyles } from './Controller';

export function IconButton(props) {
  let Icon = MuiIcons[props.iconName];

  const classes = useStyles(props);
  let classForIcon = null;
  let classForButton = null;

  switch (props.type) {
    case '1':
      if (props.active !== true) {
        classForIcon = `IconButtonFirstDisable`;
        classForButton = 'IconButtonFirstContDisabled';
      } else {
        classForIcon = `IconButtonFirst`;
        classForButton = 'IconButtonFirstCont';
      }

      break;
    case '2':
      if (props.active !== true) {
        classForIcon = `IconButtonSecondDisable`;
        classForButton = 'IconButtonSecondContDisabled';
      } else {
        classForIcon = `IconButtonSecond`;
        classForButton = 'IconButtonSecondCont';
      }

      break;
    case '3':
      if (props.active !== true) {
        classForIcon = `IconButtonThirdDisable`;
        classForButton = 'IconButtonThirdContDisabled';
      } else {
        classForIcon = `IconButtonThird`;
        classForButton = 'IconButtonThirdCont';
      }

      break;
    case '4':
      if (props.active !== true) {
        classForIcon = `IconButtonFourthDisable`;
        classForButton = 'IconButtonFourthContDisabled';
      } else {
        classForIcon = `IconButtonFourth`;
        classForButton = 'IconButtonFourthCont';
      }

      break;
    default:
      break;
  }
  return (
    <div
      onClick={props.funtionality}
      className={classNames(classes[classForButton], classes['IconButtonCont'])}
    >
      <Icon className={classes[classForIcon]} />
    </div>
  );
}
