import { config } from '../../config';

export class TestsService {
  _apiBase = `${config.API_URL}/api/tests?schoolId=`;
  _apiClasses = `${config.API_URL}/api/gradebook?schoolId=`;
  _apiAddUpdate = `${config.API_URL}/api/tests`;
  _apiForArchive = `${config.API_URL}/api/tests/archive`;

  getTests = async (schoolId: number | string = 1) => {
    const response = await fetch(`${this._apiBase}${schoolId}`);

    if (!response.ok) {
      throw new Error(`Could not fetch ${this._apiBase}, received ${response.status}`);
    }

    return await response.json();
  };

  getAllClasses = async (schoolId: number | string = 1) => {
    const response = await fetch(`${this._apiClasses}${schoolId}`);

    if (!response.ok) {
      throw new Error(`Could not fetch ${this._apiClasses}, received ${response.status}`);
    }

    return await response.json();
  };

  addTest = async (
    label: string,
    questions: number,
    concept: string,
    expSpeed: number,
    classes: Array<number>
  ) => {
    const response = await fetch(this._apiAddUpdate, {
      method: 'POST',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
      body: JSON.stringify({
        label: label,
        totalQuestions: questions,
        concept: concept,
        expectedSpeed: expSpeed,
        classes: classes,
      }),
    });

    if (!response.ok) {
      throw new Error(`Could not fetch ${this._apiAddUpdate}, received ${response.status}`);
    }

    return await response.json();
  };

  updateTest = async (
    id: number,
    label: string,
    questions: number,
    concept: string,
    expSpeed: number,
    classes: Array<number>
  ) => {
    const response = await fetch(this._apiAddUpdate, {
      method: 'PATCH',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
      body: JSON.stringify({
        id: id,
        label: label,
        totalQuestions: questions,
        concept: concept,
        expectedSpeed: expSpeed,
        classes: classes,
      }),
    });

    if (!response.ok) {
      throw new Error(`Could not fetch ${this._apiAddUpdate}, received ${response.status}`);
    }
  };

  archiveTests = async (testId: Array<number>) => {
    const response = await fetch(this._apiForArchive, {
      method: 'POST',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
      body: JSON.stringify({
        ids: testId,
        isArchive: 1,
      }),
    });

    if (!response.ok) {
      throw new Error(`Could not fetch ${this._apiForArchive}, received ${response.status}`);
    }
  };

  unarchiveTests = async (testId: Array<number>) => {
    const response = await fetch(this._apiForArchive, {
      method: 'POST',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
      body: JSON.stringify({
        ids: testId,
        isArchive: 0,
      }),
    });

    if (!response.ok) {
      throw new Error(`Could not fetch ${this._apiForArchive}, received ${response.status}`);
    }
  };
}
