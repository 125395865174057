import React, { useCallback, useState, useEffect, useContext } from 'react';
import { InternalPageContainer } from '../../components/InternalPageContainer/InternalPageContainer';
import { FiltersContainer } from '../../components/FiltersContainer/FiltersContainer';
// import { PageHead } from '../../components/PageHead/PageHead';
import { NoStudentsContent } from './NoStudentsContent/NoStudentsContent';
import StudentsModal from './StudentModalInfo/StudentsModal';
import StudentsContent from './StudentsContent/StudentsContent';
import { LoaderFullBook } from '../../components/Loaders/LoaderFullBook/LoaderFullBook';
import { Alert } from '../../components/Alert/Alert';
import StudentsModalEdit from './StudentModalInfo/StudentsModalEdit/StudentsModalEdit';
import TeachersModalOnSubmit from '../TeachersPage/TeachersModalInfo/TeacherModalOnSubmit/TeacherModalOnSubmit';
import { StyledDropdownSelect } from '../../components/DropdownSelect/StyledDropdownSelect';
import ButtonText from '../../components/buttons/buttonText';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import NotFoundPage from '../NotFoundPage/NotFoundPage';
import { AppContext } from '../../context/AppContext';
import { config } from '../../config';
import './StudentsPage.scss';

export const StudentsPage = () => {
  const [showErrorPage, setShowErrorPage] = useState(false);
  const { currentSchoolId } = useContext(AppContext);

  const [classesFilterData, setClassesFilterData] = useState(false);
  const scoreFilterData = [
    { title: '90%+', id: 1 },
    { title: '80 - 89%', id: 2 },
    { title: '50 - 79%', id: 3 },
    { title: 'BELOW 50%', id: 4 },
  ];
  const speedFilterData = [
    { title: 'Above Exp.', id: 1 },
    { title: 'As Exp.', id: 0 },
    { title: 'Below Exp.', id: -1 },
  ];

  const [modal, setOpenModal] = useState(false);
  const openModal = useCallback(() => setOpenModal(true), []);
  const closeModal = useCallback(() => setOpenModal(false), []);

  const [modalEdit, setModalEdit] = useState(false);
  const openModalEdit = useCallback(() => setModalEdit(true), []);
  const closeModalEdit = useCallback(() => setModalEdit(false), []);

  const [defaultStudent, setDefaultStudent] = useState({});
  const fillDefaultEditValues = useCallback((student) => setDefaultStudent(student), []);

  const [submit, setSubmit] = useState(false);
  const [submitModal, setSubmitModal] = useState(false);

  const openOnSubmitModal = useCallback((text) => setSubmitModal(text), []);
  const closeOnSubmitModal = useCallback(() => setSubmitModal(false), []);

  const [modalLoader, setModalLoader] = useState(false);
  const changeModalLoader = useCallback(() => setModalLoader(prev => !prev), []);

  const submitForm = useCallback(() => setSubmit(prev => !prev), []);

  const [tableData, setTableData] = useState([]);
  const [showLoader, setShowLoader] = useState(true);

  const [showAlert, setShowAlert] = useState(false);
  const [isAlert, setIsAlert] = useState({
    type: false,
    text: '',
    close: () => { }
  });

  const callAlert = useCallback((obj) => setIsAlert(obj), []);
  const closeAlert = useCallback(() => setIsAlert({
    type: false,
    text: '',
    close: () => { }
  }), []);

  const requestFetch = (url) => {
    return fetch(url)
      .then(response => {
        if (response.ok) {
          return response.json()
        }

        return response.json().then(error => {
          const e = new Error('Smth gone wrong')
          e.data = error
          throw e
        })
      });
  }

  const sortByArchive = (data) => {
    data.sort((a, b) => {
      return a.isArchive - b.isArchive;
    })
    return data;
  }

  useEffect(() => {
    setShowLoader(true);
    requestFetch(`${config.API_URL}/api/student?schoolId=${currentSchoolId}`)
      .then(data => {
        setTableData(sortByArchive(data));
        setShowLoader(false);
      })
      .catch(() => {
        setShowErrorPage(true)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit, currentSchoolId])

  useEffect(() => {
    requestFetch(`${config.API_URL}/api/gradebook?schoolId=${currentSchoolId}`)
      .then(data => {
        setClassesFilterData(data);
      })
      .catch(() => { setShowErrorPage(true) })
  }, [currentSchoolId])

  const [classesFilterId, setClassesFilterId] = useState(null);
  const [classesFilterTitle, setClassesFilterTitle] = useState('');

  const [scoreFilterId, setScoreFilterId] = useState(null);
  const [scoreFilterTitle, setScoreFilterTitle] = useState('');

  const [speedFilterId, setSpeedFilterId] = useState(null);
  const [speedFilterTitle, setSpeedFilterTitle] = useState('');

  return (
    <div className='studentsPage'>

      {!showErrorPage ? (
        <>
          <InternalPageContainer />
          <FiltersContainer>
            <div className='filtersContainer'>
              {classesFilterData.length ? (
                <StyledDropdownSelect className='styledDropdown'>
                  <div className="textAdaArrowWrapper">
                    <div className="text_selectWrapper">
                      {classesFilterId === null ? 'ALL CLASSES' : classesFilterTitle}
                    </div>
                    <ArrowDropDownIcon />
                  </div>
                  <div className="dropDownWrapper">
                    {classesFilterId !== null && (
                      <div
                        className="dropDown_item"
                        onClick={() => {
                          setSpeedFilterId(null);
                          setClassesFilterId(null);
                          setScoreFilterId(null);
                        }}
                      >
                        ALL CLASSES
                      </div>
                    )}
                    {classesFilterData.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="dropDown_item"
                          onClick={() => {
                            setClassesFilterId(item.Id);
                            setClassesFilterTitle(`${item.Grade}${item.ClassName}`);
                            setSpeedFilterId(null);
                            setScoreFilterId(null);
                          }}
                        >
                          {item.Grade}{item.ClassName}
                        </div>
                      );
                    })}
                  </div>
                </StyledDropdownSelect>
              ) : null}
              <StyledDropdownSelect className='styledDropdown'>
                <div className="textAdaArrowWrapper">
                  <div className="text_selectWrapper">
                    {scoreFilterId === null ? 'AV.SCORE' : scoreFilterTitle}
                  </div>
                  <ArrowDropDownIcon />
                </div>
                <div className="dropDownWrapper">
                  {scoreFilterId !== null && (
                    <div
                      className="dropDown_item"
                      onClick={() => {
                        setSpeedFilterId(null);
                        setClassesFilterId(null);
                        setScoreFilterId(null);
                      }}
                    >
                      ALL
                    </div>
                  )}
                  {scoreFilterData.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="dropDown_item"
                        onClick={() => {
                          setScoreFilterId(item.id);
                          setScoreFilterTitle(item.title);
                          setSpeedFilterId(null);
                          setClassesFilterId(null);
                        }}
                      >
                        {item.title}
                      </div>
                    );
                  })}
                </div>
              </StyledDropdownSelect>
              <StyledDropdownSelect className='styledDropdown'>
                <div className="textAdaArrowWrapper">
                  <div className="text_selectWrapper">
                    {speedFilterId === null ? 'AV.SPEED' : speedFilterTitle}
                  </div>
                  <ArrowDropDownIcon />
                </div>
                <div className="dropDownWrapper">
                  {speedFilterId !== null && (
                    <div
                      className="dropDown_item"
                      onClick={() => {
                        setSpeedFilterId(null);
                        setClassesFilterId(null);
                        setScoreFilterId(null);
                      }}
                    >
                      ALL
                    </div>
                  )}
                  {speedFilterData.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="dropDown_item"
                        onClick={() => {
                          setSpeedFilterId(item.id);
                          setSpeedFilterTitle(item.title);
                          setClassesFilterId(null);
                          setScoreFilterId(null);
                        }}
                      >
                        {item.title}
                      </div>
                    );
                  })}
                </div>
              </StyledDropdownSelect>
              <ButtonText
                type={`4`}
                text={`Clear all`}
                iconName={`Clear`}
                active={true}
                funtionality={() => {
                  setClassesFilterId(null);
                  setSpeedFilterId(null);
                  setScoreFilterId(null);
                }}
              />
            </div>
          </FiltersContainer>

          {!showLoader ? (
            <>
              {tableData.length ? (
                <StudentsContent
                  setShowAlert={setShowAlert}
                  fillDefaultEditValues={fillDefaultEditValues}
                  submitForm={submitForm}
                  tableData={tableData}
                  openModal={openModal}
                  callAlert={callAlert}
                  closeAlert={closeAlert}
                  openModalEdit={openModalEdit}

                  classesFilterId={classesFilterId}
                  scoreFilterId={scoreFilterId}
                  speedFilterId={speedFilterId}

                  setClassesFilterId={setClassesFilterId}
                  setSpeedFilterId={setSpeedFilterId}
                  setScoreFilterId={setScoreFilterId}
                />
              ) : (
                <NoStudentsContent openModal={openModal} />
              )}
            </>
          )
            : (
              <LoaderFullBook />
            )
          }
        </>
      ) : (
        <NotFoundPage text={'Ooops, server error. Please, contact the admin of the website.'} />
      )}

      {modal && (
        <StudentsModal
          setShowAlert={setShowAlert}
          loader={modalLoader}
          changeModalLoader={changeModalLoader}
          close={closeModal}
          submitForm={submitForm}
          openOnSubmitModal={openOnSubmitModal}
        />
      )}

      {modalEdit && (
        <StudentsModalEdit
          setShowAlert={setShowAlert}
          submitForm={submitForm}
          close={closeModalEdit}
          defaultStudent={defaultStudent}
          openOnSubmitModal={openOnSubmitModal}
          loader={modalLoader}
          changeModalLoader={changeModalLoader}
        />
      )}


      {submitModal && (
        <TeachersModalOnSubmit close={closeOnSubmitModal} text={submitModal} />
      )}


      {isAlert.type && <Alert
        alertType={isAlert.type}
        text={isAlert.text}
        close={isAlert.close}
      />}

      {showAlert && <Alert
        alertType={false}
        text={'Oops, server error. Try again later'}
        close={() => { setShowAlert(false) }}
      />}

    </div>
  );
};
