import React, { useState, useEffect } from 'react';

import { Typography } from '@material-ui/core';
import { OutlinedTextField } from '../../../../../components/OutlinedTextField/OutlinedTextField';
import { SelectTypeahead } from '../../../../../components/SelectTypeahead/SelectTypeahead';
import ButtonText from '../../../../../components/buttons/buttonText';
import styles from './StudentsSecondParentFormFields.module.scss';
import SelectParent from '../../../../../components/SelectParents/SelectParent';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


export const StudentsSecondParentFormFields = ({
  prevStep,
  register,
  errors,
  control,
  setValue,
}) => {
  const [parentsCounter, setParentsCounter] = useState([]);

  return (
    <div className={styles.fieldSet}>

      <Typography
        className={styles.text}
        gutterBottom
        paragraph
      >
        2/2 Parents information (optional)
      </Typography>

      <SelectParent
        className={styles.formField}
        control={control}
        setParentsCounter={setParentsCounter}
      />

      <Typography
        className={styles.newText}
        gutterBottom
        paragraph
        disabled={parentsCounter?.length === 1}
      >
        Create new
      </Typography>

      <div
        className={styles.inputWrapper}
        key={parentsCounter.length + 1}
        disabled={parentsCounter?.length === 1}>
        <OutlinedTextField
          {...register('secondParentFirstName', {
            required: parentsCounter.length === 0 ? 'Required Field' : false,
          })}
          fullWidth
          // defaultValue={defaultValues ? defaultValues?.firstName : ''}
          label="First Name"
          placeholder={'Enter First Name'}
          className={styles.formField}
        >
        </OutlinedTextField>


        <div className={styles.textHelper}>
          {errors?.secondParentFirstName &&
            (
              <>
                <p>{errors?.secondParentFirstName?.message || 'Error!'}</p>
                <InfoOutlinedIcon />
              </>
            )}
        </div>

      </div>

      <div
        key={parentsCounter.length + 2}
        className={styles.inputWrapper}
        disabled={parentsCounter?.length === 1}>
        <OutlinedTextField
          {...register('secondParentLastName', {
            required: parentsCounter.length === 0 ? 'Required Field' : false,
          })}
          fullWidth
          label="Last Name"
          placeholder={'Enter Last Name'}
          className={styles.formField}
        >
        </OutlinedTextField>


        <div className={styles.textHelper}>
          {errors?.secondParentLastName &&
            (
              <>
                <p>{errors?.secondParentLastName?.message || 'Error!'}</p>
                <InfoOutlinedIcon />
              </>
            )}
        </div>


      </div>

      <div
        key={parentsCounter.length + 3}
        className={styles.inputWrapper}
        disabled={parentsCounter?.length === 1}>
        <OutlinedTextField
          key={parentsCounter.length}
          {...register('secondParentEmail', {
            required: parentsCounter.length === 0 ? 'Required Field' : false,
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: "Entered value does not match email format"
            }
          })}
          fullWidth
          // defaultValue={defaultValues ? defaultValues?.firstName : ''}
          label="Email"
          placeholder={'XXXXXXX@XXXX.XX'}
          className={styles.formField}
        >
        </OutlinedTextField>

        <div className={styles.textHelper} disabled={parentsCounter?.length === 2}>
          {errors?.secondParentEmail &&
            (
              <>
                <p>{errors?.secondParentEmail?.message || 'Error!'}</p>
                <InfoOutlinedIcon />
              </>
            )}
        </div>


      </div>

      <div
        key={parentsCounter.length + 4}
        className={styles.inputWrapper}
        disabled={parentsCounter?.length === 1}>
        <OutlinedTextField
          key={parentsCounter.length}
          {...register('secondParentPhone', {
            required: parentsCounter.length === 0 ? 'Required Field' : false,
            pattern: {
              value: /[0-9]{3}-[0-9]{3}-[0-9]{4}/,
              message: "Entered value does not match phone format"
            }
          })}
          fullWidth
          // defaultValue={defaultValues ? defaultValues?.firstName : ''}
          label="Phone"
          placeholder={'XXX-XXX-XXXX'}
        >
        </OutlinedTextField>

        <div
          style={{ bottom: '5px' }}
          className={styles.textHelper}>
          {errors?.secondParentPhone &&
            (
              <>
                <p>{errors?.secondParentPhone?.message || 'Error!'}</p>
                <InfoOutlinedIcon />
              </>
            )}
        </div>


      </div>

      <Typography
        className={styles.textAccent}
        gutterBottom
        paragraph
        onClick={() => {
          prevStep();
        }}
      >
        Back
      </Typography>

    </div>
  );
};
