import React from 'react';

export const ControllerObjs = {
  cancel: {
    className: 'cancel',
    addClassName: '',
    text: 'CANCEL',
    href: '',
  },
  back: {
    className: 'back',
    addClassName: '',
    text: 'BACK',
    href: '',
  },
  skipStep: {
    className: 'back',
    addClassName: '',
    text: 'BACK',
    href: '',
  },
  previous: {
    className: 'previous',
    addClassName: '',
    text: 'PREVIOUS',
    href: '',
  },
};
