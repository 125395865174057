import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankTwoToneIcon from '@mui/icons-material/CheckBoxOutlineBlankTwoTone';
import CheckBoxTwoToneIcon from '@mui/icons-material/CheckBoxTwoTone';
import './CheckboxTwoTone.scss';

function CheckboxTwoTone({ disabled = false, checked = false, onChange = () => { } }) {
  return (
    <span className="checkbox">
      <Checkbox
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        icon={<CheckBoxOutlineBlankTwoToneIcon />}
        checkedIcon={<CheckBoxTwoToneIcon />}
        disableRipple
      />
    </span>
  );
}

export default CheckboxTwoTone;
