import { useState } from 'react';
import ErrorBoundary from '../../../components/ErrorBaundary';
import ArchivePageGradeRow from './ArchivePageGradeRow/ArchivePageGradeRow';
import CheckboxTwoTone from '../../../components/CheckboxTwoTone';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import './ArchivePageYearRow.scss';

function ArchivePageYearRow({
  year,
  serverResponse,
  setActiveClasses,
  activeClasses,
  toggleSelectedClasses,
  searchValue,
  callRender,
}) {
  const [showGrades, setShowGrades] = useState(false);

  const responseClasses = Object.values(serverResponse[year]).flat();
  let yearActive = false;
  responseClasses.forEach(item => {
    if (activeClasses.includes(item)) {
      yearActive = true;
    }
  });

  const onChangeCheck = () => {
    yearActive = !yearActive;
    if (yearActive === true) {
      toggleSelectedClasses(responseClasses)
    }
    if (yearActive === false) {
      const removeSelect = activeClasses
        .filter((item) => !responseClasses
          .includes(item));
      setActiveClasses([...removeSelect]);
    }
  };

  const gradesList = Object.keys(serverResponse[year])
    .map((grade, index) => {
      return (
        <ArchivePageGradeRow
          key={index}
          grade={grade}
          classesInSelectedGrade={serverResponse[year][grade]}
          activeClasses={activeClasses}
          setActiveClasses={setActiveClasses}
          toggleSelectedClasses={toggleSelectedClasses}
          searchValue={searchValue}
          callRender={callRender}
        />
      );
    });

  const selectedRow = (yearActive) ? ' checked' : '';
  const showGradesRow = (showGrades || (searchValue.length > 0))
    ? ' showGrades' : '';

  return (
    <ErrorBoundary>
      <div className={`archivePageYearRow${selectedRow}`}>
        <div className="archivePageYearRow__content">
          <span className="archivePageYearRow__content-checkbox">
            <CheckboxTwoTone
              checked={yearActive}
              onChange={onChangeCheck}
            />
          </span>
          <span className="archivePageYearRow__content-value">{year}</span>
          <button
            className="archivePageYearRow__content-btn"
            type="button"
            onClick={() => setShowGrades(!showGrades)}
          >
            {(showGrades) ? (
              <ArrowDropUpIcon color="action" />
            ) : (
              <ArrowDropDownIcon color="disabled" />
            )}
          </button>
        </div>
      </div>
      <div className={`archivePageGradeRow${showGradesRow}`}>
        {gradesList}
      </div>
    </ErrorBoundary>
  );
};

export default ArchivePageYearRow;
