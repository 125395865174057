import { createContext, useState, useEffect, useMemo } from 'react';
import { useHistory} from 'react-router-dom';
import { getAllSchools } from '../services/schools';
import { config } from "../config"
import axios from "axios";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const history = useHistory();
  const [showLoader, setShowLoader] = useState(false);

  //  ===== auth ======
  const [isAuth, setIsAuth] = useState(false);

  //  ===== socialLinks ======
  const [socialLinks, setSocialLinks] = useState({
    googleLink: '',
    microsoftLink: '',
    cleverLink: '',
  });

  useEffect(() => {
    getSocialLinks();
  }, []);

  async function getSocialLinks() {
    try {
      await axios.get(`${config.API_URL}/api/auth/links`).then((response) => {
        // console.log('response', response);
        // console.log('response.data', response.data);
        setSocialLinks({ ...response.data });
      });
    } catch (e) {
      console.log(e);
    }
  }


  //  ===== user ======
  const defaultUser = {
    contactName: '',
    firstName: 'No Name',
    lastName: '',
    phone: '',
    photo: '', // IN BASE64
    role: 'School Manager',
    email: '',
    isArchive: false,
    id: 0,
  }
  const [user, setUser] = useState(defaultUser);

  useEffect(() => {
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAuth) {
      getSchoolsData();
    }
  }, [isAuth]);

  function getUserData() {
    setShowLoader(true);
    axios
      .get(`${config.API_URL}/api/user/info`)
      .then((response) => {
        const userData = response.data;
        console.log('response.data', response.data);
        if (response.status === 401) {
          setIsAuth(false);
          setShowLoader(false);
          history.push('/sign-in');
        } else {
          setUser({
            ...user,
            contactName: `${userData.LastName}, ${userData.FirstName}`,
            firstName: userData.FirstName,
            lastName: userData.LastName,
            phone: userData.Phone,
            photo: userData.Photo, // IN BASE64
            role: userData.Role,
            email: userData.Email,
            isArchive: userData.isArchive,
            id: userData.Id,
            authType:  userData.AuthType
          });
          setUploadedPhoto(userData.Photo);
          setIsAuth(true);
          setShowLoader(false);
        }
      })
      .catch(function (error) {
        console.log('error', error);
        setIsAuth(false);
        setShowLoader(false);
        history.push('/sign-in');
      });
  }

  const updateUser = (data) => {
    setUser(data);
  };

  //  ===== photo & files ======
  const [uploadedPhoto, setUploadedPhoto] = useState('');
  const [fileToSave, setFileToSave] = useState({
    photo: null,
  });

  //  ===== schools === currentSchoolId ===
  const [updateSchoolInfo, setUpdateSchoolInfo] = useState(false)

  const [schools, setSchools] = useState([]);
  const updateSchools = (data) => {
    setSchools(data);
  };

  const [currentSchoolId, setCurrentSchoolId] = useState(0);
  const updateCurrentSchoolId = (id) => {
    setCurrentSchoolId(id);
  };

  const currentSchool = useMemo(
    () => schools.find(({ Id }) => Id === currentSchoolId),
    [currentSchoolId]
  );

  // console.log('currentSchool', currentSchool);
  // console.log('currentSchoolId', currentSchoolId);

  
  function getSchoolsData() {
    setShowLoader(true);
    getAllSchools()
      .then((res) => {
        updateSchools(res.data);
        console.log(res.data)
        console.log(res.data?.[0]?.Id)
        // console.log(schools?.[0]?.Id)
        // console.log('res.data-GET SCHOOLS', res.data);
        const localDataID = Number(localStorage.getItem('currentSchoolId'));
        console.log('localDataID', localDataID);
        if (!localDataID) {
          setCurrentSchoolId(res.data?.[0]?.Id);
          localStorage.setItem('currentSchoolId', JSON.stringify(res.data?.[0]?.Id));
          // setShowLoader(false);
        } else{
          setCurrentSchoolId(localDataID);
        }
        // if (!localDataID) {
        //  setCurrentSchoolId(schools?.[0]?.Id); 
        // } else{
        //   setCurrentSchoolId(localDataID);
        // }
        
        setShowLoader(false);
      })
      .catch(function (error) {
        console.log('error', error);
        setShowLoader(false);
      });
  }

  // useEffect(() => {
  //   getSchoolsData();
  // }, [updateSchoolInfo, currentSchoolId]);

  // console.log('schools', schools);
  // console.log('currentSchoolId', currentSchoolId);

  //  ===== classInfo === currentClassId ======
  const [classInfo, setClassInfo] = useState({});
  
  const [currentClassId, setCurrentClassId] = useState(null);
  const updateCurrentClassId = (id) => {
    setCurrentClassId(id);
  };

  
  //  ===== studentInfo === currentStudentId ======


  const [updateStudentInfo, setUpdateStudentInfo] = useState(false);
  const [students, setStudents] = useState([
    { Name: 'Lana', LastName: 'Del Ray', id: 1 },
    { Name: 'Selena', LastName: 'Gomez', id: 2 },
    { Name: 'Katy', LastName: 'Perry', id: 3 },
  ]);

  const [currentStudentId, setCurrentStudentId] = useState(0);
  const updateCurrentStudentId = (id) => {
    setCurrentStudentId(id);
  };

    const currentStudent = useMemo(
      () => students.find(({ Id }) => Id === currentStudentId),
      [currentStudentId]
    );



  return (
    <AppContext.Provider
      value={{
        isAuth,
        setIsAuth,
        showLoader,
        setShowLoader,
        user,
        updateUser,
        defaultUser,
        schools,
        updateSchools,
        currentSchoolId,
        setCurrentSchoolId,
        updateCurrentSchoolId,
        getSchoolsData,
        uploadedPhoto,
        setUploadedPhoto,
        fileToSave,
        setFileToSave,
        getUserData,
        socialLinks,
        currentClassId,
        updateCurrentClassId,
        classInfo,
        setClassInfo,
        setUpdateSchoolInfo,
        currentSchool,
        students,
        setStudents,
        currentStudentId,
        setCurrentStudentId,
        updateCurrentStudentId,
        currentStudent,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

    //  === SERVER RESPONSE ===
    // AuthType: 0
    // ContactName: "Carrey, Jim"
    // Email: "jimcarrey@gmail.com"
    // FirstName: "Jim"
    // Id: 522
    // LastName: "Carrey"
    // Password: "$2b$10$qdf4ZzJxHDEZes7gyxBKO.eVttLQZr.yoTqX9cwf0vb8Bl31V5gfO"
    // Phone: "502-337-9932"
    // Photo: "https://www.seekpng.com/png/full/356-3562377_personal-user.png"
    // Role: 1
    // email: "jimcarrey@gmail.com"
    // isArchive: 0
    // name: "Jim Carrey"
    // registeredWith: "google"
    // status: "District manager"
    // ============== 