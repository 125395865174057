import { SyntheticEvent } from 'react';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { OutlinedTextField } from '../OutlinedTextField/OutlinedTextField';
import Chip from '@mui/material/Chip';
import classNames from 'classnames';
import styles from './SelectTypeahead.module.scss';

const checkboxIcon = <CheckBoxOutlineBlankIcon fontSize="medium" />;
const checkboxCheckedIcon = <CheckBoxIcon fontSize="medium" style={{ color: '#A6192E' }} />;

interface Props<T> {
  options: T[];
  selectedOptions: T[];
  getOptionLabel: (option: T) => string;
  inputLabel: string;
  inputPlaceholder?: string;
  onChange: (event: SyntheticEvent, value: T[]) => void;
  className?: string;
}

export function SelectTypeahead<T>({
  options,
  selectedOptions,
  getOptionLabel,
  inputLabel,
  inputPlaceholder,
  onChange,
  className,
}: Props<T>) {
  return (
    <Autocomplete
      multiple
      value={selectedOptions}
      onChange={onChange}
      options={options}
      getOptionLabel={getOptionLabel}
      className={className}
      renderOption={(props, option, { selected }) => (
        <li {...props} style={{ fontSize: 16 }}>
          <Checkbox
            icon={checkboxIcon}
            checkedIcon={checkboxCheckedIcon}
            style={{ marginRight: 8, height: 25 }}
            checked={selected}
          />
          {getOptionLabel(option)}
          {option}
        </li>
      )}
      renderInput={(params) => (
        <OutlinedTextField {...params} label={inputLabel} placeholder={inputPlaceholder} />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          const tagProps = getTagProps({ index });
          const className = classNames(tagProps.className, styles.tag);
          return <Chip {...tagProps} label={getOptionLabel(option)} className={className} />;
        })
      }
    />
  );
}
