import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { StudentInfoPopOver } from '../StudentInfoPopOver/StudentInfoPopOver';
import { useState } from 'react';
import './GradebookSudentInfoCell.scss';

export const GradebookSudentInfoCell = ({ student, index }) => {
  const [iconAnchorEl, setIconAnchorEl] = useState(null);
  const openStudentInfo = Boolean(iconAnchorEl);
  const handleOpenInforStudent = (event) => setIconAnchorEl(event.currentTarget);
  const handleCloseInforStudent = () => setIconAnchorEl(null);

  // background-color: ${props =>  props.index % 2 === 1 ? '#F9F9F9' : '#FFFFFF'};
  const bgColor = index % 2 === 1 ? '#F9F9F9' : '#FFFFFF';
  return (
    <div className="gradeBookStudentRow_studentInfo" style={{ backgroundColor: bgColor }}>
      <InfoOutlinedIcon onClick={handleOpenInforStudent} />
      {student.name.length > 32 ? student.name.substr(0, 30) + '...' : student.name}
      {/* {student.name.length >= 32 ? student.name.slice(0, 30) + '. . .' : student.name} */}

      <Popover
        open={openStudentInfo}
        onClose={handleCloseInforStudent}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          orizontal: 'left',
        }}
        anchorEl={iconAnchorEl}
        elevation={2}
      >
        <Typography component={'span'} variant={'body2'}>
          <StudentInfoPopOver
            parentsInfo={student.parentsInfo}
            studentIdSchool={student.studentIdSchool}
            name={student.name}
          />
        </Typography>
      </Popover>
    </div>
  );
};
