import React from 'react'
import EditProfileInputs from './EditProfileInputs/EditProfileInputs';
import DragNDrop from '../../../DragNDrop/DragNDrop';
import { ModalHeader } from '../../../Modal/ModalHeader/ModalHeader';
import styles from "./ContainEditProfile.module.scss" 

const ContainEditProfile = ({ onClose, nextStep, control, errors, formData, register, onChange}) => {
  
  return (
    <>                 
      <ModalHeader header="Edit Profile" onClose={onClose} />
      <div  className={styles.EditProfileModal_infoWrapper}>
        <DragNDrop fileDNDWrapperForm="CircleWrapperForm" fileDNDImg="CircleImg"/>
        <EditProfileInputs nextStep={nextStep} control={control} errors={errors} formData={formData} register={register} onChange={onChange}/>
      </div>
    </>
  );
}

export default ContainEditProfile;