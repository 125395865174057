import { useState, useEffect, useCallback, useContext } from 'react';
import { GradebooksRow } from './GradebooksPageComponents/GradebooksRow/GradebooksRow';
import { SubTableHeadLine } from '../../components/SubTableHeadLine/SubTableHeadLine';
import { TableHeadLine } from '../../components/TableHeadLine/TableHeadLine';
import CheckBoxTwoToneIcon from '@material-ui/icons/CheckBoxTwoTone';
import CheckBoxOutlineBlankTwoToneIcon from '@material-ui/icons/CheckBoxOutlineBlankTwoTone';
import { LoaderFullBook } from '../../components/Loaders/LoaderFullBook/LoaderFullBook';
import { InternalPageContainer } from '../../components/InternalPageContainer/InternalPageContainer';
import { FiltersContainer } from '../../components/FiltersContainer/FiltersContainer';
import { AddClassModal } from './GradebooksPageComponents/GradebooksPageModals/AddClassModal/AddClassModal';
import { EditClassModal } from './GradebooksPageComponents/GradebooksPageModals/EditClassModal/EditClassModal';
import { UpgradeClassModal } from './GradebooksPageComponents/GradebooksPageModals/UpgradeClassModal/UpgradeClassModal';
import { ArchiveClassModal } from './GradebooksPageComponents/GradebooksPageModals/ArchiveClassModal/ArchiveClassModal';
import { AppContext } from '../../context/AppContext';
import { config } from "../../config"
import './GradeBookPage.scss';


export const GradeBookPage = () => {
  const {
    showLoader,
    setShowLoader,
    currentSchoolId,
    setCurrentSchoolId,
    // updateSchoolInfo,
    setUpdateSchoolInfo,
    classInfo,
    setClassInfo,
  } = useContext(AppContext);

console.log('classInfo', classInfo)

  const allGrades = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]; 
  const [allClasses, setAllClasses] = useState({
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
    7: [],
    8: [],
    9: [],
    10: [],
    11: [],
    // 12:[]
  });
  const [activeClasses, setActiveClasses] = useState([]); // array of Class ID we click on checkBoxes and add classes ID
  // const [modalType, setModalType] = useState(null); //-  string ‘archive’ or ‘upgrade’ , this is define wich kind of modal will be open
 
  const [modalLoader, setModalLoader] = useState(false);
  const changeModalLoader = useCallback(() => setModalLoader(prev => !prev), []);

  // ===== Add Class Modal =====
  const [addClassModal, setAddClassModal] = useState(false);
    const handleOpenAddClassModal = () => setAddClassModal(true);
    const handleCloseAddClassModal = () => setAddClassModal(false);
  
  // ===== Edit Class Modal =====
  const [editClassModal, setEditClassModal] = useState(false); 
  const handleOpenEditClassModal = () => setEditClassModal(true);
  const handleCloseEditClassModal = () => setEditClassModal(false);
  
  // ===== Upgrade Class Modal =====
  const [upgradeClassModal, setUpgradeClassModal] = useState(false); 
    const handleOpenUpgradeClassModal = () => setUpgradeClassModal(true);
  const handleCloseUpgradeClassModal = () => setUpgradeClassModal(false);
  
  // ===== Archive Class Modal =====
  const [archiveClassModal, setArchiveClassModal] = useState(false); 
    const handleOpenArchiveClassModal = () => setArchiveClassModal(true);
    const handleCloseArchiveClassModal = () => setArchiveClassModal(false);
 
  //загружаем данные с сервера и создаем стейт-объект для  классов/грейда
  function loadDataForGradeBook() {
    localStorage.getItem('currentSchoolId', currentSchoolId);
    if (currentSchoolId === null || currentSchoolId === undefined) {
      return
    }
    if (currentSchoolId) {
       setShowLoader(true);
        try {
        fetch(`${config.API_URL}/api/gradebook/list?schoolId=${currentSchoolId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'cache-control': 'no-cache',
            credentials: 'include',
          },
        })
          .then(async (res) => await res.json())
          .then((data) => {
            // console.log('data from server: ', data);
            setAllClasses(data);
            setUpdateSchoolInfo(prev=>!prev);
            setShowLoader(false);
          });
      } catch (error) {
        setShowLoader(false);
        console.log('error.message', error.message);
      }
    }
    

  };
  useEffect(() => {
    loadDataForGradeBook();
  }, [currentSchoolId]);

  let numOfClasses = 0;
  for (let index in allClasses) {
    // allClasses[index] // grade
    for (let i = 0; i < allClasses[index].length; i++) {
      numOfClasses = numOfClasses + 1;
      // console.log('numOfClasses', numOfClasses);
    }
  }
  // готовим пропс для  <TableHeadLine />
  const headlineType = {
    titleName: 'gradebooks',
    redButton: {
      buttonText: 'Add class',
      icon: false,
      functionality: () => handleOpenAddClassModal(),
    },
    isInput: false,
    isExportButton: false,
    isExportCsv: false,
  };
  // готовим пропс для  <SubTableHeadLine />
  const SubTableHeadLineType = {
    subHeadLine: `${activeClasses.length} ${
      activeClasses.length > 1 ? 'classes' : 'class'
    } selected`,
    titleName: `${activeClasses.length}  ${
      activeClasses.length > 1 ? 'classes' : 'class'
    } selected`,
    isCancelButton: () => setActiveClasses([]),
    isExportButton: () => console.log('export CSV'),
    isarchiveButton: () => handleOpenArchiveClassModal(),
    isUpgradeButton: () => handleOpenUpgradeClassModal(),
  };


  // ============ACTIVE=CLASSES==============
  // записываем айдишники всех классов в стейт аctiveClasses
  const setAllGradesActive = () => {
    // получить все класс Айди перезаписать их в сет эктив класс, для удаления просто все удалить
    let allClassesId = [];
    for (let index in allClasses) {
      // allClasses[index] // grade
      let result = allClasses[index].reduce((sum, current) => [...sum, current.classId], []);
      // console.log('result', result);
      allClassesId = [...allClassesId, ...result];
      // console.log('allClassesId', allClassesId);
    }
    // делаем все классы активными
    if (allClassesId.length !== 0) {
      setActiveClasses(allClassesId);
      // setActiveMainCheckBox(true)
    }
  };

  // делаем все классы неактивными
  const unSetAllGradesActive = () => setActiveClasses([]);
// ===========================================

  
  return (
    <div className="gradebooksPage">
      {showLoader && <LoaderFullBook />}
      <InternalPageContainer />
      <FiltersContainer />
      <div className="gradeBookPage_wrapper">
        {activeClasses.length === 0 ? (
          <>
            <TableHeadLine headlineType={headlineType} />
          </>
        ) : (
          <SubTableHeadLine
            subHeadlineType={SubTableHeadLineType}
            setActiveClasses={setActiveClasses}
          />
        )}

        <div className="gradeBookPage_selectAllCheckboxWrapper">
          <div className="checkbox_wrapper">
            {activeClasses.length !== 0 && activeClasses.length === numOfClasses ? (
              <CheckBoxTwoToneIcon
                className="checkBoxOutlineBlank"
                onClick={unSetAllGradesActive}
              />
            ) : (
              <CheckBoxOutlineBlankTwoToneIcon onClick={setAllGradesActive} />
            )}
          </div>
          <div className="gradeBookPage_allGrades">All Grades</div>
        </div>

        <div className="gradeBookPage_allGrades_tableWrapper">
          {allGrades.map((grade, index) => {
            return (
              <>
                <GradebooksRow
                  key={index}
                  index={index}
                  grade={grade}
                  allClasses={allClasses[grade]}
                  activeClasses={activeClasses}
                  setActiveClasses={setActiveClasses}
                  handleOpenEditClassModal={handleOpenEditClassModal}
                  handleOpenUpgradeModal={handleOpenUpgradeClassModal}
                  handleOpenArchiveModal={handleOpenArchiveClassModal}
                  // handleOpenAnalytics={handleOpen}
                />
              </>
            );
          })}
        </div>
      </div>

      {addClassModal && (
        <AddClassModal
          close={handleCloseAddClassModal}
          allClasses={allClasses}
          loadDataForGradeBook={loadDataForGradeBook}
          loader={modalLoader}
          // activeClasses={activeClasses}
        />
      )}

      {editClassModal && (
        <EditClassModal
          close={handleCloseEditClassModal}
          activeClasses={activeClasses}
          loadDataForGradeBook={loadDataForGradeBook}
          loader={modalLoader}
          classInfo={classInfo}
          // allClasses={allClasses}
        />
      )}

      {upgradeClassModal && (
        <UpgradeClassModal
          onClose={handleCloseUpgradeClassModal}
          activeClasses={activeClasses}
          loadDataForGradeBook={loadDataForGradeBook}
          // allClasses={allClasses}
          // loader={modalLoader}
        />
      )}

      {archiveClassModal && (
        <ArchiveClassModal
          onClose={handleCloseArchiveClassModal}
          activeClasses={activeClasses}
          loadDataForGradeBook={loadDataForGradeBook}
          // allClasses={allClasses}
          // loader={modalLoader}
        />
      )}
    </div>
  );
};


// UPDATE GRADEBOOK
// base_url/api/gradebook/
// type PATCH
// request body
// {
//   id: 1,
//   grade: 1,
//   className: 'A',
//   schoolId: 1,
//   teachers: [1, 2, 3]
// }


// ARCHIVE
// base_url/api/gradebook/archive
// type POST
// request body 
// {
//   ids: [1, 2, 3],
//   isUpdate: true/false
// }

// UNARCHIVE
// base_url/api/gradebook/unarchive
// type POST
// request body 
// {
//   ids: [1, 2, 3]
// }