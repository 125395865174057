export const QuestionSign = ({width, height}) => {
  return (
    <svg
      style={{ position: "absolute", right: "35%", top: "35%"}}
      width={width}
      height={height}
      viewBox="0 0 23 32"
      fill="#a6192e"
    >
    <path d="M10.557 23.979c-0.228 0-0.427-0.085-0.597-0.256s-0.256-0.37-0.256-0.597v-1.365c0.142-1.337 0.555-2.503 1.237-3.499 0.711-1.024 1.707-2.19 2.987-3.499 0.939-0.91 1.65-1.692 2.133-2.347s0.768-1.323 0.853-2.005c0.114-1.166-0.27-2.091-1.152-2.773-0.882-0.711-1.948-1.067-3.2-1.067-2.788 0-4.466 1.38-5.035 4.139-0.199 0.654-0.612 0.981-1.237 0.981h-3.627c-0.284 0-0.512-0.085-0.683-0.256-0.171-0.199-0.256-0.455-0.256-0.768 0.057-1.564 0.526-3.029 1.408-4.395s2.162-2.46 3.84-3.285c1.678-0.853 3.641-1.28 5.888-1.28 2.361 0 4.295 0.384 5.803 1.152 1.536 0.74 2.645 1.692 3.328 2.859 0.711 1.166 1.067 2.375 1.067 3.627 0 1.451-0.327 2.702-0.981 3.755-0.626 1.024-1.579 2.219-2.859 3.584-0.91 0.996-1.621 1.849-2.133 2.56-0.484 0.683-0.811 1.422-0.981 2.219-0.114 0.711-0.185 1.195-0.213 1.451-0.228 0.711-0.612 1.067-1.152 1.067h-4.181zM10.429 32c-0.284 0-0.526-0.1-0.725-0.299s-0.299-0.441-0.299-0.725v-3.712c0-0.284 0.1-0.526 0.299-0.725 0.199-0.228 0.441-0.341 0.725-0.341h4.139c0.313 0 0.569 0.1 0.768 0.299s0.299 0.455 0.299 0.768v3.712c0 0.284-0.1 0.526-0.299 0.725s-0.455 0.299-0.768 0.299h-4.139z"></path>
    </svg>
  );
};
