import styled from 'styled-components/macro';

export const StyledGradeBookStudentRow = styled.div`
  height: 48px;
  background-color: ${(props) => (props.index % 2 === 1 ? '#F9F9F9' : '#FFFFFF')};
  /* display: flex; */
  display: grid;
  width: ${(props) =>
    props.rowLength.length * 262 +
    // 216 +
    24 * props.rowLength.length -
    24}px;
  grid-template-columns: 
    /* 216px  */ ${(props) => props.gridTemplate};
  gap: 24px;
  /* margin-right: -24px; */
  .gradeBookStudentRow_studentInfo {
    /* background-color: yellow; */
    width: 216px;
    box-sizing: border-box;
    padding: 21px;
    height: 48px;
    display: flex;
    align-items: center;
    color: #828282;
    //styleName: Rubik 14px;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    box-shadow: 3px 0px 4px #f2f2f2;

    svg {
      display: inline-block;
      margin-right: 8px;
      cursor: pointer;
      color: #c0c0c0;
      transition: all 300ms ease;
      &:hover {
        color: #828282;
        transition: all 300ms ease;
      }
    }
  }
  .gradeBookStudentRow_testResultWrapper {
    display: flex;
    /* justify-content: center; */
    height: 48px;
    box-sizing: border-box;
    /* width: 262px; */
    border-left: 1px solid #f2f2f2;
    border-right: 1px solid #f2f2f2;
    /* margin-left: 24px; */
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    line-height: 48px;
    color: #424242;
    /* width: 260px; */
    text-align: center;

    div {
      box-sizing: border-box;
      margin-left: 38px;
    }
  }
  .gradeBookStudentRow_noResult {
    display: flex;
    justify-content: center;
    border: 1px solid #a6192e;
  }
  .gradeBookStudentRow_absent {
    display: flex;
    justify-content: center;
    color: #777777;
  }
`;
