export const getUniqueItems = (arr) => {
  let result = [];

  for (let item of arr) {
    if (!result.includes(item)) {
      result.push(item);
    }
  }

  const sortedItems = result.sort((a, b) => a.localeCompare(b));

  return sortedItems;
};
