import { useState, forwardRef } from 'react';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import DateRangeIcon from '@material-ui/icons/DateRange';
import { StyledDatePicker } from './StyledDatePicker';

export const DatePickerRange = ({
  type,
  setEndDateFilter,
  setStartDateFilter,
  startDateFilter,
  endDateFilter,
  startDateVisual,
  setStartDateVisual,
  endDateVisual,
  setEndDateVisual,
}) => {
  const onChange = (dates) => {
    console.log('dates', dates);

    const startDateForFilter = dates[0].toISOString().split('T')[0];
    const endDateForFilter = dates[1] !== null ? dates[1].toISOString().split('T')[0] : null;
    console.log('dates[0])', startDateForFilter);
    console.log('dates[1])', endDateForFilter);
    //   startDate
    //  endDate
    setStartDateVisual(dates[0]);
    setEndDateVisual(dates[1]);

    setStartDateFilter(startDateForFilter);
    setEndDateFilter(endDateForFilter);
  };
  //  подставной инпут для вывода данных
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className="datePicker_customInput" onClick={onClick} ref={ref}>
      {value}
    </div>
  ));
  // его конец
  //  ф-ция для вывода даты
  const visibleDateFormat = (date) => {
    // if (!date) {
    //   return 'select period';
    // }

    return `${date.getDate()} ${date.toLocaleString('en-US', {
      month: 'short',
    })} ${date.getFullYear()}`;
  };
  // конец ф-ции для вывода даты
  return (
    <StyledDatePicker type={type}>
      <div className="datePicker_visibleInput">
        {!startDateVisual || !endDateVisual
          ? 'select period'
          : `${visibleDateFormat(startDateVisual)} -
                    ${visibleDateFormat(endDateVisual)}`}
        <DateRangeIcon />
      </div>
      <div className="datePicker_inputBox">
        <DatePicker
          customInput={<ExampleCustomInput />}
          selectsRange
          selected={startDateVisual}
          onChange={onChange}
          startDate={startDateVisual}
          endDate={endDateVisual}
          shouldCloseOnSelect={true}
          dayClassName={(date) => 'customCalendarDate'}
        />
      </div>
    </StyledDatePicker>
  );
};
