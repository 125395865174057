import styled from 'styled-components';

export const StyledNoTeachersPage = styled.div`
.NoTeachersText{
  margin-top: 24px;
  text-align: center;
  font-family: Rubik;
  font-size: 16px;
  line-height: 22px;
  color: #777777;
}
.NoTeachersImgWrapper{
  position: relative;
  width: 316px;
  height: 330px;
  margin-left: auto;
  margin-right: auto;
   z-index: 2;
}
.NoTeachersImage{
    position: relative;
  display:block;
  margin-top: 68px;
  margin-left: auto;
  margin-right: auto;
  z-index: 2;
}
.roundBGImg{
  position: absolute;
  top:-45px;
  left:-52px;
  z-index: 1;
}
.IconArrow{
  position: absolute;
  top:0;
  left:438px;
}
`