import * as React from 'react';
import { Controller } from 'react-hook-form';
import Checkbox from '@mui/material/Checkbox';

import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxTwoToneIcon from '@mui/icons-material/CheckBoxTwoTone';
import { OutlinedTextField } from '../OutlinedTextField/OutlinedTextField';
import CircularProgress from '@mui/material/CircularProgress';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { AppContext } from '../../context/AppContext';
import { config } from '../../config';
import './SelectClasses.scss';


const icon = <CheckBoxOutlineBlankIcon fontSize="medium" />;
const checkedIcon = <CheckBoxTwoToneIcon fontSize="medium" style={{ color: '#5B5B5B' }} />;

export default function SelectClasses({ control, defaultClasses = [], isMultiple = true }) {
  const [serverError, setServerError] = React.useState(false);

  const { currentSchoolId } = React.useContext(AppContext);

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState()

  React.useEffect(() => {
   setLoading(open && options.length === 0)
  }, [open, options.length])

  const requestFetch = (url) => {
    return fetch(url)
      .then(response => {
        if (response.ok) {
          return response.json()
        }

        return response.json().then(error => {
          const e = new Error('Smth gone wrong')
          e.data = error
          throw e
        })
      });
  }

  React.useEffect(() => {
    if (!loading) {
      return undefined;
    }

    (async () => {
      await requestFetch(`${config.API_URL}/api/gradebook?schoolId=${currentSchoolId}`)
        .then((data) => {
          const newArr = [];

          data.forEach((item) => {
            const newObj = {};
            const title = `${item.Grade}${item.ClassName}`;
            const id = item.Id;

            newObj.title = title;
            newObj.id = id;

            newArr.push(newObj);
          });

          setOptions(newArr);
        })
        .catch(() => {
          setLoading(false);
          setServerError(true);
        });
    })();
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Controller
      name="classes"
      control={control}
      rules={{
        required: 'Required Field',
      }}
      render={({ field, formState }) => (
        <>
          <Autocomplete
            onChange={(_, data) => {
              return field.onChange(typeof data === 'object' ? [data].flat() : data)
            }}
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            loading={loading}
            isOptionEqualToValue={(option, value) => option.title === value.title}
            noOptionsText={serverError ? 'Oops, server error. Try again later' : 'No Options found'}
            ListboxProps={{ style: { maxHeight: '120px' }, position: "bottom-start" }}
            options={options}
            multiple={isMultiple}
            limitTags={2}
            disableCloseOnSelect
            defaultValue={defaultClasses.length ? ((arr = defaultClasses) => {
              if (arr[0]?.title && arr[0]?.id) {
                field.onChange(arr);
                return arr[0];
              }

              const newArr = [];

              arr.forEach((item) => {
                const newObj = {};
                let title = `${item.Grade}${item.ClassName}`;
                let id = item.Id;
                newObj.title = title;
                newObj.id = id;
                newArr.push(newObj);
              })

              field.onChange(newArr);
              return newArr;
            }) : []}
            getOptionLabel={(option) => option?.title ? option?.title : ''}
            renderOption={(props, option, { selected }) => (
              <li {...props} style={{ fontSize: '14px', height: 32 }} key={option.id}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={selected}
                />
                {option.title}
              </li>
            )}
            renderInput={(params) => (
              <div className='selectWrapper'>
                <OutlinedTextField
                  {...params}
                  label="Select Class"
                  inputRef={field.ref}
                  placeholder="Search"
                  InputProps={{
                    ...params.InputProps,
                    // required: !field.value,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={15} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
                <div className={'textHelper'}>
                  {formState?.errors?.classes?.message &&
                    (
                      <>
                        <p>{formState?.errors?.classes?.message}</p>
                        <InfoOutlinedIcon />
                      </>
                    )}
                </div>
              </div>
            )}
          />
        </>
      )}
    />
  );
}
