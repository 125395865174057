import React from 'react';
import img from '../../../../images/archiveNoData.svg';
import styles from './ArchiveNoDataContent.module.scss';

function ArchiveNoDataContent() {
  return (
    <div className={styles.inner}>
      <div className={styles.header}>
        <h2 className={styles.title}>
          Archive
        </h2>
      </div>
      <div className={styles.content}>
        <p className={styles.description}>
          You have no archived classes yet
        </p>
        <div className={styles.img}>
          <img
            src={img}
            width="331"
            height="317"
            alt="The search has not given any results, try again!" />
        </div>
      </div>
    </div>
  );
}

export default ArchiveNoDataContent;
