export const getFileCSVtests = (data) => {
  return data.map((item) => ({
    label: `${item.label}${item.isArchive === 1 ? ' (archived)' : ''}`,
    concept: item.concept,
    status: item.status,
    avScore: item.score.score,
    avSpeed: item.speed.speed,
    classes: item.classes?.map((classes) => ` ${classes.grade}${classes.className}`),
    teachers: item.teachers
      .map((teacher) =>
        ` ${teacher.firstName} ${teacher.lastName} ( ${teacher.email} | ${teacher.phone} )`),
  }));
};
