import styled from 'styled-components/macro';

export const StyledPieChartCenter = styled.div`
  position: absolute;
  top: 0px;
  z-index: 50;
  transition: all 5000 ease;
  top: 120px;
  left: 125px;
  /* &:hover{top: -125px; transition: all 5000 ease;} */
  .PieChart_LableWrapper {
    position: absolute;
    top: 0px;
    left: 0px;
  }
`;
