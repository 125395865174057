import React, { useEffect, useState } from 'react';
import { getUniqueItems } from '../../../../model/getUniqueItems';
import { StyledDropdownSelect } from '../../../DropdownSelect/StyledDropdownSelect';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function TestsDropdownSelect({
  arrayOfItems,
  defaultTitle,
  setFilterKey,
  reset,
  setReset,
}) {
  const [filterTitle, setFilterTitle] = useState(defaultTitle);

  useEffect(() => {
    if (reset) {
      setFilterKey('');
      setFilterTitle(defaultTitle);
      setReset(false);
    }
  }, [defaultTitle, reset, setFilterKey, setReset]);

  const filterValues = ((defaultTitle === 'Av.Score')
    || (defaultTitle === 'Av.Speed'))
    ? arrayOfItems
    : getUniqueItems(arrayOfItems);

  return (
    <StyledDropdownSelect className="styledDropdown">
      <div className="textAdaArrowWrapper">
        <div className="text_selectWrapper">
          {filterTitle === defaultTitle ? defaultTitle : filterTitle}
        </div>
        <ArrowDropDownIcon />
      </div>
      <div className="dropDownWrapper">
        {filterTitle !== defaultTitle && (
          <div
            className="dropDown_item"
            onClick={() => {
              setFilterKey('');
              setFilterTitle(defaultTitle);
            }}
          >
            <span>{defaultTitle}</span>
          </div>)}
        {filterValues.map((value, index) => {
          return (
            <div
              key={index}
              className="dropDown_item"
              onClick={() => {
                setFilterKey(value);
                setFilterTitle(value);
              }}
            >
              <span>{value}</span>
            </div>
          );
        })}
      </div>
    </StyledDropdownSelect>
  );
}

export default TestsDropdownSelect;
