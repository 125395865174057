import _ from 'lodash';
import InputMask from 'react-input-mask';
import './EditResultsTableRow.scss';
import { useState } from 'react';
import { StyledInput } from './StyledInput';

import CheckBoxTwoToneIcon from '@material-ui/icons/CheckBoxTwoTone';
import CheckBoxOutlineBlankTwoToneIcon from '@material-ui/icons/CheckBoxOutlineBlankTwoTone';

const getStringFromSeconds = (seconds) => {
  const int = Number(seconds);
  if (typeof int !== 'number') {
    return null;
  }
  const date = new Date(seconds * 1000);
  const stringData = date.toISOString().substr(11, 8);
  const dateArr = stringData.split(':');

  return `${dateArr[0]}${dateArr[1]}${dateArr[2]}`;
};

//  single array of obj , index,  set array of obj , array of obj
export const EditResultsTableRow = ({
  studentInfo,
  index,
  resultsToEdit,
  setTestResultsEdit,
  test,
}) => {
  // const [trainingMask, setTrainingMask] = useState(resultsToEdit[index].results.speed.speed)
  const [trainingMask, setTrainingMask] = useState();
  // console.log('trainingMask', trainingMask)

  // console.log('test to edit totalQuestions', test.totalQuestions);

  const [studentResults, setStudentResults] = useState(studentInfo);

  const handleChangeScoreInput = (e) => {
    // console.log('e', e.target.value.toString().slice(-1))
    // if (resultsToEdit[index].results.absent){ return  }
    const stringWithNumbers = e.target.value.toString().replace(/[\D]+/gi, '');
    const validatedString = stringWithNumbers.replace(/^0+/, '');
    studentInfo.results.score.score = validatedString;
    studentInfo.results.score.color =
      studentInfo.results.score.score >= 90
        ? '#4285F4'
        : studentInfo.results.score.score >= 80
        ? '#0F9D58'
        : studentInfo.results.score.score >= 50
        ? ' #E2B534'
        : '#DB4437';

    if (resultsToEdit[index].results.absent) {
      resultsToEdit[index].results.absent = false;
    }
    setTestResultsEdit(
      [...resultsToEdit].map((test, index) => {
        // test.results.testId ===  studentInfo.results.testId ? return studentInfo : test
        if (test.studentId === studentInfo.studentId) {
          return studentInfo;
        } else return test;
      })
    );
  };
  const handleChangeTime = (e) => {
    // if (resultsToEdit[index].results.absent){ return  }

    const stringWithOutLines = e.target.value.replace(/[_]+/gi, '0');
    const trainingMaskArr = stringWithOutLines.split(':');
    const seconds = +trainingMaskArr[0] * 3600 + +trainingMaskArr[1] * 60 + +trainingMaskArr[2];
    // (+a[0])
    studentInfo.results.speed.speed = seconds;
    if (resultsToEdit[index].results.absent) {
      resultsToEdit[index].results.absent = false;
    }
    //   studentInfo.results.speed.
    setTestResultsEdit(
      [...resultsToEdit].map((test, index) => {
        // test.results.testId ===  studentInfo.results.testId ? return studentInfo : test
        if (test.studentId === studentInfo.studentId) {
          return studentInfo;
        } else return test;
      })
    );
  };

  const handleChangeAbsentFalse = () => {
    studentInfo.results.absent = false;
    studentInfo.results.speed.speed = undefined;
    studentInfo.results.score.score = undefined;
    setTestResultsEdit(
      [...resultsToEdit].map((test, index) => {
        // test.results.testId ===  studentInfo.results.testId ? return studentInfo : test
        if (test.studentId === studentInfo.studentId) {
          return studentInfo;
        } else return test;
      })
    );
  };
  const handleChangeAbsentTrue = () => {
    if (!resultsToEdit[index].results.score.score && !resultsToEdit[index].results.speed.speed) {
      studentInfo.results.absent = true;
      studentInfo.results.score.score = null;
      studentInfo.results.speed.speed = null;
      setTestResultsEdit(
        [...resultsToEdit].map((test, index) => {
          // test.results.testId ===  studentInfo.results.testId ? return studentInfo : test
          if (test.studentId === studentInfo.studentId) {
            return studentInfo;
          } else return test;
        })
      );
    } else return;
  };

  return (
    <div
      className="editResultsTableRow_wrapper"
      style={{
        background: index % 2 === 1 ? '#F9F9F9' : '#FFFFFF',
        marginTop: index === 0 ? '19px' : null,
      }}
    >
      <div className="editResultsTableRow_studentName">{resultsToEdit[index].studentName}</div>
      <div
        style={{
          border:
            resultsToEdit[index].results.score.score > test.totalQuestions
              ? '1px solid #A6192E'
              : null,
        }}
        className="editResultsTableRow_studentScore"
      >
        {resultsToEdit[index].results.score.score > test.totalQuestions && (
          <div className="editResultsTableRow_scoreTooltip">
            Score must be less than {test.totalQuestions}
          </div>
        )}

        <input
          value={resultsToEdit[index].results ? resultsToEdit[index].results.score.score : null}
          type="text"
          onChange={(e) => handleChangeScoreInput(e)}
          style={{
            border:
              resultsToEdit[index].results.score.score === undefined ||
              (resultsToEdit[index].results.score.score === null &&
                resultsToEdit[index].results.absent === 0) ||
              resultsToEdit[index].results.score.score === ''
                ? '1px solid #A6192E'
                : null,
            color: resultsToEdit[index].results.score.color
              ? resultsToEdit[index].results.score.color
              : null,
          }}
        />
      </div>
      <div className="editResultsTableRow_studentSpeed">
        <InputMask
          mask="99:99:99"
          value={
            resultsToEdit[index].results.speed.speed
              ? getStringFromSeconds(resultsToEdit[index].results.speed.speed)
              : null
          }
          onChange={(e) => {
            handleChangeTime(e);
          }}
        >
          {(inputProps) => (
            <input
              {...inputProps}
              type="text"
              className="input"
              disableUnderline
              style={{
                border:
                  resultsToEdit[index].results.speed.speed === undefined ||
                  (isNaN(resultsToEdit[index].results.speed.speed) &&
                    resultsToEdit[index].results.absent === 0) ||
                  resultsToEdit[index].results.speed.speed === 0
                    ? '1px solid #A6192E'
                    : null,
                // color:  ! resultsToEdit[index].results.speed.color ? null : resultsToEdit[index].results.speed.color
              }}
            />
          )}
        </InputMask>
      </div>

      <div className="editResultsTableRow_checkBoxes">
        {!resultsToEdit[index].results.absent ? (
          <CheckBoxOutlineBlankTwoToneIcon onClick={handleChangeAbsentTrue} />
        ) : (
          <CheckBoxTwoToneIcon style={{ cursor: 'pointer' }} onClick={handleChangeAbsentFalse} />
        )}

        {/* {//null = ubsent undefined - NO data
                     resultsToEdit[index].results.score.score ===null && resultsToEdit[index].results.speed.speed ===null && 
                    < CheckBoxOutlineBlankTwoToneIcon  /> 
                  
                } */}
      </div>
    </div>
  );
};

// import _ from "lodash"
// import InputMask from "react-input-mask";
// import './EditResultsTableRow.scss'
// import {useState} from 'react'
// import {StyledInput} from './StyledInput'

// import CheckBoxTwoToneIcon from '@material-ui/icons/CheckBoxTwoTone';
// import CheckBoxOutlineBlankTwoToneIcon from '@material-ui/icons/CheckBoxOutlineBlankTwoTone';

// const getStringFromSeconds = (seconds) => {
//     const int = Number(seconds)
//     if( typeof int !=='number'){ return null}
//     const date =new Date(seconds * 1000)
//     const stringData = date.toISOString().substr(11, 8)
//     const dateArr = stringData.split(':')

//     return(`${dateArr[0]}${dateArr[1]}${dateArr[2]}`)

// }

//                                 //  single array of obj , index,  set array of obj , array of obj
// export const EditResultsTableRow = ({studentInfo, index, resultsToEdit, setTestResultsEdit, test}) => {

//     // const [trainingMask, setTrainingMask] = useState(resultsToEdit[index].results.speed.speed)
//     const [trainingMask, setTrainingMask] = useState()
//     // console.log('trainingMask', trainingMask)

//     // console.log('test to edit totalQuestions' , test.totalQuestions)

//     const [studentResults, setStudentResults]=useState(studentInfo)

//     const handleChangeScoreInput = (e) => {

//         const stringWithNumbers = e.target.value.toString().replace(/[\D]+/gi, '')
//         const validatedString = stringWithNumbers.replace(/^0+/, '');
//         studentInfo.results.score.score = validatedString;
//         studentInfo.results.score.color = studentInfo.results.score.score >=90 ? '#4285F4' :
//                                             studentInfo.results.score.score >=80 ? '#0F9D58' :
//                                             studentInfo.results.score.score >=50 ?' #E2B534' : '#DB4437';

//         if(resultsToEdit[index].results.absent){resultsToEdit[index].results.absent = false }
//         setTestResultsEdit([...resultsToEdit].map( (test,index)=>{
//             // test.results.testId ===  studentInfo.results.testId ? return studentInfo : test
//             if(test.studentId ===  studentInfo.studentId){
//                 return studentInfo
//             }else return test
//         } ))

//     }
//     const handleChangeTime = (e) =>{
//         // if (resultsToEdit[index].results.absent){ return  }

//          const stringWithOutLines = e.target.value.replace(/[_]+/gi, '0')
//          const trainingMaskArr = stringWithOutLines.split(':')
//          const seconds =  (+trainingMaskArr[0]) * 3600 + (+trainingMaskArr[1]) * 60 + (+trainingMaskArr[2])
//                         // (+a[0])
//           studentInfo.results.speed.speed = seconds;
//           if(resultsToEdit[index].results.absent){resultsToEdit[index].results.absent = false }
//         //   studentInfo.results.speed.
//           setTestResultsEdit([...resultsToEdit].map( (test,index)=>{
//                 // test.results.testId ===  studentInfo.results.testId ? return studentInfo : test
//                 if(test.studentId ===  studentInfo.studentId){
//                     return studentInfo
//                 }else return test
//             } ))

//     }

//     const handleChangeAbsentFalse = () => {
//         studentInfo.results.absent = false;
//         studentInfo.results.speed.speed = undefined;
//         studentInfo.results.score.score = undefined;
//         setTestResultsEdit([...resultsToEdit].map( (test,index)=>{
//             // test.results.testId ===  studentInfo.results.testId ? return studentInfo : test
//             if(test.studentId ===  studentInfo.studentId){
//                 return studentInfo
//             }else return test
//         } ))
//     }
//     const handleChangeAbsentTrue = () => {

//         if(!resultsToEdit[index].results.score.score && !resultsToEdit[index].results.speed.speed){
//         studentInfo.results.absent = true;
//         studentInfo.results.score.score = null;
//         studentInfo.results.speed.speed = null;
//         setTestResultsEdit([...resultsToEdit].map( (test,index)=>{
//             // test.results.testId ===  studentInfo.results.testId ? return studentInfo : test
//             if(test.studentId ===  studentInfo.studentId){
//                 return studentInfo
//             }else return test
//         } ))
//     } else return
//     }

//     return(
//         <div
//             className="editResultsTableRow_wrapper"
//             style={{background: index % 2 === 1 ? '#F9F9F9' : '#FFFFFF', marginTop: index === 0 ? '19px': null }}
//          >
//             <div className="editResultsTableRow_studentName">
//                 {resultsToEdit[index].studentName}
//             </div>
//             <div
//              style = {{border:  resultsToEdit[index].results.score.score > test.totalQuestions ? '1px solid #A6192E' : null }}
//              className="editResultsTableRow_studentScore"
//              >
//                 {/* {  resultsToEdit[index].results.score.score > test.totalQuestions && <div className="editResultsTableRow_scoreTooltip">Score must be less than {test.totalQuestions}</div> } */}

//                     <input
//                         value={resultsToEdit[index].results.score.score }
//                         type="text"
//                         onChange={(e)=>handleChangeScoreInput(e)}
//                         style={{
//                           border: resultsToEdit[index].results.score.score === undefined || ( resultsToEdit[index].results.score.score === null &&  resultsToEdit[index].results.absent === 0) ||
//                            resultsToEdit[index].results.score.score === '' ? '1px solid #A6192E' : null,
//                           color: resultsToEdit[index].results.score.color  ? resultsToEdit[index].results.score.color : null,
//                         }}
//                     />
//             </div>
//             <div className="editResultsTableRow_studentSpeed">
//                 <InputMask mask="99:99:99"

//                     value={ resultsToEdit[index].results.speed.speed? getStringFromSeconds(resultsToEdit[index].results.speed.speed) : null}
//                     onChange = {(e) => {
//                         handleChangeTime(e)
//                     }
//                     }
//                     >
//                     {(inputProps) => (
//                     <input
//                         {...inputProps}
//                         type="text"
//                         className="input"
//                         disableUnderline

//                         style={{
//                             border: resultsToEdit[index].results.speed.speed === undefined ||
//                                     ( isNaN(resultsToEdit[index].results.speed.speed) &&  resultsToEdit[index].results.absent===0)||
//                                     resultsToEdit[index].results.speed.speed === 0
//                                     ? '1px solid #A6192E' : null,
//                             // color:  ! resultsToEdit[index].results.speed.color ? null : resultsToEdit[index].results.speed.color
//                         }}

//                     />
//                     )}
//                 </InputMask>
//             </div>

//             <div className="editResultsTableRow_checkBoxes">
//                 {
//                     !resultsToEdit[index].results.absent ?
//                     < CheckBoxOutlineBlankTwoToneIcon onClick={handleChangeAbsentTrue} /> :
//                     <CheckBoxTwoToneIcon style={{cursor: 'pointer'}} onClick={handleChangeAbsentFalse}  />
//                 }

//                 {/* {//null = ubsent undefined - NO data
//                      resultsToEdit[index].results.score.score ===null && resultsToEdit[index].results.speed.speed ===null &&
//                     < CheckBoxOutlineBlankTwoToneIcon  />

//                 } */}

//             </div>

//         </div>
//     )
// }
