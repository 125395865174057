import { TablePagination } from '@material-ui/core';
import './Pagination.scss';

export const Pagination = ({
  pageSize = 10,
  setPageSize = () => { },
  currentPage = 1,
  setCurrentPage = () => { },
  totalCount = 100,
}) => {

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(0);
  }

  const handleChangePage = (_, newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <TablePagination
      component="div"
      count={totalCount}
      page={currentPage}
      onPageChange={handleChangePage}
      rowsPerPage={pageSize}
      onRowsPerPageChange={handleChangeRowsPerPage}
      rowsPerPageOptions={[10, 25, 50]}
    />
  );

};
