import { useContext } from 'react';
import { AppContext } from '../../../context/AppContext';
import { QuestionSign } from '../../../images/SvgElements/QuestionSign';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import "./HeaderProfilePhoto.scss"

const HeaderProfilePhoto = ({handleClick}) => {
  const {user} = useContext(AppContext);
  // console.log('user', user)
  // console.log('user.name', user.firstName)
  // console.log('user.photo', user.photo)

  const userHasNoName =
    user.firstName === "No Name" ||
    user.firstName === undefined;

  const userHasNoPhoto =
    user.photo === "" ||
    user.photo === null;

  return (
    <div className="header_profile" onClick={handleClick}>
        { userHasNoName && userHasNoPhoto &&
        (<div className="header_profile_photo photo_border">
        <div className="header_profile_photo_question">
          <QuestionSign width="10px" height="10px" />
        </div>
      </div>)}
      {!userHasNoName && userHasNoPhoto &&
        (<div className="header_profile_photo photo_border">
          <PersonOutlineOutlinedIcon />
        </div>)}
      { !userHasNoName && !userHasNoPhoto &&
        (<div className="header_profile_photo photo_border">
        <img className="header_profile_photo_img" src={user.photo} alt="profilePhoto" />
        </div>)}
       {/* triangle-dropdown */}
      <svg
        className="header_svg"
        viewBox="0 0 10 5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 0L5 5L10 0H0Z" fill="#777777" />
      </svg>
    </div>
  );
}

export default HeaderProfilePhoto;