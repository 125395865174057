import { WidthLimiter } from '../WidthLimiter/WidthLimiter';
import imgHeaderLogo from '../../images/LOGO-header.png';
import { SchoolSelect } from '../SchoolSelect/SchoolSelect';
import { TopNavigation } from '../buttons/TopNavigation/TopNavigation';
import HeaderProfile from '../HeaderProfile/HeaderProfile';
import './Header.scss';

export const Header = () => {
  return (
    <div className="headerWrapper">
      <WidthLimiter>
        <header className="header">
          <div className="headerLogo_wrapper">
            <img className="imgLogo" src={imgHeaderLogo} alt="" />
            <SchoolSelect />
          </div>
          <nav className="header_navbar">
            <TopNavigation />
          </nav>
          <HeaderProfile />
        </header>
      </WidthLimiter>
    </div>
  );
};

export default Header;
