import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ModalWrapper } from "../../../../components/Modal/ModalWrapper/ModalWrapper";
import { ModalContainer } from "../../../../components/Modal/ModalContainer/ModalContainer";
import { ModalHeader } from "../../../../components/Modal/ModalHeader/ModalHeader";
import { ModalFooter } from "../../../../components/Modal/ModalFooter/ModalFooter";
import { ModalSecondaryButton } from "../../../../components/Modal/buttons/ModalSecondaryButton/ModalSecondaryButton";
import { ModalPrimaryButton } from "../../../../components/Modal/buttons/ModalPrimaryButton/ModalPrimaryButton";
import { StudentsFormFields } from "../StudentModalFormFields/StudentsFormFields/StudentsFormFields";
import { StudentsParentFormFields } from "../StudentModalFormFields/StudentsParentFormFields/StudentsParentFormFields";
import { FirstParentAddedFormFields } from "../StudentModalFormFields/FirstParentAddedFormFields/FirstParentAddedFormFields";
import { StudentsSecondParentFormFields } from "../StudentModalFormFields/StudentsSecondParentFormFields/StudentsSecondParentFormFields";
import { SecondParentAddedFormFields } from "../StudentModalFormFields/SecondParentAddedFormFields/SecondParentAddedFormFields";
import { StudentsEditParentFormFields } from "../StudentModalFormFields/StudentsEditParentFormFields/StudentsEditParentFormFields";
import { config } from '../../../../config';
import "./StudentsModal.scss";

const StudentsModal = ({ close, submitForm, openOnSubmitModal, loader, changeModalLoader, setShowAlert = () => { }, }) => {
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    control,
    getValues,
    reset,
    setValue,
  } = useForm({
    mode: 'onChange',
  });

  const [step, setStep] = useState(1);
  const [skipToEnd, setSkipToEnd] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [defaultParent, setDefaultParent] = useState();
  const [editParentIndex, setEditParentIndex] = useState();

  const prevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const nextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const requestFetch = (url, method, body = null) => {
    const headers = {
      'Content-Type': 'application/json'
    }

    return fetch(url, {
      method: method,
      body: JSON.stringify(body),
      headers: headers
    }).then(response => {
      if (response.ok) {
        return response.json()
      }

      return response.json().then(error => {
        const e = new Error('Smth gone wrong')
        e.data = error
        throw e
      })
    });
  }

  const onSubmit = async (data) => {
    if (step === 2) {
      setSkipToEnd(true);
    }

    if (step !== 3) {
      setDisabledBtn(false)
      if (skipToEnd) {
        setStep(3);
      } else {
        nextStep();
      }
    }

    if (step === 3) {
      changeModalLoader();
      const dataToSubmit = {};
      dataToSubmit.firstName = data.firstName;
      dataToSubmit.lastName = data.lastName;
      dataToSubmit.classId = data.classes[0].id;
      dataToSubmit.studentId = data.studentId;

      const modifiedParentData = [];
      data.parentsToSubmit.forEach(item => {
        if (item?.id) {
          modifiedParentData.push({ id: item.id });
        } else {
          modifiedParentData.push(item);
        }
      });

      dataToSubmit.parents = modifiedParentData;
      dataToSubmit.dateOfBirth = data.birthDate.toISOString().split('T')[0];

      const method = 'POST';
      const url = `${config.API_URL}/api/student`;

      await requestFetch(url, method, dataToSubmit)
        .then(data => {
          if (data.success) {
            openOnSubmitModal('Student Successfully Added')
            submitForm();
          } else {
            openOnSubmitModal(data.message)
          }

        })
        .catch(err => { console.log(err); setShowAlert(true) });
      changeModalLoader();
      reset();
      close();
    }
  }


  return (
    <ModalWrapper loader={loader}>
      <ModalContainer>
        <div className="modalWrapper">
          <ModalHeader header="Add New Student" onClose={close} />

          <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
            {step === 1 && (
              <div className="formFields">
                <StudentsFormFields
                  getValues={getValues}
                  close={close}
                  register={register}
                  errors={errors}
                  control={control} />
                <div className="modalFooter">
                  <ModalFooter>
                    <ModalSecondaryButton onClick={close}>Cancel</ModalSecondaryButton>
                    <ModalPrimaryButton disabled={!isValid} typeBtn="submit">Next</ModalPrimaryButton>
                  </ModalFooter>
                </div>
              </div>
            )}
            {step === 2 && (
              <div className="formFields">
                <StudentsParentFormFields
                  getValues={getValues}
                  register={register}
                  errors={errors}
                  control={control}
                />
                <div className="modalFooter">
                  <ModalFooter>
                    <ModalSecondaryButton onClick={prevStep}>Previous</ModalSecondaryButton>
                    <ModalPrimaryButton disabled={!isValid} typeBtn="submit">Next</ModalPrimaryButton>
                  </ModalFooter>
                </div>
              </div>
            )}
            {step === 3 && (
              <div className="formFields">
                <FirstParentAddedFormFields
                  nextStep={nextStep}
                  getValues={getValues}
                  setDisabledBtn={setDisabledBtn}
                  setValue={setValue}
                  setStep={setStep}
                  setDefaultParent={setDefaultParent}
                  setEditParentIndex={setEditParentIndex}
                />
                <div className="modalFooter">
                  <ModalFooter>
                    <ModalSecondaryButton onClick={() => setStep(1)}>Previous</ModalSecondaryButton>
                    <ModalPrimaryButton disabled={disabledBtn} typeBtn="submit">Add Student</ModalPrimaryButton>
                  </ModalFooter>
                </div>
              </div>
            )}
            {step === 4 && (
              <div className="formFields">
                <StudentsSecondParentFormFields
                  getValues={getValues}
                  register={register}
                  errors={errors}
                  setValue={setValue}
                  control={control}
                  prevStep={prevStep} />
                <div className="modalFooter">
                  <ModalFooter>
                    <ModalSecondaryButton onClick={prevStep}>Previous</ModalSecondaryButton>
                    <ModalPrimaryButton disabled={!isValid} typeBtn="submit">Next</ModalPrimaryButton>
                  </ModalFooter>
                </div>
              </div>
            )}
            {step === 5 && (
              <div className="formFields">
                <StudentsEditParentFormFields
                  defaultParent={defaultParent}
                  getValues={getValues}
                  setValue={setValue}
                  register={register}
                  errors={errors}
                  control={control}
                  setStep={setStep}
                  editParentIndex={editParentIndex}
                />
                <div className="modalFooter">
                  <ModalFooter>
                    <ModalSecondaryButton onClick={() => {
                      setValue('editParent', undefined);
                      setValue('editFirstName', undefined);
                      setValue('editLastName', undefined);
                      setValue('editEmail', undefined);
                      setValue('editPhone', undefined);
                      setStep(3);
                    }}>Previous</ModalSecondaryButton>
                    <ModalPrimaryButton disabled={true}>Add Student</ModalPrimaryButton>
                  </ModalFooter>
                </div>
              </div>
            )}
          </form>

        </div>


      </ModalContainer>
    </ModalWrapper>
  )
}

export default StudentsModal;