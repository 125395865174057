import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import * as MuiIcons from '@mui/icons-material';
import './ButtonText.scss';

const useStyles = makeStyles({
  buttonTextFirst: {
    color: `rgba(166, 25, 46, 1)`,
    backgroundColor: `rgba(0, 0, 0, 0)`,
    textTransform: 'uppercase',
    fontFamily: `Rubik`,
    fontStyle: `normal`,
    fontWeight: `bold`,
    fontSize: `12px`,
    transition: 'none !important',
    animation: 'none !important',
    '&:hover': {
      color: `rgba(141, 20, 38, 1)`,
      backgroundColor: `rgba(0, 0, 0, 0)`,
    },
    '&:active': {},
  },

  buttonTextSecond: {
    color: `rgba(192, 192, 192, 1)`,
    backgroundColor: `rgba(0, 0, 0, 0)`,
    textTransform: 'uppercase',
    fontFamily: `Rubik`,
    fontStyle: `normal`,
    fontWeight: `bold`,
    fontSize: `12px`,
    '&:hover': {
      color: `rgba(119, 119, 119, 1)`,
      backgroundColor: `rgba(0, 0, 0, 0)`,
    },
  },
  buttonTextThird: {
    color: `rgba(66, 66, 66, 1)`,
    backgroundColor: `rgba(0, 0, 0, 0)`,
    textTransform: 'uppercase',
    fontFamily: `Rubik`,
    fontStyle: `normal`,
    fontWeight: `bold`,
    fontSize: `12px`,
    '&:hover': {
      color: `rgba(91, 91, 91, 1)`,
      backgroundColor: `rgba(0, 0, 0, 0)`,
    },
  },
  buttonTextFourth: {
    color: `rgba(255, 255, 255, 1)`,
    backgroundColor: `rgba(0, 0, 0, 0)`,
    textTransform: 'uppercase',
    fontFamily: `Rubik`,
    fontStyle: `normal`,
    fontWeight: `bold`,
    fontSize: `12px`,
    '&:hover': {
      color: `rgba(242, 242, 242, 1)`,
      backgroundColor: `rgba(0, 0, 0, 0)`,
    },
  },

  buttonTextFirstDisable: {
    color: `rgba(242, 242, 242, 1)`,
    backgroundColor: `rgba(0, 0, 0, 0)`,
    textTransform: 'uppercase',
    fontFamily: `Rubik`,
    fontStyle: `normal`,
    fontWeight: `bold`,
    fontSize: `12px`,
    '&:hover': {
      color: `rgba(242, 242, 242, 1)`,
      backgroundColor: `rgba(0, 0, 0, 0)`,
      cursor: `default`,
    },
  },

  buttonTextSecondDisable: {
    color: `rgba(242, 242, 242, 1)`,
    textTransform: 'uppercase',
    backgroundColor: `rgba(0, 0, 0, 0)`,
    fontFamily: `Rubik`,
    fontStyle: `normal`,
    fontWeight: `bold`,
    fontSize: `12px`,
    '&:hover': {
      color: `rgba(242, 242, 242, 1)`,
      backgroundColor: `rgba(0, 0, 0, 0)`,
      cursor: `default`,
    },
  },
  buttonTextThirdDisable: {
    color: `rgba(242, 242, 242, 1)`,
    // border: '1px solid rgba(192, 192, 192, 1)',
    backgroundColor: `rgba(0, 0, 0, 0)`,
    textTransform: 'uppercase',
    fontFamily: `Rubik`,
    fontStyle: `normal`,
    fontWeight: `bold`,
    fontSize: `12px`,
    '&:hover': {
      color: `rgba(242, 242, 242, 1)`,
      backgroundColor: `rgba(0, 0, 0, 0)`,
      cursor: `default`,
    },
  },
  buttonTextFourthDisable: {
    color: `rgba(232, 232, 232, 1)`,
    // border: '1px solid rgba(242, 242, 242, 1)',
    backgroundColor: `rgba(0, 0, 0, 0)`,
    textTransform: 'uppercase',
    fontFamily: `Rubik`,
    fontStyle: `normal`,
    fontWeight: `bold`,
    fontSize: `12px`,
    '&:hover': {
      backgroundColor: `rgba(0, 0, 0, 0)`,
      cursor: `default`,
    },
  },
});

export default function ButtonText(props) {
  let Icon = MuiIcons[props.iconName];

  const classes = useStyles(props);
  let classFor = null;
  switch (props.type) {
    case '1':
      if (props.active !== true) {
        classFor = `buttonTextFirstDisable`;
      } else {
        classFor = `buttonTextFirst`;
      }

      break;
    case '2':
      if (props.active !== true) {
        classFor = `buttonTextSecondDisable`;
      } else {
        classFor = `buttonTextSecond`;
      }

      break;
    case '3':
      if (props.active !== true) {
        classFor = `buttonTextThirdDisable`;
      } else {
        classFor = `buttonTextThird`;
      }

      break;
    case '4':
      if (props.active !== true) {
        classFor = `buttonTextFourthDisable`;
      } else {
        classFor = `buttonTextFourth`;
      }

      break;
    default:
      break;
  }
  return (
    <div>
      <Button
        variant="text"
        className={classes[classFor]}
        startIcon={<Icon size="small" />}
        disableRipple
        onClick={props.funtionality}
      >
        {props.text}
      </Button>
    </div>
  );
}
