import React from 'react';
import img from '../../images/errorPage.svg';
import styles from './ErrorPage.module.scss';

function ErrorPage() {
  return (
    <div className={styles.errorPage}>
      <p className={styles.description}>
        Ooops, server error. Please, contact the admin of the website.
      </p>
      <div className={styles.content}>
        <img
          className={styles.img}
          src={img}
          width="405"
          height="388"
          alt="Server error. Admin napping at his desktop."
        />
      </div>
    </div>
  );
}

export default ErrorPage;
