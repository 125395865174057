import { ModalWrapper } from "../../../../components/Modal/ModalWrapper/ModalWrapper";
import { ModalContainer } from "../../../../components/Modal/ModalContainer/ModalContainer";
import { ModalFooter } from "../../../../components/Modal/ModalFooter/ModalFooter";
import { ModalPrimaryButton } from "../../../../components/Modal/buttons/ModalPrimaryButton/ModalPrimaryButton";

import styles from "./TeacherModalOnSubmit.module.scss";

const TeachersModalOnSubmit = ({ close, text }) => {

  return (
    <ModalWrapper>
      <ModalContainer>
        <div className={styles.text}>{text}</div>
        <ModalFooter>
          <ModalPrimaryButton onClick={close}>OK</ModalPrimaryButton>
        </ModalFooter>
      </ModalContainer>
    </ModalWrapper>
  )
}

export default TeachersModalOnSubmit;
