import { IconButton } from '../buttons/IconButton/IconButton';

import './TableRowAction.scss';

export const TableRowAction = ({
  handleForOpenIcon,
  btnOne = true,
  btnTwo = true,
  btnThree = true,
  openModal,
}) => {
  return (
    <div className="tableRowAction">
      {btnOne && (<IconButton
        type={'4'}
        active={true}
        iconName={'EditTwoTone'}
        funtionality={openModal} />)}
      {btnTwo && (<IconButton type={'4'} active={true} iconName={'TrendingUpTwoTone'} />)}
      {btnThree && (<div className="" onClick={() => handleForOpenIcon()}>
        <IconButton type={'3'} active={true} iconName={'ArrowDropDownTwoTone'} />
      </div>)}
    </div>
  );
};
