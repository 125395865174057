import React from 'react';
import classNames from 'classnames';
import * as MuiIcons from '@material-ui/icons';
// status, type, active, icon, iconName, iconColor, iconFontSize, iconPosition

export const SecondaryButtonLow = ({
  selector,
  type,
  active,
  icon,
  iconName,
  iconColor,
  iconFontSize,
  iconPosition,
  functionality,
}) => {
  let Icon = MuiIcons[iconName];
  return (
    <>
      {icon ? (
        <>
          <div
            className={classNames(
              active === true ? type : `${type}` + 'Disable',
              selector.className,
              'secondaryBtn'
            )}
            onClick={functionality}
          >
            {iconPosition === 'left' || iconPosition === 'both' ? (
              <Icon style={{ color: iconColor, fontSize: iconFontSize }} />
            ) : null}

            {selector.text}

            {iconPosition === 'right' || iconPosition === 'both' ? (
              <Icon style={{ color: iconColor, fontSize: iconFontSize }} />
            ) : null}
          </div>
        </>
      ) : (
        <div
          className={classNames(
            active === true ? 'secondaryBtn' : 'secondaryBtnDisable',
            selector.className,
            type
          )}
          onClick={functionality}
        >
          {selector.text}
        </div>
      )}
    </>
  );
};
