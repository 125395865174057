import { useCallback, useState } from 'react';
import { InputSearch } from '../InputSearch/InputSearch';
import { Button } from '../Button/Button';
import ExportButton from '../buttons/exportBtn/ExportButton';
import ButtonText from '../buttons/buttonText/ButtonText.jsx';
import { Alert } from '../Alert/Alert';

import { StyledTableHeadLine } from './StyledTableHeadLine';
import './TableHeadLine.scss';

const propsToTableHeadLine = [
  {
    titleName: 'students',
    inputPlaceHolder: 'Enter Student Name, Parent or ID here',
    buttonText: 'add student',

    isInput: true,
    isExportButton: true,
  },
  {
    titleName: 'tests',
    inputPlaceHolder: 'Enter Label here',
    buttonText: 'Add Test',

    isInput: true,
    isExportButton: true,
  },
  {
    titleName: 'teachers',
    inputPlaceHolder: 'Enter Name ',
    buttonText: 'Add teacher',

    isInput: true,
    isExportButton: true,
  },
  {
    titleName: 'grade books',
    inputPlaceHolder: 'Enter Name ',
    buttonText: 'Add class',

    isInput: false,
    isExportButton: true,
  },
  {
    titleName: 'archive',
    inputPlaceHolder: 'Search',
    buttonText: 'Export csv',

    isInput: true,
    isExportButton: false,
  },
  {
    titleName: 'analytics',
    inputPlaceHolder: 'Enter Student Name',
    buttonText: 'Export csv',

    isInput: true,
    isExportButton: false,
  },
];

export const TableHeadLine = ({
  archiveClass = true,
  headlineType = 'teachers',
  searchValue,
  setSearchValue,
  handleOnClickDownloadCsv,
  openModal,
  btnTitle,
  tableRowData,
  dataType,
  config,
  noTeachers = false,
}) => {
  const [isAlert, setIsAlert] = useState({
    type: false,
    text: '',
    close: () => { },
    show: false,
  });
  const callAlert = useCallback((obj) =>
    setIsAlert(obj), []);
  const closeAlert = useCallback(() => setIsAlert({
    type: false,
    text: '',
    close: () => { },
    show: false,
  }), []);

  let currentHeadline = {};
  if (typeof headlineType === 'string') {
    for (let i = 0; i < propsToTableHeadLine.length; i++) {
      if (propsToTableHeadLine[i].titleName === headlineType) {
        currentHeadline = propsToTableHeadLine[i];
      }
    }
  } else {
    currentHeadline = headlineType;
  }

  return (
    <>
      {typeof headlineType === 'string' && (
        <div className="tableHeadLine_Wrapper">
          <div className="tableHeadLine_title">{currentHeadline.titleName}</div>
          {currentHeadline.isInput && btnTitle !== 'noTeachers' && (
            <div className={config ? config.headline : "headlineInputWrapper"}>
              <InputSearch
                placeholder={currentHeadline.inputPlaceHolder}
                value={searchValue}
                onChange={setSearchValue}
              />
            </div>
          )}
          <div className="tableHeadLine_buttonWrapper">
            {currentHeadline.isExportButton && !noTeachers && (
              // <ButtonText
              //   type={config ? config.btnType : '3'}
              //   text={config ? config.btnText : `Add Results`}
              //   iconName={config ? config.btnIcon : `PublishOutlined`}
              //   active={true}
              //   funtionality={() => {
              //     console.log('123546879');
              //   }}
              // />
              <ExportButton
                activeClasses={tableRowData}
                fileHeader={dataType}
                callAlert={callAlert}
                closeAlert={closeAlert}
              />
            )}
            <Button
              onClick={openModal || handleOnClickDownloadCsv}
            >
              {currentHeadline.buttonText}
            </Button>
          </div>
        </div>
      )}
      {typeof headlineType === 'object' && (
        <StyledTableHeadLine
          speedColor={headlineType.speedColor}
          scoreColor={headlineType.scoreColor}
        >
          <div className="tableHeadLine_Wrapper">
            <div className="tableHeadLine_title">
              {currentHeadline.titleName}
              {!(currentHeadline.scoreColor || currentHeadline.speedColor) ? null : ' with '}
              <span className="headline_scoreTitle">{currentHeadline.scoreTitle} </span>

              {/* { currentHeadline.speedTitle && currentHeadline.scoreTitle  } */}
              {currentHeadline.speedTitle && currentHeadline.scoreTitle && <span> & </span>}
              <span className="headline_speedTitle"> {currentHeadline.speedTitle} </span>

              {(currentHeadline.speedTitle || currentHeadline.scoreTitle) && (
                <div
                  style={{ position: 'relative', display: 'inline-block', marginLeft: 13 }}
                  className=""
                >
                  <ButtonText
                    type={`2`}
                    text={`Reset Filters`}
                    iconName={`CloseTwoTone`}
                    active={true}
                    funtionality={currentHeadline.resetButton}
                  />
                </div>
              )}
            </div>
            {currentHeadline.isInput && (
              <div className="headlineInputWrapper">
                <InputSearch
                  placeholder={currentHeadline.inputPlaceHolder}
                  // handleChangeInput={handleChangeInput}
                  value={searchValue}
                  onChange={setSearchValue}
                />
              </div>
            )}
            <div className="tableHeadLine_buttonWrapper" style={{ lineHeight: '40px' }}>
              {currentHeadline.isExportButton && (
                <ButtonText
                  type={`3`}
                  text={`Add Results`}
                  iconName={`PublishOutlined`}
                  active={true}
                  funtionality={() => {
                    console.log('123546879');
                  }}
                />
              )}
              {currentHeadline.isExportCsv && <Button text={currentHeadline.buttonText} />}
              {currentHeadline.isExportButton && <div />}
              {currentHeadline.greyButton && (
                <ButtonText
                  type={`2`}
                  text={currentHeadline.greyButton.buttonText}
                  iconName={currentHeadline.greyButton.icon}
                  active={true}
                  funtionality={() => {
                    currentHeadline.greyButton.functionality();
                  }}
                />
              )}
              {currentHeadline.redButton && (
                <Button
                  onClick={() => {
                    currentHeadline.redButton.functionality();
                  }}
                >
                  {currentHeadline.redButton.buttonText}
                </Button>
              )}
            </div>
          </div>
        </StyledTableHeadLine>
      )}
      {isAlert.show &&
        <Alert
          alertType={isAlert.type}
          text={isAlert.text}
          close={isAlert.close}
        />}
    </>
  );
};
