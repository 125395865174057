import React, { FC } from 'react';
import classNames from 'classnames';
import { ModalButton } from '../ModalButton/ModalButton';
import styles from './ModalPrimaryButton.module.scss';

interface Props {
  onClick?: () => void;
  classStyle?: string;
  typeBtn?: 'button' | 'submit' | 'reset' | undefined;
  disabled?: boolean;
}

export const ModalPrimaryButton: FC<Props> = ({ onClick, classStyle = '', children, typeBtn, disabled }) => (
  <ModalButton
    typeBtn={typeBtn}
    onClick={onClick}
    disabled={disabled}
    className={classNames(styles.button, styles[classStyle])}>
    {children}
  </ModalButton>
);
