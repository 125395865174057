import { PieChart } from '../../components/PieChart/PieChart';
import { TableColorLegend } from '../../components/TableColorLegend/TableColorLegend';

export const StudentAnalyticsContainer = ({
  student,
  setFilterAverageSpeed,
  setFilterAverageScore,
}) => {
  // for  AvScore
  const avScore = student.scoreAnalitycs;
  const sortableAvScore = Object.fromEntries(Object.entries(avScore).sort(([, a], [, b]) => b - a));

  const keysSortableAvScore = Object.keys(sortableAvScore);
  const valuesSortableAvScore = Object.values(sortableAvScore);
  const sumAvScoreChartValues =
    +valuesSortableAvScore[0] +
    +valuesSortableAvScore[1] +
    +valuesSortableAvScore[2] +
    +valuesSortableAvScore[3];
  const studentsToOnePrAvScore = +sumAvScoreChartValues / 100;

  // for  AvSpeed
  const avSpeed = student.speedAnalitycs;
  const sortableAvSpeed = Object.fromEntries(Object.entries(avSpeed).sort(([, a], [, b]) => b - a));
  const keysSortableAvSpeed = Object.keys(sortableAvSpeed);
  const valuesSortableAvSpeed = Object.values(sortableAvSpeed);

  const sumAvSpeedChartValues =
    +valuesSortableAvSpeed[0] + +valuesSortableAvSpeed[1] + +valuesSortableAvSpeed[2];
  const studentsToOnePrAvSpeed = +sumAvSpeedChartValues / 100;

  return (
    <div className="analiticsContainer_chartsWrapper">
      <div className="analiticsContainer_singleChartWrapper">
        <div className="analiticsContainer_chartHeader">Average Score</div>
        <div className="analiticsContainer_chartAndLegend_wrapper">
          <div className="analiticsContainer_chartWrapper">
            <PieChart dataToChart={sortableAvScore} setStateFilter={setFilterAverageScore} />
          </div>

          <div className="analiticsContainer_legendWrapper">
            {keysSortableAvScore.map((score, index) => {
              return (
                <div key={index} className={score}>
                  <span>
                    {(valuesSortableAvScore[index] / studentsToOnePrAvScore).toFixed(0)} %
                  </span>
                  -
                  <span>
                    {score === 'below50'
                      ? 'below 50% accuracy  '
                      : score === 'from50to70'
                      ? '50 - 79% accuracy  '
                      : score === 'from80to89'
                      ? '80 - 89% accuracy'
                      : '90%+ accuracy   '}
                    ({valuesSortableAvScore[index]} Students )
                  </span>
                </div>
              );
            })}
          </div>
        </div>
        <div className="analiticsContainer_tooltipFofClick">
          *Click on the pie chart to open more info
        </div>
      </div>

      <div className="analiticsContainer_singleChartWrapper">
        <div className="analiticsContainer_chartHeader">Average Speed</div>
        <div className="analiticsContainer_chartAndLegend_wrapper">
          <div className="analiticsContainer_chartWrapper">
            <PieChart dataToChart={sortableAvSpeed} setStateFilter={setFilterAverageSpeed} />
          </div>

          <div className="analiticsContainer_legendWrapper">
            {keysSortableAvSpeed.map((speed, index) => {
              return (
                <div key={index} className={speed}>
                  <span>
                    {(valuesSortableAvSpeed[index] / studentsToOnePrAvSpeed).toFixed(0)} %
                  </span>
                  -
                  <span>
                    {speed === 'belowExpected'
                      ? 'Below expected speed  '
                      : speed === 'asExpected'
                      ? ' As expected speed '
                      : speed === 'aboveExpected'
                      ? 'Above expected speed'
                      : null}
                    ({valuesSortableAvSpeed[index]} Students )
                  </span>
                </div>
              );
            })}
          </div>
        </div>
        <div className="analiticsContainer_tooltipFofClick">
          *Click on the pie chart to open more info
        </div>
      </div>
    </div>
  );
};
