import { ButtonPrimary } from '../../../../../../components/buttons/buttonPrimary/ButtonPrimary';
import { ModalContainer } from '../../../../../../components/Modal/ModalContainer/ModalContainer';
import { ModalHeader } from '../../../../../../components/Modal/ModalHeader/ModalHeader';
import { ModalWrapper } from '../../../../../../components/Modal/ModalWrapper/ModalWrapper';
import './SuccessfulEditClass.scss';

export const SuccessfulEditClass = ({
  successfulEditClass,
  close,
  loader,
  // loadDataForGradeBook,
  setSuccessfulEditClass,
}) => {
  return (
    <>
      {!successfulEditClass ? (
        <ModalWrapper loader={loader}>
          <ModalContainer>
            <ModalHeader header="Upload Error" color="darkHeader" onClose={close} />
            <div className="successfulAddClass_error_tip">
              Something went wrong. Please try again later.
            </div>
            <div className="successfulAddClass_error_buttonWrapper">
              <ButtonPrimary
                status={'ok'}
                active={true}
                functionality={() => {
                  close();
                  setSuccessfulEditClass(null);
                }}
              />
            </div>
          </ModalContainer>
        </ModalWrapper>
      ) : (
        <ModalWrapper loader={loader}>
          <ModalContainer>
            <ModalHeader
              header="File Successfully Edited"
              onClose={() => {
                close();
                // loadDataForGradeBook();
              }}
            />
            <div className="successfulAddClass_error_buttonWrapper">
              <ButtonPrimary
                status={'okRed'}
                active={true}
                functionality={() => {
                  close();
                  // loadDataForGradeBook();
                  setSuccessfulEditClass(null);
                }}
              />
            </div>
          </ModalContainer>
        </ModalWrapper>
      )}
    </>
  );
};
