import { FC } from 'react';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import styles from './ErrorPopover.module.scss';

interface Props {
  header?: string;
  subheader?: string;
  lines?: string[];
  warning?: string;
}

export const ErrorPopover: FC<Props> = ({ header, subheader, lines, warning }) => {
  if (!header && !subheader && !(Array.isArray(lines) && lines.length > 0) && !warning) {
    return null;
  }

  return (
    <div className={styles.root}>
      {header && (
        <div className={styles.header}>
          <ReportProblemOutlinedIcon className={styles.headerIcom} />
          {header}
        </div>
      )}
      {subheader && <div className={styles.subheader}>{subheader}</div>}
      {Array.isArray(lines) && lines.length > 0 && (
        <div className={styles.lines}>
          {lines.map((line) => (
            <div className={styles.line}>
              <span className={styles.lineIcon} />
              {line}
            </div>
          ))}
        </div>
      )}
      {warning && <div className={styles.warning}>{warning}</div>}
    </div>
  );
};
