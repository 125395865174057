import React, { createRef, useState } from 'react';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { TeacherInfoPopOver } from '../../TeacherInfoPopOver/TeacherInfoPopOver';

import './ArchivePageTeacherInfo.scss';

function ArchivePageTeacherInfo({ teachers }) {

  const [anchorEl, setAnchorEl] = useState(null);
  const divRef = createRef();
  const openTeacherInfo = Boolean(anchorEl);
  const handleOpenInfoTeacher = (event) => setAnchorEl(event.currentTarget);
  const handleCloseInfoTeacher = () => setAnchorEl(null);

  return (
    <div className="archivePageTeacherInfo">
      <div className="archivePageTeacherInfo__info">
        <InfoOutlinedIcon onClick={handleOpenInfoTeacher} />
      </div>
      <Popover
        className="archivePageTeacherInfo__popover"
        open={openTeacherInfo}
        onClose={handleCloseInfoTeacher}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        anchorEl={anchorEl}
        ref={divRef}
      >
        <Typography component={'span'} variant={'body2'}>
          <TeacherInfoPopOver teachers={teachers} ref={divRef} />
        </Typography>
      </Popover>
      <span className="archivePageTeacherInfo__name">
        {teachers.map((teacher) => teacher.name).join(", ")}
      </span>
    </div>
  );
}

export default ArchivePageTeacherInfo;
