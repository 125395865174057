import { useState, useEffect, useCallback, useContext } from 'react';
import { LoaderFullBook } from '../../components/Loaders/LoaderFullBook/LoaderFullBook';
import { AppContext } from '../../context/AppContext';
import OnboardingHeader from '../Onboarding/OnboardingHeader/OnboardingHeader';
import ParentHeader from './ParentPageComponents/ParentHeader/ParentHeader';
// import { config } from "../../config"
import "./ParentPage.scss"
import { FiltersContainer } from '../../components/FiltersContainer/FiltersContainer';

export const ParentPage = () => {
  const {
    showLoader,
    isAuth,
    setIsAuth,
    user,
    updateUser,
    schools,
    updateSchools,
    uploadedPhoto,
    setUploadedPhoto,
    fileToSave,
    setFileToSave,
    updateCurrentSchoolId,
  } = useContext(AppContext);

  return (
    <>
      {showLoader && <LoaderFullBook />}
      {/* <div className="onboarding_wrapper"> */}
        <ParentHeader />
        <FiltersContainer />
         <div className="parentPage_wrapper">
        <p>Parent Page info</p>
      </div>
    </>
  );
}

