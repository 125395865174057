import { useState } from 'react';
import { useDebounce } from './debounce';

export function useSearchFilter(items, filterProp, delay) {
  const [enteredSearchValue, setEnteredSearchValue] = useState('');
  const activeSearchValue = useDebounce(enteredSearchValue, delay);

  const availableItems = activeSearchValue
    ? items.filter((item) =>
      RegExp(activeSearchValue, 'i').test(item[filterProp])
    )
    : items;

  return {
    enteredSearchValue,
    setEnteredSearchValue,
    availableItems,
  };
}
