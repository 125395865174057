import { Link } from 'react-router-dom';

import { useState, useEffect } from 'react';

import { StudentDetailsSubTable } from '../StudentDetailsSubTable/StudentDetailsSubTable';
import { TableRow } from '../TableRow/TableRow';
import { CheckBox } from '../CheckBox/CheckBox';
import { TableCell } from '../TableCell/TableCell';
import { TableRowAction } from '../TableRowAction/TableRowAction';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { IconButton } from '../buttons/IconButton/IconButton';
import { ParentsInfoModal } from '../ParentsInfoModal/ParentsInfoModal';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import UnarchiveTwoToneIcon from '@mui/icons-material/UnarchiveTwoTone';
import CheckBoxTwoToneIcon from '@mui/icons-material/CheckBoxTwoTone';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'

import './TableRowStudents.scss';

export const TableRowStudent = ({
  typeTableRow,
  openModalEdit,
  student,
  index,
  isSelectAll,
  fillDefaultEditValues,
  setIsSelectedAll,
  openSelectMenu,
  selectedStudentType,
  selectedStudent = [],
  dataLength,
  callAlert,
  closeAlert,
  openModalUnArchive,
  forPage }) => {
  const [openStudentDetailInfo, setOpenStudentDetailInfo] = useState(false);
  const handleOpenStudentDetailInfo = () => {
    setOpenStudentDetailInfo((prevState) => !prevState);
    console.log('openStudentDetailInfo', openStudentDetailInfo);
  };
  // code for open parents modal
  const [iconAnchorEl, setIconAnchorEl] = useState(null);
  const openParentsInfo = Boolean(iconAnchorEl);
  const handleOpenInfoParents = (event) => setIconAnchorEl(event.currentTarget);
  const handleCloseInfoParents = () => setIconAnchorEl(null);

  const [checkBox, setCheckBox] = useState(false);

  useEffect(() => {
    if (selectedStudent.length === 0) {
      setCheckBox(false);
    }
    if (isSelectAll && student.isArchive === 0) {
      setCheckBox(true);
    }
  }, [selectedStudent, isSelectAll])

  return (
    <>
      <TableRow selected={checkBox} typeTableRow={typeTableRow} index={index}>
        <TableCell>
          {forPage !== 'analytics' &&
            <div
              style={{
                cursor: selectedStudentType !== 2 ?
                  (selectedStudentType === 1 ?
                    (student.isArchive === 1 ? 'pointer' : 'not-allowed ')
                    : (student.isArchive === 0 ? 'pointer' : 'not-allowed '))
                  : 'pointer'
              }}>
              <div
                className='checkBox'
                style={{
                  pointerEvents: selectedStudentType !== 2 ?
                    (selectedStudentType === 1 ?
                      (student.isArchive === 1 ? 'auto' : 'none')
                      : (student.isArchive === 0 ? 'auto' : 'none'))
                    : 'auto',
                }}
                onClick={() => {
                  setIsSelectedAll(false);
                  setCheckBox(!checkBox);
                  openSelectMenu(student, checkBox)
                }}>
                {checkBox ? <CheckBoxTwoToneIcon style={{ color: '#323232' }} /> 
                  : < CheckBoxOutlineBlankIcon style={{ color: '#828282' }} />}
              </div>
            </div>
          }
        </TableCell>

        <TableCell>
          {student.isArchive ? (
            <div>
              {student.name}
              <br />
              <div className='archived'>Archived</div>
            </div>
          ) : (
            <>
              {student.name}
            </>
          )}
        </TableCell>

        <TableCell>{student.id}</TableCell>
        <TableCell>{student.class}</TableCell>
        <TableCell color={student?.avScore?.color}>
          {(student?.avScore?.score) || '-'}
        </TableCell>
        <TableCell color={student?.avSpeed?.color}>
          {(student?.avSpeed?.speed) || '-'}
          </TableCell>
        <TableCell>
          <div className="tableParentsInfo_wrapper">
            {student.parentsDetailInfo ? (
              <>
                {Object.keys(student.parentsDetailInfo).length !== 0 ? (
                  <InfoOutlinedIcon onClick={handleOpenInfoParents} />
                ) : (
                  <div className="tableParentsInfo_emptySquare"></div>
                )}

                {Object.keys(student.parentsDetailInfo).length ? (
                  <>
                    {student.parentsDetailInfo.firstParent?.name}

                    {student.parentsDetailInfo?.secondParent?.name ?
                      `, ${student.parentsDetailInfo?.secondParent?.name}` : ''
                    }
                  </>
                ) : (
                  <div className="noParents">
                    No Parents Added
                  </div>
                )}

                {student.parentsDetailInfo.length !== 0 && (
                  <Popover
                    open={openParentsInfo}
                    onClose={handleCloseInfoParents}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    anchorEl={iconAnchorEl}
                    elevation={2}
                  >
                    <Typography component={'span'} variant={'body2'}>
                      <ParentsInfoModal
                        callAlert={callAlert}
                        closeAlert={closeAlert}
                        parentsDetails={student.parentsDetailInfo} />
                      {/* <PortfolioPopUp /> */}
                    </Typography>
                  </Popover>
                )}
              </>
            ) : (
              <div className="tableParentsInfo_noParentsWrapper">No Parents Added</div>
            )}
          </div>
        </TableCell>

        {forPage !== 'analytics' &&
          <TableCell>
            {student.isArchive ? (
              <button
                className='unArchiveBtn'
                style={{
                  pointerEvents: selectedStudentType !== 2 ?
                    (selectedStudentType === 1 ?
                      (student.isArchive === 1 ? 'auto' : 'none')
                      : (student.isArchive === 0 ? 'auto' : 'none'))
                    : 'auto',
                }}
                onClick={() => { openModalUnArchive(student) }}
              >
                <UnarchiveTwoToneIcon />
              </button>
            ) : (
              <TableRowAction
                // handleForOpenIcon={handleOpenTeacherDetailInfo}
                openModal={() => { openModalEdit(); fillDefaultEditValues(student) }}
                btnOne={true}
                btnTwo={false}
                btnThree={false}
              />
            )}

          </TableCell>
        }


        {/* <TableCell > 
               { forPage !== 'analytics' ?
                    <TableRowAction 
                        handleForOpenIcon={handleOpenStudentDetailInfo}
                    />
                    :
                    <Link 
                    className="movieLink" 
                    to={`/analytics/student?studentId=${student.id}`} 
                    // key={student.id}
                    // onClick={ () => { handleSetMovieId (id ) }} 
                    >
                        <div style={{paddingLeft: '30px'}} className="">
                            <IconButton 
                                type={'4'}
                                active={true}
                                iconName={'TrendingUpTwoTone'}
                                funtionality = {()=>{console.log('student.id', student.id)}}
                            />
                        </div>
                    </Link>
                 }
                </TableCell>        */}
      </TableRow>

      {/* <StudentDetailsSubTable 
                openStudentDetails={openStudentDetailInfo}
                studentInfo={student.studentDetailInfo}
            />  */}
    </>
  );
};
