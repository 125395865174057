import { useState } from 'react';

export function useSort(items, sortProp, extraSortProps = '') {
  const [sortMode, setSortMode] = useState('');

  const sortedItems = !sortMode
    ? items
    : items.slice().sort((a, b) => {
      if (sortMode === 'asc' && a[sortProp][extraSortProps] > b[sortProp][extraSortProps]) {
        return 1;
      } else if (sortMode === 'asc') {
        return -1;
      } else if (sortMode === 'desc' && a[sortProp][extraSortProps] > b[sortProp][extraSortProps]) {
        return -1;
      } else {
        return 1;
      }
    });

  return {
    sortMode,
    setSortMode,
    sortedItems,
  };
}
