import React from 'react';
import { useDebounce } from '../../hooks/debounce';
import NoSearchResultsIndicator from './NoSearchResultsIndicator';

// Передавай Boolean в props ..SHOW.. (true => покажет NoSearchResultsPage/ false => return children)
// В props ..SEARCHVALUE.. передай string
// В Props ..BACKFN.. передай то, что должно произойти при нажатии кнопки "вернуться назад"
// Например, () => setSearchValue(empty string)/setShowPageNoResults(false)
// Props ..DELAY.. опционально

function NoSearchResults(props) {
  const {
    show = false,
    searchValue = '',
    backFn = () => { },
    delay = 0,
    children,
  } = props;

  const value = useDebounce(searchValue, delay)

  if (show) {
    return <NoSearchResultsIndicator searchValue={value} backFn={() => backFn()} />;
  }

  return children;
}

export default NoSearchResults;
