import { FC } from 'react';
import { WidthLimiter } from '../WidthLimiter/WidthLimiter';
import Header from '../Header/Header';
// import Footer from '../Footer/Footer';

export const InternalPageContainer: FC = ({ children }) => (
  <WidthLimiter>
    <Header />
    {children}
    {/* <Footer/> */}
  </WidthLimiter>
);
