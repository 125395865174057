const simpleFilter = (data, filter, filterProp) => {
  return data
    .filter((test) =>
      RegExp(filter, 'i').test(test[filterProp]));
};

const complexFilter = (data, filter, filterProp,
  propValue, extraPropValue, space) => {
  return data
    .filter((test) => {
      const availableItems = test[filterProp]
        .filter((item) =>
          filter.toLowerCase()
            .includes(`${item[propValue]}${space}${item[extraPropValue]}`.toLowerCase()))
      if (test[filterProp].includes(availableItems[0])) {
        return test;
      }
      return null;
    });
};

const speedFilter = (data, speedValue) => {
  return data
    .filter((test) => test['speed']['value'] === speedValue);
};

export const getFilteredTestsData = (
  testsData, concept, teachers, status,
  score, speed, classes) => {

  let filteredValues = [...testsData];

  if (concept !== '') {
    filteredValues = simpleFilter(filteredValues, concept, 'concept');
  }

  if (teachers !== '') {
    filteredValues = complexFilter(filteredValues, teachers, 'teachers',
      'firstName', 'lastName', ' ');
  }

  if (status !== '') {
    filteredValues = simpleFilter(filteredValues, status, 'status');
  }

  if (score !== '') {
    const value = {
      noData: null,
      low: 50,
      middle: 80,
      high: 90,
    };

    if (score === 'NO DATA YET') {
      filteredValues = filteredValues
        .filter((test) => test['score']['value'] === value.noData);
    }
    if (score === 'BELOW 50%') {
      filteredValues = filteredValues
        .filter((test) => (test['score']['value'] < value.low)
          && (test['score']['value'] !== value.noData));
    }
    if (score === '50 - 79%') {
      filteredValues = filteredValues
        .filter((test) =>
        ((test['score']['value'] >= value.low)
          && (test['score']['value'] < value.middle)));
    }
    if (score === '80 - 89%') {
      filteredValues = filteredValues
        .filter((test) =>
        ((test['score']['value'] >= value.middle)
          && (test['score']['value'] < value.high)));
    }
    if (score === '90%+') {
      filteredValues = filteredValues
        .filter((test) => test['score']['value'] >= value.high);
    }
  }

  if (speed !== '') {
    const value = {
      noData: null,
      low: -1,
      middle: 0,
      high: 1,
    };

    if (speed === 'NO DATA YET') {
      filteredValues = speedFilter(filteredValues, value.noData);
    }
    if (speed === 'BELOW EXP.') {
      filteredValues = speedFilter(filteredValues, value.low);
    }
    if (speed === 'AS EXP.') {
      filteredValues = speedFilter(filteredValues, value.middle);
    }
    if (speed === 'ABOVE EXP.') {
      filteredValues = speedFilter(filteredValues, value.high);
    }
  }

  if (classes !== '') {
    filteredValues = complexFilter(filteredValues, classes, 'classes',
      'grade', 'className', '');
  }

  return filteredValues;
}
