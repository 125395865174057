import { IconButton } from '../buttons/IconButton/IconButton';

import './ParentsInfoModal.scss';

export const ParentsInfoModal = ({ parentsDetails, closeAlert = () => { }, callAlert = () => { } }) => {
  const handleCopyText = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      // setCopySuccess('Copied!');
    } catch (err) {
      // setCopySuccess('Failed to copy!');
    }
  };

  const { firstParent, secondParent } = parentsDetails;

  return (
    <div className="parentsInfoModal">
      <div className="parentsInfoModal_header">
        Parents information
        {/* <IconButton type={'4'} active={true} iconName={'CreateTwoTone'} /> */}
      </div>

      {Object.keys(secondParent).length ? (
        <div className="parentInfo_wrapper">
          <div className="parentInfo_name">{secondParent?.name}</div>
          <div className="parentInfo_emailWrapper">
            <div className="parentInfo_email">
              Email:
              <span>
                {secondParent?.email?.length > 25
                  ? secondParent?.email.substr(0, 24) + '...'
                  : secondParent?.email}
              </span>
            </div>
            <div
              className=""
              onClick={() => {
                handleCopyText(secondParent?.email);
                callAlert({ type: true, text: 'Copied!', close: closeAlert });
              }}
            >
              <IconButton type={'3'} active={true} iconName={'ContentCopyTwoTone'} />
            </div>
          </div>

          <div className="parentInfo_emailWrapper">
            <div className="parentInfo_email">
              Phone:
              <span>{secondParent?.phone}</span>
            </div>
            <div
              onClick={() => {
                handleCopyText(secondParent?.phone);
                callAlert({ type: true, text: 'Copied!', close: closeAlert });
              }}
              className=""
            >
              <IconButton type={'3'} active={true} iconName={'ContentCopyTwoTone'} />
            </div>
          </div>
        </div>
      ) : null}

      {Object.keys(firstParent).length ? (
        <div className="parentInfo_wrapper">
          <div className="parentInfo_name">{firstParent?.name}</div>
          <div className="parentInfo_emailWrapper">
            <div className="parentInfo_email">
              Email:
              <span>
                {firstParent?.email?.length > 25
                  ? firstParent?.email.substr(0, 24) + '...'
                  : firstParent?.email}
              </span>
            </div>
            <div
              onClick={() => {
                handleCopyText(firstParent?.email);
                callAlert({ type: true, text: 'Copied!', close: closeAlert });
              }}
              className=""
            >
              <IconButton type={'3'} active={true} iconName={'ContentCopyTwoTone'} />
            </div>
          </div>

          <div className="parentInfo_emailWrapper">
            <div className="parentInfo_email">
              Phone:
              <span>{firstParent?.phone}</span>
            </div>
            <div
              onClick={() => {
                handleCopyText(firstParent?.phone);
                callAlert({ type: true, text: 'Copied!', close: closeAlert });
              }}
              className=""
            >
              <IconButton type={'3'} active={true} iconName={'ContentCopyTwoTone'} />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
