export const allConcepts = ['math', 'factorials', 'fractions', 'multiplicationrerfdfdfdf'];

export const defaultStudent = {
  name: 'Elizabeth Arden',
  class: '1A',
  allTests: 40,
  avScore: 95,
  avSpeed: 'Above Expected',
  id: '4451515',
  scoreAnalitycs: {
    from90to100: 6,
    from70to90: 12,
    from50to70: 14,
    below50: 4,
  },
  speedAnalitycs: {
    belowExpected: 5,
    asExpected: 12,
    aboveExpected: 10,
  },
  tests: [
    {
      label: 'Finding Averages 1 to 400',
      concept: 'Multiplication',
      status: 'In Progress',
      teachers: [
        {
          photoPath: '/aaaa/bbbb/nnn',
          name: 'Jane Cooper',
          avScore: {
            score: 93,
            color: '#4285F4',
          },
          avSpeed: {
            speed: 'As Expected',
            color: '#0F9D58',
          },
          email: 'gabi74@gmail.com',
          phone: '+1 084 (755) 08 - 11',
        },
        {
          photoPath: '/aaaa/bbbb/nnn',
          name: 'Brooklyn Simmons',
          avScore: {
            score: 85,
            color: '#0F9D58',
          },
          avSpeed: {
            speed: 'Above Expected',
            color: '#4285F4',
          },
          email: 'gabi74@gmail.com',
          phone: '+1 084 (755) 08 - 11',
        },
      ],
      date: 'MAR 20 2021',
      score: { score: '61', color: '#E2B534' },
      speed: { speed: '1h 12m 41s', color: '#4285F4' },
    },
    {
      label: 'Finding Averages 1 to 400',
      concept: 'Subtraction',
      status: 'In Progress',
      teachers: [
        {
          photoPath: '/aaaa/bbbb/nnn',
          name: 'Jane Cooper',
          avScore: {
            score: 93,
            color: '#4285F4',
          },
          avSpeed: {
            speed: 'As Expected',
            color: '#0F9D58',
          },
          email: 'gabi74@gmail.com',
          phone: '+1 084 (755) 08 - 11',
        },
      ],
      date: 'APR 20 2021',
      score: { score: '61', color: '#E2B534' },
      speed: { speed: '1h 12m 41s', color: '#4285F4' },
    },
    {
      label: 'Finding Averages 1 to 400',
      concept: 'Subtraction',
      status: 'In Progress',
      teachers: [
        {
          photoPath: '/aaaa/bbbb/nnn',
          name: 'Jane Cooper',
          avScore: {
            score: 93,
            color: '#4285F4',
          },
          avSpeed: {
            speed: 'As Expected',
            color: '#0F9D58',
          },
          email: 'gabi74@gmail.com',
          phone: '+1 084 (755) 08 - 11',
        },
      ],
      date: 'APR 20 2021',
      score: { score: '61', color: '#E2B534' },
      speed: { speed: '1h 12m 41s', color: '#4285F4' },
    },
    {
      label: 'Finding Averages 1 to 400',
      concept: 'Subtraction',
      status: 'In Progress',
      teachers: [
        {
          photoPath: '/aaaa/bbbb/nnn',
          name: 'Jane Cooper',
          avScore: {
            score: 93,
            color: '#4285F4',
          },
          avSpeed: {
            speed: 'As Expected',
            color: '#0F9D58',
          },
          email: 'gabi74@gmail.com',
          phone: '+1 084 (755) 08 - 11',
        },
      ],
      date: 'APR 20 2021',
      score: { score: '61', color: '#E2B534' },
      speed: { speed: '1h 12m 41s', color: '#4285F4' },
    },
    {
      label: 'Finding Averages 1 to 400',
      concept: 'Subtraction',
      status: 'In Progress',
      teachers: [
        {
          photoPath: '/aaaa/bbbb/nnn',
          name: 'Jane Cooper',
          avScore: {
            score: 93,
            color: '#4285F4',
          },
          avSpeed: {
            speed: 'As Expected',
            color: '#0F9D58',
          },
          email: 'gabi74@gmail.com',
          phone: '+1 084 (755) 08 - 11',
        },
      ],
      date: 'APR 20 2021',
      score: { score: '61', color: '#E2B534' },
      speed: { speed: '1h 12m 41s', color: '#4285F4' },
    },
    {
      label: 'Finding Averages 1 to 400',
      concept: 'Subtraction',
      status: 'In Progress',
      teachers: [
        {
          photoPath: '/aaaa/bbbb/nnn',
          name: 'Jane Cooper',
          avScore: {
            score: 93,
            color: '#4285F4',
          },
          avSpeed: {
            speed: 'As Expected',
            color: '#0F9D58',
          },
          email: 'gabi74@gmail.com',
          phone: '+1 084 (755) 08 - 11',
        },
      ],
      date: 'APR 20 2021',
      score: { score: '61', color: '#E2B534' },
      speed: { speed: '1h 12m 41s', color: '#4285F4' },
    },
    {
      label: 'Finding Averages 1 to 400',
      concept: 'Subtraction',
      status: 'In Progress',
      teachers: [
        {
          photoPath: '/aaaa/bbbb/nnn',
          name: 'Jane Cooper',
          avScore: {
            score: 93,
            color: '#4285F4',
          },
          avSpeed: {
            speed: 'As Expected',
            color: '#0F9D58',
          },
          email: 'gabi74@gmail.com',
          phone: '+1 084 (755) 08 - 11',
        },
      ],
      date: 'APR 20 2021',
      score: { score: '61', color: '#E2B534' },
      speed: { speed: '1h 12m 41s', color: '#4285F4' },
    },
    {
      label: 'Finding Averages 1 to 400',
      concept: 'Subtraction',
      status: 'In Progress',
      teachers: [
        {
          photoPath: '/aaaa/bbbb/nnn',
          name: 'Jane Cooper',
          avScore: {
            score: 93,
            color: '#4285F4',
          },
          avSpeed: {
            speed: 'As Expected',
            color: '#0F9D58',
          },
          email: 'gabi74@gmail.com',
          phone: '+1 084 (755) 08 - 11',
        },
      ],
      date: 'APR 20 2021',
      score: { score: '61', color: '#E2B534' },
      speed: { speed: '1h 12m 41s', color: '#4285F4' },
    },
    {
      label: 'Finding Averages 1 to 400',
      concept: 'Subtraction',
      status: 'In Progress',
      teachers: [
        {
          photoPath: '/aaaa/bbbb/nnn',
          name: 'Jane Cooper',
          avScore: {
            score: 93,
            color: '#4285F4',
          },
          avSpeed: {
            speed: 'As Expected',
            color: '#0F9D58',
          },
          email: 'gabi74@gmail.com',
          phone: '+1 084 (755) 08 - 11',
        },
      ],
      date: 'APR 20 2021',
      score: { score: '61', color: '#E2B534' },
      speed: { speed: '1h 12m 41s', color: '#4285F4' },
    },
    {
      label: 'Finding Averages 1 to 400',
      concept: 'Subtraction',
      status: 'In Progress',
      teachers: [
        {
          photoPath: '/aaaa/bbbb/nnn',
          name: 'Jane Cooper',
          avScore: {
            score: 93,
            color: '#4285F4',
          },
          avSpeed: {
            speed: 'As Expected',
            color: '#0F9D58',
          },
          email: 'gabi74@gmail.com',
          phone: '+1 084 (755) 08 - 11',
        },
      ],
      date: 'APR 20 2021',
      score: { score: '61', color: '#E2B534' },
      speed: { speed: '1h 12m 41s', color: '#4285F4' },
    },
  ],
};