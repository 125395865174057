import { useState } from 'react';
import ErrorBoundary from '../../../../components/ErrorBaundary';
import ArchivePageClassRow from './ArchivePageClassRow/ArchivePageClassRow';
import CheckboxTwoTone from '../../../../components/CheckboxTwoTone';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import './ArchivePageGradeRow.scss';

function ArchivePageGradeRow({
  grade,
  classesInSelectedGrade,
  setActiveClasses,
  activeClasses,
  toggleSelectedClasses,
  searchValue,
  callRender,
}) {
  const [showClasses, setShowClasses] = useState(false);

  let gradeActive = false;
  classesInSelectedGrade.forEach(item => {
    if (activeClasses.includes(item)) {
      gradeActive = true;
    }
  });

  const onChangeCheck = () => {
    gradeActive = !gradeActive;
    if (gradeActive === true) {
      toggleSelectedClasses(classesInSelectedGrade);
    }
    if (gradeActive === false) {
      const removeSelect = activeClasses
        .filter((item) => !classesInSelectedGrade
          .includes(item));
      setActiveClasses([...removeSelect]);
    }
  };

  const arrowButton = (showClasses) ? (
    <ArrowDropUpIcon color="action" />) : (
    <ArrowDropDownIcon color="disabled" />);

  const filteredClasses = classesInSelectedGrade
    .map((classInfo, index) => {
      const hasClass = classInfo.class
        .toLowerCase().includes(searchValue.toLowerCase());
      const hasTeacher = classInfo.teachers
        .filter((teacher) => teacher.name
          .toLowerCase().includes(searchValue.toLowerCase())).length;
      if (hasClass || hasTeacher) {
        return (
          <ArchivePageClassRow
            key={index}
            classInfo={classInfo}
            activeClasses={activeClasses}
            setActiveClasses={setActiveClasses}
            callRender={callRender}
          />
        );
      }
      return null;
    });

  const allClasses = classesInSelectedGrade
    .map((classInfo, index) => {
      return (
        <ArchivePageClassRow
          key={index}
          classInfo={classInfo}
          activeClasses={activeClasses}
          setActiveClasses={setActiveClasses}
          callRender={callRender}
        />
      );
    });

  const classRow = (searchValue.length === 0)
    ? allClasses : filteredClasses;
  const selectedRow = (gradeActive) ? ' checked' : '';
  const showClassesRow = (showClasses || (searchValue.length > 0))
    ? ' showClasses' : '';

  return (
    <ErrorBoundary>
      <div className={`archivePageGradeRow__inner${selectedRow}`}>
        <div className="archivePageGradeRow__content">
          <span className="archivePageGradeRow__content-checkbox">
            <CheckboxTwoTone
              checked={gradeActive}
              onChange={onChangeCheck}
            />
          </span>
          <span className="archivePageGradeRow__content-value">
            {grade} grade
          </span>
          <button
            className="archivePageGradeRow__content-btn"
            type="button"
            onClick={() => setShowClasses(!showClasses)}
          >
            {arrowButton}
          </button>
        </div>
      </div>
      <div className={`archivePageClassRow${showClassesRow}`}>
        {classRow}
      </div>
    </ErrorBoundary>
  );
}

export default ArchivePageGradeRow;
