// import ButtonText from '../../../Components/buttons/buttonText'
import { useState } from 'react';
import { TestInfoPopOver } from '../../../../../components/TestInfoPopOver/TestInfoPopOver';
import ButtonText from '../../../../../components/buttons/buttonText/ButtonText';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Modal from '@mui/material/Modal';

import './TestTableHeader.scss';
import { EditResultsModal } from '../EditResultsModal/EditResultsModal';
// about props : test - one test info, studentTests - state, all students, and all their state

export const TestTableHeader = ({ test, studentTests, fetchToGetdata }) => {
  const [iconAnchorEl, setIconAnchorEl] = useState(null);
  const openTestInfo = Boolean(iconAnchorEl);
  const handleOpenInfoTest = (event) => setIconAnchorEl(event.currentTarget);
  const handleCloseInfoTeacher = () => setIconAnchorEl(null);
  // open Edit result modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const resultsToEdit = [];
  const studentSelectedTestResult = studentTests.map((student) => {
    const testToPush = student.tests.find((studentTest) => studentTest.testId === test.testId);
    // console.log('student.tests', test)
    resultsToEdit.push({
      studentName: student.name,
      studentId: student.studentId,
      results:
        testToPush === undefined
          ? {
              score: { score: undefined, color: undefined },
              speed: { score: undefined, color: undefined },
              absent: undefined,
            }
          : testToPush.absent === 1
          ? {
              score: { score: null, color: null },
              speed: { speed: null, color: null },
              absent: 1,
              testId: testToPush.testId,
            }
          : testToPush,
    });
  });

  // const aaa = [{ studentName: 'student Name', studentId: 3, absent: 1 }];

  // console.log('resultsToEdit', resultsToEdit)
  return (
    <div className="classGradeBook_testheaderWrapper">
      <div className="classGradeBook_testheaderWrapper_header">
        <InfoOutlinedIcon onClick={handleOpenInfoTest} />
        <Popover
          open={openTestInfo}
          onClose={handleCloseInfoTeacher}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          anchorEl={iconAnchorEl}
          elevation={2}
        >
          <Typography component={'span'} variant={'body2'}>
            <TestInfoPopOver
              testName={test.testName}
              totalQuestions={test.totalQuestions}
              estTime={test.estTime}
              concept={test.concept}
              date={test.date}
              status={test.status}
            />
          </Typography>
        </Popover>

        {test.testName.length > 29 ? test.testName.substr(0, 26) + '. . .' : test.testName}
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }} className="">
        <ButtonText
          type={`1`}
          text={`Edit Results`}
          iconName={`EditTwoTone`}
          active={true}
          funtionality={handleOpen}
        />
        <Modal
          keepMounted
          open={open}
          onClose={handleClose}
          style={{ display: 'flex', alignItems: 'center' }}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <EditResultsModal
            resultsToEdit={resultsToEdit}
            close={handleClose}
            test={test}
            testId={test.testId}
            fetchToGetdata={fetchToGetdata} //{()=>{}}
          />
        </Modal>
      </div>
      <div style={{ display: 'flex' }} className="classGradeBook_testheaderScoreWrapper">
        <div className="classGradeBook_testheaderWrapperScore">Score</div>
        <div className="classGradeBook_testheaderWrapperScore">Speed</div>
      </div>
    </div>
  );
};
