import styled from 'styled-components';

export const StyledNoStudentsPage = styled.div`
.NoStudentsText{
  margin-top: 24px;
  text-align: center;
  font-family: Rubik;
  font-size: 16px;
  line-height: 22px;
  color: #777777;
}
.NoStudentsImgWrapper{
  position: relative;
  width: 240px;
  height: 240px;
  margin-left: auto;
  margin-right: auto;
}
.NoStudentsImage{
  display:block;
  margin-top: 68px;
  margin-left: auto;
  margin-right: auto;
}
.roundBGImg{
  position: absolute;
  top:-45px;
  left:-90px;
}
.IconArrow{
  position: absolute;
  top:0;
  left:400px;
}
`