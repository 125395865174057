import * as React from 'react';
import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import OnboardingHeader from "./OnboardingHeader/OnboardingHeader"
import DragNDrop from '../../components/DragNDrop/DragNDrop';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { SuccessfulTeacher } from '../../images/SvgElements/SuccessfulTeacher';
import { OutlinedTextField } from '../../components/OutlinedTextField/OutlinedTextField';
import { config } from "../../config"
import { AppContext } from '../../context/AppContext';
import { LoaderFullBook } from '../../components/Loaders/LoaderFullBook/LoaderFullBook';
import './Onboarding.scss';

const ZeroTab = ({ control, errors, register }) => {
  return (
    <div className="zeroTab_wrapper" style={{ paddingTop: 10, paddingBottom: 94 }}>
      <div className="stepperTab_headerWrapper">1/2 Please, add your personal data to continue</div>
      <div className="zeroTab_inputWrapper">
        <DragNDrop fileDNDWrapperForm="SquareWrapperForm" fileDNDImg="SquareImg" />
        <div className="zeroTab_textFieldWrapper">
          <div style={{ position: 'relative', marginBottom: 16 }} className="">
            <Controller
              control={control}
              name="firstName"
              rules={{ required: true }}
              render={({ field }) => (
                <OutlinedTextField
                  variant="outlined"
                  margin="normal"
                  id="firstName"
                  color="primary"
                  label="First Name"
                  name="firstName"
                  style={{ width: 302, margin: 0 }}
                  autoComplete="off"
                  {...field}
                  error={Boolean(errors?.firstName)}
                  helperText={errors.firstName?.message}
                />
              )}
            />
            {errors.firstName && (
              <span className="onboarding_errorInInput">
                First name is required <ReportProblemOutlinedIcon style={{ cursor: 'default' }} />
              </span>
            )}
          </div>
          <div
            style={{ position: 'relative', marginBottom: 16 }}
            className="onboarding_inputWrapper"
          >
            <Controller
              control={control}
              name="lastName"
              rules={{ required: true, minLength: 2 }}
              render={({ field }) => (
                <OutlinedTextField
                  variant="outlined"
                  margin="normal"
                  id="lastName"
                  color="primary"
                  label="Last Name"
                  name="lastName"
                  style={{ width: 302, margin: 0 }}
                  autoComplete="off"
                  error={Boolean(errors?.lastName)}
                  helperText={errors.lastName?.message}
                  {...field}
                />
              )}
            />
            {errors.lastName && (
              <span className="onboarding_errorInInput">
                Last name is required <ReportProblemOutlinedIcon style={{ cursor: 'default' }} />
              </span>
            )}
          </div>
          <div
            style={{ position: 'relative', marginBottom: 16 }}
            className="onboarding_inputWrapper"
          >
            <Controller
              control={control}
              name="phone"
              rules={{
                pattern: {
                  value: /[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
                  message: 'Entered value does not match phone format',
                },
              }}
              render={({ field }) => (
                <div>
                  <OutlinedTextField
                    label="Phone (Optional)"
                    placeholder={'XXX-XXX-XXXX'}
                    style={{ width: 302, margin: 0 }}
                    autoComplete="off"
                    {...field}
                  />
                  <div className="textHelper">
                    {errors?.phone && (
                      <>
                        <span>{errors?.phone?.message || 'Required Field'}</span>
                        <InfoOutlinedIcon />
                      </>
                    )}
                  </div>
                </div>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};


const FirstTab = ({ control, errors }) => {
  return (
    <div style={{ paddingTop: 10, paddingBottom: 184 }}>
      <div className="stepperTab_headerWrapper">2/2 Please, create School</div>
      <div className="secondStep_formWrapper">
        <div style={{ position: 'relative', marginBottom: 16 }} className="">
          <Controller
            control={control}
            name="schoolName"
            rules={{ required: true, minLength: 2 }}
            render={({ field }) => (
              <OutlinedTextField
                variant="outlined"
                margin="normal"
                id="schoolName"
                color="primary"
                label="School name"
                name="schoolName"
                style={{ width: 302, margin: 0 }}
                autoComplete="off"
                error={Boolean(errors?.schoolName)}
                helperText={errors.schoolName?.message}
                {...field}
              />
            )}
          />
          {errors.schoolName && (
            <span className="onboarding_errorInInput">
              School name is required <ReportProblemOutlinedIcon style={{ cursor: 'default' }} />
            </span>
          )}
        </div>
        <Controller
          control={control}
          name="schoolAddress"
          // rules={{ }}
          render={({ field }) => (
            <OutlinedTextField
              variant="outlined"
              margin="normal"
              id="schoolAddress"
              color="primary"
              label="Address (optional)"
              name="schoolAddress"
              style={{ width: 302, margin: 0 }}
              autoComplete="off"
              error={Boolean(errors?.schoolAddress)}
              helperText={errors.schoolAddress?.message}
              {...field}
            />
          )}
        />
      </div>
    </div>
  );
};

const FinishTab = () => {
  return (
    <div style={{ marginTop: 20 }} className="stepper_finishTabWrapper">
      <div className="stepperTab_headerWrapper"> Congratulations!</div>
      <div className="stepper_stepTooltip">The data successfully uploaded</div>
      <SuccessfulTeacher />
    </div>
  );
};


// ============= ONBOARDING =============

export const Onboarding = () => {

  const [showLoader, setShowLoader] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const history = useHistory();

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onBlur',
  });

  const {
    // isAuth,
    setIsAuth,
    user,
    updateUser,
    schools,
    updateSchools,
    uploadedPhoto,
    setUploadedPhoto,
    fileToSave,
    setFileToSave,
    updateCurrentSchoolId,
  } = useContext(AppContext);

  function getStepContent(stepIndex, fileToSave, setFileToSave, control, errors, uploadedPhoto, setUploadedPhoto) {
    switch (stepIndex) {
      case 0:
        return (
          <ZeroTab
            fileToSave={fileToSave}
            setFileToSave={setFileToSave}
            control={control}
            errors={errors}
            uploadedPhoto={uploadedPhoto}
            setUploadedPhoto={setUploadedPhoto}
            register={register}
          />
        );
      case 1:
        return <FirstTab control={control} errors={errors} register={register} />;
      case 2:
        return <FinishTab />;
      default:
        return 'Unknown stepIndex';
    }
  }

  const handleNext = async (inputValues) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

    if (activeStep === 0) {
      const objToSend = {
        firstName: inputValues.firstName,
        lastName: inputValues.lastName,
        phone: inputValues?.phone ?? "",
        photo: uploadedPhoto ?? "", // IN BASE64
        email: user.email,
        id: user.id
      };
      try {
        setShowLoader(true);
        const response = await axios.patch(`${config.API_URL}/api/user`, objToSend);
        // console.log('response', response)
        // console.log('response.data', response.data)
        if (response.status === 200) {
          updateUser({
            ...user,
            firstName: objToSend.firstName,
            lastName: objToSend.lastName,
            phone: objToSend.phone,
            photo: objToSend.photo,
          })
          setShowLoader(false);
        }
      } catch (e) {
        console.log(e);
        setShowLoader(false);
      }
    }

    if (activeStep === 1) {
      const newSchoolToSend = {
        name: inputValues.schoolName,
        address: inputValues?.schoolAddress ?? ""
      }
      try {
        setShowLoader(true);
        const response = await axios.post(`${config.API_URL}/api/schools`, newSchoolToSend);
        if (response.data) {
          updateSchools([...schools, {
            Name: newSchoolToSend.schoolName,
            Address: newSchoolToSend.schoolAddress,
            ClassCount: 0,
            TeacherCount: 0,
            TestCount: 0,
            StudentCount: 0,
            Id: response.data.id
          }]);
          updateCurrentSchoolId(response.data.id);
          localStorage.setItem('currentSchoolId', JSON.stringify(response.data.id));
          setIsAuth(true)
          setShowLoader(false);
        }
      } catch (e) {
        console.log(e);
        setShowLoader(false);
      }
    }
  }

  const buttonStyle =
    activeStep === 0
      ? true
      : activeStep === 1
        ? true
        : activeStep === 2
          ? true
          : false;


  return (
    <>
      {showLoader && (<LoaderFullBook />)}
      <div className="onboarding_wrapper">
        <OnboardingHeader />
        <div className="gradeBookPage_greyWrapperHeader">WELCOME!</div>
        <div className="onboarding_stepperWrapper">
          <form onSubmit={handleSubmit(handleNext)}>
            <div className="onboarding_stepper_ContentWrapper">
              {getStepContent(activeStep, fileToSave, setFileToSave, control, errors, uploadedPhoto, setUploadedPhoto)}
            </div>

            {activeStep === 2 ? (
              <button
                type="button"
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto"
                }}
                className="startWorkingButton"
                onClick={() => { history.push("/gradebooks") }}
              >
                Start working
              </button>

            ) : (
              <div className="onboarding_stepper_buttonsWrapper">
                <button
                  style={{
                    cursor: activeStep === 0 ? 'not-allowed' : 'pointer',
                    color: activeStep === 0 ? '#F2F2F2' : null,
                    borderColor: activeStep === 0 ? '#F2F2F2' : null,
                  }}
                  type="button"
                  onClick={() => {
                    if (activeStep === 0) {
                      return;
                    }
                    setActiveStep((prevState) => prevState - 1);
                  }}
                >
                  Back
                </button>
                {activeStep === 0 &&
                  (
                    <button
                      style={{
                        cursor: !buttonStyle ? 'not-allowed' : 'pointer',
                        background: !buttonStyle ? '#F2F2F2' : null,
                        borderColor: !buttonStyle ? '#F2F2F2' : null,
                      }}
                      type='submit'
                    >
                      Next
                    </button>
                  )}
                {activeStep === 1 &&
                  (
                    <button
                      style={{
                        cursor: !buttonStyle ? 'not-allowed' : 'pointer',
                        background: !buttonStyle ? '#F2F2F2' : null,
                        borderColor: !buttonStyle ? '#F2F2F2' : null,
                      }}
                      type='submit'
                    >
                      Finish
                    </button>)}
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
};
