import noStudentsImage from "../../../images/noStudentsImg.png"
import roundBGImg from "../../../images/roundBGImage.png"
import sprite from "../../../images/sprite.svg"
import { StyledNoStudentsPage } from "./StyledNoStudentsPage"
import { TableHeadLine } from "../../../components/TableHeadLine/TableHeadLine";

export const NoStudentsContent = ({openModal}) => {
  return (
    <>
      <TableHeadLine
        headlineType='students'
        searchValue={undefined}
        setSearchValue={undefined}
        handleOnClickDownloadCsv={undefined}
        openModal={openModal}
        btnTitle={'noTeachers'}
        noTeachers={true}
      />
      <StyledNoStudentsPage>
        <p className='NoStudentsText'>You have no students yet.  Please, add student to continue</p>
        <div className='NoStudentsImgWrapper'>
          <img src={noStudentsImage} className="NoStudentsImage" alt="Not found image" />
          <img src={roundBGImg} className="roundBGImg" alt="roundBGImg" />
          <svg className="IconArrow">
            <use href={sprite + '#icon-arrow'}></use>
          </svg>
        </div>
      </StyledNoStudentsPage>
    </>

  );
}



