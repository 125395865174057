import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import { OutlinedTextField } from '../../../../OutlinedTextField/OutlinedTextField';
import CheckboxTwoTone from '../../../../CheckboxTwoTone';

import './InputSelect.scss';

function InputSelect({
  control,
  data = [],
  inputLabel,
  checkboxLabel,
  setSelectedValue,
}) {
  const [selectAll, setSelectAll] = useState(false);
  const [select, setSelect] = useState([]);
  const [open, setOpen] = useState(false);
  const options = data;

  return (
    <div className="inputSelectField">
      <Controller
        control={control}
        name='selectInput'
        render={({ field: { ref } }) => (
          <>
            <Autocomplete
              multiple
              limitTags={1}
              value={select}
              options={options}
              getOptionLabel={(option) => option?.year
                ? `${option.class} - ${option.year}`
                : option}
              renderInput={(params) => (
                <OutlinedTextField
                  {...params}
                  label={inputLabel}
                  placeholder="Search"
                  inputRef={ref}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (<>{params.InputProps.endAdornment}</>),
                  }}
                />
              )}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option?.year ? `${option.class}-${option.year}` : option}>
                  <CheckboxTwoTone checked={selected} />
                  <span className="value">{option?.year ? `${option.class}` : option}</span>
                  <span className="year">{option?.year ? `${option.year}` : null}</span>
                </li>
              )}
              onChange={(_, newValues) => {
                setSelect(newValues);
                setSelectedValue(newValues);
              }}
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              disabled={selectAll}
              disableCloseOnSelect
              ListboxProps={{ style: { maxHeight: '120px' }, position: "bottom-start" }}
            />
            <div className="inputSelectField__checkbox">
              <CheckboxTwoTone
                checked={selectAll}
                onChange={() => { setSelectedValue(selectAll ? [] : options); setSelectAll(!selectAll); setSelect(selectAll ? [] : options) }}
              />
              <span className="label">{checkboxLabel}</span>
            </div>
          </>
        )}
      />
    </div>
  );
}

export default InputSelect;
