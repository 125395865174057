import { useState, useCallback } from 'react';
import { EditClassModal } from '../../GradeBookPage/GradebooksPageComponents/GradebooksPageModals/EditClassModal/EditClassModal';
import { DatePickerRange } from '../../../components/DatePicker/DatePicker';
import { DropdownSelect } from '../../../components/DropdownSelect/DropdownSelect';
import ButtonText from '../../../components/buttons/buttonText/ButtonText';
import { TeacherInfoPopOver } from '../../../components/TeacherInfoPopOver/TeacherInfoPopOver';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FileUploadTwoToneIcon from '@mui/icons-material/FileUploadTwoTone';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import UpgradeTwoToneIcon from '@mui/icons-material/UpgradeTwoTone';
import { UpgradeClassModal } from '../../GradeBookPage/GradebooksPageComponents/GradebooksPageModals/UpgradeClassModal/UpgradeClassModal';
import { InputSearch } from '../../../components/InputSearch/InputSearch';
import ExportBtn from '../../../components/buttons/exportBtn';
import ArchivePageModalUnarchive from '../../../components/ArchiveComponents/ArchivePageModalUnarchive';
import { useHistory } from "react-router-dom";
import { routes } from '../../../routes'
import AddTestModal from '../../../components/TestsComponents/TestModals/AddTestModal';
import TeachersModalOnSubmit from '../../TeachersPage/TeachersModalInfo/TeacherModalOnSubmit/TeacherModalOnSubmit';
import StudentsModal from '../../StudentsPage/StudentModalInfo/StudentsModal';
import './ClassGradeBookPageHeader.scss';

const allConcepts = ['Arithmetic', 'Multiplication'];

export const ClassGradeBookPageHeader = ({
  conceptFilter,
  archiveClass,
  setConceptFilter,
  startDateFilter,
  setStartDateFilter,
  endDateFilter,
  setEndDateFilter,
  classInfo,
  studentsTest,
  searchValue,
  setSearchValue,
  objectForEditClassModal,
  tests,
  tableHeaderType = '',
}) => {
  const history = useHistory();
  const [activeClasses, setActiveClasses] = useState([]);

  const [unarchiveModal, setUnarchiveModal] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const className = queryParams.get('class');

  const [startDateVisual, setStartDateVisual] = useState(null);
  const [endDateVisual, setEndDateVisual] = useState(null);
  // to open teacher popUp
  const [iconAnchorEl, setIconAnchorEl] = useState(null);
  const openTeacherInfo = Boolean(iconAnchorEl);
  const handleOpenInfoTeacher = (event) => setIconAnchorEl(event.currentTarget);
  const handleCloseInfoTeacher = () => setIconAnchorEl(null);
  // to open class edit popUp
  const [openEditClass, setOpenEditClass] = useState(false);
  const handleOpenEditClass = () => setOpenEditClass(true);
  const handleCloseEditClass = () => setOpenEditClass(false);
  //  END class edit popUp

  const handleClearFilters = () => {
    setConceptFilter(null);
    setStartDateFilter(null);
    setEndDateFilter(null);
    setStartDateVisual(null);
    setEndDateVisual(null);
  };

  // ===== Edit Class Modal =====
  const [editClassModal, setEditClassModal] = useState(false);
  const handleOpenEditClassModal = () => setEditClassModal(true);
  const handleCloseEditClassModal = () => setEditClassModal(false);

  // ===== Upgrade Class Modal =====
  const [upgradeClassModal, setUpgradeClassModal] = useState(false);
  const handleOpenUpgradeClassModal = () => setUpgradeClassModal(true);
  const handleCloseUpgradeClassModal = () => setUpgradeClassModal(false);
  
    // ===== Add Student Modal =====
  const [addStudentModal, setAddStudentModal] = useState(false);
  const openAddStudentModal = useCallback(() => setAddStudentModal(true), []);
  const closeAddStudentModal = useCallback(() => setAddStudentModal(false), []);
    // ===== SubmitStudentModal =====
  const [submitModal, setSubmitModal] = useState(false);
  const openSubmitStudentModal = useCallback(() => setSubmitModal(true), []);
  const closeSubmitStudentModal = useCallback(() => setSubmitModal(false), []);

    // ===== Add Test Modal =====
  const [addTestModal, setAddTestModal] = useState(false);
  const openAddTestModal = useCallback(() => setAddTestModal(true), []);
  const closeAddTestModal = useCallback(() => setAddTestModal(false), []);

  return (
    <div className="analyticsPageHeader_container">
      <div className="analyticsPageHeader_wrapper">
        <div className="greyDropdownWrapper">
          <DropdownSelect
            className="gradebookSelect"
            dataToSelect={allConcepts}
            defaultValue={'All Concepts'}
            stateFilter={conceptFilter}
            setStateFilter={setConceptFilter}
          />
          <DatePickerRange
            type={'light'}
            setStartDateFilter={setStartDateFilter}
            setEndDateFilter={setEndDateFilter}
            startDateFilter={startDateFilter}
            endDateFilter={endDateFilter}
            startDateVisual={startDateVisual}
            setStartDateVisual={setStartDateVisual}
            endDateVisual={endDateVisual}
            setEndDateVisual={setEndDateVisual}
          />
          <ButtonText
            type={`4`}
            text={`Clear all`}
            iconName={`Clear`}
            active={true}
            funtionality={() => {
              handleClearFilters();
            }}
          />
        </div>
      </div>
      <div className="classGradeBookHeader_wrapper">
        <div className="classGradeBookHeader_header">
          {classInfo.grade}
          {classInfo.className} Gradebook, {classInfo.year}
        </div>
        <div className="headlineInputWrapper">
          <InputSearch placeholder="Test Label" value={searchValue} onChange={setSearchValue} />
        </div>
        <div style={{ display: 'flex' }} className="classGradeBookHeader_dropDownSelectionsWrapper">
          {tableHeaderType === 'archive' && (
            <div
              style={{
                position: 'absolute',
                width: 120,
                right: 125,
                top: -20,
                height: '48px',
                display: 'flex',
                alignItems: 'center',
              }}
              className=""
            >
              <ExportBtn
                activeClasses={[...studentsTest]}
                fileHeader="gradebook"
                additionalData={tests}
              />
            </div>
          )}

          {tableHeaderType === 'archive' && (
            <div style={{ position: 'absolute', width: 120, right: 0, top: -20 }} className="">
              <ButtonText
                type={`3`}
                text={`unarchive`}
                iconName={`UnarchiveTwoTone`}
                active={true}
                funtionality={() => {
                  setUnarchiveModal(true);
                }}
              />

              {unarchiveModal && (
                <ArchivePageModalUnarchive
                  close={() => setUnarchiveModal(false)}
                  openArchivePage={() => {
                    history.push(routes.archive());
                  }}
                  unarchive={[classInfo.classId]}
                />
              )}
            </div>
          )}
          {tableHeaderType !== 'archive' && (
            <div className="classGradeBookHeader_dropDownSelectionContainer">
              <div className="classGradeBookHeader_dropDownSelectionContainer_grey_title">
                <MoreVertIcon />
                ACTIONS
              </div>
              <div
                className="classGradeBookHeader_dropDownSelectionContainer_greyitems"
                onClick={handleOpenUpgradeClassModal}
              >
                <UpgradeTwoToneIcon />
                Upgrade
              </div>
              {upgradeClassModal && (
                <UpgradeClassModal
                  onClose={handleCloseUpgradeClassModal}
                  activeClasses={[classInfo.classId]}
                  upgradeClassModal={upgradeClassModal}
                  openGradebooksPage={() => {
                    history.push(routes.bradebooks());
                  }}
                />
              )}

              <div
                className="classGradeBookHeader_dropDownSelectionContainer_greyitems"
                onClick={handleOpenEditClassModal}
              >
                <EditTwoToneIcon />
                Edit class
              </div>
              {editClassModal && (
                <EditClassModal
                  objectForEditClassModal={objectForEditClassModal}
                  classInfo={classInfo}
                  close={handleCloseEditClassModal}
                />
              )}
              <div className="classGradeBookHeader_dropDownSelectionContainer_greyitems">
                <ExportBtn
                  activeClasses={[...studentsTest]}
                  fileHeader="gradebook"
                  additionalData={tests}
                >
                  <FileUploadTwoToneIcon />
                  Export CSV
                </ExportBtn>
              </div>
              <div
                className="classGradeBookHeader_dropDownSelectionContainer_greyitems"
                onClick={() => {
                  history.push(routes.analytics());
                }}
              >
                <TrendingUpIcon />
                Analytics
              </div>
            </div>
          )}
          {!archiveClass && (
            <>
              <div className="classGradeBookHeader_dropDownSelectionContainer">
                <div className="classGradeBookHeader_dropDownSelectionContainer_title">
                  <ArrowDropDownIcon />
                  ADD
                </div>
                <div
                  className="classGradeBookHeader_dropDownSelectionContainer_items"
                  onClick={openAddStudentModal}
                >
                  Student
                </div>

                {addStudentModal && (
                  <StudentsModal
                    close={closeAddStudentModal}
                    openOnSubmitModal={openSubmitStudentModal}
                    // setShowAlert={setShowAlert}
                    // loader={modalLoader}
                    // changeModalLoader={changeModalLoader}
                    // submitForm={submitForm}
                  />
                )}
                {submitModal && (
                  <TeachersModalOnSubmit close={closeSubmitStudentModal} text={submitModal} />
                )}

                <div
                  className="classGradeBookHeader_dropDownSelectionContainer_items"
                  onClick={openAddTestModal}
                >
                  Test
                </div>
                {addTestModal && <AddTestModal closeModal={closeAddTestModal} />}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="classGradeBookHeader_classInfo_wrapper">
        <div className="classGradeBookHeader_classInfo_item">
          <span>{classInfo.tests}</span>
          tests
        </div>
        <div className="classGradeBookHeader_classInfo_item">
          <span>{classInfo.score || 0}</span>
          Av. Score
        </div>
        <div className="classGradeBookHeader_classInfo_item">
          <span>{classInfo.speed}</span>
          Av. Speed
        </div>
        <div className="classGradeBookHeader_classInfo_item">
          <InfoOutlinedIcon onClick={handleOpenInfoTeacher} />
          <Popover
            open={openTeacherInfo}
            onClose={handleCloseInfoTeacher}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            anchorEl={iconAnchorEl}
            elevation={2}
          >
            <Typography component={'span'} variant={'body2'}>
              <TeacherInfoPopOver teachers={classInfo.teachers} />
            </Typography>
          </Popover>

          <span className="teachersContainer">
            {classInfo?.teachers?.length ? (
              <>
                {classInfo.teachers.map((teacher, index) => {
                  return (
                    <span style={{ margin: '0' }}>
                      {index > 0 && ', '}
                      {teacher.name}
                    </span>
                  );
                })}
              </>
            ) : (
              0
            )}
          </span>

          {classInfo?.teachers?.length > 1 ? ' Teachers' : 'Teacher'}
        </div>
      </div>
    </div>
  );
};
