import { FC } from 'react';
import { ErrorPopover } from '../../../../components/ErrorPopover/ErrorPopover';
import Popover from '@material-ui/core/Popover';

interface Props {
  anchorElement?: Element | null;
  visible: boolean;
  onClose: () => void;
}

export const IncorrectEmailPopover: FC<Props> = ({ anchorElement, visible, onClose }) => (
  <Popover
    open={visible}
    onClose={onClose}
    anchorOrigin={{
      vertical: 'center',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'center',
      horizontal: 'left',
    }}
    anchorEl={anchorElement}
    elevation={2}
  >
    <ErrorPopover
      header="Incorrect Email"
      subheader="Email requirements:"
      lines={[
        'Use format xxxxx@xxxx.xxx'
      ]}
    />
  </Popover>
);
