import React from 'react';
import classNames from 'classnames';

export const ButtonPrimaryLow = ({ selector, active, functionality }) => {
  return (
    <div
      className={classNames(
        active === true ? 'defaultBtn' : 'defaultBtnDisable',
        selector.className,
        selector.addClassName !== false ? selector.addClassName : ''
      )}
      onClick={functionality}
    >
      {selector.text}
    </div>
  );
};
