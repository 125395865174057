import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CSVLink } from 'react-csv';
import { getBodyForFileCSV, getClassesForSelect } from '../../../../model';
import InputSelect from './InputSelect';
import { ModalFooter } from '../../../Modal/ModalFooter/ModalFooter';
import { ModalSecondaryButton } from '../../../Modal/buttons/ModalSecondaryButton/ModalSecondaryButton';

import './ArchiveCsvConstructor.scss';

function ArchiveCsvConstructor({
  archiveData,
  closeModal,
  callAlert,
  closeAlert,
}) {

  const [selectedData, setSelectedData] = useState([]);
  const [selectedYears, setSelectedYears] = useState([]);
  const [selectedClasses, setSelectedClasses] = useState([]);
  const { control } = useForm({ mode: 'onBlur' });
  const dataClasses = getClassesForSelect(archiveData, selectedYears);
  const fileCSV = getBodyForFileCSV(selectedData);

  //useEffect for completing CSV file
  useEffect(() => {
    if (!selectedClasses.length && !selectedYears.length) {
      return setSelectedData([]);
    }
    if (!selectedClasses.length && selectedYears.length > 0) {
      const onlyYears = getClassesForSelect(archiveData, selectedYears);
      return setSelectedData(onlyYears);
    }
    if (selectedClasses.length ||
      (selectedClasses.length && selectedYears.length)) {
      return setSelectedData(selectedClasses);
    }
  }, [
    archiveData,
    selectedClasses,
    selectedYears,
    setSelectedData,
    setSelectedYears,
  ]);

  const onExportCSV = () => {
    if (!selectedData.length) {
      callAlert({
        type: false,
        text: 'Ooops, something went wrong!'
          + ' Please, select the data you want to export.',
        close: closeAlert,
        show: true,
      });
      return false;
    }
    callAlert({
      type: true,
      text: 'Export CSV Success!',
      close: closeAlert,
      show: true,
    });
    return true;
  };

  return (
    <div className="archiveCsvConstructor">
      <p className="archiveCsvConstructor__text">
        Please, select the data you want to export
      </p>
      <form className="form">
        <InputSelect //NO REQUIRED FIELD, OTHER LOGIC
          control={control}
          data={Object.keys(archiveData)}
          inputLabel={'Years'}
          checkboxLabel={'All Years'}
          setSelectedValue={setSelectedYears}
        />
        <InputSelect //NO REQUIRED FIELD, OTHER LOGIC
          control={control}
          data={dataClasses}
          inputLabel={'Classes'}
          checkboxLabel={'All Classes'}
          setSelectedValue={setSelectedClasses}
        />
        <ModalFooter>
          <ModalSecondaryButton
            onClick={closeModal}>
            Cancel
          </ModalSecondaryButton>
          <CSVLink
            className="buttonCSV"
            onClick={onExportCSV}
            {...fileCSV}
          >
            <span>export csv</span>
          </CSVLink>
        </ModalFooter>
      </form>
    </div>
  );
}

export default ArchiveCsvConstructor;
