import styled from 'styled-components/macro';

export const StyledDropdownSelect = styled.div`
  //
  /* .selectWrapper{ */
  font-family: Rubik;
  font-size: 12px;
  color: #c0c0c0;
  font-weight: 400;
  transition: all 400ms ease;
  width: ${(props) => (props.type === 'teachers' ? '180px' : '140px')};

  &:hover {
    color: #f2f2f2;
    transition: all 400ms ease;
  }
  &:hover .dropDownWrapper {
    max-height: 250px;
    overflow: scroll;
    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
      background-color: #5b5b5b;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c0c0c0;
      border-radius: 10px;
    }

    transition: max-height 400ms ease;
  }

  .textAdaArrowWrapper {
    /* margin-top: 4px; */
    display: flex;
    padding: 6px 12px;
    line-height: 20px;
    justify-content: space-between;
  }
  .text_selectWrapper {
    text-transform: uppercase;
  }
  svg {
    width: 20px;
    height: 20px;
  }
  // }
  .dropDownWrapper {
    box-sizing: border-box;
    width: inherit;
    max-height: 0px;
    position: relative;
    z-index: 10;
    overflow-y: ${(props) => (props.showInsertSelect ? 'visible' : 'hidden')}; // have to change
    /* overflow-y: visible  ;  */
    transition: max-height 400ms ease;
    .dropDown_item,
    .dropDown_incertItem {
      box-sizing: border-box;
      padding: 9px 12px 8px 12px;
      height: 32px;
      text-transform: uppercase;
      background-color: #5b5b5b;
      transition: all 400ms ease;
      cursor: pointer;

      position: relative;
      display: flex;
      svg {
        position: absolute;
        top: 1px;
        left: 100px;
        transform: rotate(-90deg);
      }

      .insert_dropDown_wrapper {
        position: absolute;
        right: -100px;
        top: 0px;
      }
      &:hover {
        background-color: #f2f2f2;
        color: #5b5b5b;
        .insert_dropDown_wrapper {
          width: 100px;

          .insert_dropDown_item {
            height: 32px;
            box-sizing: border-box;
            padding: 9px 12px 8px 12px;
            background-color: #5b5b5b;
            color: #f2f2f2;
            width: 100px;
            cursor: pointer;
            transition: color 400ms ease background-color 400ms ease;
            &:hover {
              background-color: #f2f2f2;
              color: #5b5b5b;
              transition: all 400ms ease;
            }
          }
        }
      }
    }

    .insert_dropDown_wrapper {
      overflow: hidden;
      width: 0px;
    }
  }
`;
