import React, { useCallback, useState, useEffect, useContext } from 'react';
import { InternalPageContainer } from '../../components/InternalPageContainer/InternalPageContainer';
import { FiltersContainer } from '../../components/FiltersContainer/FiltersContainer';
import TeachersContent from './TeachersContent/TeachersContent';
import { typeTableRow } from "./dataTeachersPage"
import { tableHeadForTeachers } from "./dataTeachersPage"
import { NoTeachersContent } from './NoTeachersContent/NoTeachersContent';
import TeachersModal from './TeachersModalInfo/TeachersModal';
import TeachersModalEdit from './TeachersModalInfo/TeachersModalEdit/TeacherModalEdit';
import { LoaderFullBook } from '../../components/Loaders/LoaderFullBook/LoaderFullBook';
import TeachersModalOnSubmit from './TeachersModalInfo/TeacherModalOnSubmit/TeacherModalOnSubmit';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Alert } from '../../components/Alert/Alert';
import { StyledDropdownSelect } from '../../components/DropdownSelect/StyledDropdownSelect';
import ButtonText from '../../components/buttons/buttonText';
import NotFoundPage from '../NotFoundPage/NotFoundPage';
import { AppContext } from '../../context/AppContext';
import { config } from '../../config';
import './TeachersPage.scss'

export const TeachersPage = () => {
  const { currentSchoolId } = useContext(AppContext);

  const [modalAdd, setOpenModalAdd] = useState(false);
  const [modalEdit, setOpenModalEdit] = useState(false);
  const [submitModal, setSubmitModal] = useState(false);
  const [teacherData, setTeacherData] = useState({});

  const [showErrorPage, setShowErrorPage] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isAlert, setIsAlert] = useState({
    type: false,
    text: '',
    close: () => { }
  });

  const callAlert = useCallback((obj) => setIsAlert(obj), []);
  const closeAlert = useCallback(() => setIsAlert({
    type: false,
    text: '',
    close: () => { }
  }), []);

  const [modalLoader, setModalLoader] = useState(false);
  const changeModalLoader = useCallback(() => setModalLoader(prev => !prev), []);

  const [submit, setSubmit] = useState(false);
  const submitForm = useCallback(() => setSubmit(prev => !prev), []);

  const openOnSubmitModal = useCallback((text) => setSubmitModal(text), []);
  const closeOnSubmitModal = useCallback(() => setSubmitModal(false), []);

  const openModalAdd = useCallback(() => setOpenModalAdd(true), []);
  const closeModalAdd = useCallback(() => setOpenModalAdd(false), []);

  const openModalEdit = useCallback(() => setOpenModalEdit(true), []);
  const closeModalEdit = useCallback(() => setOpenModalEdit(false), []);
  const fillDefaultEditValues = useCallback((teacher) => setTeacherData(teacher), []);

  const [loader, setLoader] = useState(true);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setLoader(true);
    setTableData([]);
    requestFetch(`${config.API_URL}/api/teacher/list?schoolId=${currentSchoolId}`)
      .then(data => {
        const newArr = [];

        data.forEach(item => {
          item.classesString = Object.values(item.classes).join(', ');
          newArr.push(item);
        })

        setTableData(sortByArchive(newArr));
        setLoader(false);
      })
      .catch(() => {
        setShowErrorPage(true)
      })
  }, [submit, currentSchoolId])

  const [classesFilterData, setClassesFilterData] = useState(false);
  const [classesFilterId, setClassesFilterId] = useState(null);
  const [classesFilterTitle, setClassesFilterTitle] = useState('');
  const [tableDataClone, setTableDataClone] = useState([]);

  const [isSort, setIsSort] = useState(false);

  useEffect(() => {
    setTableDataClone([...tableData]);
  }, [tableData])

  useEffect(() => {
    const result = tableData.filter(item => {
      let filterValue = false;
      for (let i = 0; i < item.classArray.length; i++) {
        if (item.classArray[i].Id === classesFilterId) {
          filterValue = true;
        }
      }
      return filterValue;
    })

    if (classesFilterId === null) {
      setTableDataClone([...tableData]);
    } else {
      if (result.length) {
        setNoResultPage(false);
        setTableDataClone(result);
      } else {
        setNoResultPage(true);
      }
    }

    setStatusSortDir(0);
    setScoreSortDir(0);
    setLetterSortDir(0);
    setSearchValue('');
    setIsSort(!isSort);
  }, [classesFilterId])

  useEffect(() => {
    requestFetch(`${config.API_URL}/api/gradebook?schoolId=${currentSchoolId}`)
      .then((data) => {
        setClassesFilterData(data);
      })
      .catch(() => {
        setShowErrorPage(true);
      });
  }, [currentSchoolId])

  const sortByArchive = (data) => {
    data.sort((a, b) => {
      return a.isArchive - b.isArchive;
    })
    return data;
  }

  const [statusSortDir, setStatusSortDir] = useState(0)

  const sortByStatus = useCallback(() => {
    setLetterSortDir(0);
    setScoreSortDir(0);
    let newArr;
    if (statusSortDir === 0 || statusSortDir === -1) {
      newArr = tableDataClone.sort((a, b) => b?.stats?.avStatus?.value - a?.stats?.avStatus?.value);
      setStatusSortDir(1);
    } else if (statusSortDir === 1) {
      newArr = tableDataClone.sort((a, b) => a?.stats?.avStatus?.value - b?.stats?.avStatus?.value);
      setStatusSortDir(-1)
    }

    setTableDataClone(sortByArchive(newArr));
    setIsSort(!isSort);
  }, [statusSortDir, isSort, tableDataClone]);

  const [letterSortDir, setLetterSortDir] = useState(0);

  const sortByLetter = useCallback(() => {
    setScoreSortDir(0);
    setStatusSortDir(0);
    let newArr;
    if (letterSortDir === 0 || letterSortDir === -1) {
      newArr = tableDataClone.sort((a, b) => a.lastName.localeCompare(b.lastName));
      setLetterSortDir(1);
    } else if (letterSortDir === 1) {
      newArr = tableDataClone.sort((a, b) => b.lastName.localeCompare(a.lastName));
      setLetterSortDir(-1);
    }

    setTableDataClone(sortByArchive(newArr));
    setIsSort(!isSort);
  }, [letterSortDir, isSort, tableDataClone]);

  const [scoreSortDir, setScoreSortDir] = useState(0)

  const sortByScore = useCallback(() => {
    setLetterSortDir(0);
    setStatusSortDir(0);
    let newArr;
    if (scoreSortDir === 0 || scoreSortDir === -1) {
      newArr = tableDataClone.sort((a, b) => b?.stats?.avScore?.score - a?.stats?.avScore?.score);
      setScoreSortDir(1);
    } else if (scoreSortDir === 1) {
      newArr = tableDataClone.sort((a, b) => a?.stats?.avScore?.score - b?.stats?.avScore?.score);
      setScoreSortDir(-1)
    }

    setTableDataClone(sortByArchive(newArr));
    setIsSort(!isSort);
  }, [tableDataClone, isSort, scoreSortDir]);

  const [searchValue, setSearchValue] = useState('');
  const [secondTableClone, setSecondTableClone] = useState([]);
  const [noResultPage, setNoResultPage] = useState(false);

  useEffect(() => {
    setSecondTableClone(tableDataClone);
  }, [tableDataClone])

  const closeNoResultPage = useCallback(() => {
    setNoResultPage(false);
    setSearchValue('');
  }, []);

  useEffect(() => {
    if (searchValue) {
      const newArr = [];

      secondTableClone.forEach(item => {
        let str = item.teacherName
        if (str.toLowerCase().includes(searchValue.toLowerCase())) {
          newArr.push(item);
        }
      });

      if (newArr.length) {
        setSecondTableClone(newArr);
        setNoResultPage(false);
      } else {
        setNoResultPage(true);
      }
    } else {
      setSecondTableClone(tableDataClone);
    }
  }, [searchValue])


  const requestFetch = (url) => {
    return fetch(url)
      .then(response => {
        if (response.ok) {
          return response.json()
        }

        return response.json().then(error => {
          const e = new Error('Smth gone wrong')
          e.data = error
          throw e
        })
      });
  }

  return (
    <div className='teacherPage'>

      {!showErrorPage ? (<>
        <InternalPageContainer />

        <FiltersContainer>
          {classesFilterData.length ? (
            <div className='filtersContainer'>
              <StyledDropdownSelect className='styledDropdown'>
                <div className="textAdaArrowWrapper">
                  <div className="text_selectWrapper">
                    {classesFilterId === null ? 'ALL CLASSES' : classesFilterTitle}
                  </div>
                  <ArrowDropDownIcon />
                </div>
                <div className="dropDownWrapper">
                  {classesFilterId !== null && (
                    <div
                      className="dropDown_item"
                      onClick={() => {
                        setClassesFilterId(null);
                      }}
                    >
                      ALL CLASSES
                    </div>
                  )}
                  {classesFilterData.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="dropDown_item"
                        onClick={() => {
                          setClassesFilterId(item.Id);
                          setClassesFilterTitle(`${item.Grade}${item.ClassName}`);
                        }}
                      >
                        {item.Grade}{item.ClassName}
                      </div>
                    );
                  })}
                </div>
              </StyledDropdownSelect>
              <ButtonText
                type={`4`}
                text={`Clear all`}
                iconName={`Clear`}
                active={true}
                funtionality={() => {
                  if (classesFilterId === null) {
                    setStatusSortDir(0);
                    setScoreSortDir(0);
                    setLetterSortDir(0);
                    setTableDataClone([...tableData]);
                    setSearchValue('');
                  }
                  setClassesFilterId(null);
                }}
              />
            </div>
          ) : null}
        </FiltersContainer>

        {!loader ? (
          tableData.length ?
            <TeachersContent
              setShowAlert={setShowAlert}
              sortByStatus={sortByStatus}
              statusSortDir={statusSortDir}
              backFn={closeNoResultPage}
              searchValue={searchValue}
              noResultPage={noResultPage}
              setSearchValue={setSearchValue}
              scoreSortDir={scoreSortDir}
              sortByScore={sortByScore}
              letterSortDir={letterSortDir}
              sortByLetter={sortByLetter}
              tableHeadForTeachers={tableHeadForTeachers}
              typeTableRow={typeTableRow}
              openModalAdd={openModalAdd}
              openModalEdit={openModalEdit}
              submitForm={submitForm}
              fillDefaultEditValues={fillDefaultEditValues}
              key={isSort}
              tableData={secondTableClone}
              callAlert={callAlert}
              closeAlert={closeAlert} />
            :
            < NoTeachersContent openModal={openModalAdd} />
        ) : <LoaderFullBook />}
      </>) : (
        <NotFoundPage text={'Ooops, server error. Please, contact the admin of the website.'} />
      )}

      {modalAdd && (
        <TeachersModal
          setShowAlert={setShowAlert}
          loader={modalLoader}
          changeModalLoader={changeModalLoader}
          close={closeModalAdd}
          submitForm={submitForm}
          openOnSubmitModal={openOnSubmitModal} />
      )}
      {modalEdit && (
        <TeachersModalEdit
          setShowAlert={setShowAlert}
          loader={modalLoader}
          changeModalLoader={changeModalLoader}
          close={closeModalEdit}
          teacherData={teacherData}
          submitForm={submitForm}
          openOnSubmitModal={openOnSubmitModal} />
      )}
      {submitModal && (
        <TeachersModalOnSubmit close={closeOnSubmitModal} text={submitModal} />
      )}

      {isAlert.type && <Alert
        alertType={isAlert.type}
        text={isAlert.text}
        close={isAlert.close}
      />}

      {showAlert && <Alert
        alertType={false}
        text={'Oops, server error. Try again later'}
        close={() => { setShowAlert(false) }}
      />}
    </div>
  );
};