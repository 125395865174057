import {createPortal} from "react-dom";
import { LoaderFullBook } from "../../Loaders/LoaderFullBook/LoaderFullBook";

import styles from "./ModalWrapper.module.scss";

const modalRoot = document.getElementById("modal-root");

export const ModalWrapper = ({ children, loader = false }) => {
  return (
    createPortal(
      <div className={styles.backdrop}>
        <div className={styles.modal}>
          {children}
          {loader && <LoaderFullBook/>}
        </div>
      </div>,
      modalRoot
    )
   )
  }