import { forwardRef, useCallback, useState } from 'react';
import { Alert } from '../Alert/Alert';
import { IconButton } from '../buttons/IconButton/IconButton';
import { StyledTeacherInfoPopOver } from './StyledTeacherInfoPopOver';
import './TeacherInfoPopOver.scss';

export const TeacherInfoPopOver = forwardRef(({ teachers }, ref) => {
  const [isAlert, setIsAlert] = useState({
    type: false,
    text: '',
    close: () => { }
  });
  const closeAlert = useCallback(() => setIsAlert({
    type: false,
    text: '',
    close: () => { }
  }), []);
  const callAlert = useCallback((obj) => setIsAlert(obj), []);

  const copy = (value) => {
    navigator.clipboard.writeText(value);
    callAlert({ type: true, text: 'Copied!', close: closeAlert });
  };
  console.log(teachers);
  return (
    <div className="teacherInfoPopOver_wrapper" ref={ref}>
      {teachers.map((teacher, index) => {
        return (
          <div key={index}>
            <div className="teacherInfoPopOver_headerWrapper">
              <div className="teacherInfoPopOver_teacherPhotoWrapper">
                <StyledTeacherInfoPopOver
                  archived={teacher.isArchive}
                  bgUrl={teacher?.photoPath ? teacher.photoPath : teacher.photo}
                ></StyledTeacherInfoPopOver>
              </div>
              <div
                style={{ color: teacher.isArchive ? '#C0C0C0' : '#424242' }}
                className="teacherInfoPopOver_teacherName"
              >
                {teacher?.name ? teacher.name : `${teacher.firstName} ${teacher.lastName}`}
                {teacher?.isArchive && teacher.isArchive !== 0 ? (
                  <div style={{ color: '#A6192E', fontSize: '12px', lineHeight: '16.8px' }}>
                    ARCHIVED
                  </div>
                ) : null} 
              </div>
            </div>
            <div className="teacherInfoPopOver_teacherCopyDataWrapper">
              <div className="teacherInfoPopOver_averageDataWrapper">
                EMAIL:
                <span>{teacher.email}</span>
              </div>
              <div onClick={() => copy(teacher.email)}>
                <IconButton type={'3'} active={true} iconName={'ContentCopyTwoTone'} />
              </div>
            </div>
            <div className="teacherInfoPopOver_teacherCopyDataWrapper">
              <div className="teacherInfoPopOver_averageDataWrapper">
                PHONE:
                <span>{teacher.phone}</span>
              </div>
              <div onClick={() => copy(teacher.phone)}>
                <IconButton type={'3'} active={true} iconName={'ContentCopyTwoTone'} />
              </div>
            </div>
          </div>
        );
      })}
      {isAlert.type && <Alert alertType={isAlert.type} text={isAlert.text} close={isAlert.close} />}
    </div>
  );
});
