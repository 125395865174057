import styled from 'styled-components/macro';

export const StyledTableHeadLine = styled.div`
  .headline_speedTitle {
    color: ${(props) => props.speedColor};
  }
  .headline_scoreTitle {
    color: ${(props) => props.scoreColor};
    /* ${(props) => props.speedColor}; */
  }
`;
