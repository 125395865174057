import React from 'react';
import notFoundPageImage from "../../images/errorPage.svg";
import { InternalPageContainer } from '../../components/InternalPageContainer/InternalPageContainer';
import { StyledNotFoundPage } from "./StyledNotFoundPage"

function NotFoundPage({ text }) {
  return (
    <StyledNotFoundPage>
      <InternalPageContainer />
      <p className='NotFoundPageText'>
        {text ? text : 'Oops! Page not found. Please, try again later'}
      </p>
      <div className='NotFoundPageImgWrapper'>
        <img
          className="NotFoundPageImage"
          src={notFoundPageImage}
          width="405"
          height="388"
          alt="Not found page"
        />
      </div>
    </StyledNotFoundPage>
  );
}

export default NotFoundPage;
