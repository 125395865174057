export const routes = {
  signIn: () => '/sign-in',
  signUp: () => '/sign-up',
  analytics: () => '/analytics',
  gradebooks: () => '/gradebooks',
  tests: () => '/tests',
  students: () => '/students',
  teachers: () => '/teachers',
  archive: () => '/archive',
};
