import noTeachersImage from "../../../images/noTeachersImg.png"
import roundBGImg from "../../../images/roundBGImage.png"
import sprite from "../../../images/sprite.svg"
import { StyledNoTeachersPage } from "./StyledNoTeachersPage"
import { TableHeadLine } from "../../../components/TableHeadLine/TableHeadLine";

export const NoTeachersContent = ({ openModal }) => {

  return (
    <>
      <TableHeadLine
        searchValue={undefined}
        setSearchValue={undefined}
        handleOnClickDownloadCsv={undefined}
        openModal={openModal}
        btnTitle = {'noTeachers'}
        noTeachers={true}
      />
      <StyledNoTeachersPage>
        <p className='NoTeachersText'>You have no teachers yet.  Please, add teacher to continue</p>
        <div className='NoTeachersImgWrapper'>
          <img src={noTeachersImage} className="NoTeachersImage" alt="Not found" />
          <img src={roundBGImg} className="roundBGImg" alt="roundBGImg" />
          <svg className="IconArrow">
            <use href={sprite + '#icon-arrow'}></use>
          </svg>
        </div>
      </StyledNoTeachersPage>
    </>

  );
}



