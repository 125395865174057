import { forwardRef, useMemo } from 'react';
import { default as MUITextField, TextFieldProps } from '@mui/material/TextField';
import classNames from 'classnames';

import styles from './OutlinedTextField.module.scss';

type Props = Omit<TextFieldProps, 'variant'> & {
  fluid?: boolean;
};

export const OutlinedTextField = forwardRef<HTMLDivElement, Props>(
  ({ InputProps, InputLabelProps, className, fluid, ...props }, ref) => {

    const inputProps = useMemo(
      () => ({
        classes: {
          root: styles.inputRoot,
          focused: styles.inputRootFocused,
          notchedOutline: styles.inputRootNotchedOutline,
        },
        ...InputProps,
      }),
      [InputProps]
    );

    const inputLabelProps = useMemo(
      () => ({
        classes: {
          root: styles.labelRoot,
          focused: styles.labelRootFocused,
        },
        ...InputLabelProps,
      }),
      [InputLabelProps]
    );

    return (
      <MUITextField
        {...props}
        ref={ref}
        variant="outlined"
        className={classNames(styles.root, { [styles.fluid]: fluid }, className)}
        InputProps={inputProps}
        InputLabelProps={inputLabelProps}
      />
    );
  }
);
