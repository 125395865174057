import styles from './Button.module.scss';
import { FC } from 'react';
import classNames from 'classnames';

interface Props {
  onClick?: () => void;
  className?: string;
  typeBtn?: 'button' | 'submit' | 'reset' | undefined;
  disabled?: boolean;
}

export const Button: FC<Props> = ({ children, onClick, className, typeBtn = 'button', disabled }) => (
  <button
    type={typeBtn}
    onClick={onClick}
    disabled={disabled}
    className={classNames(styles.button, className)}>
    {children}
  </button>
);
