import styled from 'styled-components/macro';

export const StyledStudentDetailsSubTable = styled.div`
  max-height: ${(props) => (props.openStudentDetails ? '638px' : '0px')};
  background-color: yellow;
  overflow: hidden;
  transition: max-height 300ms ease-in-out;
  width: 1360px;
  margin: auto;
  box-shadow: 0px 1px 4px rgba(91, 91, 91, 0.15);

  .studentDetailsSubTable_footer {
    display: flex;
    height: 48px;
    color: #777777;
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 48px;
    margin-top: 6px;
    padding-left: 34px;
    /* background: #000; */
    span {
      display: block;
    }
    .studentDetailsSubTable_footer__avScore {
      color: ${(props) => props.studentInfo.avScore.color};
      margin-left: 245px;
    }
    .studentDetailsSubTable_footer__avSpeed {
      color: ${(props) => props.studentInfo.avSpeed.color};
      margin-left: 34px;
    }
  }
`;
