import { useState } from 'react';
import axios from 'axios';
import { useForm, Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { OutlinedTextField } from '../../../components/OutlinedTextField/OutlinedTextField';
import { LoaderFullBook } from '../../../components/Loaders/LoaderFullBook/LoaderFullBook';
import { PageContainer } from '../components/PageContainer/PageContainer';
import { MemoriaLogo } from '../components/MemoriaLogo/MemoriaLogo';
import { ContentHead } from '../components/ContentHead/ContentHead';
import { config } from '../../../config';
import styles from './RecoverPasswordPage.module.scss';

export const RecoverPasswordPage = () => {
  // const [showPassword, setShowPassword] = useState(false)

  const [showLoader, setShowLoader] = useState(false);
  const [successfulConfirmPassword, setSuccessfulConfirmPassword] = useState(false);
  // const handleClickShowPassword = () => {setShowPassword(prevState => !prevState) }
  // const handleMouseDownPassword = (event) => {event.preventDefault() };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const handleSendEditSatellite = async (values) => {
    setShowLoader(true);
    setSuccessfulConfirmPassword(true);
    // console.log('data.phone', values);
    // console.log('data.phone');

    try {
      const data = await axios.post(`${config.API_URL}/api/auth/recoveryPassword`, values);
      if (data.status === 200) {
        console.log('tableData', data.data)
        setShowLoader(false);
        // setSuccessfulConfirmPassword(true)
        // setSuccessfulAddClass(true)
        setSuccessfulConfirmPassword(true);
      }
    } catch (e) {
      // close()
      console.log(e);
      setShowLoader(false);
      // setSuccessfulAddClass(false)
    }
    
  };

  return (
    <>
     <PageContainer>
      <MemoriaLogo />
      <div className={styles.pageBody}>
          {showLoader && <LoaderFullBook />}
          <ContentHead />
              <div className={styles.root}>
                 <div className={styles.form}>
                     <div className={styles.inputContainer}>
            {!successfulConfirmPassword ? (
              <>
                    <div className={styles.recoverPasswordHeader}>
                  let’s reset your password
                </div>
                    <div className={styles.recover_password_header_tooltip}>
                  A confirmation will be sent to your registered email
                </div>
                <form onSubmit={handleSubmit((values) => handleSendEditSatellite(values))}>
                  <div style={{ position: 'relative' }} className="singInPage_textLabelWrapper">
                    <Controller
                      control={control}
                      name="email"
                      rules={{
                        required: true,
                        // pattern: /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/g,
                      }}
                      render={({ field }) => (
                        <OutlinedTextField
                          id="outlined-basic"
                          label="Email"
                          variant="outlined"
                          style={{ width: 302 }}
                          autoComplete="off"
                          name="email"
                          // field.onChange = ()
                          {...field}
                          error={Boolean(errors?.email)}
                        />
                      )}
                    />
                    {errors.email && (
                      <span className="singUp_errorInInput" style={{ color: '#DB4437' }}>
                        Please, enter valid e-mail
                        <ReportProblemOutlinedIcon style={{ cursor: 'default' }} />
                      </span>
                    )}
                  </div>
                       <div className={styles.actions}>
                        <Link
                          to="/sign-in"
                          className={styles.backToLoginBtn}
                        >
                          Back to login
                        </Link>
                          <button
                          type="submit"
                         className={styles.sendBtn}
                          onClick={handleSubmit}  
                          >
                          SEND
                          </button>
                      </div>
                </form>
              </>
            ) : (
              <div className={styles.recoverPassword_successWrapper}>
                <div className={styles.recoverPassword_successHeader}>The confirmation has been sent </div>
                <div className={styles.recoverPassword_successText}>Please, check your email. </div>
                  <Link to="/sign-in" style={{ textDecoration: 'none' }}>
                    <button className={styles.recoverPassword_successBtn}
                      style={{
                        marginLeft: "auto",
                        marginRight: "auto"
                      }}
                      type="button"
                     >
                     OK
                    </button>
                  </Link>
              </div>
            )}
          </div>
        </div>
          </div>
          </div>
              </PageContainer>
    </>
  );
};


