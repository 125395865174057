import React from 'react';
import NoTestsIndicator from './NoTestIndicator';

function NoTestsContent({ show, children }) {
  if (show) {
    return <NoTestsIndicator />;
  }
  return children;
}

export default NoTestsContent;
