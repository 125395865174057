import axios from 'axios';
import { useState, useEffect } from 'react';
import { GradeBookStudentRow } from '../GradeBookPage/GradebooksPageComponents/ClassGradeBook/GradeBookStudentRow/GradeBookStudentRow';
// import { ClassGradeBookHeader } from '../GradeBookPage/GradebooksPageComponents/ClassGradeBook';
import { StyledClassGradeBook } from '../ClassGradeBookPage/StyledClassGradeBook';
import { defaultResponseFromServer } from '../../GradeBookClassObjects';
import { GradebookSudentInfoCell } from '../GradeBookPage/GradebooksPageComponents/ClassGradeBook/GradebookSudentInfoCell/GradebookSudentInfoCell';
import { TableColorLegend } from '../../components/TableColorLegend/TableColorLegend';
// import ButtonText from '../../Components/buttons/buttonText/ButtonText.jsx'

import { LoaderFullBook } from '../../components/Loaders/LoaderFullBook/LoaderFullBook';
import './ClassArchivePage.scss';
import { InternalPageContainer } from '../../components/InternalPageContainer/InternalPageContainer';
import { TestTableHeader } from '../GradeBookPage/GradebooksPageComponents/ClassGradeBook/TestTableHeader/TestTableHeader';
import { ClassGradeBookPageHeader } from '../ClassGradeBookPage/ClassGradeBookPageHeader/ClassGradeBookPageHeader';
import { config } from '../../config';

export const ClassArchivePage = () => {
  const [showLoader, setShowLoader] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const classId = queryParams.get('class');
  const [classInfo, setClassInfo] = useState();
  const [unfilteredTests, setUnFilteredTests] = useState();

  const [tests, setTests] = useState();
  const [studentTests, setStudentTests] = useState();

  const [conceptFilter, setConceptFilter] = useState(null);
  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);

  const [searchValue, setSearchValue] = useState('');

  const filterBySearchValue = () => {
    const filter = [];
    if (unfilteredTests) {
      for (let i = 0; i < unfilteredTests.length; i++) {
        const testNameToloer = unfilteredTests[i].testName.toLowerCase();
        if (testNameToloer.includes(searchValue.toLowerCase())) {
          filter.push(unfilteredTests[i]);
        }
      }
      if (searchValue) {
        setTests(filter);
      } else {
        setTests(unfilteredTests);
      }
    }
  };
  const fetchToGetdata = async () => {
    setShowLoader(true);

    axios
      .post(`${config.API_URL}/api/gradebook/info`, {
        archive: 1,
        classId: classId,
        conceptFilter: conceptFilter,
        startDateFilter: startDateFilter,
        endDateFilter: endDateFilter,
      })
      .then(
        (response) => {
          setStudentTests(response.data.students);
          setTests(response.data.tests);
          setUnFilteredTests(response.data.tests);
          setClassInfo({
            tests: response.data.tests.length,
            score: response.data.score,
            speed: response.data.speed,
            grade: response.data.grade,
            year: response.data.year,
            className: response.data.className,
            classId: response.data.classId,
            teachers: response.data.teachers,
          });
          setShowLoader(false);
        },
        (error) => {
          console.log(error);
        }
      );
  };
  useEffect(() => {
    fetchToGetdata();
  }, [conceptFilter, endDateFilter]);

  useEffect(() => {
    filterBySearchValue();
  }, [searchValue]);

  const testOrder = tests ? tests.reduce((sum, currentId) => [...sum, currentId.testId], []) : [];

  const gridWidth = [];
  for (let i = 0; i < testOrder.length; i++) {
    gridWidth.push('262px');
  }

  // console.log('searchValue' , searchValue)

  return (<>
    <InternalPageContainer />
    <div className="classArchivePage_wrapper">
      {/* <h1>Hello world</h1> */}
      <>
        {showLoader && <LoaderFullBook />}

        {!tests || !classInfo ? (
          <div style={{ color: 'white' }} className="">
            loading...{' '}
          </div>
        ) : (
          <>
            <ClassGradeBookPageHeader
              archiveClass={true}
              conceptFilter={conceptFilter}
              setConceptFilter={setConceptFilter}
              startDateFilter={startDateFilter}
              setStartDateFilter={setStartDateFilter}
              endDateFilter={endDateFilter}
              setEndDateFilter={setEndDateFilter}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              classInfo={classInfo}
              studentsTest={studentTests}
              tableHeaderType={'archive'}
              tests={tests}
            // objectForEditClassModal={objectForEditClassModal}
            />

            <StyledClassGradeBook rowLength={testOrder} gridTemplate={gridWidth.join(' ')}>
              <div style={{ display: 'flex' }} className="">
                {/* first student column */}
                <div className="classGradeBook_gradeBook_studentsInfo_container">
                  <div className="classGradeBook_gradeBook_table_headerForStudent">
                    Students ({studentTests.length})
                  </div>
                  {studentTests.map((student, index) => {
                    return (
                      <GradebookSudentInfoCell
                        student={student}
                        // testOrder = {testOrder}
                        index={index}
                        key={index}
                      // key = {student.studentIdSchool}
                      />
                    );
                  })}
                </div>
                {/* secont test column */}
                <div className="classGradeBook_gradeBook_tablecontainer">
                  <div className="classGradeBook_gradeBook_table_header">
                    {tests.map((test) => {
                      return (
                        <TestTableHeader
                          test={test}
                          key={test.testId}
                          studentTests={studentTests}
                          fetchToGetdata={fetchToGetdata}
                        />
                      );
                    })}
                  </div>
                  <div className="classGradeBook_gradeBook_table_Wrapper">
                    <div className="classGradeBook_gradeBook_table_Body">
                      {studentTests.map((student, index) => {
                        return (
                          <GradeBookStudentRow
                            student={student}
                            testOrder={testOrder}
                            index={index}
                            key={student.studentIdSchool}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              {/* average data column */}
              <div className="classGradeBook_gradeBook_averageDataColumn">
                <div className="classGradeBook_gradeBook_averageDataColumn_header">Average</div>
                <div className="classGradeBook_gradeBook_averageDataColumn_allTestsWrapper">
                  {tests.map((test) => {
                    // console.log('test', test)
                    return (
                      <div className="classGradeBook_gradeBook_averageDataColumn_testData">
                        {test.testAvScoreInClass ? (
                          <div
                            className="classGradeBook_gradeBook_averageDataColumn_testScore"
                            style={{
                              color: test.testAvScoreInClass ? test.testAvScoreInClass.color : null,
                            }}
                          >
                            {test.testAvScoreInClass.score.toFixed(0)}
                          </div>
                        ) : (
                          <div className="classGradeBook_gradeBook_averageDataColumn_testScore"></div>
                        )}
                        {test.testAvSpeedInClass ? (
                          <div
                            className="classGradeBook_gradeBook_averageDataColumn_testSpeed"
                            style={{ color: test.testAvSpeedInClass.color }}
                          >
                            {test.testAvSpeedInClass.speed}
                          </div>
                        ) : (
                          <div
                            style={{ color: '#828282' }}
                            className="classGradeBook_gradeBook_averageDataColumn_testSpeed"
                          >
                            NO DATA
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </StyledClassGradeBook>
            <div className="classGradeBook_gradeBook_colorLegendWrapper">
              <TableColorLegend />
            </div>
          </>
        )}
      </>
    </div>
  </>
  );
};
