import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { ProfilePopUp } from "../HeaderProfilePopup/ProfilePopUp";

const HeaderProfilePopover = ({ open, anchorEl, handleClose, handleEditProfileModalOpen}) => {
  return (
     <Popover
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
      anchorEl={anchorEl}
      elevation={2}
          >
            <Typography component={'span'} variant={'body2'}>
             <ProfilePopUp handleEditProfileModalOpen={handleEditProfileModalOpen}/>
            </Typography>
          </Popover>
  );
}

export default HeaderProfilePopover;