import styled from 'styled-components/macro';

import { StyledTableRow } from '../TableRow/StyledTableRow';

// import StyledTableRow from '../TableRow/StyledTableRow';

// props {checkBox, columns}
export const StyledTableHead = styled(StyledTableRow)`
  box-sizing: border-box;
  /* width: 1360px; */
  height: 48px;
  font-size: 1.4rem;
  /* border: 1px solid black; */
  margin: auto;
  /* display: flex; */
  margin: auto;
  /* padding-left: 22px; */
  padding-right: 0px;
  color: #777777;
  box-shadow: unset;
  /* background-color: blue; */
`;
