import { useState, useEffect } from 'react';
import { TableCell } from '../TableCell/TableCell';
import { StyledTableHead } from './StyledTableHead';
import CheckBoxTwoToneIcon from '@mui/icons-material/CheckBoxTwoTone';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import './TableHead.scss';

// const tableHeaderFields = [
//     {
//         fieldsFor: 'childrens',
//         chekBox: true,
//         fieldForName: 'Name',
//         fieldForClaass: 'Class',
//         fieldForAvScore: 'Av. Score, %',
//         fieldForAvSpeed: 'Av. Speed',
//         fieldForParents: 'Parents',
//         actions: true

//     },
//     {
//         fieldsFor: 'teachers',
//         chekBox: true,
//         fieldForName: 'Name',
//         fieldForAvScore: 'Av. Score, %',
//         fieldForAvSpeed: 'Av. Speed',
//         fieldForClaass: 'Classes',
//         actions: true
//     },
//     {
//         fieldsFor: 'tests',
//         chekBox: true,
//         fieldForLable: 'Label',
//         fieldForAvScore: 'Av. Score, %',
//         fieldForAvSpeed: 'Av. Speed',
//         fieldForClaass: 'Classes',
//         actions: true
//     },

// ]
//

// const tableHeadForChildren = [
//     {
//         fieldName: 'checkBox',
//         width: '20px',
//         sortByLetters: false,
//         sortByNum: false,
//     },
//     {
//         fieldName: 'Name',
//         width:'300px',
//         sortByLetters: true,
//         sortByNum: false
//     },
//     {
//         fieldName: 'ID',
//         width: '96px',
//         sortByLetters: false,
//         sortByNum: true
//     },
//     {
//         fieldName: 'Class',
//         width: '41px',
//         sortByLetters: false,
//         sortByNum: false
//     },
//     {
//         fieldName: 'Av. score, %',
//         width: '103px',
//         sortByNum: true,
//         sortByLetters: false
//     },
//     {
//         fieldName: 'Av. speed',
//         width: '118px',
//         sortByNum: true,
//         sortByLetters: false
//     },
//     {
//         fieldName: 'Parents',
//         width: '400px',
//         sortByLetters: false,
//         sortByNum: false
//     },
//     {
//         fieldName:'Actions',
//         width: '105px',
//         sortByLetters: false,
//         sortByNum: false
//     }
// ]
// const tableHeadForTeachers = [
//     {
//         fieldName: 'checkBox',
//         width: '20px',
//         sortByLetters: false,
//         sortByNum: false,
//     },
//     {
//         fieldName: 'Photo',
//         width: '40px',
//         sortByLetters: false,
//         sortByNum: false
//     },
//     {
//         fieldName: 'Name',
//         width:'240px',
//         sortByLetters: true,
//         sortByNum: false
//     },
//     {
//         fieldName: 'Av. score, %',
//         width: '103px',
//         sortByNum: true,
//         sortByLetters: false
//     },
//     {
//         fieldName: 'Speed',
//         width: '118px',
//         sortByNum: true,
//         sortByLetters: false
//     },
//     {
//         fieldName: 'Classes',
//         width: '277px',
//         sortByLetters: false,
//         sortByNum: false
//     },
//     {
//         fieldName:'Actions',
//         width: '25px',
//         sortByLetters: false,
//         sortByNum: false
//     }
// ]

// {tableType = tableHeaderFields[0] }

export const TableHead = ({
  selectedTeacherType,
  selectedStudentType,
  typeTableHead,
  sortDirection,
  sortName,
  scoreSortDir = 0,
  statusSortDir = 0,
  typeTableRow,
  isSelectAll,
  sortByLetter = () => { },
  sortByScoreFunc = () => { },
  sortByStatusFunc = () => { },
  forPage,
  letterSortDir = 0,
  selectAll = () => { },
  selectedTeacher = null,
  selectedStudent = null }) => {
  const [checkBox, setCheckBox] = useState(false);
  // fieldName:'Actions',

  useEffect(() => {
    if (selectedStudent) {
      if (selectedStudent.length === 0) {
        setCheckBox(false);
      }
    } else if (selectedTeacher) {
      if (selectedTeacher.length === 0) {
        setCheckBox(false);
      }
    }

    if (!isSelectAll) {
      setCheckBox(false);
    }

  }, [selectedTeacher, selectedStudent, isSelectAll])

  return (
    <StyledTableHead typeTableRow={typeTableRow}>
      {typeTableHead.map(({ fieldName, width, sortByLetters, sortByScore, sortByStatus, functionality = () => { } }, index) => {
        if (fieldName === 'checkBox' && forPage === 'analytics') return <div key={index}></div>;
        if (fieldName === 'checkBox') return (
          <div
            key={index}
            className='checkBox'
            style={{
              cursor: selectedTeacherType === 1 || selectedStudentType === 1 ? 'not-allowed' : 'pointer'
            }}>
            <div
              style={{
                pointerEvents: selectedTeacherType === 1 || selectedStudentType === 1 ? 'none' : 'auto'
              }}
              onClick={() => { setCheckBox(!checkBox); selectAll(checkBox) }}>
              {checkBox ? <CheckBoxTwoToneIcon style={{ color: '#323232' }} /> 
                : < CheckBoxOutlineBlankIcon style={{ color: '#828282' }} />}
            </div>
          </div>
        );
        if (fieldName === 'Actions' && forPage === 'analytics') return;

        return (
          <TableCell key={index}>
            {fieldName}
            {sortByLetters && (
              <div
                className={`
                letterBtnSort 
                ${letterSortDir !== 0 ? (letterSortDir === 1 ? 'sortUp' : 'sortDown') : ''}
                ${sortName === 'Name' ? (sortDirection ? 'sortUp' : 'sortDown') : ''}
                `}
                onClick={() => {
                  sortByLetter();
                  functionality()
                }}>
                <ArrowDropUpIcon />
                <div>AZ</div>
                <ArrowDropDownIcon />
              </div>
            )}
            {sortByScore && (
              <>
                <div
                  className={`
                  unfoldBtnSort 
                  ${scoreSortDir !== 0 ? (scoreSortDir === 1 ? 'sortUp' : 'sortDown') : ''}
                  ${sortName === 'AvScore' ? (sortDirection ? 'sortUp' : 'sortDown') : ''}
                  `}
                  onClick={() => { sortByScoreFunc(); functionality() }}>
                  <ExpandLessIcon fontSize="large" />
                  <ExpandMoreIcon fontSize="large" />
                </div>
              </>
            )}
            {sortByStatus && (
              <>
                <div
                  className={`
                  unfoldBtnSort 
                  ${statusSortDir !== 0 ? (statusSortDir === 1 ? 'sortUp' : 'sortDown') : ''}
                  ${sortName === 'AvSpeed' ? (sortDirection ? 'sortUp' : 'sortDown') : ''}
                  `}
                  onClick={() => { sortByStatusFunc(); functionality() }}>
                  <ExpandLessIcon fontSize="large" />
                  <ExpandMoreIcon fontSize="large" />
                </div>
              </>
            )}
          </TableCell>
        );
      })}
    </StyledTableHead>
  );
};
