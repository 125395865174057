import React, { useCallback, useEffect, useState } from 'react';
import { convertSecondsToString } from '../../../../model';
import ErrorBoundary from '../../../ErrorBaundary';
import TestsPageTeacherInfo from '../../TestsPageTeacherInfo';
import CheckboxTwoTone from '../../../CheckboxTwoTone';
import LightTooltip from '../../../LightTooltip';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import UnarchiveTwoToneIcon from '@mui/icons-material/UnarchiveTwoTone';
import EditTestModal from '../../TestModals/EditTestModal';
import './TestRow.scss';

function TestRow({
  test,
  selectedTests,
  setSelectedTests,
  testReadOnly,
  notArchivedTests,
  setDisabledSelectAll,
  setTestForUnarchiving,
  openUnarchiveModal,
  setRerender,
}) {
  const [editTestModal, setEditTestModal] = useState(false);

  const {
    id, label, isArchive, concept, status,
    teachers, score, speed, classes,
  } = test;

  const openEditTestModal = useCallback(() => setEditTestModal(true), []);
  const closeEditTestModal = useCallback(() => setEditTestModal(false), []);

  // for checkbox disabled
  useEffect(() => {
    if (!isArchive && testReadOnly) {
      setDisabledSelectAll(true);
    }
    if (!isArchive && !testReadOnly
      && notArchivedTests.length > 0) {
      setDisabledSelectAll(false);
    }
    if (isArchive && !testReadOnly
      && notArchivedTests.length === 0) {
      setDisabledSelectAll(true);
    }
    if (isArchive && !testReadOnly
      && notArchivedTests.length !== 0) {
      setDisabledSelectAll(false);
    }
  }, [
    isArchive,
    notArchivedTests.length,
    setDisabledSelectAll,
    testReadOnly
  ]);

  // for checkbox selected
  let isSelect = false;
  if (selectedTests.includes(test)) {
    isSelect = true;
  }

  const deleteSelectedTest = (activeClassId) => {
    const dataToSetAsSelectedTests = [...selectedTests];
    const index = dataToSetAsSelectedTests.indexOf(activeClassId);
    if (index > -1) {
      dataToSetAsSelectedTests.splice(index, 1);
    }
    setSelectedTests(dataToSetAsSelectedTests);
  };

  const onChangeCheckbox = () => (isSelect) ?
    deleteSelectedTest(test) :
    setSelectedTests((prevState) => [...prevState, test]);

  // LOGIC MOVED FROM COMPONENT RETURN VALUE
  const testLabel = isArchive ? (
    <span className="value">
      {label}
      <span className="isArchive">
        Archived
      </span>
    </span>)
    : (<span className="value">{label}</span>);

  const conceptInfo = (
    <span className="value">
      {concept ?
        `${concept[0].toUpperCase() + concept.slice(1).toLowerCase()}`
        : '-'}
    </span>
  );

  const statusInfo = (
    <span className="value">
      {status ?
        `${status[0].toUpperCase() + status.slice(1).toLowerCase()}`
        : '-'}
    </span>
  );

  const teachersInfo = (
    <ErrorBoundary>
      {(teachers.length > 0) ?
        <TestsPageTeacherInfo teachers={teachers} /> :
        <span className="value noTeachers">-</span>}
    </ErrorBoundary>
  );

  const scoreInfo = (
    <span className="value" style={{ color: (score.color !== null) ? score.color : '' }}>
      {(score.score === null) ? '-' : score.score.toFixed(1)}
    </span>
  );

  const speedInfo = (
    <span className="value" style={{ color: (speed.color !== null) ? speed.color : '' }}>
      {(speed.speed === null) ? '-' : convertSecondsToString(speed.speed)}
    </span>
  );

  const classesInfo = (
    <span className="value">
      {(classes.length > 0) ?
        (classes.map(({ grade, className }) =>
          `${grade}${className}`).join(', ')) : '-'}
    </span>
  );

  const actionsInfo = isArchive ? (
    <LightTooltip title="Unarchive" placement="top">
      <span className="value action">
        <UnarchiveTwoToneIcon onClick={() => {
          setTestForUnarchiving([id]);
          openUnarchiveModal();
        }} />
      </span>
    </LightTooltip>) : (
    <LightTooltip title="Edit" placement="top">
      <span className="value action">
        <EditTwoToneIcon onClick={openEditTestModal} />
      </span>
    </LightTooltip>);

  // for correct styles
  const stylesForRow = isSelect ? 'testRow checked' : 'testRow';
  const stylesForCheckbox = `tableData checkbox ${testReadOnly ? 'disabled' : ''}`;

  return (
    <tr className={stylesForRow}>
      <ErrorBoundary>
        <td className={stylesForCheckbox}>
          <CheckboxTwoTone
            disabled={testReadOnly}
            checked={isSelect}
            onChange={onChangeCheckbox}
          />
        </td>
        <td className="tableData label">{testLabel}</td>
        <td className="tableData concept">{conceptInfo}</td>
        <td className="tableData status">{statusInfo}</td>
        <td className="tableData teachers">{teachersInfo}</td>
        <td className="tableData score">{scoreInfo}</td>
        <td className="tableData speed">{speedInfo}</td>
        <td className="tableData classes">{classesInfo}</td>
        <td className="tableData actions">{actionsInfo}</td>
      </ErrorBoundary>
      {editTestModal &&
        <EditTestModal
          editData={test}
          closeModal={closeEditTestModal}
          callRerender={setRerender}
        />}
    </tr >
  );
}

export default TestRow;
