import { FC } from 'react';
import DatePicker from 'react-datepicker';
import { OutlinedTextField } from '../OutlinedTextField/OutlinedTextField';
import DateRangeIcon from '@material-ui/icons/DateRange';
import './DateTextField.scss';

type NullableDate = Date | null;

const dateIcon = <DateRangeIcon style={{color: '#777'}}/>;

interface Props {
  value?: NullableDate;
  onChange: (date: NullableDate) => void;
  inputLabel?: string;
  className?: string;
  register?: any;
  fieldRef?: any;
}

export const DateTextField: FC<Props> = ({ value, onChange, inputLabel, className, register, fieldRef }) => (
  <DatePicker
    customInput={
      <OutlinedTextField
        {...register('birthDate', {
          required: 'Required Field',
        })}
        fullWidth
        inputRef={fieldRef}
        label={inputLabel}
        InputProps={{
          endAdornment: dateIcon,
        }}
      />
    }
    selected={value}
    onChange={onChange}
    shouldCloseOnSelect={true}
    className={className}
    popperClassName={'popper'}
  />
);
