import { useState } from 'react';
import { useForm, Controller} from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { LoaderFullBook } from '../../../components/Loaders/LoaderFullBook/LoaderFullBook';
import { ErrorPopover } from '../../../components/ErrorPopover/ErrorPopover';
import { PageContainer } from '../components/PageContainer/PageContainer';
import { MemoriaLogo } from '../components/MemoriaLogo/MemoriaLogo';
import { ContentHead } from '../components/ContentHead/ContentHead';
import { OutlinedTextField } from '../../../components/OutlinedTextField/OutlinedTextField';
import { config } from '../../../config';
import styles from './CreateNewPasswordPage.module.scss';

export const CreateNewPasswordPage = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [errorInPassword, setErrorInPassword] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // for password error tooltip oped
  const [iconAnchorEl, setIconAnchorEl] = useState(null);
  const openTestInfo = Boolean(iconAnchorEl);
  const handleOpenInfoTest = (event) => setIconAnchorEl(event.currentTarget);
  const handleCloseInfoTeacher = () => setIconAnchorEl(null);

  // openConfirmPassword
  const [iconAnchorElConfirm, setIconAnchorElConfirm] = useState(null);
  const openConfirmPassword = Boolean(iconAnchorElConfirm);
  const handleOpenConfirmPassword = (event) => setIconAnchorElConfirm(event.currentTarget);
  const handleCloseConfirmPassword = () => setIconAnchorElConfirm(null);
  const loc = useLocation();
  const history = useHistory();

  
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const handleSendEditSatellite = async (values) => {
    // setShowLoader(true)
    if (values.confirmNewPassword !== values.newPassword) {
      setErrorInPassword(true);
      return;
    }
    if (values.confirmNewPassword === values.newPassword) {
      setErrorInPassword(false);
      // return
    }
    // setShowLoader(true);
    // console.log('data.phone', values);
    // console.log('data.phone');

    try {
      const token = loc.search.split('token=')[1];
      const body = {
        password: values.newPassword,
        token
      }

      const data = await axios.post(`${config.API_URL}/api/auth/recoverLogin`, body);
      if (data.status === 200) {
        setShowLoader(false);
        history.push('/analytics');
        // setSuccessfulConfirmPassword(true)
        // setSuccessfulAddClass(true)
      }
    } catch (e) {
      // close()
      console.log(e);
      setShowLoader(false);
      // setSuccessfulAddClass(false)
    }
    // setSuccessfulConfirmPassword(true)
  };

  return (
      <PageContainer>
      <MemoriaLogo />
      <div className={styles.pageBody}>
          {showLoader && <LoaderFullBook />}
          <ContentHead />
            <div className={styles.root}>
              <div className={styles.form}>
                <div className={styles.inputContainer}>
                  <div className={styles.createPasswordHeader}>logIn to your account</div>
                     <div className={styles.createPasswordText}>Please, create new password </div>
                      <form onSubmit={handleSubmit((values) => handleSendEditSatellite(values))}>
                          <div className={styles.createPassword_textLabelWrapper}>
                            <div className={styles.createPassword_controller_wrapper}>
                            <Controller
                              control={control}
                              name="newPassword"
                              rules={{
                                required: true,
                                pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,255}$/,
                              }}
                              render={({ field }) => (
                                <OutlinedTextField
                                  id="outlined-basic"
                                  label="New password"
                                  variant="outlined"
                                  style={{ width: 302 }}
                                  type={showPassword ? 'text' : 'password'}
                                  autoComplete="off"
                                  name="newPassword"
                                  {...field}
                                  error={Boolean(errors?.newPassword)}
                                  InputProps={{
                                    // <-- This is where the toggle button is added.
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowPassword}
                                          onMouseDown={handleMouseDownPassword}
                                        >
                                          {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              )}
                            />
                            {errors.newPassword && (
                                <span
                                  className={styles.createPassword_errorInInput}
                                  style={{ color: '#DB4437' }}
                                >
                                Incorrect Password
                        <InfoOutlinedIcon
                          onClick={(e) => handleOpenInfoTest(e)}
                          style={{ cursor: 'default', marginLeft: 5, marginRight: 5,  }}
                        />
                                <div style={{ position: 'relative' }} className="">
                                  <Popover
                                    open={openTestInfo}
                                    onClose={handleCloseInfoTeacher}
                                    // anchorReference="anchorPosition"
                                    // anchorPosition={{ top: 370, left: 750 }}
                                    anchorOrigin={{
                                      vertical: 'center',
                                      horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                      vertical: 'center',
                                      horizontal: 'left',
                                    }}
                                    // anchorPosition={{ top: -100, left: 100 }}
                                    anchorEl={iconAnchorEl}
                                     elevation={2}
                                  >
                                    {/* <Typography style={{marginLeft: 30}} component={'span'} variant={'body2'}> */}
                                    <ErrorPopover
                                      header="Incorrect Password"
                                      subheader="Password requirements:"
                                      lines={[
                                        'At least 8 characters,',
                                        'A mixture of both uppercase and lowercase letters,',
                                        'A mixture of letters and numbers,',
                                        'Inclusion of at least one special character, e.g., ! @ # ? ]',
                                      ]}
                                      warning="Note: do not use < or > in your password, as both can cause problems in Web browsers."
                                    />
                                    {/* </Typography> */}
                                  </Popover>
                                </div>
                              </span>
                            )}
                           </div>
                  
                          {/* <div style={{ height: 24 }} className=""></div> */}
                            <div className={styles.createPassword_controller_wrapper}>
                            <Controller
                              control={control}
                              name="confirmNewPassword"
                              rules={{
                                required: true,
                                pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,255}$/,
                              }}
                              // pattern: /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/g
                              render={({ field }) => (
                                <OutlinedTextField
                                  variant="outlined"
                                  margin="normal"
                                  style={{ width: 302 }}
                                  name="confirmNewPassword"
                                  label="Confirm new password"
                                  type={showPassword ? 'text' : 'password'} // <- magic
                                  id="confirmNewPassword"
                                  autoComplete="off"
                                  // onChange={()=>{ setErrorInPassword(false) }}
                                  {...field}
                                  error={Boolean(errors?.confirmNewPassword)}
                                  InputProps={{
                                    // <-- This is where the toggle button is added.
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowPassword}
                                          onMouseDown={handleMouseDownPassword}
                                        >
                                          {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              )}
                            />
                            {errors.confirmNewPassword && (
                                <span className={styles.createPassword_errorInInputConfirm}
                                  style={{ color: '#DB4437' }}
                                >
                                Incorrect Password
                        <InfoOutlinedIcon
                          onClick={(e) => handleOpenConfirmPassword(e)}
                          style={{ cursor: 'default', marginLeft: 5, marginRight: 5,  }}
                        />
                                <Popover
                                  open={openConfirmPassword}
                                  onClose={handleCloseConfirmPassword}
                                  // anchorReference="anchorPosition"
                                  //     anchorPosition={{ top: 370, left: 750 }}
                                  anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'right',
                                  }}
                                  transformOrigin={{
                                    vertical: 'center',
                                    horizontal: 'left',
                                  }}
                                  anchorEl={iconAnchorElConfirm}
                                   elevation={2}
                                >
                                  <Typography style={{ marginLeft: 30 }} component={'span'} variant={'body2'}>
                                    <ErrorPopover
                                      header={'Incorrect Password '}
                                      subHeader={'Password requirements:'}
                                      tooltipText={[
                                        'At least 8 characters,',
                                        'A mixture of both uppercase and lowercase letters,',
                                        'A mixture of letters and numbers,',
                                        'Inclusion of at least one special character, e.g., ! @ # ? ]',
                                      ]}
                                      redWarning={
                                        'Note: do not use < or > in your password, as both can cause problems in Web browsers.'
                                      }
                                    />
                                  </Typography>
                                </Popover>
                              </span>
                            )}
                            {errorInPassword && !errors.confirmNewPassword && (
                                <span className={styles.createPassword_errorInInput}
                                  style={{ color: '#DB4437' }}
                                >
                                Password confirmation does not match
                                <ReportProblemOutlinedIcon />
                              </span>
                            )}
                          </div>
                        </div>
                            <button type="submit" className={styles.loginBtn}>
                              LOGIN
                          {/* <Link to="/students" style={{ textDecoration: 'none' , color: "#ffffff"}}>
                              LOGIN
                          </Link> */}
                            </button>
                      </form>
                  </div>
                </div>
             </div>
          </div>
      </PageContainer>
  );
};
