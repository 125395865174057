import React from 'react';
import ArchiveNoDataContent from './ArchiveNoDataContent';

function ArchiveNoData(props) {
  const { show = false, children } = props;

  if(show) {
    return <ArchiveNoDataContent />;
  }

  return children;
}

export default ArchiveNoData;
