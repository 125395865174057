import styled from 'styled-components';

export const SubHeaderCollapseStyled = styled.div`
  height: 48px;
  background-color: rgba(192, 192, 192, 1);
  .SubHeaderCollapseWrapper {
    max-width: 1360px;
    margin-left: auto;
    margin-right: auto;
    height: 48px;
    display: flex;
    justify-content: space-between;
  }
  .SubHeaderCollapseInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
  }
  .SubHeaderCollapseBtnWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .SubHeaderCollapseBtnCancel {
    margin-right: 16px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 17px;
    width: 141px;
    text-align: end;
    background-color: transparent;
    text-transform: uppercase;
    cursor: pointer;
    outline: none;
    border: none;
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: #ffffff;

    & svg{
      width: 16px;
      height: 16px;
    }

    &.black {
      color: #000000;
    }
    &:hover{
      color: #F2F2F2;
    }
  }

  .SubHeaderCollapseBtn {
    padding: 0;
    padding-right: 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 17px;
    text-align: end;
    background-color: transparent;
    text-transform: uppercase;
    outline: none;
    border: none;
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: #424242;
    cursor: pointer;

    &:hover{
      color: #5B5B5B;
    }

    & svg{
      width: 16px;
      height: 16px;
    }
  }
`;
