import styled from 'styled-components';

export const ExportButtonStyled = styled.div`
  margin-right: 16px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg{
    height: 16px;
    width: 16px;
  }

  & a{
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;

    &.default{
      color: rgba(192, 192, 192, 1);

      &:hover{
       color: #777777;
      }
    }

    &.white{
      color: #fff;

      &:hover{
       color: #F2F2F2;
      }
    }

    
  }

  .ExportBtn {
    padding: 0;
    width: 100px;
    text-align: end;
    background-color: transparent;
    text-transform: uppercase;
    cursor: pointer;
    outline: none;
    border: none;
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
  }
`;
