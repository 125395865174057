import React, { useCallback, useEffect, useState } from 'react';
import { ModalPrimaryButton } from "../../Modal/buttons/ModalPrimaryButton/ModalPrimaryButton";
import { ModalSecondaryButton } from "../../Modal/buttons/ModalSecondaryButton/ModalSecondaryButton";
import { ModalContainer } from "../../Modal/ModalContainer/ModalContainer";
import { ModalFooter } from "../../Modal/ModalFooter/ModalFooter";
import { ModalHeader } from "../../Modal/ModalHeader/ModalHeader";
import { ModalWrapper } from "../../Modal/ModalWrapper/ModalWrapper";
import { Alert } from '../../Alert/Alert';

import styles from "./ArchivePageModalUnarchive.module.scss";

import { ArchiveService } from '../../../pages/ArchivePage/ArchiveService';
const service = new ArchiveService();

function ArchivePageModalUnarchive({
  openArchivePage = () => { },
  unarchive = [],
  close,
  callRender = () => { },
  setActiveClasses = () => { },
}) {
  const [loader, setLoader] = useState(false);
  const [isAlert, setIsAlert] = useState({
    type: false,
    text: '',
    close: () => { },
    show: false,
  });
  const callAlert = useCallback((obj) =>
    setIsAlert(obj), []);
  const closeAlert = useCallback(() => setIsAlert({
    type: false,
    text: '',
    close: () => { },
    show: false,
  }), []);

  const delayedClosure = useCallback(() =>
    setTimeout(() =>
      close(), 2900), [close]);

  const unarchiveClasses = useCallback(() => {
    setLoader(true);
    service.removeFromArchive(unarchive)
      .then(() => {
        setLoader(false);
        setActiveClasses([]);
        callRender(true);
        callAlert({
          type: true,
          text: 'Operation Success!',
          close: closeAlert,
          show: true,
        });
        delayedClosure();
        openArchivePage();
      })
      .catch(() => {
        setLoader(false);
        callAlert({
          type: false,
          text: 'Ooops, server error.'
            + ' Please, contact the admin of the website.',
          close: closeAlert,
          show: true,
        });
        callRender(false);
      });
  }, [
    callAlert,
    callRender,
    closeAlert,
    delayedClosure,
    setActiveClasses,
    unarchive,
  ]);

  useEffect(() => {
    return () => {
      clearTimeout(delayedClosure);
    }
  }, [delayedClosure]);

  return (
    <>
      <ModalWrapper loader={loader}>
        <ModalContainer>
          <ModalHeader
            header="Unarchive Class(es)"
            onClose={close}
            color='darkHeader' />
          <div className={styles.text}>
            Are you sure you want to unarchive
            <span className={styles.subText}> selected class(es)?</span>
          </div>
          <ModalFooter>
            <ModalSecondaryButton
              onClick={close}
            >
              Cancel
            </ModalSecondaryButton>
            <ModalPrimaryButton
              classStyle={'dark'}
              onClick={unarchiveClasses}
            >
              Unarchive
            </ModalPrimaryButton>
          </ModalFooter>
        </ModalContainer>
      </ModalWrapper>
      {isAlert.show &&
        <Alert
          alertType={isAlert.type}
          text={isAlert.text}
          close={isAlert.close}
        />}
    </>
  )
}

export default ArchivePageModalUnarchive;
