import { ButtonPrimary } from '../../../../../../components/buttons/buttonPrimary/ButtonPrimary';
import { ModalContainer } from '../../../../../../components/Modal/ModalContainer/ModalContainer';
import { ModalHeader } from '../../../../../../components/Modal/ModalHeader/ModalHeader';
import { ModalWrapper } from '../../../../../../components/Modal/ModalWrapper/ModalWrapper';

import './SuccessfulAddClass.scss';

export const SuccessfulAddClass = ({
  successfulAddClass,
  close,
  loader,
  loadDataForGradeBook,
  setSuccessfulAddClass,
}) => {
  return (
    <>
      {!successfulAddClass ? (
          <ModalWrapper loader={loader}>
          <ModalContainer>
          <ModalHeader header="Upload Error" color="darkHeader"  onClose={close} />
          <div className="successfulAddClass_error_tip">
            Something went wrong. Please try again later.
          </div>
          <div className="successfulAddClass_error_buttonWrapper">
            <ButtonPrimary
              status={'ok'}
              active={true}
              functionality={() => {
                close();
                setSuccessfulAddClass(null);
              }}
              />
          </div>
              </ModalContainer>
            </ModalWrapper>
      ) : (
        <ModalWrapper loader={loader}>
          <ModalContainer>
              <ModalHeader 
                header="Class Successfully Added"
                onClose={() => {
                  close()
                  loadDataForGradeBook();
                }} />
          <div className="successfulAddClass_error_buttonWrapper">
            <ButtonPrimary
              status={'okRed'}
              active={true}
              functionality={() => {
                close();
                loadDataForGradeBook();
                setSuccessfulAddClass(null);
              }}
              />
          </div>
              </ModalContainer>
            </ModalWrapper>
      )}
    </>
  );
};
