import './TableColorLegend.scss';

export const TableColorLegend = () => {
  return (
    <div className="tableColorLegend_wrapper">
      <div className="tableColorLegend_scoreWrapper">
        <span>SCORE</span>
        <div className="tableColorLegend_colorBlock blueLegend"></div>
        <span className="tableColorLegend_blueText">90%+ ACCURACY</span>
        <div className="tableColorLegend_colorBlock greenLegend"></div>
        <span className="tableColorLegend_greenText">80 - 89% ACCURACY</span>
        <div className="tableColorLegend_colorBlock yellowLegend"></div>
        <span className="tableColorLegend_yellowText">50 - 79% ACCURACY</span>
        <div className="tableColorLegend_colorBlock redLegend"></div>
        <span className="tableColorLegend_redText">BELOW 50% ACCURACY</span>
      </div>
      <div className="tableColorLegend_speedWrapper">
        <span>SPEED</span>
        <div className="tableColorLegend_colorBlock blueLegend"></div>
        <span className="tableColorLegend_blueText">ABOVE EXPECTED</span>
        <div className="tableColorLegend_colorBlock greenLegend"></div>
        <span className="tableColorLegend_greenText">AS EXPECTED</span>
        <div className="tableColorLegend_colorBlock redLegend"></div>
        <span className="tableColorLegend_redText">BELOW EXPECTED</span>
      </div>
    </div>
  );
};
