  export const tableHeadForTeachers = [
    {
      fieldName: 'checkBox',
      width: '16px',
      sortByLetters: false,
      sortByScore: false,
      sortByStatus: false,
    },
    {
      fieldName: 'Photo',
      width: '40px',
      sortByLetters: false,
      sortByScore: false,
      sortByStatus: false,
    },
    {
      fieldName: 'Name',
      width: '300px',
      sortByLetters: true,
      sortByScore: false,
      sortByStatus: false,
    },
    {
      fieldName: 'Av. score, %',
      width: '100px',
      sortByLetters: false,
      sortByScore: true,
      sortByStatus: false,
    },
    {
      fieldName: 'Av. Speed',
      width: '112px',
      sortByLetters: false,
      sortByScore: false,
      sortByStatus: true,
    },
    {
      fieldName: 'Phone',
      width: '170px',
      sortByScore: false,
      sortByStatus: false,
      sortByLetters: false,
    },
    {
      fieldName: 'Email',
      width: '200px',
      sortByScore: false,
      sortByStatus: false,
      sortByLetters: false,
    },
    {
      fieldName: 'Classes',
      width: '88px',
      sortByLetters: false,
      sortByScore: false,
      sortByStatus: false,
    },
    {
      fieldName: 'Actions',
      width: '122px',
      sortByLetters: false,
      sortByScore: false,
      sortByStatus: false,
    },
  ];
  
export const typeTableRow = 'teachers';