
import { createPortal } from "react-dom";
import { ModalPrimaryButton } from "../../../../components/Modal/buttons/ModalPrimaryButton/ModalPrimaryButton";
import { ModalSecondaryButton } from "../../../../components/Modal/buttons/ModalSecondaryButton/ModalSecondaryButton";
import { ModalContainer } from "../../../../components/Modal/ModalContainer/ModalContainer";
import { ModalFooter } from "../../../../components/Modal/ModalFooter/ModalFooter";
import { ModalHeader } from "../../../../components/Modal/ModalHeader/ModalHeader";
import { TeachersFormFields } from "../TeachersFormFields/TeachersFormFields";
import { useState } from 'react'

import { ModalWrapper } from "../../../../components/Modal/ModalWrapper/ModalWrapper";
import { Alert } from "../../../../components/Alert/Alert";

const TeachersModal = ({
  close,
  submitForm,
  openOnSubmitModal,
  loader,
  setShowAlert,
  changeModalLoader }) => {
  return (
    <>
      <ModalWrapper loader={loader}>
        <ModalContainer>
          <ModalHeader header="Add New Teacher" onClose={close} />
          <TeachersFormFields
            setShowAlert={setShowAlert}
            changeModalLoader={changeModalLoader}
            close={close}
            submitForm={submitForm}
            openOnSubmitModal={openOnSubmitModal} />
        </ModalContainer>
      </ModalWrapper>
    </>
  )
}

export default TeachersModal;