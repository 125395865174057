import { getFileCSVarchive } from './getFileCSVarchive';

export const getBodyForFileCSV = (selectedData) => {
  const headers = [
    { label: 'Year', key: 'year' },
    { label: 'Class', key: 'class' },
    { label: 'Av.Score', key: 'avScore' },
    { label: 'Av.Speed', key: 'avSpeed' },
    { label: 'Teachers ( email | phone )', key: 'teachers' },
  ];
  const data = getFileCSVarchive(selectedData);

  return {
    filename: 'Report.csv',
    headers: headers,
    data: data,
  };
};
