import React, {useState, useContext} from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { ModalWrapper } from '../../Modal/ModalWrapper/ModalWrapper';
import { ModalFooter } from '../../Modal/ModalFooter/ModalFooter';
import { ModalSecondaryButton } from '../../Modal/buttons/ModalSecondaryButton/ModalSecondaryButton';
import { ModalPrimaryButton } from '../../Modal/buttons/ModalPrimaryButton/ModalPrimaryButton';
import { LoaderFullBook } from '../../Loaders/LoaderFullBook/LoaderFullBook';
import { AppContext } from '../../../context/AppContext';
import { config } from "../../../config"
import ContainEditProfile from "./ContainEditProfile/ContainEditProfile"
import ContainChangePassword from "./ContainChangePassword/ContainChangePassword"
import styles from "./EditProfileModal.module.scss"
// import { FileDownloadSharp } from '@mui/icons-material';


const EditProfileModal = ({ onClose }) => {
  const {user, updateUser, uploadedPhoto, getUserData, showLoader, setShowLoader} = useContext(AppContext);

  const [requestAddress, setRequestAddress] = useState("/api/user");
  const [errorInConfirm, setErrorInConfirm] = useState(false);
  const [formData, setFormData] = useState({ ...user })
  const [formDataPassword, setFormDataPassword] = useState({
    password: '',
    newPassword: '',
    confirmNewPassword: '',
  })
  // console.log('formData', formData)
  
  const [step, setStep] = useState(1);
  const prevStep = (e) => {
    e.preventDefault();
    setRequestAddress("/api/user")
    setStep((prevStep) => prevStep - 1);
  };
  const nextStep = (e) => {
    e.preventDefault();
    setRequestAddress("/api/auth/password")
    setStep((prevStep) => prevStep + 1);
  };

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onBlur',
  });
 
  const onSubmit = async (values) => {
    // console.log('onSubmit-formData', formData)
    // console.log('onSubmit-errors', errors)
    // console.log('onSubmit-values', values)
    // console.log('values.firstName', values.firstName)
    // if () {
    //   alert('Name is required field.');
    //   return;
    // }
    if (errors?.firstName?.message || errors?.lastName?.message) {
      alert('Name & Lastname are required fields.');
      return;
    }
    // if (!formData.email) {
    //   alert("Enter your email.");
    // return;
    // }
    if (!formData.email || errors?.email?.message) {
      alert('Incorrect email.');
      return;
    }
    if (errors?.phone?.message) {
      alert('Incorrect phone format.');
      return;
    }
    const objToSend = {
      photo: uploadedPhoto ?? '', // IN BASE64
      firstName: formData.firstName,
      lastName: formData.lastName,
      phone: formData.phone ?? '',
      email: formData.email,
      id: user.id,
    };
    
    const passwordDataToSend = {
      password: formDataPassword.password,
      newPassword: formDataPassword.newPassword,
    };

    if (requestAddress === "/api/user") {
      if (objToSend.firstName === "" || objToSend.lastName === "") {
        alert("Name & LastName are required fields.");
        return
      }
      try {
        setShowLoader(true);
        const response = await axios.patch(`${config.API_URL}${requestAddress}`, objToSend);
        // console.log('response', response)
        if (response.status === 200) {
          updateUser({
            ...user,
            photo: objToSend.photo,
            firstName: objToSend.firstName,
            lastName: objToSend.lastName,
            phone: objToSend.phone,
            email: objToSend.email,
          })
          setShowLoader(false);
        }
      } catch (e) {
        console.log(e);
        setShowLoader(false);
      }
    }

    if (requestAddress === "/api/auth/password") {
      // console.log('requestAddress - /api/auth/password')
      // console.log('formDataPassword.newPassword', formDataPassword.newPassword)
      // console.log('formDataPassword.confirmNewPassword', formDataPassword.confirmNewPassword)
      if (formDataPassword.newPassword !== formDataPassword.confirmNewPassword) {
        setErrorInConfirm(true);
        return;
        }
      try {
        setShowLoader(true);
        const response = await axios.patch(`${config.API_URL}/api/auth/password`, passwordDataToSend);
        if (response.status === 200) {
          setShowLoader(false);
        }
      } catch (e) {
        alert("Current password incorrect. Try again.")
        setShowLoader(false);
      }
    }

    getUserData();
    onClose();
  }

  const onInputChange = ({ target }) => {
    const {name, value} = target
    setFormData((prevState)=>({ ...prevState, [name]: value }));
  }

  const onPasswordChange = ({ target }) => {
    const {name, value} = target
    setFormDataPassword((prevState)=>({ ...prevState, [name]: value }));
  }
  
  return (
    <ModalWrapper>
      {showLoader && <LoaderFullBook />}
      <form
        className={styles.EditProfileModal_wrapper}
        onSubmit={handleSubmit((values) => onSubmit(values))}
        // onSubmit={handleSubmit(onSubmit)}
      >
        {step === 1 && (
          <ContainEditProfile
            onClose={onClose}
            nextStep={nextStep}
            control={control}
            errors={errors}
            formData={formData}
            register={register}
            onChange={onInputChange}
          />
        )}
        {(step === 2 && formData.authType === 0) && (
          <ContainChangePassword
            onClose={onClose}
            control={control}
            errors={errors}
            prevStep={prevStep}
            onChange={onPasswordChange}
            errorInConfirm={errorInConfirm}
            setErrorInConfirm={setErrorInConfirm}
          />
        )}
        <ModalFooter>
          <ModalSecondaryButton onClick={onClose}>Cancel</ModalSecondaryButton>
          <ModalPrimaryButton onClick={onSubmit}>Save Changes</ModalPrimaryButton>
        </ModalFooter>
      </form>
    </ModalWrapper>
  );
}

export default EditProfileModal;