import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './TopNavigation.module.scss';
import { routes } from '../../../routes';

const navLinks = [
  {
    text: 'Analytics',
    to: '/analytics',
  },
  {
    text: 'Gradebooks',
    to: '/gradebooks',
  },
  {
    text: 'Tests',
    to: '/tests',
  },
  {
    text: 'Students',
    to: routes.students(),
  },
  {
    text: 'Teachers',
    to: routes.teachers(),
  },
  {
    text: 'Archive',
    to: 'archive',
  },
];

export const TopNavigation: FC = () => (
  <nav className={styles.root}>
    {navLinks.map((link) => (
      <NavLink
        key={link.to}
        to={link.to}
        className={styles.navLink}
        activeClassName={styles.activeNavLink}
        data-label={link.text}
      >
        <span className={styles.navLinkLabel}>{link.text}</span>
      </NavLink>
    ))}
  </nav>
);
