import { FC } from 'react';
import { Button } from '../../../Button/Button';
import classNames from 'classnames';
import styles from './ModalButton.module.scss';

interface Props {
  className?: string;
  onClick?: () => void;
  typeBtn?: 'button' | 'submit' | 'reset' | undefined;
  disabled?: boolean;
}

export const ModalButton: FC<Props> = ({ children, className, onClick, typeBtn, disabled }) => (
  <Button
    typeBtn={typeBtn}
    disabled={disabled}
    onClick={onClick}
    className={classNames(styles.button, className)}>
    {children}
  </Button>
);
