import './LoaderFullBook.scss';

export const LoaderFullBook = () => {
  return (
    <>
      <div className="loaderFullBook_backDrop">
        <div className='loader Wrapper'>
          <div className="loader book">
            <figure className="page"></figure>
            <figure className="page"></figure>
            <figure className="page"></figure>
          </div>

          <h1>Loading</h1>
        </div>
      </div>
    </>
  );
};
