import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { SchoolInfoPopover } from './SchoolInfoPopover/SchoolInfoPopover';
import { ReactComponent as PlusIcon } from './plus-icon.svg';
import { LoaderFullBook } from '../Loaders/LoaderFullBook/LoaderFullBook';
import { AppContext } from '../../context/AppContext';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AddSchoolModal from './SchoolModal/AddSchoolModal/AddSchoolModal';
import EditSchoolModal from './SchoolModal/EditSchoolModal/EditSchoolModal';
import RemoveSchoolModal from './SchoolModal/RemoveSchoolModal/RemoveSchoolModal';
// import { getAllSchools } from '../../services/schools';
import classNames from 'classnames';
import styles from './SchoolSelect.module.scss';
import { Alert } from '../Alert/Alert';


export const SchoolSelect = () => {
  const {
    showLoader,
    setShowLoader,
    schools = [],
    updateSchools,
    currentSchoolId,
    setCurrentSchoolId,
    updateCurrentSchoolId,
    getSchoolsData,
    updateSchoolName,
    currentSchool,
    setUpdateSchoolInfo,
  } = useContext(AppContext);
  // const [showLoader, setShowLoader] = useState(false)
  
  // const [showAlert, setShowAlert] = useState(false);
  const [isAlert, setIsAlert] = useState({
    type: false,
    text: '',
    close: () => {},
    show: false,
  });

  const callAlert = useCallback((obj) => setIsAlert(obj), []);
  const closeAlert = useCallback(
    () =>
    setIsAlert({
      type: false,
      text: '',
      close: () => {},
      show: false,
    }),
    []
    );
    
    const [addSchoolModal, setAddSchoolModal] = useState(false);
    const [editSchoolModal, setEditSchoolModal] = useState(false);
    const [removeSchoolModal, setRemoveSchoolModal] = useState(false);
    
    // const currentSchool = useMemo(
      //   () => schools.find(({ Id }) => Id === currentSchoolId),
      //   [currentSchoolId]
      // );
      const [mouseOverSchoolId, setMouseOverSchoolId] = useState(null);

  const mouseOverSchool = schools.find((school) => school.Id === mouseOverSchoolId)
  // const mouseOverSchool = useMemo(
  //   () => schools.find((school) => school.Id === mouseOverSchoolId),
  //   [mouseOverSchoolId]
  // );

  // console.log('currentSchool', currentSchool);
  // console.log('currentSchoolId', currentSchoolId);
  // console.log('mouseOverSchool', mouseOverSchool);
  // console.log('mouseOverSchoolId', mouseOverSchoolId);

  const handleSchoolMouseOver = useCallback((schoolId) => () => setMouseOverSchoolId(schoolId), []);
  const handleSchoolMouseOut = useCallback(() => setMouseOverSchoolId(null), []);

  // const handleChooseSchool = useCallback((schoolId) => () => setCurrentSchoolId(schoolId),[]);

  const handleChooseSchool = (schoolId) => () => {
    schools.find(({ Id }) => Id === schoolId);
    setCurrentSchoolId(schoolId);
    localStorage.setItem('currentSchoolId', JSON.stringify(schoolId));
  };

  // === ADD SCHOOL ===
  const handleAddSchoolModal = () => setAddSchoolModal(true);
  const handleAddSchoolModalClose = () => setAddSchoolModal(false);

  // === EDIT SCHOOL ===
  // const handleEditSchoolModal = useCallback(() => setEditSchoolModal(true), []);
  const handleEditSchoolModal = () => {
    setEditSchoolModal(true);
    setCurrentSchoolId(mouseOverSchoolId);
    localStorage.setItem('currentSchoolId', JSON.stringify(mouseOverSchoolId));
  };
  const handleEditSchoolModalClose = () => {
    setEditSchoolModal(false);
    setMouseOverSchoolId(null);
  };

  // === REMOVE SCHOOL ===
  // const handleRemoveSchoolModal = useCallback(() => {
  //   setRemoveSchoolModal(true)
  //   setCurrentSchoolId(mouseOverSchoolId)
  //   , []
  // });
  const handleRemoveSchoolModal = () => {
    setRemoveSchoolModal(true);
    setCurrentSchoolId(mouseOverSchoolId);
  };
  const handleRemoveSchoolModalClose = () => {
    setRemoveSchoolModal(false);
    setMouseOverSchoolId(null);
  };

  return (
    <>
      {showLoader && <LoaderFullBook />}
      <div
        id="SchoolSelectContainer"
        className={styles.container}
        onMouseLeave={handleSchoolMouseOut}
      >
        <div className={styles.currentSchoolName}>
          {currentSchool?.Name ? currentSchool.Name : schools[0]?.Name}
        </div>

        <div className={styles.optionsContainer}>
          <div
            className={classNames(styles.option, styles.addSchoolOption)}
            onClick={() => handleAddSchoolModal()}
          >
            <PlusIcon />
            <span className={styles.addSchoolOptionLabel}>add new</span>
          </div>

          {schools.map(({ Id = 1, Name }) => (
            <div
              key={Id}
              onClick={handleChooseSchool(Id)}
              onMouseOver={handleSchoolMouseOver(Id)}
              className={styles.option}
            >
              {Name}
            </div>
          ))}
        </div>

        <ArrowDropDownIcon color="disabled" fontSize="large" className={styles.arrowDown} />

        {mouseOverSchool && (
          <SchoolInfoPopover
            school={mouseOverSchool}
            handleEditSchool={handleEditSchoolModal}
            handleRemoveSchool={handleRemoveSchoolModal}
          />
        )}
      </div>
      {addSchoolModal && (
        <AddSchoolModal
          onClose={handleAddSchoolModalClose}
          schools={schools}
          updateSchools={updateSchools}
          setShowLoader={setShowLoader}
          //  setShowAlert={setShowAlert}
          callAlert={callAlert}
          closeAlert={closeAlert}
        />
      )}
      {editSchoolModal && (
        <EditSchoolModal
          onClose={handleEditSchoolModalClose}
          currentSchool={currentSchool}
          schools={schools}
          updateSchools={updateSchools}
          setShowLoader={setShowLoader}
          setCurrentSchoolId={setCurrentSchoolId}
          mouseOverSchoolId={mouseOverSchoolId}
          setUpdateSchoolInfo={setUpdateSchoolInfo}
        />
      )}
      {removeSchoolModal && (
        <RemoveSchoolModal
          onClose={handleRemoveSchoolModalClose}
          currentSchool={currentSchool}
          setCurrentSchoolId={setCurrentSchoolId}
          schools={schools}
          updateSchools={updateSchools}
          setShowLoader={setShowLoader}
        />
      )}

      {isAlert.type && <Alert alertType={isAlert.type} text={isAlert.text} close={isAlert.close} />}
    </>
  );
};


