import { FC, ChangeEvent as ReactChangeEvent, useCallback } from 'react';

import SearchIcon from '@material-ui/icons/Search';

import styles from './InputSearch.module.scss';

interface Props {
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
}

export const InputSearch: FC<Props> = ({ placeholder, value, onChange }) => {
  const onValueChange = useCallback(
    (event: ReactChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value);
    },
    [onChange]
  );

  return (
    <div className={styles.container}>
      <input
        className={styles.input}
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onValueChange}
      />
      <SearchIcon className={styles.icon} />
    </div>
  );
};
